import {
  GetCurrentUserQuery,
  GetCurrentUserQueryVariables,
  GetOrderByIdQuery,
  Maybe,
  PackageInput,
  Parcel,
  ParcelInput,
} from '../../../../../generated/graphql';
import { QUERY_CURRENT_USER } from '../../../../../GraphQL/queries/getCurrentUser';
import { ApolloClient } from '@apollo/client';
import { SnackbarMessage, OptionsObject, SnackbarKey } from 'notistack';
import { History } from 'history';
import { mutateCreateType } from '../index';
import { match } from 'react-router';
import { ParcelInputUpdate } from './mutateCreate';

type ParcelUpdate = {
  declaredWeight: number;
  declaredAmount: number;
  vatKey: string;
  packages: Maybe<PackageInput>[];
};

type createNewParcelType = {
  isFetchCalculated: boolean;
  enqueueSnackbar: (
    message: SnackbarMessage,
    options?: OptionsObject | undefined,
  ) => SnackbarKey;
  setLoading: (value: boolean) => void;
  values: Parcel & ParcelUpdate;
  declaredWeight: number;
  declaredAmount: number;
  pickPackages: (from: Maybe<PackageInput>[]) => PackageInput[];
  history: History;
  shipmentSchemaDraft: any;
  mutateCreate: (value: mutateCreateType) => Promise<unknown>;
  urlSearchParams: URLSearchParams;
  formValue: any;
  globalProductCode: string;
  isCreation: match<{}> | null;
  isCopying: match<{}> | null;
  createParcelMutation: any;
  setSavedParcelResponseId: React.Dispatch<React.SetStateAction<string | null>>;
  id: string | undefined;
  setSubmitting: any;
  client: ApolloClient<object>;
  addEtsyTrackNumberMutation: any;
  orderByIdData?: GetOrderByIdQuery;
  isVatInformation: boolean;
};

export const createNewParcel = async ({
  isFetchCalculated,
  enqueueSnackbar,
  setLoading,
  values,
  declaredWeight,
  declaredAmount,
  pickPackages,
  history,
  shipmentSchemaDraft,
  mutateCreate,
  urlSearchParams,
  formValue,
  globalProductCode,
  isCreation,
  isCopying,
  createParcelMutation,
  setSavedParcelResponseId,
  id,
  setSubmitting,
  client,
  addEtsyTrackNumberMutation,
  orderByIdData,
  isVatInformation,
}: createNewParcelType) => {
  if (!isFetchCalculated) {
    enqueueSnackbar('Нужно рассчитать стоимость', { variant: 'error' });
    return;
  }

  setLoading(true);
  values.declaredWeight = declaredWeight;
  values.declaredAmount = declaredAmount;

  // it's not Draft
  mutateCreate({
    data: values as unknown as ParcelInput & ParcelInputUpdate,
    urlSearchParams,
    formValue,
    globalProductCode,
    isCreation,
    isCopying,
    createParcelMutation,
    setSavedParcelResponseId,
    id,
    setSubmitting,
    enqueueSnackbar,
    setLoading,
    addEtsyTrackNumberMutation,
    orderByIdData,
    isVatInformation,
  })
    .then(
      (result: any) =>
        typeof result === 'string' &&
        enqueueSnackbar(result, { variant: 'success' }),
    )
    .finally(() => {
      // Refetch user balance
      client
        .query<GetCurrentUserQuery, GetCurrentUserQueryVariables>({
          query: QUERY_CURRENT_USER,
          fetchPolicy: 'network-only',
        })
        .catch((error: any) =>
          enqueueSnackbar(error.message, { variant: 'error' }),
        );
    });
};
