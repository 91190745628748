import React, { useEffect, useState } from 'react';
import {
  Button,
  CircularProgress,
  Divider,
  Grid,
  TextField,
  Tooltip,
  Typography,
} from '@material-ui/core';
import CreditCardIcon from '@material-ui/icons/CreditCard';
import CropFreeRoundedIcon from '@material-ui/icons/CropFreeRounded';
import yookassaImg from '../../assets/img/payments/ykassa.png';
import sqpImg from '../../assets/img/payments/sqp.png';
import { Else, If, Then, When } from 'react-if';
import useStyles from './styles';
import { useSnackbar } from 'notistack';
import { ShowLoadingText } from '../../utils/helperComponents';
import { useParams } from 'react-router-dom';
import {
  Transaction,
  useCreatePaymentMutation,
  useGetCurrentUserQuery,
  useGetPaymentHistoryListByUserIdLazyQuery,
  useGetConfigurationListQuery,
  useCreateQrPaymentMutation,
} from '../../generated/graphql';
import PaymentsTable from '../../components/PaymentsTable/PaymentsTable';
import { useDebouncedCallback } from 'use-debounce';
import { LIMIT_ITEM_PAGE } from '../../utils/helpers';
import ModalBox from '../../components/ModalBox/ModalBox';
import { useTranslation } from 'react-i18next';

export default function PaymentsHistory() {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const { t } = useTranslation();

  const params = useParams<{ parcelAmountParam?: string }>();
  const [paymentValue, setPaymentValue] = useState(
    params.parcelAmountParam && +params.parcelAmountParam
      ? params.parcelAmountParam
      : '0',
  );

  const [qrCode, setQrCode] = useState('');
  const [isOpenChangePayment, setIsOpenChangePayment] = useState(false);
  const [isOpenQRCode, setIsOpenQRCode] = useState(false);
  const [isYokassaPaymentMethod, setIsYokassaPaymentMethod] = useState(true);

  const { methodPaymentValue } = useComission(
    paymentValue,
    isYokassaPaymentMethod,
  );

  const [debt, setDebt] = useState(0);
  const [balance, setBalance] = useState(0);
  const [toPay, setToPay] = useState(0);
  const [isReplenish, setIsReplenish] = useState(
    !!(params.parcelAmountParam && +params.parcelAmountParam),
  );
  const [filter, setFilter] = useState({
    page: 1,
  });

  const [createPayment, { loading: isLoadingCreatePayment }] =
    useCreatePaymentMutation();
  const [createQRPayment, { loading: isLoadingCreateQRPayment }] =
    useCreateQrPaymentMutation();

  const {
    data: dataCurrentUser,
    loading: isLoadingCurrentUser,
    error: errorCurrentUser,
  } = useGetCurrentUserQuery({
    fetchPolicy: 'network-only',
  });

  const [
    getPaymentsLazy,
    { loading: isLoadingPayments, error: errorPayments, data: dataPayments },
  ] = useGetPaymentHistoryListByUserIdLazyQuery({
    fetchPolicy: 'network-only',
  });

  useEffect(() => {
    if (
      dataCurrentUser &&
      dataCurrentUser.currentUser &&
      dataCurrentUser.currentUser.balance
    ) {
      const balance = dataCurrentUser.currentUser.balance;
      if (balance < 0) setDebt(balance);
      if (balance >= 0) setBalance(balance);
      if (balance < 0) setToPay(balance);
    }
  }, [dataCurrentUser]);

  useEffect(() => {
    refetch();
    // eslint-disable-next-line
  }, [filter]);

  const refetch = useDebouncedCallback(() => {
    getPaymentsLazy({
      variables: {
        offset: (filter.page - 1) * LIMIT_ITEM_PAGE,
        limit: LIMIT_ITEM_PAGE,
      },
    });
  });

  useEffect(() => {
    getPaymentsLazy();
    // eslint-disable-next-line
  }, []);

  if (errorCurrentUser?.message)
    enqueueSnackbar(errorCurrentUser.message, { variant: 'error' });
  if (errorPayments?.message)
    enqueueSnackbar(errorPayments.message, { variant: 'error' });

  const handleCreatePayment = (isYokassaPaymentMethod: boolean) => {
    isYokassaPaymentMethod
      ? createPayment({
          variables: {
            value: paymentValue,
          },
        })
          .then((response) => {
            if (response?.data?.createPayment) {
              window.location.href = response?.data?.createPayment;
            } else {
              enqueueSnackbar(t('app.unableToGetConfirmationURL'), {
                variant: 'error',
              });
            }
          })
          .catch((err) => {
            console.error(err);
          })
      : createQRPayment({
          variables: {
            value: +paymentValue,
          },
        })
          .then((response) => {
            if (response?.data?.createQRPayment) {
              setQrCode(response.data.createQRPayment);
              setIsOpenQRCode(true);
            } else {
              enqueueSnackbar(t('app.unableToGetQRCode'), {
                variant: 'error',
              });
            }
          })
          .catch((err) => {
            console.error(err);
          });
  };

  return (
    <>
      <Grid container spacing={2} justify='center'>
        <Grid item xs={12} sm={12}>
          <Typography variant='h2' className={classes.mainTitle} align='center'>
            {t('app.balanceAndPayment').toUpperCase()}
          </Typography>
          <Divider />
        </Grid>
      </Grid>

      <If condition={isLoadingPayments || isLoadingCurrentUser}>
        <Then>
          <ShowLoadingText name={t('app.paymentHistory')} />
        </Then>
        <Else>
          <Grid container spacing={4} justify='center'>
            <Grid item xs={12} sm={6}>
              <Grid container spacing={4} justify='center'>
                <Grid item xs={12}>
                  <Typography variant='h6' className={classes.mainTitle}>
                    {t('app.balanceAndPayment')}
                  </Typography>
                </Grid>
                <Grid item xs={12} className={classes.balanceInfo}>
                  <span>{t('app.debt')}:</span>
                  <span className={classes.divider} />
                  <span>
                    {debt} <span title={t('app.russianRuble')}>₽</span>
                  </span>
                </Grid>
                <Grid item xs={12} className={classes.balanceInfo}>
                  <span>{t('app.onAccount')}:</span>
                  <span className={classes.divider} />
                  <span>
                    {balance} <span title={t('app.russianRuble')}>₽</span>
                  </span>
                </Grid>
                <Grid item xs={12} className={classes.balanceInfo}>
                  <span>{t('app.toPay')}:</span>
                  <span className={classes.divider} />
                  <span>
                    {toPay && +toPay ? (
                      <Tooltip title={t('app.clickToEnterAmount')}>
                        <span
                          style={{ cursor: 'pointer' }}
                          onClick={(e) => {
                            setPaymentValue(Math.abs(toPay).toString());
                            enqueueSnackbar(t('app.amountEnteredNowRefill'));
                          }}
                        >
                          {toPay}
                        </span>
                      </Tooltip>
                    ) : (
                      toPay
                    )}
                    <span title={t('app.russianRuble')}>₽</span>
                  </span>
                </Grid>
                <Grid item xs={12} className={classes.buttonWrapper}>
                  <Button
                    variant='contained'
                    classes={{ root: classes.replenish }}
                    onClick={() => setIsReplenish(true)}
                  >
                    <CreditCardIcon /> {t('app.refill')}
                  </Button>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={12} sm={6}>
              <When condition={isReplenish}>
                <Grid container spacing={4} justify='center'>
                  <Grid item xs={12}>
                    <Typography variant='h6' className={classes.mainTitle}>
                      {t('app.refillAccount')}
                    </Typography>
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    className={classes.payWrapper}
                    style={{ paddingBottom: '6px', paddingTop: '6px' }}
                  >
                    {isYokassaPaymentMethod ? (
                      <img
                        src={yookassaImg}
                        style={{
                          width: '130px',
                          height: '30px',
                          marginBottom: '10px',
                          marginTop: '10px',
                        }}
                        alt='payment_methods'
                      />
                    ) : (
                      <img
                        src={sqpImg}
                        style={{ width: '100px', height: '50px' }}
                        alt='payment_methods'
                      />
                    )}
                    <Button
                      className={classes.changePaymentButton}
                      onClick={() => {
                        setIsOpenChangePayment(true);
                      }}
                      style={{ height: '40px' }}
                    >
                      {t('app.changePaymentMethod')}
                    </Button>
                  </Grid>
                  <Grid item xs={12} className={classes.payWrapper}>
                    <span>{t('app.amount')}:</span>
                    <TextField
                      id='outlined-number'
                      classes={{ root: classes.payInput }}
                      size='small'
                      type='number'
                      value={paymentValue}
                      InputProps={{ inputProps: { min: 0, step: 10 } }}
                      variant='outlined'
                      onChange={(e) => setPaymentValue(e.target.value)}
                    />
                  </Grid>
                  <Grid item xs={12} className={classes.payWrapper}>
                    <span>{t('app.amountIncludingCommission')}:</span>
                    <TextField
                      classes={{ root: classes.payInput }}
                      size='small'
                      value={methodPaymentValue}
                      InputProps={{ inputProps: { min: 0, step: 10 } }}
                      variant='outlined'
                      disabled
                    />
                  </Grid>
                  <Grid
                    item
                    xs={12}
                    className={classes.buttonWrapper}
                    style={{ paddingTop: '26px' }}
                  >
                    <Button
                      variant='contained'
                      classes={{ root: classes.pay }}
                      disabled={!paymentValue || paymentValue === '0'}
                      onClick={() => {
                        handleCreatePayment(isYokassaPaymentMethod);
                      }}
                      startIcon={
                        isLoadingCreatePayment || isLoadingCreateQRPayment ? (
                          <CircularProgress size={22} />
                        ) : isYokassaPaymentMethod ? (
                          <CreditCardIcon />
                        ) : (
                          <CropFreeRoundedIcon />
                        )
                      }
                    >
                      {t('app.pay')}
                    </Button>
                  </Grid>
                </Grid>
              </When>
            </Grid>
            <Typography variant='h6' className={classes.mainTitle}>
              {t('app.transactionHistory')}
            </Typography>
            <PaymentsTable
              transactions={
                (dataPayments?.getPaymentHistoryListByUserId
                  ?.rows as Transaction[]) || []
              }
              count={dataPayments?.getPaymentHistoryListByUserId?.count || 1}
              page={filter.page}
              setFilter={setFilter}
            />
          </Grid>
        </Else>
      </If>
      <ModalBox isOpen={isOpenChangePayment} setOpen={setIsOpenChangePayment}>
        <Grid item xs={12} className={classes.payWrapper}>
          <img
            className={classes.imagePayment}
            onClick={() => {
              setIsOpenChangePayment(false);
              setIsYokassaPaymentMethod(true);
            }}
            src={yookassaImg}
            style={{
              width: '260px',
              height: '70px',
              marginRight: '40px',
            }}
            alt='payment_methods'
          />
          <img
            className={classes.imagePayment}
            onClick={() => {
              setIsOpenChangePayment(false);
              setIsYokassaPaymentMethod(false);
            }}
            src={sqpImg}
            style={{ width: '223px', height: '112px' }}
            alt='payment_methods'
          />
        </Grid>
      </ModalBox>
      <ModalBox isOpen={isOpenQRCode} setOpen={setIsOpenQRCode}>
        <span dangerouslySetInnerHTML={{ __html: `${qrCode}` }} />
      </ModalBox>
    </>
  );
}

const useComission = (paymentValue: any, isYokassaPayment: boolean) => {
  const { data: dataConfiguration } = useGetConfigurationListQuery();

  const [commission, setCommission] = useState(1);
  const [methodPaymentValue, setMethodPaymentValue] = useState(0.0);

  useEffect(() => {
    if (!dataConfiguration?.getConfigurationList?.commissionYookassa) return;
    isYokassaPayment
      ? setCommission(
          dataConfiguration?.getConfigurationList?.commissionYookassa,
        )
      : setCommission(dataConfiguration?.getConfigurationList?.commissionSQP);
  }, [dataConfiguration, isYokassaPayment]);

  useEffect(() => {
    if (!parseFloat(paymentValue)) return;
    setMethodPaymentValue(
      (parseFloat(paymentValue as any) * commission).toFixed(2) as any,
    );
  }, [paymentValue, commission, setMethodPaymentValue]);

  return { commission, methodPaymentValue };
};
