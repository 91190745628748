import React from 'react';
import {
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import FormContact from '../../../components/FormContact/FormContact';

import useStyles from './styles';
import { GetContactsQuery, Parcel } from '../../../generated/graphql';
import {
  TFormikSetFieldValue,
  TSetStateBoolean,
  TSetStateNumber,
} from '../../../interfaces';
import { useTranslation } from 'react-i18next';

const ShipmentsFormStep1: React.FC<{
  values: Parcel;
  contactsData: GetContactsQuery | undefined;
  setFieldValue: TFormikSetFieldValue;
  updateView: number;
  setUpdateView: TSetStateNumber;
  outerIsLoadingValidateAddress: boolean;
  outerIsMelissaValidated: boolean;
  outerSetIsMelissaValidated: TSetStateBoolean;
  outerSetIsLoadingValidateAddress: TSetStateBoolean;
}> = ({
  values,
  contactsData,
  setFieldValue,
  updateView,
  setUpdateView,
  outerIsLoadingValidateAddress,
  outerIsMelissaValidated,
  outerSetIsLoadingValidateAddress,
  outerSetIsMelissaValidated,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  const theme = useTheme();
  const hidden = useMediaQuery(theme.breakpoints.down('xs'));

  const contactsDataSenders = contactsData?.contacts?.filter(
    /* prettier-ignore */
    //@ts-ignore
    (contact) => contact?.type === "SENDER",
  );

  const contactsDataRecipients = contactsData?.contacts?.filter(
    /* prettier-ignore */
    //@ts-ignore
    (contact) => contact?.type === "RECIPIENT",
  );

  return (
    <div className={classes.wrapper}>
      <div className={classes.selectContactsWrap}>
        <FormControl className={classes.selectSenderContactsControl}>
          <InputLabel shrink={false} htmlFor='eori-input'>
            {t('app.chooseFromAddressBook')}
          </InputLabel>
          <Select
            className={classes.selectContacts}
            disableUnderline
            value={values.sender.id || ''}
            onChange={(e) => {
              const contact = contactsDataSenders?.find(
                (item) => item && item.id === e.target.value,
              );
              if (contact) {
                const contactCopy = { ...contact };
                delete contactCopy.contactName;
                setFieldValue('sender', contactCopy);
                setUpdateView((updateView) => ++updateView);
              }
            }}
            displayEmpty
          >
            {contactsDataSenders?.map((contact) =>
              contact ? (
                <MenuItem key={contact.id} value={contact.id}>
                  {contact.contactName}
                </MenuItem>
              ) : null,
            )}
          </Select>
        </FormControl>
        <FormControl className={classes.selectReceiverContactsControl}>
          <InputLabel shrink={false} htmlFor='eori-input'>
            {t('app.chooseFromAddressBook')}
          </InputLabel>
          <Select
            className={classes.selectContacts}
            disableUnderline
            value={values.receiver.id || ''}
            onChange={(e) => {
              const contact = contactsDataRecipients?.find(
                (item) => item && item.id === e.target.value,
              );
              if (contact) {
                const contactCopy = { ...contact };
                delete contactCopy.contactName;
                setFieldValue('receiver', contactCopy);
                setUpdateView((updateView) => ++updateView);
              }
            }}
            displayEmpty
          >
            {contactsDataRecipients?.map((contact) =>
              contact ? (
                <MenuItem key={contact.id} value={contact.id}>
                  {contact.contactName}
                </MenuItem>
              ) : null,
            )}
          </Select>
        </FormControl>
      </div>
      <Grid container justify='space-between'>
        <Grid item xs={12} sm className={classes.contactFields}>
          <Typography className={classes.contactFieldsTitle} variant='h3'>
            {t('app.sender')}
          </Typography>
          <FormContact
            nameShape='sender'
            isRestrict
            key={updateView}
            outerSetFieldValue={setFieldValue}
            //@ts-ignore
            outerSelectedCountry={values?.sender?.country}
            isPersonal={false}
          />
        </Grid>
        {hidden ? null : (
          <Grid item xs={1} container justify='center'>
            <Divider orientation='vertical' />
          </Grid>
        )}
        <Grid item xs={12} sm className={classes.contactFields}>
          <Typography className={classes.contactFieldsTitle} variant='h3'>
            {t('app.recipient')}
          </Typography>
          <FormContact
            nameShape='receiver'
            isRestrict
            key={updateView}
            outerSetFieldValue={setFieldValue}
            //@ts-ignore
            outerSelectedCountry={values?.receiver?.country}
            outerIsLoadingValidateAddress={outerIsLoadingValidateAddress}
            outerSetIsLoadingValidateAddress={outerSetIsLoadingValidateAddress}
            outerSetIsMelissaValidated={outerSetIsMelissaValidated}
            outerIsMelissaValidated={outerIsMelissaValidated}
            isPersonal={false}
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default ShipmentsFormStep1;
