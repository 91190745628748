import { gql } from '@apollo/client';
import { DELIVERY_COUNTRY } from '../fragments/deliveryCountryFragment';

export const QUERY_MY_PRODUCT_LIST = gql`
  ${DELIVERY_COUNTRY}
  query getMyProductList(
    $limit: Int
    $offset: Int
    $search: String
    $warehouseId: ID
  ) {
    getMyProductList(
      limit: $limit
      offset: $offset
      search: $search
      warehouseId: $warehouseId
    ) {
      count
      rows {
        id
        name
        sku
        detailsDescriptionRU
        detailsDescriptionEN
        state
        trademark
        declaredValue
        country {
          ...FragmentDeliveryCountry
        }
        netWeight
        code
        userId
        fnsku
        upc
        actualDimensions {
          id
          warehouseShipmentId
          weight
          length
          width
          height
        }
        declaredDimensions {
          id
          warehouseShipmentId
          weight
          length
          width
          height
        }
        barcodeId
        productAvailabilityQuantity {
          id
          inTransitQuantity
          reservedQuantity
          avaliableQuantity
        }
        createdAt
        isCustomBarcodeId
        currency
      }
    }
  }
`;
