import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions =  {}
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  BigInt: any;
  /** The `JSON` scalar type represents JSON values as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSON: any;
  /** The `JSONObject` scalar type represents JSON objects as specified by [ECMA-404](http://www.ecma-international.org/publications/files/ECMA-ST/ECMA-404.pdf). */
  JSONObject: any;
  /** The `Upload` scalar type represents a file upload. */
  Upload: any;
};


export enum Availability_Operation {
  Increment = 'INCREMENT',
  Decrement = 'DECREMENT'
}

export type AdminConsolidationRateResponse = {
  __typename?: 'AdminConsolidationRateResponse';
  weight?: Maybe<Scalars['Float']>;
  volume_weight?: Maybe<Scalars['Float']>;
  weight_to_pay?: Maybe<Scalars['Float']>;
  variants?: Maybe<Array<Maybe<RateUserAccount>>>;
};

export type ArrivalResponceObject = {
  __typename?: 'ArrivalResponceObject';
  status?: Maybe<Scalars['String']>;
  entity?: Maybe<Entity>;
  shipment?: Maybe<WarehouseShipment>;
  parcel?: Maybe<Parcel>;
  product?: Maybe<Product>;
  warehouseZoneCell?: Maybe<WarehouseZoneCell>;
  expectedQuantityItem?: Maybe<Scalars['Int']>;
  acceptedQuantityItem?: Maybe<Scalars['Int']>;
};

export type Balance = {
  __typename?: 'Balance';
  id?: Maybe<Scalars['ID']>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  income?: Maybe<Scalars['Float']>;
  paymentParcel?: Maybe<Scalars['Float']>;
  discrepancyInWeight?: Maybe<Scalars['Float']>;
  balance?: Maybe<Scalars['Float']>;
  indebtedness?: Maybe<Scalars['Float']>;
};

export type BalanceResponse = {
  __typename?: 'BalanceResponse';
  count?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Balance>>>;
  totalIncome?: Maybe<Scalars['Float']>;
  totalPaymentParcel?: Maybe<Scalars['Float']>;
  totalDiscrepancyInWeight?: Maybe<Scalars['Float']>;
  totalIndebtedness?: Maybe<Scalars['Float']>;
  totalBalance?: Maybe<Scalars['Float']>;
};


export type CdekBarcode = {
  __typename?: 'CDEKBarcode';
  entity?: Maybe<CdekEntityBarcode>;
  requests?: Maybe<Array<Maybe<CdekRequest>>>;
};

export type CdekCalculationByTariffCodeInput = {
  from_location: Scalars['String'];
  packages?: Maybe<Array<CargoPlaceInput>>;
};

export type CdekCalculationByTariffCodeResponse = {
  __typename?: 'CDEKCalculationByTariffCodeResponse';
  delivery_sum?: Maybe<Scalars['Float']>;
  period_min?: Maybe<Scalars['Int']>;
  period_max?: Maybe<Scalars['Int']>;
  weight_calc?: Maybe<Scalars['Int']>;
  total_sum?: Maybe<Scalars['Float']>;
  currency?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Maybe<CdekError>>>;
  tariff?: Maybe<CdekTariffInfo>;
};

export type CdekCitiesListFilterInput = {
  country_codes?: Maybe<Array<Maybe<Scalars['String']>>>;
  region_code?: Maybe<Scalars['Int']>;
  kladr_region_code?: Maybe<Scalars['String']>;
  fias_region_code?: Maybe<Scalars['String']>;
  kladr_code?: Maybe<Scalars['String']>;
  fias_guid?: Maybe<Scalars['String']>;
  postal_code?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['Int']>;
  city?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  lang?: Maybe<Scalars['String']>;
  payment_limit?: Maybe<Scalars['Float']>;
};

export type CdekCitiesListResponse = {
  __typename?: 'CDEKCitiesListResponse';
  code?: Maybe<Scalars['Int']>;
  city?: Maybe<Scalars['String']>;
  fias_guid?: Maybe<Scalars['String']>;
  kladr_code?: Maybe<Scalars['String']>;
  country_code?: Maybe<Scalars['String']>;
  contry?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  region_code?: Maybe<Scalars['Int']>;
  fias_region_guid?: Maybe<Scalars['String']>;
  kladr_region_code?: Maybe<Scalars['String']>;
  sub_region?: Maybe<Scalars['String']>;
  postal_code?: Maybe<Array<Maybe<Scalars['String']>>>;
  longitude?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  time_zone?: Maybe<Scalars['String']>;
  payment_limit?: Maybe<Scalars['Float']>;
  errors?: Maybe<Array<Maybe<CdekError>>>;
};

export type CdekCourierCreateRequest = {
  intake_date: Scalars['String'];
  intake_time_from: Scalars['String'];
  intake_time_to: Scalars['String'];
  name: Scalars['String'];
};

export type CdekCourierEntity = {
  __typename?: 'CDEKCourierEntity';
  uuid?: Maybe<Scalars['String']>;
  intake_date?: Maybe<Scalars['String']>;
  intake_time_from?: Maybe<Scalars['String']>;
  intake_time_to?: Maybe<Scalars['String']>;
  lunch_time_from?: Maybe<Scalars['String']>;
  lunch_time_to?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Int']>;
  length?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  comment?: Maybe<Scalars['String']>;
  sender?: Maybe<CdekSender>;
  from_location?: Maybe<CdekLocation>;
  need_call?: Maybe<Scalars['Boolean']>;
  statuses?: Maybe<Array<Maybe<CdekCourierStatuses>>>;
};

export type CdekCourierInfo = {
  __typename?: 'CDEKCourierInfo';
  entity?: Maybe<CdekCourierEntity>;
  requests?: Maybe<Array<Maybe<CdekRequest>>>;
};

export type CdekCourierPickUpRequestInput = {
  __typename?: 'CDEKCourierPickUpRequestInput';
  intake_date?: Maybe<Scalars['String']>;
  intake_time_from?: Maybe<Scalars['String']>;
  intake_time_to?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Int']>;
  length?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
};

export type CdekCourierRequestInput = {
  intake_date?: Maybe<Scalars['String']>;
  intake_time_from?: Maybe<Scalars['String']>;
  intake_time_to?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type CdekCourierStatuses = {
  __typename?: 'CDEKCourierStatuses';
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  date_time?: Maybe<Scalars['String']>;
};

export type CdekDeliveryDetail = {
  __typename?: 'CDEKDeliveryDetail';
  date?: Maybe<Scalars['String']>;
  recepient_name?: Maybe<Scalars['String']>;
  total_sum?: Maybe<Scalars['Float']>;
};

export type CdekDeliveryRecipientCost = {
  __typename?: 'CDEKDeliveryRecipientCost';
  value?: Maybe<Scalars['Float']>;
  vat_sum?: Maybe<Scalars['Float']>;
  vat_rate?: Maybe<Scalars['Int']>;
};

export type CdekDeliveryRecipientCostAdv = {
  __typename?: 'CDEKDeliveryRecipientCostAdv';
  threshold?: Maybe<Scalars['Int']>;
  sum?: Maybe<Scalars['Float']>;
  vat_sum?: Maybe<Scalars['Float']>;
  vat_rate?: Maybe<Scalars['Int']>;
};

export type CdekDimension = {
  __typename?: 'CDEKDimension';
  width?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  depth?: Maybe<Scalars['Float']>;
};

export type CdekEntity = {
  __typename?: 'CDEKEntity';
  uuid?: Maybe<Scalars['String']>;
};

export type CdekEntityBarcode = {
  __typename?: 'CDEKEntityBarcode';
  uuid?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
};

export type CdekEntityResponse = {
  __typename?: 'CDEKEntityResponse';
  entity?: Maybe<CdekEntity>;
  requests?: Maybe<Array<Maybe<CdekRequest>>>;
  courierInfo?: Maybe<CdekCourierInfo>;
};

export type CdekError = {
  __typename?: 'CDEKError';
  code?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export type CdekImageList = {
  __typename?: 'CDEKImageList';
  url?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['Int']>;
};

export type CdekInfoAbountOrderDeliveryDetail = {
  __typename?: 'CDEKInfoAbountOrderDeliveryDetail';
  date?: Maybe<Scalars['String']>;
  recipient_name?: Maybe<Scalars['String']>;
  payment_sum?: Maybe<Scalars['Float']>;
};

export type CdekInfoAboutOrderEntity = {
  __typename?: 'CDEKInfoAboutOrderEntity';
  uuid?: Maybe<Scalars['String']>;
  is_return?: Maybe<Scalars['Boolean']>;
  is_reverse?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['Int']>;
  cdek_number?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  tariff_code?: Maybe<Scalars['Int']>;
  comment?: Maybe<Scalars['String']>;
  shipment_point?: Maybe<Scalars['String']>;
  delivery_point?: Maybe<Scalars['String']>;
  date_invoice?: Maybe<Scalars['String']>;
  shipper_name?: Maybe<Scalars['String']>;
  shipper_address?: Maybe<Scalars['String']>;
  delivery_recipient_cost?: Maybe<CdekDeliveryRecipientCost>;
  delivery_recipient_cost_adv?: Maybe<CdekDeliveryRecipientCostAdv>;
  seller?: Maybe<CdekSeller>;
  recipient?: Maybe<CdekRecipient>;
  statuses?: Maybe<Array<Maybe<CdekCourierStatuses>>>;
  from_location?: Maybe<CdekLocation>;
  to_location?: Maybe<CdekLocation>;
  packages?: Maybe<Array<Maybe<CdekInfoAboutOrderPackage>>>;
  delivery_detail?: Maybe<CdekDeliveryDetail>;
  sender?: Maybe<CdekSender>;
};

export type CdekInfoAboutOrderPackage = {
  __typename?: 'CDEKInfoAboutOrderPackage';
  package_id?: Maybe<Scalars['String']>;
  number?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Int']>;
  weight_volume?: Maybe<Scalars['Int']>;
  weight_calc?: Maybe<Scalars['Int']>;
  length?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  comment?: Maybe<Scalars['String']>;
  items?: Maybe<Array<Maybe<CdekRegistrationOrderItemForPackage>>>;
};

export type CdekInfoAboutOrderResponse = {
  __typename?: 'CDEKInfoAboutOrderResponse';
  entity?: Maybe<CdekInfoAboutOrderEntity>;
  requests?: Maybe<Array<Maybe<CdekRequest>>>;
  otherInfo?: Maybe<CdekParcel>;
  courierInfo?: Maybe<CdekCourierEntity>;
};

export type CdekInputFilter = {
  city: Scalars['String'];
  address: Scalars['String'];
  postal_code: Scalars['String'];
};

export type CdekLocation = {
  __typename?: 'CDEKLocation';
  code?: Maybe<Scalars['Int']>;
  country?: Maybe<Scalars['String']>;
  country_code?: Maybe<Scalars['String']>;
  region_code?: Maybe<Scalars['Int']>;
  region?: Maybe<Scalars['String']>;
  city_code?: Maybe<Scalars['Int']>;
  city?: Maybe<Scalars['String']>;
  postal_code?: Maybe<Scalars['String']>;
  longitude?: Maybe<Scalars['Float']>;
  latitude?: Maybe<Scalars['Float']>;
  address?: Maybe<Scalars['String']>;
  address_full?: Maybe<Scalars['String']>;
};

export type CdekLocationFilters = {
  region?: Maybe<CdekRegionListFilterInput>;
  office?: Maybe<CdekOfiiceListFilterInput>;
  city?: Maybe<CdekCitiesListFilterInput>;
};

export type CdekLocationList = {
  __typename?: 'CDEKLocationList';
  regions?: Maybe<Array<Maybe<CdekRegionListResponse>>>;
  offices?: Maybe<Array<Maybe<CdekOfficeListResponse>>>;
  cities?: Maybe<Array<Maybe<CdekCitiesListResponse>>>;
};

export type CdekOfficeListResponse = {
  __typename?: 'CDEKOfficeListResponse';
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  location?: Maybe<CdekLocation>;
  address_comment?: Maybe<Scalars['String']>;
  nearest_station?: Maybe<Scalars['String']>;
  nearest_metro_station?: Maybe<Scalars['String']>;
  work_time?: Maybe<Scalars['String']>;
  phones?: Maybe<Array<Maybe<CdekPhone>>>;
  email?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  owner_code?: Maybe<Scalars['String']>;
  take_only?: Maybe<Scalars['Boolean']>;
  is_handout?: Maybe<Scalars['Boolean']>;
  is_dressing_room?: Maybe<Scalars['Boolean']>;
  have_cashless?: Maybe<Scalars['Boolean']>;
  have_cash?: Maybe<Scalars['Boolean']>;
  allowed_code?: Maybe<Scalars['Boolean']>;
  site?: Maybe<Scalars['String']>;
  office_image_list?: Maybe<Array<Maybe<CdekImageList>>>;
  work_time_list?: Maybe<Array<Maybe<CdekWorkTime>>>;
  work_time_exceptions?: Maybe<Array<Maybe<CdekWorkTimeException>>>;
  weight_min?: Maybe<Scalars['Float']>;
  weight_max?: Maybe<Scalars['Float']>;
  fulfillment?: Maybe<Scalars['Boolean']>;
  dimensions?: Maybe<CdekDimension>;
  errors?: Maybe<Array<Maybe<CdekError>>>;
};

export type CdekOfiiceListFilterInput = {
  postal_code?: Maybe<Scalars['Int']>;
  city_code?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['String']>;
  country_code?: Maybe<Scalars['String']>;
  region_code?: Maybe<Scalars['Int']>;
  have_cash?: Maybe<Scalars['Boolean']>;
  allowed_cod?: Maybe<Scalars['Boolean']>;
  is_dressing_room?: Maybe<Scalars['Boolean']>;
  weight_max?: Maybe<Scalars['Int']>;
  weight_min?: Maybe<Scalars['Int']>;
  lang?: Maybe<Scalars['String']>;
  take_only?: Maybe<Scalars['Boolean']>;
  is_handout?: Maybe<Scalars['Boolean']>;
};

export type CdekOrderListInfo = {
  __typename?: 'CDEKOrderListInfo';
  count?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<CdekParcel>>>;
};

export type CdekOrderLocation = {
  address?: Maybe<Scalars['String']>;
  code: Scalars['Int'];
  city?: Maybe<Scalars['String']>;
};

export type CdekParcel = {
  __typename?: 'CDEKParcel';
  id?: Maybe<Scalars['ID']>;
  userId?: Maybe<Scalars['Int']>;
  entityUuid?: Maybe<Scalars['String']>;
  requestUuid?: Maybe<Scalars['String']>;
  dateTime?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  parcelIds?: Maybe<Scalars['String']>;
  parcelBarcodes?: Maybe<Scalars['String']>;
  courierUuid?: Maybe<Scalars['String']>;
  paymentStatusId?: Maybe<Scalars['Int']>;
  from?: Maybe<Scalars['String']>;
  trackNumber?: Maybe<Scalars['String']>;
  parcelErrorsReason?: Maybe<Array<Maybe<Scalars['JSONObject']>>>;
  courierStatus?: Maybe<Scalars['String']>;
  courierErrorsReason?: Maybe<Array<Maybe<Scalars['JSONObject']>>>;
  status?: Maybe<Scalars['String']>;
  note?: Maybe<Scalars['String']>;
  city_code?: Maybe<Scalars['Int']>;
  office_code?: Maybe<Scalars['String']>;
  apartmentNumber?: Maybe<Scalars['String']>;
  street?: Maybe<Scalars['String']>;
  tariffCode?: Maybe<Scalars['Int']>;
  houseNumber?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  pdf?: Maybe<Scalars['String']>;
  packages?: Maybe<Array<Maybe<CargoPlace>>>;
};

export type CdekPayment = {
  __typename?: 'CDEKPayment';
  value?: Maybe<Scalars['Float']>;
};

export type CdekPhone = {
  __typename?: 'CDEKPhone';
  number?: Maybe<Scalars['String']>;
  additional?: Maybe<Scalars['String']>;
};

export type CdekRecipient = {
  __typename?: 'CDEKRecipient';
  name: Scalars['String'];
  email: Scalars['String'];
  phones: Array<Maybe<CdekPhone>>;
};

export type CdekRegionListFilterInput = {
  country_codes?: Maybe<Array<Maybe<Scalars['String']>>>;
  region_code?: Maybe<Scalars['Int']>;
  kladr_region_code?: Maybe<Scalars['String']>;
  flash_region_guid?: Maybe<Scalars['String']>;
  size?: Maybe<Scalars['Int']>;
  page?: Maybe<Scalars['Int']>;
  lang?: Maybe<Scalars['String']>;
};

export type CdekRegionListResponse = {
  __typename?: 'CDEKRegionListResponse';
  country_code?: Maybe<Scalars['String']>;
  country?: Maybe<Scalars['String']>;
  region?: Maybe<Scalars['String']>;
  prefix?: Maybe<Scalars['String']>;
  region_code?: Maybe<Scalars['Int']>;
  kladr_region_guid?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Maybe<CdekError>>>;
};

export type CdekRegistrationOrderInpur = {
  preliminaryCost: Scalars['Float'];
  from: Scalars['String'];
  tariff_code: Scalars['Int'];
  parcel_ids: Array<Maybe<Scalars['Int']>>;
  packages?: Maybe<Array<Maybe<CargoPlaceInput>>>;
  shipment_point?: Maybe<Scalars['String']>;
  from_location?: Maybe<CdekOrderLocation>;
  to_location?: Maybe<CdekOrderLocation>;
  comment?: Maybe<Scalars['String']>;
  sender?: Maybe<CdekRegistrationOrderSender>;
  recipient?: Maybe<CdekRegistrationOrderRecipient>;
  delivery_point?: Maybe<Scalars['String']>;
};

export type CdekRegistrationOrderInput = {
  preliminaryCost?: Maybe<Scalars['Float']>;
  from?: Maybe<Scalars['String']>;
  tariff_code?: Maybe<Scalars['Int']>;
  shipment_point?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  delivery_point?: Maybe<Scalars['String']>;
};

export type CdekRegistrationOrderItemForPackage = {
  __typename?: 'CDEKRegistrationOrderItemForPackage';
  ware_key?: Maybe<Scalars['String']>;
  payment?: Maybe<CdekPayment>;
  cost?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Int']>;
  amount?: Maybe<Scalars['Int']>;
};

export type CdekRegistrationOrderPackage = {
  __typename?: 'CDEKRegistrationOrderPackage';
  number?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Int']>;
  length?: Maybe<Scalars['Int']>;
  width?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Int']>;
  items?: Maybe<Array<Maybe<CdekRegistrationOrderItemForPackage>>>;
};

export type CdekRegistrationOrderRecipient = {
  company: Scalars['String'];
  name: Scalars['String'];
  email: Scalars['String'];
  phoneNumber: Scalars['String'];
};

export type CdekRegistrationOrderSender = {
  fullName: Scalars['String'];
  phone: Scalars['String'];
  street: Scalars['String'];
  houseNumber: Scalars['String'];
  apartmentNumber: Scalars['String'];
  comment?: Maybe<Scalars['String']>;
};

export type CdekRequest = {
  __typename?: 'CDEKRequest';
  request_uuid?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  date_time?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Maybe<CdekError>>>;
  warnings?: Maybe<Array<Maybe<CdekError>>>;
};

export type CdekSeller = {
  __typename?: 'CDEKSeller';
  name?: Maybe<Scalars['String']>;
  inn?: Maybe<Scalars['String']>;
  phone?: Maybe<CdekPhone>;
  ownership_form?: Maybe<Scalars['Int']>;
  address?: Maybe<Scalars['String']>;
};

export type CdekSender = {
  __typename?: 'CDEKSender';
  company?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  pasport_requirements_statisfied?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  phones?: Maybe<Array<Maybe<CdekPhone>>>;
  tin?: Maybe<Scalars['String']>;
};

export type CdekTariffInfo = {
  __typename?: 'CDEKTariffInfo';
  code?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  mode?: Maybe<Scalars['String']>;
  weight_restrictions?: Maybe<Scalars['String']>;
  service?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
};

export type CdekWorkTime = {
  __typename?: 'CDEKWorkTime';
  day?: Maybe<Scalars['Int']>;
  time?: Maybe<Scalars['String']>;
};

export type CdekWorkTimeException = {
  __typename?: 'CDEKWorkTimeException';
  data?: Maybe<Scalars['String']>;
  time?: Maybe<Scalars['String']>;
  is_work?: Maybe<Scalars['Boolean']>;
};

export enum Currency {
  Usd = 'USD',
  Eur = 'EUR',
  Gbp = 'GBP',
  Rub = 'RUB'
}

export type CalculationInput = {
  mode?: Maybe<Mode_Types>;
  warehouseId?: Maybe<Scalars['ID']>;
  sender?: Maybe<ParcelContactInput>;
  receiver: ParcelContactInput;
  address_validation: Scalars['Boolean'];
  cargo_price?: Maybe<Scalars['Float']>;
  currency_code: Scalars['String'];
  iso: Scalars['String'];
  positions: Array<Maybe<CalculatorPositions>>;
  userId?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['String']>;
  readyTime?: Maybe<Scalars['String']>;
  additionalInsurance?: Maybe<Scalars['Boolean']>;
  insuranceAmount?: Maybe<Scalars['Float']>;
  signature?: Maybe<Scalars['Boolean']>;
};

export type CalculatorParcelUnits = {
  quantity: Scalars['Int'];
  price: Scalars['Float'];
  code?: Maybe<Scalars['BigInt']>;
  countryId?: Maybe<Scalars['Int']>;
  nameEN?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Float']>;
  sku?: Maybe<Scalars['String']>;
};

export type CalculatorPositions = {
  weight: Scalars['Float'];
  length: Scalars['Float'];
  height: Scalars['Float'];
  width: Scalars['Float'];
  count: Scalars['Int'];
  units?: Maybe<Array<Maybe<CalculatorParcelUnits>>>;
};

export type CalculatorResponse = {
  __typename?: 'CalculatorResponse';
  weight?: Maybe<Scalars['Float']>;
  volume_weight?: Maybe<Scalars['Float']>;
  weight_to_pay?: Maybe<Scalars['Float']>;
  special_tax?: Maybe<Scalars['Float']>;
  insurance?: Maybe<Scalars['Float']>;
  data?: Maybe<Array<Maybe<CalculatorResponseData>>>;
  userAccount?: Maybe<QuoteResponseForCalculator>;
};

export type CalculatorResponseData = {
  __typename?: 'CalculatorResponseData';
  name?: Maybe<Scalars['String']>;
  success?: Maybe<Scalars['Boolean']>;
  days_to_delivery?: Maybe<Scalars['Int']>;
  summary_amount?: Maybe<Scalars['Float']>;
  remoteArea?: Maybe<Scalars['Float']>;
  typeDelivery?: Maybe<Scalars['Int']>;
  fuelSurcharge?: Maybe<Scalars['Float']>;
  dollarRate?: Maybe<Scalars['Float']>;
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  rateId?: Maybe<Scalars['ID']>;
  shipmentId?: Maybe<Scalars['ID']>;
  info?: Maybe<VariantInfo>;
  priceCurrency?: Maybe<Scalars['String']>;
};

export type CalculatorResponseObject = {
  __typename?: 'CalculatorResponseObject';
  standard?: Maybe<Array<Maybe<CalculatorResponseData>>>;
  doorToDoor?: Maybe<Array<Maybe<CalculatorResponseData>>>;
};

export type CargoPlace = {
  __typename?: 'CargoPlace';
  id?: Maybe<Scalars['Int']>;
  warehouseShipmentId?: Maybe<Scalars['Int']>;
  weight?: Maybe<Scalars['Float']>;
  length?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
};

export type CargoPlaceInput = {
  weight?: Maybe<Scalars['Float']>;
  length?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
};

export type CheckParcels = {
  __typename?: 'CheckParcels';
  parcels?: Maybe<Array<Maybe<Parcel>>>;
  numberOfFount?: Maybe<Scalars['Int']>;
  numberOfNotFount?: Maybe<Scalars['Int']>;
  foundList?: Maybe<Array<Maybe<Scalars['String']>>>;
  notFoundList?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type CityInfo = {
  __typename?: 'CityInfo';
  cityId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  altName?: Maybe<Scalars['String']>;
  /** Country ISO 3166-1 alpha-2 */
  country?: Maybe<Scalars['String']>;
  featureCode?: Maybe<Scalars['String']>;
  adminCode?: Maybe<Scalars['String']>;
  population?: Maybe<Scalars['Int']>;
};

export type Config = {
  __typename?: 'Config';
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['JSON']>;
};

export type ConfigInput = {
  key: Scalars['String'];
  value: Scalars['JSON'];
};

export type ConfigList = {
  __typename?: 'ConfigList';
  count?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Config>>>;
};

export type Consolidation = {
  __typename?: 'Consolidation';
  id?: Maybe<Scalars['ID']>;
  serialNumber?: Maybe<Scalars['String']>;
  trackNumber?: Maybe<Scalars['String']>;
  deliveryStatus?: Maybe<StatusDelivery>;
  paymentStatus?: Maybe<StatusPayment>;
  packages?: Maybe<Array<Maybe<CargoPlace>>>;
  parcels?: Maybe<Array<Maybe<Parcel>>>;
  sender?: Maybe<ParcelContact>;
  receiver?: Maybe<ParcelContact>;
  insurance?: Maybe<Scalars['Boolean']>;
  shipmentServiceName?: Maybe<Scalars['String']>;
  waybillPath?: Maybe<Scalars['String']>;
  cargoPlaces?: Maybe<Scalars['Int']>;
  totalGrossWeight?: Maybe<Scalars['Float']>;
  totalNetWeight?: Maybe<Scalars['Float']>;
  totalVolumetricWeight?: Maybe<Scalars['Float']>;
  totalCostOfEnteties?: Maybe<Scalars['Float']>;
  totalCost?: Maybe<Scalars['Float']>;
  date?: Maybe<Scalars['String']>;
  senderAccount?: Maybe<Scalars['String']>;
  fileTokenInvoiceXlsx?: Maybe<Scalars['String']>;
  barcode?: Maybe<Scalars['String']>;
};

export type ConsolidationList = {
  __typename?: 'ConsolidationList';
  consolidations?: Maybe<Array<Maybe<Consolidation>>>;
  count?: Maybe<Scalars['Int']>;
};

export type Contact = {
  __typename?: 'Contact';
  id: Scalars['ID'];
  name: Scalars['String'];
  company: Scalars['String'];
  country?: Maybe<DeliveryCountry>;
  address: Scalars['String'];
  address2?: Maybe<Scalars['String']>;
  address3?: Maybe<Scalars['String']>;
  zipCode: Scalars['String'];
  city: Scalars['String'];
  state: Scalars['String'];
  phone: Scalars['String'];
  email: Scalars['String'];
  eoriCode?: Maybe<Scalars['String']>;
  innKppCode?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  contactName?: Maybe<Scalars['String']>;
  type?: Maybe<ContactType>;
};

export type ContactFilter = {
  contactType?: Maybe<ContactType>;
  contactName?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type ContactInput = {
  name: Scalars['String'];
  company: Scalars['String'];
  countryId: Scalars['Int'];
  address: Scalars['String'];
  address2?: Maybe<Scalars['String']>;
  address3?: Maybe<Scalars['String']>;
  zipCode: Scalars['String'];
  city: Scalars['String'];
  state: Scalars['String'];
  phone: Scalars['String'];
  email?: Maybe<Scalars['String']>;
  eoriCode?: Maybe<Scalars['String']>;
  innKppCode?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  contactName?: Maybe<Scalars['String']>;
  /** Available variants: SENDER, RECIPIENT(default) */
  type?: Maybe<ContactType>;
};

export type ContactList = {
  __typename?: 'ContactList';
  count?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Contact>>>;
};

export enum ContactType {
  Sender = 'SENDER',
  Recipient = 'RECIPIENT'
}

export type CourierList = {
  __typename?: 'CourierList';
  count?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<CourierResponse>>>;
};

export type CourierRequestInput = {
  /** Date of pickup */
  pickupDate: Scalars['String'];
  /** Time pickup must be ready by */
  readyByTime: Scalars['String'];
  /** Closing time of pickup location */
  closeTime: Scalars['String'];
  /** The contactName element contains the name of the consignee for the pickup. It is mandatory element in the ConsigneeDetails Contact element. It is of type String with maximum length 35 */
  contactName?: Maybe<Scalars['String']>;
  /** The contactPhone element contains the phone number of the consignee for the pickup. It is mandatory element in the ConsigneeDetails Contact Element */
  contactPhone?: Maybe<Scalars['String']>;
  /** Unless location type is residence, company name is required */
  company?: Maybe<Scalars['String']>;
  /** The PackageLocation element contains the information about the location of the package at the pick address. e.g. Front Desk. It must be declared in the Place element */
  packageLocation: Scalars['String'];
  /** The Pieces Element contains the information about the pieces. */
  pieces: Array<Maybe<PieceInput>>;
  /** Service identifier through which the package is sent */
  senderAccountId: Scalars['Int'];
  /** Sender contact id */
  contactId?: Maybe<Scalars['Int']>;
};

export type CourierResponse = {
  __typename?: 'CourierResponse';
  id?: Maybe<Scalars['Int']>;
  pickupDate?: Maybe<Scalars['String']>;
  readyByTime?: Maybe<Scalars['String']>;
  closeTime?: Maybe<Scalars['String']>;
  contactName?: Maybe<Scalars['String']>;
  contactPhone?: Maybe<Scalars['String']>;
  packageLocation?: Maybe<Scalars['String']>;
  pieces?: Maybe<Array<Maybe<Piece>>>;
  confirmationNumber?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type CreateWarehouseShipmentOrderInput = {
  warehouseId?: Maybe<Scalars['Int']>;
  products?: Maybe<Array<Maybe<ShipmentProductInput>>>;
  receiver: ParcelContactInput;
  carrierService?: Maybe<Scalars['String']>;
  typeDeliveryId?: Maybe<Scalars['Int']>;
  wrap?: Maybe<Wrap_Type>;
  giftWrap?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
  rateId: Scalars['ID'];
  shipmentId: Scalars['ID'];
  lastMileCost: Scalars['String'];
  additionalInsurance?: Maybe<Scalars['Boolean']>;
  insuranceAmount?: Maybe<Scalars['Float']>;
  signature?: Maybe<Scalars['Boolean']>;
  senderMark?: Maybe<Scalars['String']>;
  vatKey?: Maybe<Vatkey>;
  vatValue?: Maybe<Scalars['String']>;
  totalGrossWeight?: Maybe<Scalars['Float']>;
  totalNetWeight?: Maybe<Scalars['Float']>;
  orderCostCurrency?: Maybe<Scalars['String']>;
  preliminaryCost?: Maybe<Scalars['Float']>;
  currency?: Maybe<Scalars['String']>;
  handlingFee?: Maybe<Scalars['Float']>;
  marketplaceInvoiceToken?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
  marketPlaceOrderId?: Maybe<Scalars['Int']>;
};

export type CreateWarehouseZoneCell = {
  name: Scalars['String'];
  warehouseZoneId: Scalars['Int'];
};

export type DeliveryCountry = {
  __typename?: 'DeliveryCountry';
  id?: Maybe<Scalars['Int']>;
  order: Scalars['Int'];
  name: Scalars['String'];
  daysToDelivery: Scalars['Int'];
  zoneId: Scalars['Int'];
  nameEng: Scalars['String'];
  iso?: Maybe<Scalars['String']>;
  phoneCode?: Maybe<Scalars['String']>;
  phoneMin?: Maybe<Scalars['Int']>;
  phoneMax?: Maybe<Scalars['Int']>;
  customsInfo?: Maybe<Scalars['String']>;
};

export type DeliveryDateInfo = {
  __typename?: 'DeliveryDateInfo';
  /** The DeliveryType element defines the type of Delivery. */
  DeliveryType?: Maybe<Scalars['String']>;
  /** The DlvyDateTime element is the delivery date and time. */
  DlvyDateTime?: Maybe<Scalars['String']>;
  /** The DeliveryDate element is the date the shipment is delivered. */
  DeliveryDateTimeOffset?: Maybe<Scalars['String']>;
};

export type DeliveryOptionsResponse = {
  __typename?: 'DeliveryOptionsResponse';
  name?: Maybe<Scalars['String']>;
  days_to_delivery?: Maybe<Scalars['Int']>;
  summary_amount?: Maybe<Scalars['Float']>;
  lastMileCost?: Maybe<Scalars['String']>;
  typeDelivery?: Maybe<Scalars['Int']>;
  info?: Maybe<VariantInfoWarehouseShipmentOrder>;
  rateId?: Maybe<Scalars['ID']>;
  shipmentId?: Maybe<Scalars['ID']>;
  monthly_pay_amount?: Maybe<Scalars['Float']>;
  priceCurrency?: Maybe<Scalars['String']>;
};

export type DeliveryOptionsResponseObject = {
  __typename?: 'DeliveryOptionsResponseObject';
  variants?: Maybe<Array<Maybe<DeliveryOptionsResponse>>>;
  monthly_pay_amount?: Maybe<Scalars['Float']>;
};

export type Discount = {
  __typename?: 'Discount';
  id?: Maybe<Scalars['Int']>;
  email?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  discount?: Maybe<Scalars['Float']>;
  whFeePersonalDiscount?: Maybe<Scalars['Float']>;
};

export type DiscountList = {
  __typename?: 'DiscountList';
  count?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Discount>>>;
};

export enum Entity {
  Product = 'PRODUCT',
  Parcel = 'PARCEL',
  Warehouseshipment = 'WAREHOUSESHIPMENT',
  Warehousezonecell = 'WAREHOUSEZONECELL',
  NotDetermined = 'NOT_DETERMINED'
}

export type EntityLegalData = {
  __typename?: 'EntityLegalData';
  id: Scalars['Int'];
  company: Scalars['String'];
  inn: Scalars['String'];
  kpp?: Maybe<Scalars['String']>;
  MSRN: Scalars['String'];
  generalManager: Scalars['String'];
  legalAddress: Scalars['String'];
  nameOfTheBank: Scalars['String'];
  BIK: Scalars['String'];
  paymentAccount: Scalars['String'];
  correspondentAccount: Scalars['String'];
  fileName: Scalars['String'];
  userId: Scalars['Int'];
  isDataVerified: Scalars['Boolean'];
};

export type EntityLegalDataInput = {
  company: Scalars['String'];
  inn: Scalars['String'];
  /** used regex: /^([0-9]{10}$)|([0-9]{12}$)/gm */
  kpp?: Maybe<Scalars['String']>;
  /**
   * Main state registration number
   * used regex: /^([0-9]{13}$)|([0-9]{15}$)/gm
   */
  MSRN: Scalars['String'];
  /** Full name */
  generalManager: Scalars['String'];
  /** The address must be entered in the following order: Country, Zip Code, State, City, Street, Building, Apt. */
  legalAddress: Scalars['String'];
  nameOfTheBank: Scalars['String'];
  /** Bank identification code */
  BIK: Scalars['String'];
  paymentAccount: Scalars['String'];
  /** Correspondent account */
  correspondentAccount: Scalars['String'];
  dataUpToDate?: Scalars['Boolean'];
};

export type EntityLegalUpdateDataInput = {
  company?: Maybe<Scalars['String']>;
  inn?: Maybe<Scalars['String']>;
  kpp?: Maybe<Scalars['String']>;
  MSRN?: Maybe<Scalars['String']>;
  generalManager?: Maybe<Scalars['String']>;
  legalAddress?: Maybe<Scalars['String']>;
  nameOfTheBank?: Maybe<Scalars['String']>;
  BIK?: Maybe<Scalars['String']>;
  paymentAccount?: Maybe<Scalars['String']>;
  correspondentAccount?: Maybe<Scalars['String']>;
  dataUpToDate?: Scalars['Boolean'];
};

export type ExchangeRate = {
  __typename?: 'ExchangeRate';
  code: Scalars['String'];
  nominal: Scalars['String'];
  value: Scalars['String'];
};

export type ExchangeRateResponse = {
  __typename?: 'ExchangeRateResponse';
  exchangeRate?: Maybe<Array<Maybe<ExchangeRate>>>;
  lastUpdate?: Maybe<Scalars['String']>;
};

export type File = {
  __typename?: 'File';
  filename: Scalars['String'];
  mimetype: Scalars['String'];
  encoding: Scalars['String'];
};

export type GetSwapHistoryWarehouseCellProduct = {
  search?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type GetWarehouseZoneCellResidues = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  warehouseZoneCellId?: Maybe<Scalars['Int']>;
};

export type HistoryWarehouseProduct = {
  __typename?: 'HistoryWarehouseProduct';
  quantity?: Maybe<Scalars['Int']>;
  comment?: Maybe<Scalars['String']>;
  operation?: Maybe<HistoryWarehouseProductOperation>;
  shipmentId?: Maybe<Scalars['Int']>;
  createdAt?: Maybe<Scalars['String']>;
};

export enum HistoryWarehouseProductOperation {
  Refill = 'REFILL',
  Order = 'ORDER',
  Defill = 'DEFILL',
  Warehouse = 'WAREHOUSE',
  OrderRefund = 'ORDER_REFUND'
}

export enum IdentifiersType {
  /** track number`s parcel */
  TrackNumber = 'TRACK_NUMBER',
  /** identifier from marketplace */
  MarketplaceNumber = 'MARKETPLACE_NUMBER',
  /** inner identifier from DB */
  InnerNumber = 'INNER_NUMBER'
}



export type LabelDataEasyPost = {
  shipmentId?: Maybe<Scalars['String']>;
  rateId?: Maybe<Scalars['String']>;
};

export type ListOfSenderAccounts = {
  __typename?: 'ListOfSenderAccounts';
  count?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<SenderAccount>>>;
};

export type LoginResponse = {
  __typename?: 'LoginResponse';
  token?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
};

export enum Mode_Types {
  Full = 'FULL',
  ForwardingOnly = 'FORWARDING_ONLY'
}

export type Manager = {
  __typename?: 'Manager';
  id?: Maybe<Scalars['Int']>;
  warehouse?: Maybe<Warehouse>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  role?: Maybe<ManagerRoles>;
};

export type ManagerInput = {
  warehouseId?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  role?: Maybe<ManagerRoles>;
};

export type ManagerList = {
  __typename?: 'ManagerList';
  rows?: Maybe<Array<Maybe<Manager>>>;
  count?: Maybe<Scalars['Int']>;
};

export enum ManagerRoles {
  Manager = 'MANAGER',
  Admin = 'ADMIN'
}

export type Mutation = {
  __typename?: 'Mutation';
  cancelWarehouseShipment?: Maybe<WarehouseShipment>;
  changePassword?: Maybe<Scalars['Boolean']>;
  uploadMarketplaceInvoice?: Maybe<Scalars['String']>;
  pickingWithoutScanning?: Maybe<WarehouseShipmentInfoForManager>;
  pickingWithScanning?: Maybe<WarehouseShipmentInfoForManager>;
  createReportByShipments?: Maybe<ReportFilename>;
  createReportByProducts?: Maybe<ReportFilename>;
  createReportByTransactions?: Maybe<ReportFilename>;
  createReportByFulfillment?: Maybe<ReportFilename>;
  arrivalOfTheParcelForAdmin?: Maybe<WarehouseShipment>;
  setProductActualDimensions?: Maybe<Product>;
  createQRPayment?: Maybe<Scalars['String']>;
  addEventToWarehouseShipment?: Maybe<WarehouseShipment>;
  addTrackNumber?: Maybe<WarehouseShipment>;
  removeTrackNumber?: Maybe<WarehouseShipment>;
  removeWarehouseShipment?: Maybe<WarehouseShipment>;
  setArrivalWarehouseShipment?: Maybe<ArrivalResponceObject>;
  setArrivalWarehouseShipmentItem?: Maybe<ArrivalResponceObject>;
  changeProductAvalibility?: Maybe<Scalars['Boolean']>;
  returnOfTheParcel?: Maybe<Scalars['String']>;
  createWarehouseShipmentUseProducts?: Maybe<WarehouseShipment>;
  createWarehouseShipmentUseParcels?: Maybe<WarehouseShipment>;
  createWarehouseShipmentOrder?: Maybe<WarehouseShipmentOrder>;
  sendWarehouseShipmentOrder?: Maybe<WarehouseShipmentOrder>;
  cancelWarehouseShipmentOrder?: Maybe<WarehouseShipmentOrder>;
  createWarehouse?: Maybe<Warehouse>;
  updateWarehouse?: Maybe<Warehouse>;
  removeWarehouse?: Maybe<Warehouse>;
  createManager?: Maybe<Manager>;
  signInManager?: Maybe<Token>;
  updateManager?: Maybe<Manager>;
  removeManager?: Maybe<Manager>;
  addEntityLegalData?: Maybe<EntityLegalData>;
  updateEntityLegalData?: Maybe<EntityLegalData>;
  verifyLegalData?: Maybe<EntityLegalData>;
  updateCreditLimitForUser?: Maybe<User>;
  addPassportData?: Maybe<UserPasport>;
  updatePassportData?: Maybe<UserPasport>;
  verifyPassportData?: Maybe<UserPasport>;
  addEtsyTrackNumber?: Maybe<Scalars['JSON']>;
  addPackageToOrder?: Maybe<Order>;
  removeStore?: Maybe<Store>;
  updateStore?: Maybe<Store>;
  addStore?: Maybe<Store>;
  switchStoreStatus?: Maybe<Store>;
  createShipmentRequest?: Maybe<ShipmentResponse>;
  createRequestToCallCourier?: Maybe<CourierResponse>;
  createSenderAccount?: Maybe<SenderAccount>;
  updateSenderAccount?: Maybe<SenderAccount>;
  removeSenderAccount?: Maybe<Scalars['Boolean']>;
  addTariff?: Maybe<Array<Maybe<Tariff>>>;
  updateTariff?: Maybe<Array<Maybe<Tariff>>>;
  removeTariff?: Maybe<Scalars['Int']>;
  addConfiguration?: Maybe<Config>;
  updateConfiguration?: Maybe<Scalars['JSON']>;
  createPackagingTemplate?: Maybe<PackagingTemplate>;
  updatePackagingTemplate?: Maybe<PackagingTemplate>;
  removePackagingTemplate?: Maybe<Scalars['Boolean']>;
  importProductListFromFile?: Maybe<ProductListUpload>;
  updateProduct?: Maybe<Product>;
  createProduct?: Maybe<Product>;
  removeProduct?: Maybe<Scalars['Boolean']>;
  removeWeight?: Maybe<Parcel>;
  debitingAndCreditingFunds?: Maybe<Transaction>;
  importOfActualWeight?: Maybe<Scalars['Boolean']>;
  userDiscountManagement?: Maybe<Discount>;
  updateEntrySelfExport?: Maybe<CdekParcel>;
  registrationOrder?: Maybe<CdekEntityResponse>;
  cancelOrder?: Maybe<CdekEntityResponse>;
  removeOrder?: Maybe<CdekEntityResponse>;
  cancelEntrySelfExport?: Maybe<Scalars['String']>;
  signIn?: Maybe<LoginResponse>;
  signInAdmin?: Maybe<LoginResponse>;
  signUp?: Maybe<LoginResponse>;
  addEmail: User;
  authFacebook?: Maybe<LoginResponse>;
  authGoogle?: Maybe<LoginResponse>;
  authVkontakte?: Maybe<LoginResponse>;
  createUser?: Maybe<User>;
  editCurrentUser?: Maybe<User>;
  saveNewPassword: Status;
  createContact?: Maybe<Contact>;
  updateContact?: Maybe<Contact>;
  removeContact: Status;
  createParcel?: Maybe<Parcel>;
  cancelParcel: Parcel;
  editActualWeightParcel?: Maybe<Parcel>;
  editTrackNumberParcel?: Maybe<Parcel>;
  createPayment?: Maybe<Scalars['String']>;
  removeTracking?: Maybe<Parcel>;
  saveOrSendConsolidation?: Maybe<Consolidation>;
  updateTrackNumberConsolidation?: Maybe<Consolidation>;
  cancelConsolidation?: Maybe<Scalars['Boolean']>;
  createWarehouseZone?: Maybe<WarehouseZone>;
  updateWarehouseZone?: Maybe<WarehouseZone>;
  deleteWarehouseZone?: Maybe<Scalars['Boolean']>;
  createWarehouseZoneCell?: Maybe<WarehouseZoneCell>;
  updateWarehouseZoneCell?: Maybe<WarehouseZoneCell>;
  deleteWarehouseZoneCell?: Maybe<Scalars['Boolean']>;
  uploadWarehouseZoneCells?: Maybe<UploadWarehouseZoneCellsResult>;
  swapWarehouseCellProducs?: Maybe<Scalars['Boolean']>;
  synchronizeWarehouseCellProducs?: Maybe<Scalars['Boolean']>;
};


export type MutationCancelWarehouseShipmentArgs = {
  id: Scalars['Int'];
};


export type MutationChangePasswordArgs = {
  oldPassword: Scalars['String'];
  newPassword: Scalars['String'];
};


export type MutationUploadMarketplaceInvoiceArgs = {
  file: Scalars['Upload'];
};


export type MutationPickingWithoutScanningArgs = {
  shipmentBarcodeId: Scalars['String'];
};


export type MutationPickingWithScanningArgs = {
  shipmentBarcodeId: Scalars['String'];
  productBarcodeId: Scalars['String'];
  quantity?: Maybe<Scalars['Int']>;
  cellId?: Maybe<Scalars['ID']>;
};


export type MutationCreateReportByShipmentsArgs = {
  input?: Maybe<ReportRangeInput>;
};


export type MutationCreateReportByProductsArgs = {
  input?: Maybe<ReportProductInput>;
};


export type MutationCreateReportByTransactionsArgs = {
  input?: Maybe<ReportRangeInput>;
};


export type MutationCreateReportByFulfillmentArgs = {
  input?: Maybe<ReportFulfillment>;
};


export type MutationArrivalOfTheParcelForAdminArgs = {
  id: Scalars['Int'];
};


export type MutationSetProductActualDimensionsArgs = {
  id: Scalars['Int'];
  weight: Scalars['Float'];
  width: Scalars['Float'];
  length: Scalars['Float'];
  height: Scalars['Float'];
};


export type MutationCreateQrPaymentArgs = {
  value: Scalars['Float'];
};


export type MutationAddEventToWarehouseShipmentArgs = {
  warehouseShipmentId: Scalars['Int'];
  operation?: Maybe<WarehouseOperationForUser>;
};


export type MutationAddTrackNumberArgs = {
  shipmentId: Scalars['Int'];
  trackNumber: Scalars['String'];
  carrierCode: Scalars['String'];
};


export type MutationRemoveTrackNumberArgs = {
  shipmentId: Scalars['Int'];
};


export type MutationRemoveWarehouseShipmentArgs = {
  id: Scalars['Int'];
};


export type MutationSetArrivalWarehouseShipmentArgs = {
  identifier?: Maybe<Scalars['String']>;
  acceptWithDiscrepancy?: Maybe<Scalars['Boolean']>;
};


export type MutationSetArrivalWarehouseShipmentItemArgs = {
  identifier?: Maybe<Scalars['String']>;
  shipmentId: Scalars['ID'];
  quantity: Scalars['Int'];
};


export type MutationChangeProductAvalibilityArgs = {
  productId: Scalars['Int'];
  warehouseId?: Maybe<Scalars['Int']>;
  quantity: Scalars['Int'];
  operation?: Maybe<Availability_Operation>;
  comment?: Maybe<Scalars['String']>;
  cellId: Scalars['ID'];
};


export type MutationReturnOfTheParcelArgs = {
  identifier?: Maybe<Scalars['String']>;
};


export type MutationCreateWarehouseShipmentUseProductsArgs = {
  input?: Maybe<WarehouseShipmentUseProductInput>;
};


export type MutationCreateWarehouseShipmentUseParcelsArgs = {
  input?: Maybe<WarehouseShipmentUseParcelInput>;
};


export type MutationCreateWarehouseShipmentOrderArgs = {
  data: CreateWarehouseShipmentOrderInput;
};


export type MutationSendWarehouseShipmentOrderArgs = {
  data: SendWarehouseShipmentOrderInput;
};


export type MutationCancelWarehouseShipmentOrderArgs = {
  id: Scalars['ID'];
};


export type MutationCreateWarehouseArgs = {
  input?: Maybe<WarehouseInput>;
};


export type MutationUpdateWarehouseArgs = {
  id: Scalars['Int'];
  input?: Maybe<WarehouseInput>;
};


export type MutationRemoveWarehouseArgs = {
  id: Scalars['Int'];
};


export type MutationCreateManagerArgs = {
  input?: Maybe<ManagerInput>;
};


export type MutationSignInManagerArgs = {
  password: Scalars['String'];
  email: Scalars['String'];
};


export type MutationUpdateManagerArgs = {
  input?: Maybe<ManagerInput>;
  id: Scalars['Int'];
};


export type MutationRemoveManagerArgs = {
  id: Scalars['Int'];
};


export type MutationAddEntityLegalDataArgs = {
  input?: Maybe<EntityLegalDataInput>;
  file?: Maybe<Scalars['Upload']>;
};


export type MutationUpdateEntityLegalDataArgs = {
  input?: Maybe<EntityLegalUpdateDataInput>;
  file?: Maybe<Scalars['Upload']>;
};


export type MutationVerifyLegalDataArgs = {
  entityLegalDataId: Scalars['Int'];
};


export type MutationUpdateCreditLimitForUserArgs = {
  userId: Scalars['Int'];
  value: Scalars['Int'];
};


export type MutationAddPassportDataArgs = {
  input?: Maybe<UserPassportDataInput>;
  file?: Maybe<Scalars['Upload']>;
};


export type MutationUpdatePassportDataArgs = {
  input?: Maybe<UserPassportUpdateDataInput>;
  file?: Maybe<Scalars['Upload']>;
};


export type MutationVerifyPassportDataArgs = {
  passportId: Scalars['Int'];
};


export type MutationAddEtsyTrackNumberArgs = {
  storeId: Scalars['Int'];
  carrier_name: Scalars['String'];
  receipt_id: Scalars['String'];
  send_bcc?: Maybe<Scalars['Boolean']>;
  tracking_code: Scalars['String'];
};


export type MutationAddPackageToOrderArgs = {
  packageId?: Maybe<Scalars['Int']>;
  orderId: Scalars['Int'];
  weight?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  length?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
};


export type MutationRemoveStoreArgs = {
  marketplaceId: Scalars['Int'];
};


export type MutationUpdateStoreArgs = {
  input?: Maybe<StoreInput>;
};


export type MutationAddStoreArgs = {
  name?: Maybe<Scalars['String']>;
  url?: Maybe<Scalars['String']>;
  storeId?: Maybe<Scalars['Int']>;
  marketplace?: Maybe<Scalars['String']>;
};


export type MutationSwitchStoreStatusArgs = {
  storeId: Scalars['ID'];
};


export type MutationCreateShipmentRequestArgs = {
  input?: Maybe<ShipmentInput>;
};


export type MutationCreateRequestToCallCourierArgs = {
  input?: Maybe<CourierRequestInput>;
};


export type MutationCreateSenderAccountArgs = {
  input?: Maybe<SenderAccountInput>;
};


export type MutationUpdateSenderAccountArgs = {
  input?: Maybe<SenderAccountInput>;
};


export type MutationRemoveSenderAccountArgs = {
  id: Scalars['Int'];
};


export type MutationAddTariffArgs = {
  input: TariffInput;
};


export type MutationUpdateTariffArgs = {
  input: TariffInput;
};


export type MutationRemoveTariffArgs = {
  weight: Scalars['Float'];
};


export type MutationAddConfigurationArgs = {
  key: Scalars['String'];
  value: Scalars['JSON'];
};


export type MutationUpdateConfigurationArgs = {
  input: Array<Maybe<ConfigInput>>;
};


export type MutationCreatePackagingTemplateArgs = {
  input?: Maybe<PackagingTemplateInput>;
};


export type MutationUpdatePackagingTemplateArgs = {
  input?: Maybe<PackagingTemplateInput>;
};


export type MutationRemovePackagingTemplateArgs = {
  id: Scalars['Int'];
};


export type MutationImportProductListFromFileArgs = {
  file?: Maybe<Scalars['Upload']>;
};


export type MutationUpdateProductArgs = {
  product?: Maybe<ProductInput>;
};


export type MutationCreateProductArgs = {
  product?: Maybe<ProductInput>;
};


export type MutationRemoveProductArgs = {
  productId: Scalars['Int'];
};


export type MutationRemoveWeightArgs = {
  parcelId: Scalars['Int'];
};


export type MutationDebitingAndCreditingFundsArgs = {
  userId: Scalars['Int'];
  amount: Scalars['Float'];
  reason: Scalars['String'];
  isDebiting?: Maybe<Scalars['Boolean']>;
  type: TransactionTypes;
};


export type MutationImportOfActualWeightArgs = {
  file: Scalars['Upload'];
};


export type MutationUserDiscountManagementArgs = {
  userId: Scalars['Int'];
  discount?: Maybe<Scalars['Float']>;
  whFeePersonalDiscount?: Maybe<Scalars['Float']>;
};


export type MutationUpdateEntrySelfExportArgs = {
  parcelId: Scalars['Int'];
  parcelIds: Array<Maybe<Scalars['Int']>>;
};


export type MutationRegistrationOrderArgs = {
  registrationOrder: CdekRegistrationOrderInpur;
  registrationCourier?: Maybe<CdekCourierCreateRequest>;
  uuidToUpdateTheRecord?: Maybe<Scalars['String']>;
};


export type MutationCancelOrderArgs = {
  uuid: Scalars['String'];
};


export type MutationRemoveOrderArgs = {
  uuid: Scalars['String'];
};


export type MutationCancelEntrySelfExportArgs = {
  parcelId: Scalars['Int'];
};


export type MutationSignInArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
  rememberMe?: Maybe<Scalars['Boolean']>;
};


export type MutationSignInAdminArgs = {
  email: Scalars['String'];
  password: Scalars['String'];
  rememberMe?: Maybe<Scalars['Boolean']>;
};


export type MutationSignUpArgs = {
  email: Scalars['String'];
  name: Scalars['String'];
  password: Scalars['String'];
};


export type MutationAddEmailArgs = {
  email: Scalars['String'];
};


export type MutationAuthFacebookArgs = {
  accessToken: Scalars['String'];
};


export type MutationAuthGoogleArgs = {
  accessToken: Scalars['String'];
};


export type MutationAuthVkontakteArgs = {
  accessToken: Scalars['String'];
};


export type MutationCreateUserArgs = {
  name: Scalars['String'];
  email: Scalars['String'];
  company?: Maybe<Scalars['String']>;
  countryId: Scalars['Int'];
  address?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  address3?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  eoriCode?: Maybe<Scalars['String']>;
};


export type MutationEditCurrentUserArgs = {
  name: Scalars['String'];
  company?: Maybe<Scalars['String']>;
  countryId: Scalars['Int'];
  address?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  address3?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  eoriCode?: Maybe<Scalars['String']>;
};


export type MutationSaveNewPasswordArgs = {
  token: Scalars['String'];
  password: Scalars['String'];
};


export type MutationCreateContactArgs = {
  input: ContactInput;
};


export type MutationUpdateContactArgs = {
  id: Scalars['ID'];
  input: ContactInput;
};


export type MutationRemoveContactArgs = {
  id: Scalars['ID'];
};


export type MutationCreateParcelArgs = {
  userId?: Maybe<Scalars['Int']>;
  input: ParcelInput;
  registrationCourier?: Maybe<CdekCourierRequestInput>;
  registrationOrder?: Maybe<CdekRegistrationOrderInput>;
};


export type MutationCancelParcelArgs = {
  id: Scalars['ID'];
};


export type MutationEditActualWeightParcelArgs = {
  id: Scalars['ID'];
  actualWeight: Scalars['Float'];
};


export type MutationEditTrackNumberParcelArgs = {
  id: Scalars['ID'];
  trackNumber: Scalars['String'];
  carrierCode: Scalars['String'];
};


export type MutationCreatePaymentArgs = {
  value?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  parcelId?: Maybe<Scalars['Int']>;
};


export type MutationRemoveTrackingArgs = {
  carrierCode: Scalars['String'];
  barCode: Scalars['String'];
};


export type MutationSaveOrSendConsolidationArgs = {
  parcelsIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  consolidationId?: Maybe<Scalars['ID']>;
  accountId?: Maybe<Scalars['ID']>;
  shipmentData: ShipmentDataSend;
  doSend?: Maybe<Scalars['Boolean']>;
};


export type MutationUpdateTrackNumberConsolidationArgs = {
  id?: Maybe<Scalars['ID']>;
  trackNumber?: Maybe<Scalars['String']>;
  carrierCode?: Maybe<Scalars['String']>;
};


export type MutationCancelConsolidationArgs = {
  id: Scalars['ID'];
};


export type MutationCreateWarehouseZoneArgs = {
  name: Scalars['String'];
};


export type MutationUpdateWarehouseZoneArgs = {
  id: Scalars['ID'];
  data: UpdateWarehouseZone;
};


export type MutationDeleteWarehouseZoneArgs = {
  id: Scalars['ID'];
};


export type MutationCreateWarehouseZoneCellArgs = {
  data: CreateWarehouseZoneCell;
};


export type MutationUpdateWarehouseZoneCellArgs = {
  id: Scalars['ID'];
  data: UpdateWarehouseZoneCell;
};


export type MutationDeleteWarehouseZoneCellArgs = {
  id: Scalars['ID'];
};


export type MutationUploadWarehouseZoneCellsArgs = {
  file: Scalars['Upload'];
};


export type MutationSwapWarehouseCellProducsArgs = {
  input: SwapWarehouseCellProducs;
};


export type MutationSynchronizeWarehouseCellProducsArgs = {
  productId: Scalars['Int'];
  cellToId: Scalars['Int'];
};

export type OnlyPackage = {
  weightKg: Scalars['Float'];
  lengthCm: Scalars['Float'];
  widthCm: Scalars['Float'];
  heightCm: Scalars['Float'];
};

export type Order = {
  __typename?: 'Order';
  id?: Maybe<Scalars['Int']>;
  marketplace?: Maybe<Scalars['String']>;
  orderId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  isPaid?: Maybe<Scalars['Boolean']>;
  userId?: Maybe<Scalars['Int']>;
  country?: Maybe<Scalars['String']>;
  addressFirstLine?: Maybe<Scalars['String']>;
  addressSecondLine?: Maybe<Scalars['String']>;
  zip?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  deliveryType?: Maybe<Scalars['String']>;
  deliveryPrice?: Maybe<Scalars['Float']>;
  shippingMethod?: Maybe<Scalars['String']>;
  isShipped?: Maybe<Scalars['Boolean']>;
  orderProducts?: Maybe<Array<Maybe<OrderProduct>>>;
  package?: Maybe<PackagingTemplate>;
  itemId?: Maybe<Scalars['String']>;
  receiverName?: Maybe<Scalars['String']>;
  storeId?: Maybe<Scalars['String']>;
  /** Recipient phone number */
  phone?: Maybe<Scalars['String']>;
};

export type OrderList = {
  __typename?: 'OrderList';
  count?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Order>>>;
};

export type OrderProduct = {
  __typename?: 'OrderProduct';
  id?: Maybe<Scalars['Int']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Float']>;
  length?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Int']>;
  priceAmount?: Maybe<Scalars['Float']>;
  priceCurrency?: Maybe<Scalars['String']>;
  shippingCostAmount?: Maybe<Scalars['Float']>;
  shippingCostCurrency?: Maybe<Scalars['String']>;
  sku?: Maybe<Scalars['String']>;
};

export type OriginServiceAreaInfo = {
  __typename?: 'OriginServiceAreaInfo';
  /** The FacilityCode element is the origin facility code of the shipment. */
  FacilityCode?: Maybe<Scalars['String']>;
  /** The ServiceAreaCode element is the origin service area code of the shipment. */
  ServiceAreaCode?: Maybe<Scalars['String']>;
};

export type Package = {
  __typename?: 'Package';
  id: Scalars['ID'];
  quantity?: Maybe<Scalars['Int']>;
  weightKg?: Maybe<Scalars['Float']>;
  lengthCm?: Maybe<Scalars['Float']>;
  widthCm?: Maybe<Scalars['Float']>;
  heightCm?: Maybe<Scalars['Float']>;
  units?: Maybe<Array<Maybe<ParcelUnit>>>;
};

export type PackageInput = {
  weightKg: Scalars['Float'];
  lengthCm: Scalars['Float'];
  widthCm: Scalars['Float'];
  heightCm: Scalars['Float'];
  units: Array<Maybe<ParcelUnitInput>>;
};

export type PackagingTemplate = {
  __typename?: 'PackagingTemplate';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Float']>;
  length?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  userId?: Maybe<Scalars['Int']>;
};

export type PackagingTemplateInput = {
  id?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  weight: Scalars['Float'];
  length: Scalars['Float'];
  width: Scalars['Float'];
  height: Scalars['Float'];
};

export type PackagingTemplateList = {
  __typename?: 'PackagingTemplateList';
  count?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<PackagingTemplate>>>;
};

export type Parcel = {
  __typename?: 'Parcel';
  id: Scalars['ID'];
  sender: ParcelContact;
  receiver: ParcelContact;
  packages: Array<Maybe<Package>>;
  deliveryStatus?: Maybe<StatusDelivery>;
  paymentStatus?: Maybe<StatusPayment>;
  fileTokenInvoiceXlsx?: Maybe<Scalars['String']>;
  senderNotes?: Maybe<Scalars['String']>;
  additionalInsurance: Scalars['Boolean'];
  insuranceAmount?: Maybe<Scalars['Float']>;
  signature?: Maybe<Scalars['Boolean']>;
  declaredWeight?: Maybe<Scalars['Float']>;
  actualWeight?: Maybe<Scalars['Float']>;
  declaredAmount?: Maybe<Scalars['Float']>;
  actualAmount?: Maybe<Scalars['Float']>;
  trackNumber?: Maybe<Scalars['String']>;
  carrierCode?: Maybe<Scalars['String']>;
  paymentStatusId?: Maybe<Scalars['Int']>;
  deliveryStatusId?: Maybe<Scalars['Int']>;
  user?: Maybe<User>;
  transactions?: Maybe<Array<Maybe<Transaction>>>;
  currency?: Maybe<Scalars['String']>;
  senderMark?: Maybe<Scalars['String']>;
  sentToWarehouseId?: Maybe<Scalars['Int']>;
  isDiscountUsed?: Maybe<Scalars['Boolean']>;
  typeDelivery?: Maybe<TypeDelivery>;
  exportReason?: Maybe<Scalars['String']>;
  paymentOfTaxes?: Maybe<Scalars['String']>;
  vatID?: Maybe<Scalars['String']>;
  /** Tax ID, VAT, IOSS, EMPTY */
  vatKey?: Maybe<Vatkey>;
  pathNameFile?: Maybe<Scalars['String']>;
  /** fuel surcharge at the time of order creation */
  fuelSurcharge?: Maybe<Scalars['Float']>;
  /** dollar rate at the moment of order creation */
  dollarRate?: Maybe<Scalars['Float']>;
  consolidationTrackNumber?: Maybe<ConsolidationTrackNumberObject>;
  barcode?: Maybe<Scalars['String']>;
  isConsolidationPiece?: Maybe<Scalars['Boolean']>;
  warehouseShipmentId?: Maybe<Scalars['Int']>;
  accepted?: Maybe<Scalars['Boolean']>;
  orderCostCurrency?: Maybe<Scalars['String']>;
  handlingFee?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  warehouseId?: Maybe<Scalars['Int']>;
  warehouse?: Maybe<Warehouse>;
};

export type ParcelContact = {
  __typename?: 'ParcelContact';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  country?: Maybe<DeliveryCountry>;
  address?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  address3?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  innKppCode?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  eoriCode?: Maybe<Scalars['String']>;
};

export type ParcelContactInput = {
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  countryId: Scalars['Int'];
  address?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  address3?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  city: Scalars['String'];
  state?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  innKppCode?: Maybe<Scalars['String']>;
  remark?: Maybe<Scalars['String']>;
  eoriCode?: Maybe<Scalars['String']>;
};

export type ParcelInput = {
  sender: ParcelContactInput;
  receiver: ParcelContactInput;
  packages: Array<Maybe<PackageInput>>;
  typeDeliveryId?: Maybe<Scalars['Int']>;
  labelDataEasyPost?: Maybe<LabelDataEasyPost>;
  declaredWeight?: Maybe<Scalars['Float']>;
  declaredAmount?: Maybe<Scalars['Float']>;
  senderNotes?: Maybe<Scalars['String']>;
  additionalInsurance: Scalars['Boolean'];
  insuranceAmount?: Maybe<Scalars['Float']>;
  signature?: Maybe<Scalars['Boolean']>;
  carrierCode?: Maybe<Scalars['String']>;
  userId?: Maybe<Scalars['Int']>;
  currency: Currency;
  senderMark?: Maybe<Scalars['String']>;
  /** Format: YY-MM-DD */
  intake_date?: Maybe<Scalars['String']>;
  /** Format: HH:MM */
  intake_time_from?: Maybe<Scalars['String']>;
  /** Sender street */
  street?: Maybe<Scalars['String']>;
  /** Format: HH:MM */
  intake_time_to?: Maybe<Scalars['String']>;
  /** Description of cargo */
  name?: Maybe<Scalars['String']>;
  /** Sender house number  */
  houseNumber?: Maybe<Scalars['String']>;
  apartmentNumber?: Maybe<Scalars['String']>;
  /** Marketplace order ID */
  orderId?: Maybe<Scalars['Int']>;
  /** Available variants: COMMERCIAL(default), GIFT, SAMPLE, RETURN, REPAIR */
  exportReason?: Maybe<Scalars['String']>;
  /** Available varians: CONSIGNEE(default), SENDER */
  paymentOfTaxes?: Maybe<Scalars['String']>;
  /** Tax ID, VAT, IOSS, EMPTY */
  vatKey?: Maybe<Vatkey>;
  vatValue?: Maybe<Scalars['String']>;
  warehouseId?: Maybe<Scalars['Int']>;
  globalProductCode?: Maybe<Scalars['String']>;
  /** fuel surcharge at the time of order creation */
  fuelSurcharge?: Maybe<Scalars['Float']>;
  /** dollar rate at the moment of order creation */
  dollarRate?: Maybe<Scalars['Float']>;
  orderCostCurrency?: Maybe<Scalars['String']>;
  handlingFee?: Maybe<Scalars['Float']>;
};

export type ParcelPaginationResponse = {
  __typename?: 'ParcelPaginationResponse';
  count: Scalars['Int'];
  rows: Array<Maybe<Parcel>>;
};

export enum ParcelType {
  /** For use is like FROM MY WAREHOUSE */
  Shipment = 'SHIPMENT',
  Forwarding = 'FORWARDING',
  Custom = 'CUSTOM'
}

export type ParcelUnit = {
  __typename?: 'ParcelUnit';
  id: Scalars['ID'];
  nameRU?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['BigInt']>;
  quantity?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Float']>;
  country?: Maybe<DeliveryCountry>;
  netWeight?: Maybe<Scalars['Float']>;
  parcel?: Maybe<Parcel>;
  nameEN?: Maybe<Scalars['String']>;
  tradeMark?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

export type ParcelUnitInput = {
  nameRU: Scalars['String'];
  code?: Maybe<Scalars['BigInt']>;
  quantity: Scalars['Int'];
  price: Scalars['Float'];
  countryId: Scalars['Int'];
  netWeight: Scalars['Float'];
  nameEN?: Maybe<Scalars['String']>;
  tradeMark?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
};

export type PaymentAmount = {
  __typename?: 'PaymentAmount';
  value?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
};

export type PaymentConfirmation = {
  __typename?: 'PaymentConfirmation';
  type?: Maybe<Scalars['String']>;
  return_url?: Maybe<Scalars['String']>;
  confirmation_url?: Maybe<Scalars['String']>;
};

export type PaymentMethod = {
  __typename?: 'PaymentMethod';
  type?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  saved?: Maybe<Scalars['Boolean']>;
  title?: Maybe<Scalars['String']>;
};

export type Piece = {
  __typename?: 'Piece';
  weight?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  length?: Maybe<Scalars['Float']>;
};

export type PieceInput = {
  weight: Scalars['Float'];
  width: Scalars['Float'];
  height: Scalars['Float'];
  length: Scalars['Float'];
};

export type Product = {
  __typename?: 'Product';
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  sku?: Maybe<Scalars['String']>;
  detailsDescriptionRU?: Maybe<Scalars['String']>;
  detailsDescriptionEN?: Maybe<Scalars['String']>;
  state?: Maybe<ProdyctState>;
  trademark?: Maybe<Scalars['String']>;
  declaredValue?: Maybe<Scalars['Float']>;
  currency?: Maybe<Scalars['String']>;
  country?: Maybe<DeliveryCountry>;
  netWeight?: Maybe<Scalars['Float']>;
  code?: Maybe<Scalars['BigInt']>;
  userId?: Maybe<Scalars['Int']>;
  owner?: Maybe<User>;
  fnsku?: Maybe<Scalars['String']>;
  upc?: Maybe<Scalars['String']>;
  actualDimensions?: Maybe<CargoPlace>;
  declaredDimensions?: Maybe<CargoPlace>;
  barcodeId?: Maybe<Scalars['String']>;
  isCustomBarcodeId?: Maybe<Scalars['Boolean']>;
  createdAt?: Maybe<Scalars['String']>;
  productAvailabilityQuantity?: Maybe<WarehouseProduct>;
};

export type ProductIdAndQuantity = {
  id?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
};

export type ProductInParcel = {
  __typename?: 'ProductInParcel';
  id?: Maybe<Scalars['Int']>;
  warehouseShipmentId?: Maybe<Scalars['Int']>;
  product?: Maybe<Product>;
  quantity?: Maybe<Scalars['Int']>;
  arrivalQuantity?: Maybe<Scalars['Int']>;
};

export type ProductInput = {
  id?: Maybe<Scalars['Int']>;
  name: Scalars['String'];
  sku?: Maybe<Scalars['String']>;
  detailsDescriptionRU: Scalars['String'];
  detailsDescriptionEN: Scalars['String'];
  state?: Maybe<ProdyctState>;
  trademark?: Maybe<Scalars['String']>;
  declaredValue: Scalars['Float'];
  currency: Scalars['String'];
  countryId: Scalars['Int'];
  netWeight: Scalars['Float'];
  code?: Maybe<Scalars['BigInt']>;
  fnsku?: Maybe<Scalars['String']>;
  upc?: Maybe<Scalars['String']>;
  customBarcodeId?: Maybe<Scalars['String']>;
  package?: Maybe<PieceInput>;
};

export type ProductList = {
  __typename?: 'ProductList';
  count?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Product>>>;
};

export type ProductListUpload = {
  __typename?: 'ProductListUpload';
  products?: Maybe<ProductList>;
  messages?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export enum ProdyctState {
  New = 'NEW',
  Used = 'USED',
  Unknown = 'UNKNOWN'
}

export type QtdSInAdCurInfo = {
  __typename?: 'QtdSInAdCurInfo';
  /** The CurrencyCode element indicates the currency code of the service charge value. */
  CurrencyCode?: Maybe<Scalars['String']>;
  /**
   * The CurrencyRoleTypeCode element is the roles in which the currency used in extra charge
   * calculation.
   */
  CurrencyRoleTypeCode?: Maybe<Scalars['String']>;
  /** The WeightChargeTaxDet element contains the weight charge tax breakdown pricing details. */
  WeightCharge?: Maybe<Scalars['Float']>;
  /** The Total Amount */
  TotalAmount?: Maybe<Scalars['Float']>;
  /** The TotalTaxAmount element is the total tax for weight and all applicable charges. */
  TotalTaxAmount?: Maybe<Scalars['Float']>;
  /** The WeightChargeTax element contains the tax type amount for the weight charge. */
  WeightChargeTax?: Maybe<Scalars['Float']>;
};

export type Query = {
  __typename?: 'Query';
  checkWarehouseShipmentByIdentifiers?: Maybe<CheckParcels>;
  getWarehouseShipments?: Maybe<WarehouseShipmentListForManager>;
  getWarehouseShipmentOrderInfo?: Maybe<WarehouseShipmentInfoForManager>;
  getScanInfo?: Maybe<ArrivalResponceObject>;
  currentManager?: Maybe<Manager>;
  getManagers?: Maybe<ManagerList>;
  getManager?: Maybe<Manager>;
  getParcelsByType?: Maybe<ShipmentOrderList>;
  getReports?: Maybe<ReportList>;
  getWarehouseShipmentInfo?: Maybe<WarehouseShipment>;
  getListOfReturnedShipments?: Maybe<WarehouseShipmentsList>;
  getWarehouseShipmentsList?: Maybe<WarehouseShipmentsList>;
  getShipmentBarcodeList?: Maybe<Scalars['String']>;
  getAnalyticData?: Maybe<AnalyticData>;
  converCurrency?: Maybe<Scalars['Float']>;
  getWarehousesList?: Maybe<WarehousesList>;
  getWarehouse?: Maybe<Warehouse>;
  getAvaliableWarehouseProductById?: Maybe<WarehouseProduct>;
  getAvaliableWarehouseProduct?: Maybe<WarehouseShipmentProductsList>;
  getWarehouseShipmentOrderById?: Maybe<WarehouseShipmentOrder>;
  getWarehouseShipmentOrders?: Maybe<WarehouseShipmentOrdersList>;
  getUser?: Maybe<User>;
  getCountriesByISO?: Maybe<Array<Maybe<DeliveryCountry>>>;
  getListOfTypesDelivery?: Maybe<TypeDelivery>;
  getOrders?: Maybe<OrderList>;
  getOrderById?: Maybe<Order>;
  getCDEKTariffs?: Maybe<Scalars['JSON']>;
  getMyStores?: Maybe<Array<Maybe<Store>>>;
  generateUrlForAuthorizationInEtsy?: Maybe<Scalars['String']>;
  generateUrlForAuthorizationInEbay?: Maybe<Scalars['String']>;
  generateUrlForAuthorizationInAmazon?: Maybe<Scalars['String']>;
  getRegionsCodes?: Maybe<Array<Maybe<Scalars['String']>>>;
  getListOfDeliveryOptions?: Maybe<CalculatorResponse>;
  validationAddress?: Maybe<ValidationAddressResponse>;
  getQuote?: Maybe<QuoteResponse>;
  getMyProductList?: Maybe<ProductList>;
  getMyProductById?: Maybe<Product>;
  getTheExchangeRate?: Maybe<ExchangeRateResponse>;
  currentUser?: Maybe<User>;
  users: UserPaginationResponse;
  contacts?: Maybe<Array<Maybe<Contact>>>;
  contact?: Maybe<Contact>;
  parcel: Parcel;
  parcels: ParcelPaginationResponse;
  repeatConfirmLetter: Status;
  recoveryPassword: Status;
  trackParcel?: Maybe<TrackParcelResponse>;
  getStatusesList?: Maybe<Statuses>;
  getBalance?: Maybe<BalanceResponse>;
  getPaymentHistoryListByUserId?: Maybe<TransactionList>;
  deliveryCountries?: Maybe<Array<Maybe<DeliveryCountry>>>;
  getLocationList?: Maybe<CdekLocationList>;
  getTariffsByParcel?: Maybe<Array<Maybe<CdekCalculationByTariffCodeResponse>>>;
  getOrderInformation?: Maybe<CdekInfoAboutOrderResponse>;
  getBarcodeByParcelUuid?: Maybe<Scalars['String']>;
  weigtToPayValidator?: Maybe<Scalars['Boolean']>;
  getRegionList?: Maybe<Array<Maybe<CdekRegionListResponse>>>;
  getOfficeList?: Maybe<Array<Maybe<CdekOfficeListResponse>>>;
  getCityList?: Maybe<Array<Maybe<CdekCitiesListResponse>>>;
  getOrderList?: Maybe<CdekOrderListInfo>;
  getInformationOrderByUuid?: Maybe<CdekInfoAboutOrderResponse>;
  getPackagingTemplatesList?: Maybe<PackagingTemplateList>;
  getPackagingTemplateById?: Maybe<PackagingTemplate>;
  getTariffsList?: Maybe<TariffList>;
  getConfigurationList?: Maybe<Scalars['JSON']>;
  getPublicConfigurations?: Maybe<Scalars['JSON']>;
  getSenderAccountList?: Maybe<ListOfSenderAccounts>;
  getSenderAccountById?: Maybe<SenderAccount>;
  getCourierList?: Maybe<CourierList>;
  getCourierById?: Maybe<CourierResponse>;
  getDHLShipmentList?: Maybe<ShipmentResponseList>;
  getDHLShipmentById?: Maybe<ShipmentDataResponse>;
  getCitiesByCountryIso?: Maybe<Array<Maybe<CityInfo>>>;
  getCityByName?: Maybe<Array<Maybe<CityInfo>>>;
  getConsolidationsDeliveryVariants?: Maybe<AdminConsolidationRateResponse>;
  getConsolidationsList?: Maybe<ConsolidationList>;
  getConsolidationById?: Maybe<Consolidation>;
  getTransactionTypesList?: Maybe<Array<Maybe<TransactionTypeItem>>>;
  getWarehouseZones?: Maybe<Array<Maybe<WarehouseZone>>>;
  getWarehouseZoneById?: Maybe<WarehouseZone>;
  getWarehouseZoneCellsResidues?: Maybe<WarehouseCellProductList>;
  getWarehouseZoneCells?: Maybe<WarehouseZoneCellsResult>;
  getWarehouseZoneCellById?: Maybe<WarehouseZoneCell>;
  getRelatedWarehouseZoneCellsByProductId?: Maybe<Array<Maybe<WarehouseZoneCellByProduct>>>;
  suggesWarehousCellByProductQuantity?: Maybe<WarehouseZoneCellByProduct>;
  getDefaultRecevingWarehouseZoneCell?: Maybe<WarehouseZoneCell>;
  getWarehouseZoneCellProduct?: Maybe<WarehouseCellProduct>;
  getSwapHistoryWarehouseCellProduct?: Maybe<SwapHistoryWarehouseCellProductList>;
};


export type QueryCheckWarehouseShipmentByIdentifiersArgs = {
  identifiers?: Maybe<Array<Maybe<Scalars['String']>>>;
  identifiersType?: Maybe<IdentifiersType>;
};


export type QueryGetWarehouseShipmentsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  status?: Maybe<ShipmentWarehouseStatus>;
  sort?: Maybe<SortType>;
};


export type QueryGetWarehouseShipmentOrderInfoArgs = {
  barcodeId: Scalars['String'];
};


export type QueryGetScanInfoArgs = {
  barcode?: Maybe<Scalars['String']>;
  trackNumber?: Maybe<Scalars['String']>;
};


export type QueryGetManagersArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryGetManagerArgs = {
  id: Scalars['Int'];
};


export type QueryGetParcelsByTypeArgs = {
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['Int']>;
  type?: Maybe<ParcelType>;
  deliveryStatusId?: Maybe<Scalars['Int']>;
  paymentStatusId?: Maybe<Scalars['Int']>;
};


export type QueryGetReportsArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryGetWarehouseShipmentInfoArgs = {
  id: Scalars['Int'];
};


export type QueryGetListOfReturnedShipmentsArgs = {
  filter?: Maybe<WarehouseShipmentFilter>;
};


export type QueryGetWarehouseShipmentsListArgs = {
  filter?: Maybe<WarehouseShipmentFilter>;
  search?: Maybe<Scalars['String']>;
};


export type QueryGetShipmentBarcodeListArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryConverCurrencyArgs = {
  from: Currency;
  nominal: Scalars['Float'];
  to: Currency;
};


export type QueryGetWarehousesListArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  code?: Maybe<Scalars['String']>;
  operation?: Maybe<Warehouse_Operations>;
};


export type QueryGetWarehouseArgs = {
  id: Scalars['Int'];
};


export type QueryGetAvaliableWarehouseProductByIdArgs = {
  warehouseId?: Maybe<Scalars['ID']>;
  productId?: Maybe<Scalars['ID']>;
};


export type QueryGetAvaliableWarehouseProductArgs = {
  warehouseId?: Maybe<Scalars['ID']>;
  search?: Maybe<Scalars['String']>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
};


export type QueryGetWarehouseShipmentOrderByIdArgs = {
  id?: Maybe<Scalars['ID']>;
};


export type QueryGetWarehouseShipmentOrdersArgs = {
  data?: Maybe<WarehouseShipmentOrdersOptions>;
};


export type QueryGetUserArgs = {
  id: Scalars['Int'];
};


export type QueryGetCountriesByIsoArgs = {
  ISOList: Array<Maybe<Scalars['String']>>;
};


export type QueryGetOrdersArgs = {
  marketplace?: Maybe<Scalars['String']>;
  period?: Maybe<Scalars['Int']>;
  paymentStatus?: Maybe<Scalars['Boolean']>;
  shippedStatus?: Maybe<Scalars['Boolean']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
};


export type QueryGetOrderByIdArgs = {
  id: Scalars['Int'];
};


export type QueryGetCdekTariffsArgs = {
  fromLocation: CdekInputFilter;
  toLocation: CdekInputFilter;
  listOfPackage: Array<OnlyPackage>;
};


export type QueryGenerateUrlForAuthorizationInEtsyArgs = {
  name: Scalars['String'];
  url?: Maybe<Scalars['String']>;
  storeId: Scalars['Int'];
};


export type QueryGenerateUrlForAuthorizationInEbayArgs = {
  name: Scalars['String'];
  url?: Maybe<Scalars['String']>;
  storeId?: Maybe<Scalars['Int']>;
};


export type QueryGenerateUrlForAuthorizationInAmazonArgs = {
  name: Scalars['String'];
  merchantId: Scalars['String'];
  region: Scalars['String'];
};


export type QueryGetListOfDeliveryOptionsArgs = {
  input?: Maybe<CalculationInput>;
};


export type QueryValidationAddressArgs = {
  input?: Maybe<ValidationAddress>;
};


export type QueryGetQuoteArgs = {
  input?: Maybe<QuoteInput>;
};


export type QueryGetMyProductListArgs = {
  warehouseId?: Maybe<Scalars['ID']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
};


export type QueryGetMyProductByIdArgs = {
  productId: Scalars['Int'];
};


export type QueryUsersArgs = {
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
};


export type QueryContactsArgs = {
  filter?: Maybe<ContactFilter>;
};


export type QueryContactArgs = {
  id: Scalars['ID'];
};


export type QueryParcelArgs = {
  id: Scalars['ID'];
};


export type QueryParcelsArgs = {
  convert_to_usd?: Maybe<Scalars['Boolean']>;
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['Int']>;
  paymentStatusId?: Maybe<Scalars['Int']>;
  deliveryStatusId?: Maybe<Scalars['Int']>;
  isUserAccount?: Maybe<Scalars['Boolean']>;
};


export type QueryRecoveryPasswordArgs = {
  email: Scalars['String'];
};


export type QueryTrackParcelArgs = {
  carrier_code?: Maybe<Scalars['String']>;
  barcode?: Maybe<Scalars['String']>;
};


export type QueryGetBalanceArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  period?: Maybe<Scalars['Int']>;
  dateFrom?: Maybe<Scalars['String']>;
  dateTo?: Maybe<Scalars['String']>;
};


export type QueryGetPaymentHistoryListByUserIdArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
};


export type QueryGetLocationListArgs = {
  filters?: Maybe<CdekLocationFilters>;
};


export type QueryGetTariffsByParcelArgs = {
  calculationInput?: Maybe<CdekCalculationByTariffCodeInput>;
};


export type QueryGetOrderInformationArgs = {
  id: Scalars['Int'];
};


export type QueryGetBarcodeByParcelUuidArgs = {
  uuid: Scalars['String'];
};


export type QueryWeigtToPayValidatorArgs = {
  parcelIds?: Maybe<Array<Scalars['Int']>>;
  packages?: Maybe<Array<CargoPlaceInput>>;
};


export type QueryGetRegionListArgs = {
  filter?: Maybe<CdekRegionListFilterInput>;
};


export type QueryGetOfficeListArgs = {
  filter?: Maybe<CdekOfiiceListFilterInput>;
};


export type QueryGetCityListArgs = {
  filter?: Maybe<CdekCitiesListFilterInput>;
};


export type QueryGetOrderListArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  date?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['String']>;
};


export type QueryGetInformationOrderByUuidArgs = {
  uuid: Scalars['String'];
};


export type QueryGetPackagingTemplatesListArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
};


export type QueryGetPackagingTemplateByIdArgs = {
  id: Scalars['Int'];
};


export type QueryGetTariffsListArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryGetSenderAccountListArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryGetSenderAccountByIdArgs = {
  id: Scalars['Int'];
};


export type QueryGetCourierListArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryGetCourierByIdArgs = {
  id: Scalars['Int'];
};


export type QueryGetDhlShipmentListArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryGetDhlShipmentByIdArgs = {
  id: Scalars['Int'];
};


export type QueryGetCitiesByCountryIsoArgs = {
  iso: Scalars['String'];
};


export type QueryGetCityByNameArgs = {
  name: Scalars['String'];
};


export type QueryGetConsolidationsDeliveryVariantsArgs = {
  data: ShipmentDataRate;
};


export type QueryGetConsolidationsListArgs = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryGetConsolidationByIdArgs = {
  id: Scalars['ID'];
};


export type QueryGetTransactionTypesListArgs = {
  operation?: Maybe<Availability_Operation>;
};


export type QueryGetWarehouseZoneByIdArgs = {
  id: Scalars['ID'];
};


export type QueryGetWarehouseZoneCellsResiduesArgs = {
  options: GetWarehouseZoneCellResidues;
};


export type QueryGetWarehouseZoneCellsArgs = {
  search?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};


export type QueryGetWarehouseZoneCellByIdArgs = {
  id: Scalars['ID'];
};


export type QueryGetRelatedWarehouseZoneCellsByProductIdArgs = {
  productId: Scalars['ID'];
  fromCellId?: Maybe<Scalars['ID']>;
};


export type QuerySuggesWarehousCellByProductQuantityArgs = {
  options: SuggesWarehousCellByProductQuantity;
};


export type QueryGetWarehouseZoneCellProductArgs = {
  options: GetWarehouseZoneCellProduct;
};


export type QueryGetSwapHistoryWarehouseCellProductArgs = {
  options: GetSwapHistoryWarehouseCellProduct;
};

export type Quote = {
  __typename?: 'Quote';
  /**
   * The OriginServiceArea element is a complex type of element which contains the child
   * elements for origin service area and facility code details.
   */
  OriginServiceArea?: Maybe<OriginServiceAreaInfo>;
  /**
   * The DestinationServiceArea element is a complex type of element which contains the
   * child elements for destination service area and facility code details.
   */
  DestinationServiceArea?: Maybe<OriginServiceAreaInfo>;
  /**
   * The GlobalProductCode element is the global product code of the shipment. Please refer to the
   * Reference Data and use appropriate Global codes enabled for the country/region.
   */
  GlobalProductCode?: Maybe<Scalars['String']>;
  /** The LocalProductCode element is the local product code of the shipment. */
  LocalProductCode?: Maybe<Scalars['String']>;
  /** The ProductShortName element is the global product name of the shipment. */
  ProductShortName?: Maybe<Scalars['String']>;
  /** The LocalProductName element is the local product name of the shipment. */
  LocalProductName?: Maybe<Scalars['String']>;
  /** The NetworkTypeCode element is the network type of the product in the shipment. */
  NetworkTypeCode?: Maybe<Scalars['String']>;
  /**
   * The POfferedCustAgreement element indicates that the product or service or solution only can be
   * offered to a customer agreement that are not default to a billing entity.
   */
  POfferedCustAgreement?: Maybe<Scalars['String']>;
  /**
   * The TransInd element indicates the web user interface indicator. If value of Y, it is show on the
   * Web user interface.
   */
  TransInd?: Maybe<Scalars['String']>;
  /** The PickupDate element is the pick up date. */
  PickupDate?: Maybe<Scalars['String']>;
  /** The PickupCutoffTime element is the pick up cut off time. */
  PickupCutoffTime?: Maybe<Scalars['String']>;
  /** The BookingTime element is the booking cut off time. */
  BookingTime?: Maybe<Scalars['String']>;
  /** The ExchangeRate element is the exchange rate between EUR and charge currency code. */
  ExchangeRate?: Maybe<Scalars['Float']>;
  /** The WeightCharge element is the weight price including tax. */
  WeightCharge?: Maybe<Scalars['Float']>;
  /** The WeightChargeTax element is the tax for weight quote. */
  WeightChargeTax?: Maybe<Scalars['Float']>;
  /** The TotalTransitDays element is the number of transit days. */
  TotalTransitDays?: Maybe<Scalars['Int']>;
  /**
   * The PickupPostalLocAddDays element is the in-forwarding days. This is additional transit delays
   * (in days) for shipment picked up from the mentioned city or postal area to arrival at the service area.
   */
  PickupPostalLocAddDays?: Maybe<Scalars['Int']>;
  /**
   * The DeliveryPostalLocAddDays element is the on-forwarding days. This is additional transit delays
   * (in days) for shipment delivered to the mentioned city or postal area following arrival at the service
   * area.
   */
  DeliveryPostalLocAddDays?: Maybe<Scalars['Int']>;
  /** The DeliveryDate element is the date the shipment is delivered. */
  DeliveryDate?: Maybe<DeliveryDateInfo>;
  /**
   * The DeliveryTime element is the commited delivery by time (actual delivery can occur before this
   * time).
   */
  DeliveryTime?: Maybe<Scalars['String']>;
  /** The DimensionalWeight element is the volumetric weight of the shipment. */
  DimensionalWeight?: Maybe<Scalars['Float']>;
  /** The WeightUnit element is the volumetric weight unit of measurement of the shipment. */
  WeightUnit?: Maybe<Scalars['String']>;
  /** The PickupDayOfWeekNum element is the pickup day in week number. */
  PickupDayOfWeekNum?: Maybe<Scalars['Int']>;
  /** The DestinationDayOfWeekNum element is the destination day in week number. */
  DestinationDayOfWeekNum?: Maybe<Scalars['Int']>;
  /** The QuotedWeight element is the quoted weight of the shipment. */
  QuotedWeight?: Maybe<Scalars['Float']>;
  /** The QuotedWeightUOM element is the quoted weight unit of measurement of the shipment. */
  QuotedWeightUOM?: Maybe<Scalars['String']>;
  /** The PricingDate element is the date of the price. */
  PricingDate?: Maybe<Scalars['String']>;
  /** The ShippingCharge element is the total quote with tax for weight and all applicable charges. */
  ShippingCharge?: Maybe<Scalars['Float']>;
  /** The TotalTaxAmount element is the total tax for weight and all applicable charges. */
  TotalTaxAmount?: Maybe<Scalars['Float']>;
  /** The QtdSInAdCur element contains the optional services information. */
  QtdSInAdCur?: Maybe<Array<Maybe<QtdSInAdCurInfo>>>;
  /** The PickupWindowEarliestTime element contains the pickup window earliest time. */
  PickupWindowEarliestTime?: Maybe<Scalars['String']>;
  /** The PickupWindowLatestTime element contains the pickup window latest time. */
  PickupWindowLatestTime?: Maybe<Scalars['String']>;
  /** The BookingCutoffOffset element contains the booking cutoff offset. */
  BookingCutoffOffset?: Maybe<Scalars['String']>;
};

export type QuoteDetailsInput = {
  /**
   * The Date element contains the pickup date of the shipment. If the pu date is holiday, or weekend
   * and in TTS is not set pu capability, DCT can't offer pickup on that date. PU date is shifted just in
   * case the ready by time is after cut off time.
   */
  date?: Maybe<Scalars['String']>;
  /**
   * The Date element contains the pickup date of the shipment. If the pu date is holiday, or weekend
   * and in TTS is not set pu capability, DCT can't offer pickup on that date. PU date is shifted just in
   * case the ready by time is after cut off time.
   */
  readyTime?: Maybe<Scalars['String']>;
};

export type QuoteForCalculator = {
  __typename?: 'QuoteForCalculator';
  TypeDeliveryName?: Maybe<Scalars['String']>;
  accountName?: Maybe<Scalars['String']>;
  TypeDeliveryId?: Maybe<Scalars['Int']>;
  day_to_delivery?: Maybe<Scalars['Int']>;
  amount?: Maybe<Scalars['Float']>;
  error?: Maybe<Scalars['String']>;
  priceCurrency?: Maybe<Scalars['String']>;
  rateId?: Maybe<Scalars['String']>;
  shipmentId?: Maybe<Scalars['String']>;
};

export type QuoteFromToInput = {
  /** The CountryCode element contains the country/region code of the origin address. */
  countryCode?: Maybe<Scalars['String']>;
  /**
   * The Postalcode element contains the postal code of the origin address. It is required for countries
   * with postcodes and in correct format. Please refer to the Reference Data (DHL Country/Region) –
   * Postcode format.
   */
  postalCode?: Maybe<Scalars['String']>;
};

export type QuoteInput = {
  date: Scalars['String'];
  readyTime: Scalars['String'];
  contactId?: Maybe<Scalars['Int']>;
  parcelId: Scalars['Int'];
  senderAccountId: Scalars['Int'];
};

export type QuotePiecesInput = {
  id?: Maybe<Scalars['Int']>;
  height?: Maybe<Scalars['Float']>;
  length?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
  weight?: Maybe<Scalars['Float']>;
};

export type QuoteResponse = {
  __typename?: 'QuoteResponse';
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  rows?: Maybe<Array<Maybe<Quote>>>;
};

export type QuoteResponseForCalculator = {
  __typename?: 'QuoteResponseForCalculator';
  rows?: Maybe<Array<Maybe<QuoteForCalculator>>>;
};

export type RateUserAccount = {
  __typename?: 'RateUserAccount';
  name?: Maybe<Scalars['String']>;
  summary_amount?: Maybe<Scalars['Float']>;
  typeDelivery?: Maybe<Scalars['String']>;
  accountId?: Maybe<Scalars['Int']>;
  error?: Maybe<Scalars['String']>;
};

export type Report = {
  __typename?: 'Report';
  id: Scalars['ID'];
  userId: Scalars['Int'];
  type: ReportType;
  from: Scalars['String'];
  to: Scalars['String'];
  fileToken: Scalars['String'];
  productBarcode?: Maybe<Scalars['String']>;
  warehouseCode?: Maybe<Scalars['String']>;
  createdAt: Scalars['String'];
};

export type ReportFilename = {
  __typename?: 'ReportFilename';
  filename: Scalars['String'];
};

export type ReportFulfillment = {
  from: Scalars['String'];
  to: Scalars['String'];
  warehouseId: Scalars['Int'];
};

export type ReportList = {
  __typename?: 'ReportList';
  rows: Array<Maybe<Report>>;
  count: Scalars['Int'];
};

export type ReportProductInput = {
  from: Scalars['String'];
  to: Scalars['String'];
  productId: Scalars['String'];
  warehouseId: Scalars['Int'];
};

export type ReportRangeInput = {
  from: Scalars['String'];
  to: Scalars['String'];
};

export enum ReportType {
  Shipments = 'SHIPMENTS',
  Transactions = 'TRANSACTIONS',
  Product = 'PRODUCT',
  Fulfillment = 'FULFILLMENT'
}

export type SendWarehouseShipmentOrderInput = {
  products?: Maybe<Array<Maybe<ShipmentProductInput>>>;
  warehouseShipmentOrderId: Scalars['ID'];
  typeDeliveryId: Scalars['Int'];
  additionalInsurance?: Maybe<Scalars['Boolean']>;
  carrierService?: Maybe<Scalars['String']>;
  giftWrap?: Maybe<Scalars['Boolean']>;
  insuranceAmount?: Maybe<Scalars['Float']>;
  signature?: Maybe<Scalars['Boolean']>;
  wrap?: Maybe<Wrap_Type>;
  message?: Maybe<Scalars['String']>;
  rateId: Scalars['ID'];
  shipmentId: Scalars['ID'];
  dimensions: CargoPlaceInput;
  vatKey?: Maybe<Vatkey>;
  vatValue?: Maybe<Scalars['String']>;
  warehouseId?: Maybe<Scalars['Int']>;
  handlingFee?: Maybe<Scalars['Float']>;
  currency?: Maybe<Scalars['String']>;
};

export type SenderAccount = {
  __typename?: 'SenderAccount';
  id?: Maybe<Scalars['Int']>;
  accountName?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
  accountNumber?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  TaxIdentificationNumber?: Maybe<Scalars['String']>;
  attentionName?: Maybe<Scalars['String']>;
  clientIdFEDEX?: Maybe<Scalars['String']>;
  clientSecretFEDEX?: Maybe<Scalars['String']>;
  userNameUPS?: Maybe<Scalars['String']>;
  userPasswordUPS?: Maybe<Scalars['String']>;
  accessLicenseNumberUPS?: Maybe<Scalars['String']>;
  requestorName?: Maybe<Scalars['String']>;
  requestorPhone?: Maybe<Scalars['String']>;
  requestorAddress?: Maybe<Scalars['String']>;
  requestorCity?: Maybe<Scalars['String']>;
  requestorCountryCode?: Maybe<Scalars['String']>;
  requestorPostalCode?: Maybe<Scalars['String']>;
  serviceName?: Maybe<Scalars['String']>;
  isTestMode?: Maybe<Scalars['Boolean']>;
  user?: Maybe<User>;
  easypostTestApiKey?: Maybe<Scalars['String']>;
  easypostProdApiKey?: Maybe<Scalars['String']>;
};

/**
 * For admin required all field
 *
 * For user required only:
 *
 * -accountName
 *
 * -accountNumber
 *
 * -isTestMode(optional and default false)
 */
export type SenderAccountInput = {
  id?: Maybe<Scalars['Int']>;
  accountName?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['Boolean']>;
  accountNumber?: Maybe<Scalars['String']>;
  password?: Maybe<Scalars['String']>;
  siteId?: Maybe<Scalars['String']>;
  requestorName?: Maybe<Scalars['String']>;
  requestorPhone?: Maybe<Scalars['String']>;
  requestorAddress?: Maybe<Scalars['String']>;
  requestorCity?: Maybe<Scalars['String']>;
  requestorCountryCode?: Maybe<Scalars['String']>;
  requestorPostalCode?: Maybe<Scalars['String']>;
  TaxIdentificationNumber?: Maybe<Scalars['String']>;
  attentionName?: Maybe<Scalars['String']>;
  clientIdFEDEX?: Maybe<Scalars['String']>;
  clientSecretFEDEX?: Maybe<Scalars['String']>;
  userNameUPS?: Maybe<Scalars['String']>;
  userPasswordUPS?: Maybe<Scalars['String']>;
  accessLicenseNumberUPS?: Maybe<Scalars['String']>;
  /** Available variants: DHL, UPS, FEDEX */
  serviceName?: Maybe<ServiceNameAvailableVariants>;
  isTestMode?: Maybe<Scalars['Boolean']>;
  easypostTestApiKey?: Maybe<Scalars['String']>;
  easypostProdApiKey?: Maybe<Scalars['String']>;
};

export enum ServiceNameAvailableVariants {
  Dhl = 'DHL',
  Ups = 'UPS',
  Fedex = 'FEDEX',
  Easypost = 'EASYPOST'
}

export type ShipmentDataResponse = {
  __typename?: 'ShipmentDataResponse';
  id?: Maybe<Scalars['Int']>;
  /**
   * The AirwayBillNumber element contains the DHL defines 10-digit waybill number. It is a mandatory
   * field in the shipment validation response. If shipment validation request is successful, this is the
   * actual waybill number assigned to the shipment.
   */
  airwayBillNumber?: Maybe<Scalars['String']>;
  /** Сurrent shipment status */
  status?: Maybe<Scalars['String']>;
  /** The account ID from which the sending was made */
  senderAccountId?: Maybe<Scalars['Int']>;
  /** Id pick&pack parcels */
  parcelId?: Maybe<Scalars['Int']>;
  /** Total price */
  price?: Maybe<Scalars['Float']>;
  senderCode: Scalars['String'];
  /** waybill filename for rout: /api/get-receipt/:filename */
  waybill?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
};

export type ShipmentInput = {
  /** The DHLInvoiceType element identifies the type of invoice */
  DHLInvoiceType?: Maybe<Scalars['String']>;
  /** Reason code for export */
  exportReason?: Maybe<Scalars['String']>;
  /** The account ID from which the sending was made */
  senderAccountId: Scalars['Int'];
  /** The TermsOfTrade element contains the codes for terms of trade of the shipment. */
  termsOfTrade?: Maybe<Scalars['String']>;
  /** Sd pick&pack parcels */
  parcelId: Scalars['Int'];
  /** Sender contact id */
  contactId?: Maybe<Scalars['Int']>;
  /** Departure information */
  shipmentDate?: Maybe<Scalars['String']>;
  globalProductCode: Scalars['String'];
  senderCode: Scalars['String'];
  customDescription?: Maybe<Array<Maybe<UnitDescription>>>;
};

export type ShipmentOrderList = {
  __typename?: 'ShipmentOrderList';
  count?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Parcel>>>;
};

export type ShipmentProductInput = {
  id?: Maybe<Scalars['Int']>;
  quantity?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Float']>;
  code?: Maybe<Scalars['BigInt']>;
  countryId?: Maybe<Scalars['Int']>;
  nameEN?: Maybe<Scalars['String']>;
  weight?: Maybe<Scalars['Float']>;
  sku?: Maybe<Scalars['String']>;
};

export type ShipmentResponse = {
  __typename?: 'ShipmentResponse';
  errors?: Maybe<Array<Maybe<Scalars['String']>>>;
  data?: Maybe<ShipmentDataResponse>;
};

export type ShipmentResponseList = {
  __typename?: 'ShipmentResponseList';
  count?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<ShipmentDataResponse>>>;
};

export type ShipmentWarehouseProduct = {
  __typename?: 'ShipmentWarehouseProduct';
  price?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Int']>;
  productAvailabilityQuantity?: Maybe<WarehouseProduct>;
};

export enum ShipmentWarehouseStatus {
  Picked = 'PICKED',
  Cancelled = 'CANCELLED',
  Shipped = 'SHIPPED',
  Unshipped = 'UNSHIPPED'
}

export enum SortType {
  Desc = 'DESC',
  Asc = 'ASC'
}

export type Status = {
  __typename?: 'Status';
  status?: Maybe<Scalars['Boolean']>;
};

export type StatusDelivery = {
  __typename?: 'StatusDelivery';
  id: Scalars['Int'];
  name: Scalars['String'];
  explain: Scalars['String'];
};

export type StatusPayment = {
  __typename?: 'StatusPayment';
  id: Scalars['Int'];
  name: Scalars['String'];
  explain: Scalars['String'];
};

export type Statuses = {
  __typename?: 'Statuses';
  DeliveryStatusList?: Maybe<Array<Maybe<StatusDelivery>>>;
  PaymentStatusList?: Maybe<Array<Maybe<StatusPayment>>>;
};

export type Store = {
  __typename?: 'Store';
  id?: Maybe<Scalars['Int']>;
  /** Connection name */
  name: Scalars['String'];
  url?: Maybe<Scalars['String']>;
  userId: Scalars['Int'];
  /** Shop ID from etsy website */
  storeId?: Maybe<Scalars['Int']>;
  /** Enabled/Disabled */
  isActive?: Maybe<Scalars['Boolean']>;
  /** Available variants: etsy, ebay, amazon, shopify, woo_commerce, big_commerce, paypal, wildberries, ozon */
  marketplace: Scalars['String'];
};

export type StoreInput = {
  name?: Maybe<Scalars['String']>;
  isActive: Scalars['Boolean'];
  url?: Maybe<Scalars['String']>;
  shopId?: Maybe<Scalars['String']>;
  /** marketplace id from DB */
  marketplaceId: Scalars['Int'];
  /** available value: etsy, ebay, amazon, shopify, woo_commerce, big_commerce, paypal, wildberries, ozon */
  marketplace?: Maybe<StoreMarketplace>;
};

export enum StoreMarketplace {
  Etsy = 'etsy',
  Ebay = 'ebay',
  Amazon = 'amazon',
  Shopify = 'shopify',
  WooCommerce = 'woo_commerce',
  BigCommerce = 'big_commerce',
  Paypal = 'paypal',
  Wildberries = 'wildberries',
  Ozon = 'ozon'
}

export type SuggesWarehousCellByProductQuantity = {
  productId: Scalars['ID'];
  quantity: Scalars['Int'];
};

export type SwapHistoryWarehouseCellProduct = {
  __typename?: 'SwapHistoryWarehouseCellProduct';
  id?: Maybe<Scalars['ID']>;
  productId?: Maybe<Scalars['ID']>;
  productBarcodeId?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
  cellNameFrom?: Maybe<Scalars['String']>;
  cellNameTo?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
};

export type SwapHistoryWarehouseCellProductList = {
  __typename?: 'SwapHistoryWarehouseCellProductList';
  rows?: Maybe<Array<Maybe<SwapHistoryWarehouseCellProduct>>>;
  count?: Maybe<Scalars['Int']>;
};

export type SwapWarehouseCellProduc = {
  productId: Scalars['ID'];
  quantity: Scalars['Int'];
};

export type SwapWarehouseCellProducs = {
  cellFromId: Scalars['ID'];
  cellToId: Scalars['ID'];
  swapProducs?: Maybe<Array<Maybe<SwapWarehouseCellProduc>>>;
};

export type Tariff = {
  __typename?: 'Tariff';
  id?: Maybe<Scalars['Int']>;
  zoneId?: Maybe<Scalars['Int']>;
  weight?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  tariffVariant?: Maybe<Scalars['String']>;
};

export type TariffInput = {
  weight?: Maybe<Scalars['Float']>;
  rows?: Maybe<Array<Maybe<Tariff_>>>;
};

export type TariffList = {
  __typename?: 'TariffList';
  count?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Tariff>>>;
};

export type Tariff_ = {
  zoneId?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['Float']>;
};

export type Token = {
  __typename?: 'Token';
  token?: Maybe<Scalars['String']>;
};

export type Transaction = {
  __typename?: 'Transaction';
  id: Scalars['Int'];
  userId?: Maybe<Scalars['Int']>;
  transactionId?: Maybe<Scalars['String']>;
  parcelId?: Maybe<Scalars['Int']>;
  amount?: Maybe<Scalars['Float']>;
  currency?: Maybe<Scalars['String']>;
  confirmationUrl?: Maybe<Scalars['String']>;
  /** Available variants: REFILL, ORDER_PAYMENT, WEIGHT_ADJUSTMENT, CANCEL_ADJUSTMENT, REFUND, AWAITING_PAYMENT, CDEK, WAREHOUSE_STORAGE */
  paymentType: Scalars['String'];
  /** Available variants: pending, succeeded, canceled */
  status: Scalars['String'];
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  cancellationReason?: Maybe<Scalars['String']>;
  user?: Maybe<User>;
  parcel?: Maybe<Parcel>;
  warehouseShipmentOrder?: Maybe<WarehouseShipmentOrder>;
  cdekParcelId?: Maybe<Scalars['Int']>;
  reason?: Maybe<Scalars['String']>;
  balance?: Maybe<Scalars['Float']>;
  paymentStatus?: Maybe<Scalars['String']>;
};

export type TransactionList = {
  __typename?: 'TransactionList';
  count?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<Transaction>>>;
  name?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  address3?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  balance?: Maybe<Scalars['Float']>;
  state?: Maybe<Scalars['String']>;
  country?: Maybe<DeliveryCountry>;
  zipCode?: Maybe<Scalars['String']>;
};

export type TransactionTypeItem = {
  __typename?: 'TransactionTypeItem';
  id?: Maybe<Scalars['Int']>;
  paymentType?: Maybe<TransactionTypes>;
};

export enum TransactionTypes {
  Refund = 'REFUND',
  AdminOperation = 'ADMIN_OPERATION',
  CancelAdjustment = 'CANCEL_ADJUSTMENT',
  WeightAdjustment = 'WEIGHT_ADJUSTMENT',
  OrderPayment = 'ORDER_PAYMENT',
  AwaitingPayment = 'AWAITING_PAYMENT',
  WarehouseStorage = 'WAREHOUSE_STORAGE'
}

export type TypeDelivery = {
  __typename?: 'TypeDelivery';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  serviceName?: Maybe<Scalars['String']>;
  isUserAccount?: Maybe<Scalars['Boolean']>;
};

export type UnitDescription = {
  id: Scalars['Int'];
  description: Scalars['String'];
};

export type UpdateWarehouseZone = {
  name?: Maybe<Scalars['String']>;
  orderNumber?: Maybe<Scalars['Int']>;
};

export type UpdateWarehouseZoneCell = {
  name?: Maybe<Scalars['String']>;
  orderNumber?: Maybe<Scalars['Int']>;
  warehouseZoneId?: Maybe<Scalars['Int']>;
};


export type UploadWarehouseZoneCellsResult = {
  __typename?: 'UploadWarehouseZoneCellsResult';
  status?: Maybe<Scalars['String']>;
  all?: Maybe<Scalars['Int']>;
  loaded?: Maybe<Scalars['Int']>;
  messages?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type User = {
  __typename?: 'User';
  id: Scalars['ID'];
  email?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  company?: Maybe<Scalars['String']>;
  country?: Maybe<DeliveryCountry>;
  address?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  address3?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  eoriCode?: Maybe<Scalars['String']>;
  /** Available variants: ACTIVE, NOT_ACTIVE */
  status: Scalars['Int'];
  transactions?: Maybe<Array<Maybe<Transaction>>>;
  createdAt: Scalars['String'];
  updatedAt: Scalars['String'];
  balance?: Maybe<Scalars['Float']>;
  discount?: Maybe<Scalars['Float']>;
  whFeePersonalDiscount?: Maybe<Scalars['Float']>;
  passportData?: Maybe<UserPasport>;
  entityLegalData?: Maybe<EntityLegalData>;
  role?: Maybe<UserRoles>;
  senderAccount?: Maybe<Array<Maybe<Scalars['String']>>>;
  creditLimit?: Maybe<Scalars['Int']>;
  warehouse?: Maybe<Warehouse>;
  fullAccess?: Maybe<Scalars['Boolean']>;
};

export type UserInput = {
  name: Scalars['String'];
  company?: Maybe<Scalars['String']>;
  countryId: Scalars['Int'];
  address?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  address3?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  eoriCode?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
};

export type UserPaginationResponse = {
  __typename?: 'UserPaginationResponse';
  count: Scalars['Int'];
  rows: Array<Maybe<User>>;
};

export type UserPasport = {
  __typename?: 'UserPasport';
  id: Scalars['Int'];
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  middleName: Scalars['String'];
  birthDay: Scalars['String'];
  seriesAndNumberOfPassport: Scalars['String'];
  dateOfIssueOfTheDocument: Scalars['String'];
  inn: Scalars['String'];
  scanPath: Scalars['String'];
  userId: Scalars['Int'];
  isDataVerified: Scalars['Boolean'];
};

export type UserPassportDataInput = {
  firstName: Scalars['String'];
  lastName: Scalars['String'];
  middleName: Scalars['String'];
  birthDay: Scalars['String'];
  seriesAndNumberOfPassport: Scalars['String'];
  dateOfIssueOfTheDocument: Scalars['String'];
  inn: Scalars['String'];
  dataUpToDate?: Scalars['Boolean'];
};

export type UserPassportUpdateDataInput = {
  firstName?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  middleName?: Maybe<Scalars['String']>;
  birthDay?: Maybe<Scalars['String']>;
  seriesAndNumberOfPassport?: Maybe<Scalars['String']>;
  dateOfIssueOfTheDocument?: Maybe<Scalars['String']>;
  inn?: Maybe<Scalars['String']>;
  dataUpToDate?: Scalars['Boolean'];
};

export enum UserRoles {
  Manager = 'MANAGER',
  SuperAdmin = 'SUPER_ADMIN',
  Admin = 'ADMIN',
  Customer = 'CUSTOMER'
}

export enum Vatkey {
  Vat = 'VAT',
  Ioss = 'IOSS',
  Ein = 'EIN',
  Empty = 'EMPTY'
}

export type ValidationAddress = {
  address: Scalars['String'];
  city: Scalars['String'];
  state: Scalars['String'];
  postalCode: Scalars['String'];
  countryName: Scalars['String'];
  countryIso: Scalars['String'];
};

export type ValidationAddressResponse = {
  __typename?: 'ValidationAddressResponse';
  address?: Maybe<ValidationField>;
  city?: Maybe<ValidationField>;
  state?: Maybe<ValidationField>;
  postalCode?: Maybe<ValidationField>;
};

export type ValidationField = {
  __typename?: 'ValidationField';
  inputValue?: Maybe<Scalars['String']>;
  returnValue?: Maybe<Scalars['String']>;
};

export type VariantInfo = {
  __typename?: 'VariantInfo';
  warehouse_delivery_cost?: Maybe<Scalars['Float']>;
  warehouse_proccesing_cost?: Maybe<Scalars['Float']>;
  last_mile_label_cost?: Maybe<Scalars['Float']>;
};

export type VariantInfoWarehouseShipmentOrder = {
  __typename?: 'VariantInfoWarehouseShipmentOrder';
  warehouse_delivery_cost?: Maybe<Scalars['Float']>;
  warehouse_proccesing_cost?: Maybe<Scalars['Float']>;
  last_mile_label_cost?: Maybe<Scalars['Float']>;
  wrap_cost?: Maybe<Scalars['Int']>;
  message_cost?: Maybe<Scalars['Int']>;
};

export enum Warehouse_Operations {
  ArrivalOrders = 'ARRIVAL_ORDERS',
  Forwarding = 'FORWARDING',
  Fulfillment = 'FULFILLMENT',
  Returns = 'RETURNS'
}

export enum Warehouse_Shipment_Type {
  Forwarding = 'FORWARDING',
  Inbound = 'INBOUND'
}

export enum Wrap_Type {
  Default = 'DEFAULT',
  BoxS = 'BOX_S',
  BoxM = 'BOX_M',
  BoxL = 'BOX_L',
  Bubble = 'BUBBLE'
}

export type Warehouse = {
  __typename?: 'Warehouse';
  id?: Maybe<Scalars['Int']>;
  code?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  country?: Maybe<DeliveryCountry>;
  name?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  managerName?: Maybe<Scalars['String']>;
  availableOperation?: Maybe<Array<Maybe<WarehouseOperation>>>;
};

export type WarehouseCellProduct = {
  __typename?: 'WarehouseCellProduct';
  product?: Maybe<Product>;
  cellName?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Int']>;
};

export type WarehouseCellProductList = {
  __typename?: 'WarehouseCellProductList';
  rows?: Maybe<Array<Maybe<WarehouseCellProduct>>>;
  count?: Maybe<Scalars['Int']>;
};

export type WarehouseInput = {
  name?: Maybe<Scalars['String']>;
  countryId?: Maybe<Scalars['Int']>;
  city?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  code?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  company?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  managerName?: Maybe<Scalars['String']>;
  operations?: Maybe<Array<Maybe<Warehouse_Operations>>>;
};

export type WarehouseOperation = {
  __typename?: 'WarehouseOperation';
  operation?: Maybe<Warehouse_Operations>;
  warehouseId?: Maybe<Scalars['Int']>;
};

export enum WarehouseOperationForManager {
  TheParcelHasArrived = 'THE_PARCEL_HAS_ARRIVED',
  ReturnOfTheParcel = 'RETURN_OF_THE_PARCEL'
}

export enum WarehouseOperationForUser {
  Resending = 'RESENDING',
  Destroy = 'DESTROY'
}

export type WarehouseProduct = {
  __typename?: 'WarehouseProduct';
  id?: Maybe<Scalars['ID']>;
  related_product?: Maybe<Product>;
  inTransitQuantity?: Maybe<Scalars['Int']>;
  reservedQuantity?: Maybe<Scalars['Int']>;
  avaliableQuantity?: Maybe<Scalars['Int']>;
  history?: Maybe<Array<Maybe<HistoryWarehouseProduct>>>;
};

export type WarehouseProductForManager = {
  __typename?: 'WarehouseProductForManager';
  barcodeId?: Maybe<Scalars['String']>;
  sku?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type WarehouseProductInfo = {
  __typename?: 'WarehouseProductInfo';
  id?: Maybe<Scalars['Int']>;
  product?: Maybe<WarehouseProductForManager>;
};

export type WarehouseShipment = {
  __typename?: 'WarehouseShipment';
  id?: Maybe<Scalars['Int']>;
  from?: Maybe<ParcelContact>;
  events?: Maybe<Array<Maybe<WarehouseShipmentEvent>>>;
  createdAt?: Maybe<Scalars['String']>;
  warehouse?: Maybe<Warehouse>;
  trackNumber?: Maybe<Scalars['String']>;
  carrierCode?: Maybe<Scalars['String']>;
  deliveryStatus?: Maybe<StatusDelivery>;
  places?: Maybe<Scalars['Int']>;
  user?: Maybe<User>;
  parcels?: Maybe<Array<Maybe<Parcel>>>;
  expectedQuantity?: Maybe<Scalars['Int']>;
  acceptedQuantity?: Maybe<Scalars['Int']>;
  barcode?: Maybe<Scalars['String']>;
  packages?: Maybe<Array<Maybe<CargoPlace>>>;
  products?: Maybe<Array<Maybe<ProductInParcel>>>;
  warehouseStatus?: Maybe<WarehouseShipmentStatus>;
  note?: Maybe<Scalars['String']>;
};

export type WarehouseShipmentEvent = {
  __typename?: 'WarehouseShipmentEvent';
  id?: Maybe<Scalars['Int']>;
  warehouseShipment?: Maybe<WarehouseShipment>;
  warehouse?: Maybe<Warehouse>;
  manager?: Maybe<Manager>;
  user?: Maybe<User>;
  operation?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['String']>;
};

export type WarehouseShipmentFilter = {
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  dateFilter?: Maybe<Scalars['Int']>;
  deliveryStatusId?: Maybe<Scalars['Int']>;
  warehouseStatus?: Maybe<WarehouseShipmentStatus>;
  type?: Maybe<Warehouse_Shipment_Type>;
};

export type WarehouseShipmentInfoForManager = {
  __typename?: 'WarehouseShipmentInfoForManager';
  barcodeId?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['String']>;
  warehouseStatus?: Maybe<ShipmentWarehouseStatus>;
  User?: Maybe<WarehouseShipmentUserInfo>;
  shipmentProduct?: Maybe<Array<Maybe<WarehouseShipmentProductInfo>>>;
  wrap?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  giftWrap?: Maybe<Scalars['Boolean']>;
  comment?: Maybe<Scalars['String']>;
};

export type WarehouseShipmentInput = {
  from: ParcelContactInput;
  parcelIds?: Maybe<Array<Maybe<Scalars['Int']>>>;
  products?: Maybe<Array<Maybe<ProductIdAndQuantity>>>;
  warehouseId?: Maybe<Scalars['Int']>;
  packages?: Maybe<Array<Maybe<CargoPlaceInput>>>;
  trackNumber?: Maybe<Scalars['String']>;
  carrierCode?: Maybe<Scalars['String']>;
};

export type WarehouseShipmentListForManager = {
  __typename?: 'WarehouseShipmentListForManager';
  count?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<WarehouseShipmentInfoForManager>>>;
};

export type WarehouseShipmentOrder = {
  __typename?: 'WarehouseShipmentOrder';
  id?: Maybe<Scalars['ID']>;
  clientUser?: Maybe<User>;
  receiver?: Maybe<ParcelContact>;
  sender?: Maybe<Warehouse>;
  trackNumber?: Maybe<Scalars['String']>;
  carrierCode?: Maybe<Scalars['String']>;
  carrierService?: Maybe<Scalars['String']>;
  declaredAmount?: Maybe<Scalars['Float']>;
  actualAmount?: Maybe<Scalars['Float']>;
  additionalInsurance?: Maybe<Scalars['Boolean']>;
  wrap?: Maybe<Wrap_Type>;
  giftWrap?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
  insuranceAmount?: Maybe<Scalars['Float']>;
  signature?: Maybe<Scalars['Boolean']>;
  dimensions?: Maybe<CargoPlace>;
  deliveryStatus?: Maybe<StatusDelivery>;
  paymentStatus?: Maybe<StatusPayment>;
  adminDeliveryStatus?: Maybe<Scalars['String']>;
  typeDelivery?: Maybe<TypeDelivery>;
  pathNameFile?: Maybe<Scalars['String']>;
  senderMark?: Maybe<Scalars['String']>;
  vatKey?: Maybe<Vatkey>;
  vatValue?: Maybe<Scalars['String']>;
  barcodeId?: Maybe<Scalars['String']>;
  easyPostShipmentId?: Maybe<Scalars['ID']>;
  easyPostRateId?: Maybe<Scalars['ID']>;
  lastMileCost?: Maybe<Scalars['String']>;
  products?: Maybe<Array<Maybe<ShipmentWarehouseProduct>>>;
  orderCostCurrency?: Maybe<Scalars['String']>;
  preliminaryCost?: Maybe<Scalars['Float']>;
  currency?: Maybe<Scalars['String']>;
  handlingFee?: Maybe<Scalars['Float']>;
  createdAt?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['String']>;
  warehouseStatus?: Maybe<ShipmentWarehouseStatus>;
  marketplaceInvoiceToken?: Maybe<Scalars['String']>;
  comment?: Maybe<Scalars['String']>;
};

export type WarehouseShipmentOrderDeliveryOptionsInput = {
  warehouseId?: Maybe<Scalars['Int']>;
  warehouseShipmentOrderId?: Maybe<Scalars['Int']>;
  products?: Maybe<Array<Maybe<ShipmentProductInput>>>;
  receiver: ParcelContactInput;
  additionalInsurance?: Maybe<Scalars['Boolean']>;
  wrap: Wrap_Type;
  giftWrap?: Maybe<Scalars['Boolean']>;
  message?: Maybe<Scalars['String']>;
  insuranceAmount?: Maybe<Scalars['Float']>;
  signature?: Maybe<Scalars['Boolean']>;
  dimensions?: Maybe<CargoPlaceInput>;
  only_calculation?: Maybe<Scalars['Boolean']>;
  address_validation?: Maybe<Scalars['Boolean']>;
  vatKey?: Maybe<Vatkey>;
  vatValue?: Maybe<Scalars['String']>;
};

export type WarehouseShipmentOrdersList = {
  __typename?: 'WarehouseShipmentOrdersList';
  rows?: Maybe<Array<Maybe<WarehouseShipmentOrder>>>;
  count?: Maybe<Scalars['Int']>;
};

export type WarehouseShipmentOrdersOptions = {
  warehouseId?: Maybe<Scalars['ID']>;
  dateFilter?: Maybe<Scalars['Int']>;
  paymentStatusId?: Maybe<Scalars['ID']>;
  deliveryStatusId?: Maybe<Scalars['ID']>;
  search?: Maybe<Scalars['String']>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
};

export type WarehouseShipmentProductInfo = {
  __typename?: 'WarehouseShipmentProductInfo';
  quantity?: Maybe<Scalars['Int']>;
  accepted?: Maybe<Scalars['Int']>;
  warehouseProduct?: Maybe<WarehouseProductInfo>;
};

export type WarehouseShipmentProductsList = {
  __typename?: 'WarehouseShipmentProductsList';
  rows?: Maybe<Array<Maybe<WarehouseProduct>>>;
  count?: Maybe<Scalars['Int']>;
};

export enum WarehouseShipmentStatus {
  Created = 'CREATED',
  Processing = 'PROCESSING',
  Accepted = 'ACCEPTED',
  Canceled = 'CANCELED'
}

export type WarehouseShipmentUseParcelInput = {
  from: ParcelContactInput;
  warehouseId?: Maybe<Scalars['Int']>;
  packages?: Maybe<Array<Maybe<CargoPlaceInput>>>;
  trackNumber?: Maybe<Scalars['String']>;
  carrierCode?: Maybe<Scalars['String']>;
  identifiers?: Maybe<Array<Maybe<Scalars['String']>>>;
  identifiersType?: Maybe<IdentifiersType>;
};

export type WarehouseShipmentUseProductInput = {
  from: ParcelContactInput;
  products?: Maybe<Array<Maybe<ProductIdAndQuantity>>>;
  warehouseId?: Maybe<Scalars['Int']>;
  packages?: Maybe<Array<Maybe<CargoPlaceInput>>>;
  trackNumber?: Maybe<Scalars['String']>;
  carrierCode?: Maybe<Scalars['String']>;
};

export type WarehouseShipmentUserInfo = {
  __typename?: 'WarehouseShipmentUserInfo';
  name?: Maybe<Scalars['String']>;
};

export type WarehouseShipmentsList = {
  __typename?: 'WarehouseShipmentsList';
  count?: Maybe<Scalars['Int']>;
  rows?: Maybe<Array<Maybe<WarehouseShipment>>>;
};

export type WarehouseZone = {
  __typename?: 'WarehouseZone';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  totalCellsCount?: Maybe<Scalars['Int']>;
  freeCellsCount?: Maybe<Scalars['Int']>;
  occupiedCellsCount?: Maybe<Scalars['Int']>;
  orderNumber?: Maybe<Scalars['Int']>;
};

export type WarehouseZoneCell = {
  __typename?: 'WarehouseZoneCell';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  isDefault?: Maybe<Scalars['Boolean']>;
  orderNumber?: Maybe<Scalars['Int']>;
  totalProductsCount?: Maybe<Scalars['Int']>;
  warehouseZoneId?: Maybe<Scalars['Int']>;
  barcode?: Maybe<Scalars['String']>;
};

export type WarehouseZoneCellByProduct = {
  __typename?: 'WarehouseZoneCellByProduct';
  id?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
  targetProductsCount?: Maybe<Scalars['Int']>;
  targetFromCell?: Maybe<Scalars['Boolean']>;
};

export type WarehouseZoneCellsResult = {
  __typename?: 'WarehouseZoneCellsResult';
  rows?: Maybe<Array<Maybe<WarehouseZoneCell>>>;
  count?: Maybe<Scalars['Int']>;
};

export type WarehousesList = {
  __typename?: 'WarehousesList';
  rows?: Maybe<Array<Maybe<Warehouse>>>;
  count?: Maybe<Scalars['Int']>;
};

export type WriteEtsyKey = {
  code: Scalars['String'];
  state?: Maybe<Scalars['String']>;
};

export type AnalyticData = {
  __typename?: 'analyticData';
  volume?: Maybe<Scalars['Float']>;
  fee?: Maybe<Scalars['Float']>;
  quantity?: Maybe<Scalars['Int']>;
};

export type ConsolidationTrackNumberObject = {
  __typename?: 'consolidationTrackNumberObject';
  service?: Maybe<Scalars['String']>;
  consolidationTrackNumber?: Maybe<Scalars['String']>;
};

export type GetWarehouseZoneCellProduct = {
  productId: Scalars['ID'];
  cellId: Scalars['ID'];
};

export type ShipmentDataRate = {
  parcelsIds: Array<Maybe<Scalars['Int']>>;
  sender: ParcelContactInput;
  receiver: ParcelContactInput;
  readyTime: Scalars['String'];
  packages: Array<Maybe<CargoPlaceInput>>;
  currency_code: Scalars['String'];
  exportReason?: Maybe<Scalars['String']>;
  paymentOfTaxes: Scalars['String'];
  vatValue?: Maybe<Scalars['String']>;
  iso: Scalars['String'];
};

export type ShipmentDataSend = {
  sender: ParcelContactInput;
  receiver: ParcelContactInput;
  packages: Array<Maybe<CargoPlaceInput>>;
  declaredWeight: Scalars['Float'];
  declaredAmount: Scalars['Float'];
  senderNotes?: Maybe<Scalars['String']>;
  additionalInsurance: Scalars['Boolean'];
  userId?: Maybe<Scalars['Int']>;
  currency_code: Scalars['String'];
  senderMark?: Maybe<Scalars['String']>;
  exportReason?: Maybe<Scalars['String']>;
  paymentOfTaxes: Scalars['String'];
  vatValue?: Maybe<Scalars['String']>;
  cargoPlaces: Scalars['Int'];
  totalGrossWeight?: Maybe<Scalars['Float']>;
  totalNetWeight: Scalars['Float'];
  totalVolumetricWeight?: Maybe<Scalars['Float']>;
  totalCostOfEnteties: Scalars['Float'];
  totalCost: Scalars['Float'];
};

export type TrackParcelHistory = {
  __typename?: 'trackParcelHistory';
  eventName: Scalars['String'];
  timestamp: Scalars['Int'];
};

export type TrackParcelResponse = {
  __typename?: 'trackParcelResponse';
  error?: Maybe<Scalars['String']>;
  /** Return back the carrier received from request */
  carrier_code?: Maybe<Scalars['String']>;
  /** Return back the track number received from request */
  track_number?: Maybe<Scalars['String']>;
  message?: Maybe<Scalars['String']>;
  statusDescription?: Maybe<Scalars['String']>;
  /** Which city is the parcel now */
  city?: Maybe<Scalars['String']>;
  /** Which coutry (ISO code) is the parcel now */
  countryISO?: Maybe<Scalars['String']>;
  history?: Maybe<Array<Maybe<TrackParcelHistory>>>;
  /**
   * Current parcel step. Can be one of 4:
   * 1 - Shipment information sent to FedEx (Обрабатывается)
   * 2 - pick up (Груз отгружен)
   * 3 - in transit (В пути)
   * 4 - delivered (Доставлен)
   */
  currentStep?: Maybe<Scalars['Int']>;
  senderCity?: Maybe<Scalars['String']>;
  senderStateOrProvinceCode?: Maybe<Scalars['String']>;
  senderCountryISO?: Maybe<Scalars['String']>;
  receiverCity?: Maybe<Scalars['String']>;
  receiverStateOrProvinceCode?: Maybe<Scalars['String']>;
  receiverCountryISO?: Maybe<Scalars['String']>;
};

export type FragmentDeliveryCountryFragment = (
  { __typename?: 'DeliveryCountry' }
  & Pick<DeliveryCountry, 'id' | 'order' | 'name' | 'daysToDelivery' | 'zoneId' | 'nameEng' | 'iso' | 'phoneCode' | 'phoneMin' | 'phoneMax' | 'customsInfo'>
);

export type FragmentEntityLegalDataFragment = (
  { __typename?: 'EntityLegalData' }
  & Pick<EntityLegalData, 'id' | 'company' | 'inn' | 'kpp' | 'MSRN' | 'generalManager' | 'legalAddress' | 'nameOfTheBank' | 'BIK' | 'paymentAccount' | 'correspondentAccount' | 'fileName' | 'userId' | 'isDataVerified'>
);

export type FragmentUserPasportFragment = (
  { __typename?: 'UserPasport' }
  & Pick<UserPasport, 'id' | 'firstName' | 'lastName' | 'middleName' | 'birthDay' | 'seriesAndNumberOfPassport' | 'dateOfIssueOfTheDocument' | 'inn' | 'scanPath' | 'userId' | 'isDataVerified'>
);

export type AddEmailMutationVariables = Exact<{
  email: Scalars['String'];
}>;


export type AddEmailMutation = (
  { __typename?: 'Mutation' }
  & { addEmail: (
    { __typename?: 'User' }
    & Pick<User, 'id' | 'email'>
  ) }
);

export type AddEntityLegalDataMutationVariables = Exact<{
  input?: Maybe<EntityLegalDataInput>;
  file?: Maybe<Scalars['Upload']>;
}>;


export type AddEntityLegalDataMutation = (
  { __typename?: 'Mutation' }
  & { addEntityLegalData?: Maybe<(
    { __typename?: 'EntityLegalData' }
    & Pick<EntityLegalData, 'id'>
  )> }
);

export type AddEtsyTrackNumberMutationVariables = Exact<{
  storeId: Scalars['Int'];
  carrierName: Scalars['String'];
  receiptId: Scalars['String'];
  trackingCode: Scalars['String'];
  sendBcc?: Maybe<Scalars['Boolean']>;
}>;


export type AddEtsyTrackNumberMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'addEtsyTrackNumber'>
);

export type AddPackageToOrderMutationVariables = Exact<{
  packageId?: Maybe<Scalars['Int']>;
  orderId: Scalars['Int'];
  weight?: Maybe<Scalars['Float']>;
  height?: Maybe<Scalars['Float']>;
  length?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
}>;


export type AddPackageToOrderMutation = (
  { __typename?: 'Mutation' }
  & { addPackageToOrder?: Maybe<(
    { __typename?: 'Order' }
    & Pick<Order, 'id'>
  )> }
);

export type AddPassportDataMutationVariables = Exact<{
  input?: Maybe<UserPassportDataInput>;
  file?: Maybe<Scalars['Upload']>;
}>;


export type AddPassportDataMutation = (
  { __typename?: 'Mutation' }
  & { addPassportData?: Maybe<(
    { __typename?: 'UserPasport' }
    & FragmentUserPasportFragment
  )> }
);

export type AddTrackNumberMutationVariables = Exact<{
  shipmentId: Scalars['Int'];
  trackNumber: Scalars['String'];
  carrierCode: Scalars['String'];
}>;


export type AddTrackNumberMutation = (
  { __typename?: 'Mutation' }
  & { addTrackNumber?: Maybe<(
    { __typename?: 'WarehouseShipment' }
    & Pick<WarehouseShipment, 'id'>
  )> }
);

export type AuthFacebookMutationVariables = Exact<{
  accessToken: Scalars['String'];
}>;


export type AuthFacebookMutation = (
  { __typename?: 'Mutation' }
  & { authFacebook?: Maybe<(
    { __typename?: 'LoginResponse' }
    & Pick<LoginResponse, 'token'>
  )> }
);

export type AuthGoogleMutationVariables = Exact<{
  accessToken: Scalars['String'];
}>;


export type AuthGoogleMutation = (
  { __typename?: 'Mutation' }
  & { authGoogle?: Maybe<(
    { __typename?: 'LoginResponse' }
    & Pick<LoginResponse, 'token'>
  )> }
);

export type AuthVkontakteMutationVariables = Exact<{
  accessToken: Scalars['String'];
}>;


export type AuthVkontakteMutation = (
  { __typename?: 'Mutation' }
  & { authVkontakte?: Maybe<(
    { __typename?: 'LoginResponse' }
    & Pick<LoginResponse, 'token'>
  )> }
);

export type CancelParcelMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type CancelParcelMutation = (
  { __typename?: 'Mutation' }
  & { cancelParcel: (
    { __typename?: 'Parcel' }
    & Pick<Parcel, 'id' | 'deliveryStatusId' | 'paymentStatusId'>
  ) }
);

export type ChangePasswordMutationVariables = Exact<{
  oldPassword: Scalars['String'];
  newPassword: Scalars['String'];
}>;


export type ChangePasswordMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'changePassword'>
);

export type CreateContactMutationVariables = Exact<{
  input: ContactInput;
}>;


export type CreateContactMutation = (
  { __typename?: 'Mutation' }
  & { createContact?: Maybe<(
    { __typename?: 'Contact' }
    & Pick<Contact, 'id' | 'email' | 'remark' | 'name' | 'company' | 'address' | 'address2' | 'address3' | 'zipCode' | 'city' | 'state' | 'phone' | 'eoriCode' | 'innKppCode' | 'contactName'>
    & { country?: Maybe<(
      { __typename?: 'DeliveryCountry' }
      & FragmentDeliveryCountryFragment
    )> }
  )> }
);

export type CreatePackagingTemplateMutationVariables = Exact<{
  input?: Maybe<PackagingTemplateInput>;
}>;


export type CreatePackagingTemplateMutation = (
  { __typename?: 'Mutation' }
  & { createPackagingTemplate?: Maybe<(
    { __typename?: 'PackagingTemplate' }
    & Pick<PackagingTemplate, 'id' | 'name' | 'weight' | 'length' | 'width' | 'height' | 'userId'>
  )> }
);

export type CreateParcelMutationVariables = Exact<{
  input: ParcelInput;
}>;


export type CreateParcelMutation = (
  { __typename?: 'Mutation' }
  & { createParcel?: Maybe<(
    { __typename?: 'Parcel' }
    & Pick<Parcel, 'id' | 'senderNotes' | 'senderMark' | 'additionalInsurance' | 'declaredWeight' | 'declaredAmount' | 'deliveryStatusId' | 'paymentStatusId' | 'vatKey' | 'trackNumber' | 'carrierCode'>
    & { sender: (
      { __typename?: 'ParcelContact' }
      & Pick<ParcelContact, 'id' | 'name' | 'company' | 'address' | 'address2' | 'address3' | 'zipCode' | 'city' | 'state' | 'phone' | 'email' | 'innKppCode' | 'eoriCode' | 'remark'>
      & { country?: Maybe<(
        { __typename?: 'DeliveryCountry' }
        & FragmentDeliveryCountryFragment
      )> }
    ), receiver: (
      { __typename?: 'ParcelContact' }
      & Pick<ParcelContact, 'id' | 'name' | 'company' | 'address' | 'address2' | 'address3' | 'zipCode' | 'city' | 'state' | 'phone' | 'email' | 'innKppCode' | 'eoriCode' | 'remark'>
      & { country?: Maybe<(
        { __typename?: 'DeliveryCountry' }
        & FragmentDeliveryCountryFragment
      )> }
    ), typeDelivery?: Maybe<(
      { __typename?: 'TypeDelivery' }
      & Pick<TypeDelivery, 'id' | 'name' | 'serviceName'>
    )> }
  )> }
);

export type CreatePaymentMutationVariables = Exact<{
  value?: Maybe<Scalars['String']>;
  currency?: Maybe<Scalars['String']>;
  parcelId?: Maybe<Scalars['Int']>;
}>;


export type CreatePaymentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createPayment'>
);

export type CreateProductMutationVariables = Exact<{
  product?: Maybe<ProductInput>;
}>;


export type CreateProductMutation = (
  { __typename?: 'Mutation' }
  & { createProduct?: Maybe<(
    { __typename?: 'Product' }
    & Pick<Product, 'id' | 'name' | 'sku' | 'detailsDescriptionRU' | 'detailsDescriptionEN' | 'state' | 'trademark' | 'declaredValue' | 'netWeight' | 'code' | 'userId' | 'fnsku' | 'upc'>
    & { country?: Maybe<(
      { __typename?: 'DeliveryCountry' }
      & FragmentDeliveryCountryFragment
    )>, actualDimensions?: Maybe<(
      { __typename?: 'CargoPlace' }
      & Pick<CargoPlace, 'id' | 'warehouseShipmentId' | 'weight' | 'length' | 'width' | 'height'>
    )>, declaredDimensions?: Maybe<(
      { __typename?: 'CargoPlace' }
      & Pick<CargoPlace, 'id' | 'warehouseShipmentId' | 'weight' | 'length' | 'width' | 'height'>
    )> }
  )> }
);

export type CreateQrPaymentMutationVariables = Exact<{
  value: Scalars['Float'];
}>;


export type CreateQrPaymentMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'createQRPayment'>
);

export type CreateReportByShipmentsMutationVariables = Exact<{
  input?: Maybe<ReportRangeInput>;
}>;


export type CreateReportByShipmentsMutation = (
  { __typename?: 'Mutation' }
  & { createReportByShipments?: Maybe<(
    { __typename?: 'ReportFilename' }
    & Pick<ReportFilename, 'filename'>
  )> }
);

export type CreateReportByTransactionsMutationVariables = Exact<{
  input?: Maybe<ReportRangeInput>;
}>;


export type CreateReportByTransactionsMutation = (
  { __typename?: 'Mutation' }
  & { createReportByTransactions?: Maybe<(
    { __typename?: 'ReportFilename' }
    & Pick<ReportFilename, 'filename'>
  )> }
);

export type CreateSenderAccountMutationVariables = Exact<{
  input?: Maybe<SenderAccountInput>;
}>;


export type CreateSenderAccountMutation = (
  { __typename?: 'Mutation' }
  & { createSenderAccount?: Maybe<(
    { __typename?: 'SenderAccount' }
    & Pick<SenderAccount, 'id' | 'accountName' | 'status' | 'accountNumber' | 'password' | 'siteId' | 'requestorName' | 'requestorAddress' | 'requestorCity' | 'requestorCountryCode' | 'requestorPostalCode' | 'serviceName' | 'isTestMode'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'name' | 'company' | 'address' | 'address2' | 'address3' | 'zipCode' | 'city' | 'state' | 'phone' | 'eoriCode' | 'status' | 'createdAt' | 'updatedAt' | 'balance' | 'discount'>
      & { country?: Maybe<(
        { __typename?: 'DeliveryCountry' }
        & FragmentDeliveryCountryFragment
      )> }
    )> }
  )> }
);

export type EditCurrentUserMutationVariables = Exact<{
  name: Scalars['String'];
  company?: Maybe<Scalars['String']>;
  countryId: Scalars['Int'];
  address?: Maybe<Scalars['String']>;
  address2?: Maybe<Scalars['String']>;
  address3?: Maybe<Scalars['String']>;
  zipCode?: Maybe<Scalars['String']>;
  city?: Maybe<Scalars['String']>;
  state?: Maybe<Scalars['String']>;
  phone?: Maybe<Scalars['String']>;
  eoriCode?: Maybe<Scalars['String']>;
}>;


export type EditCurrentUserMutation = (
  { __typename?: 'Mutation' }
  & { editCurrentUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'name' | 'company' | 'address' | 'address2' | 'address3' | 'zipCode' | 'city' | 'state' | 'phone' | 'eoriCode'>
    & { country?: Maybe<(
      { __typename?: 'DeliveryCountry' }
      & FragmentDeliveryCountryFragment
    )> }
  )> }
);

export type ImportProductListFromFileMutationVariables = Exact<{
  file: Scalars['Upload'];
}>;


export type ImportProductListFromFileMutation = (
  { __typename?: 'Mutation' }
  & { importProductListFromFile?: Maybe<(
    { __typename?: 'ProductListUpload' }
    & Pick<ProductListUpload, 'messages'>
    & { products?: Maybe<(
      { __typename?: 'ProductList' }
      & Pick<ProductList, 'count'>
      & { rows?: Maybe<Array<Maybe<(
        { __typename?: 'Product' }
        & Pick<Product, 'id'>
      )>>> }
    )> }
  )> }
);

export type RemoveContactMutationVariables = Exact<{
  id: Scalars['ID'];
}>;


export type RemoveContactMutation = (
  { __typename?: 'Mutation' }
  & { removeContact: (
    { __typename?: 'Status' }
    & Pick<Status, 'status'>
  ) }
);

export type RemovePackagingTemplateMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type RemovePackagingTemplateMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removePackagingTemplate'>
);

export type RemoveProductMutationVariables = Exact<{
  productId: Scalars['Int'];
}>;


export type RemoveProductMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeProduct'>
);

export type RemoveSenderAccountMutationVariables = Exact<{
  id: Scalars['Int'];
}>;


export type RemoveSenderAccountMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'removeSenderAccount'>
);

export type RemoveStoreMutationVariables = Exact<{
  marketplaceId: Scalars['Int'];
}>;


export type RemoveStoreMutation = (
  { __typename?: 'Mutation' }
  & { removeStore?: Maybe<(
    { __typename?: 'Store' }
    & Pick<Store, 'id' | 'name' | 'url' | 'userId' | 'storeId' | 'isActive' | 'marketplace'>
  )> }
);

export type RemoveTrackNumberMutationVariables = Exact<{
  shipmentId: Scalars['Int'];
}>;


export type RemoveTrackNumberMutation = (
  { __typename?: 'Mutation' }
  & { removeTrackNumber?: Maybe<(
    { __typename?: 'WarehouseShipment' }
    & Pick<WarehouseShipment, 'id'>
  )> }
);

export type SaveNewPasswordMutationVariables = Exact<{
  token: Scalars['String'];
  password: Scalars['String'];
}>;


export type SaveNewPasswordMutation = (
  { __typename?: 'Mutation' }
  & { saveNewPassword: (
    { __typename?: 'Status' }
    & Pick<Status, 'status'>
  ) }
);

export type SignInMutationVariables = Exact<{
  email: Scalars['String'];
  password: Scalars['String'];
  rememberMe?: Maybe<Scalars['Boolean']>;
}>;


export type SignInMutation = (
  { __typename?: 'Mutation' }
  & { signIn?: Maybe<(
    { __typename?: 'LoginResponse' }
    & Pick<LoginResponse, 'token' | 'message'>
  )> }
);

export type SignUpMutationVariables = Exact<{
  name: Scalars['String'];
  email: Scalars['String'];
  password: Scalars['String'];
}>;


export type SignUpMutation = (
  { __typename?: 'Mutation' }
  & { signUp?: Maybe<(
    { __typename?: 'LoginResponse' }
    & Pick<LoginResponse, 'token' | 'message'>
  )> }
);

export type UpdateContactMutationVariables = Exact<{
  id: Scalars['ID'];
  input: ContactInput;
}>;


export type UpdateContactMutation = (
  { __typename?: 'Mutation' }
  & { updateContact?: Maybe<(
    { __typename?: 'Contact' }
    & Pick<Contact, 'id' | 'email' | 'remark' | 'name' | 'company' | 'address' | 'address2' | 'address3' | 'zipCode' | 'city' | 'state' | 'phone' | 'eoriCode' | 'innKppCode' | 'contactName'>
    & { country?: Maybe<(
      { __typename?: 'DeliveryCountry' }
      & FragmentDeliveryCountryFragment
    )> }
  )> }
);

export type UpdateEntityLegalDataMutationVariables = Exact<{
  input?: Maybe<EntityLegalUpdateDataInput>;
  file: Scalars['Upload'];
}>;


export type UpdateEntityLegalDataMutation = (
  { __typename?: 'Mutation' }
  & { updateEntityLegalData?: Maybe<(
    { __typename?: 'EntityLegalData' }
    & Pick<EntityLegalData, 'id' | 'company' | 'inn' | 'kpp' | 'MSRN' | 'generalManager' | 'legalAddress' | 'nameOfTheBank' | 'BIK' | 'paymentAccount' | 'correspondentAccount' | 'fileName' | 'userId' | 'isDataVerified'>
  )> }
);

export type UpdatePackagingTemplateMutationVariables = Exact<{
  input?: Maybe<PackagingTemplateInput>;
}>;


export type UpdatePackagingTemplateMutation = (
  { __typename?: 'Mutation' }
  & { updatePackagingTemplate?: Maybe<(
    { __typename?: 'PackagingTemplate' }
    & Pick<PackagingTemplate, 'id' | 'name' | 'weight' | 'length' | 'width' | 'height' | 'userId'>
  )> }
);

export type UpdatePassportDataMutationVariables = Exact<{
  input?: Maybe<UserPassportUpdateDataInput>;
  file?: Maybe<Scalars['Upload']>;
}>;


export type UpdatePassportDataMutation = (
  { __typename?: 'Mutation' }
  & { updatePassportData?: Maybe<(
    { __typename?: 'UserPasport' }
    & FragmentUserPasportFragment
  )> }
);

export type UpdateProductMutationVariables = Exact<{
  product?: Maybe<ProductInput>;
}>;


export type UpdateProductMutation = (
  { __typename?: 'Mutation' }
  & { updateProduct?: Maybe<(
    { __typename?: 'Product' }
    & Pick<Product, 'id' | 'name' | 'sku' | 'detailsDescriptionRU' | 'detailsDescriptionEN' | 'state' | 'trademark' | 'declaredValue' | 'netWeight' | 'code' | 'userId'>
    & { country?: Maybe<(
      { __typename?: 'DeliveryCountry' }
      & FragmentDeliveryCountryFragment
    )> }
  )> }
);

export type UpdateSenderAccountMutationVariables = Exact<{
  input: SenderAccountInput;
}>;


export type UpdateSenderAccountMutation = (
  { __typename?: 'Mutation' }
  & { updateSenderAccount?: Maybe<(
    { __typename?: 'SenderAccount' }
    & Pick<SenderAccount, 'id' | 'accountName' | 'status' | 'accountNumber' | 'password' | 'siteId' | 'requestorName' | 'requestorPhone' | 'requestorAddress' | 'requestorCity' | 'requestorCountryCode' | 'requestorPostalCode' | 'serviceName' | 'isTestMode'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'name' | 'company' | 'address' | 'address2' | 'address3' | 'zipCode' | 'city' | 'state' | 'phone' | 'eoriCode' | 'status' | 'createdAt' | 'updatedAt' | 'balance' | 'discount'>
      & { country?: Maybe<(
        { __typename?: 'DeliveryCountry' }
        & FragmentDeliveryCountryFragment
      )> }
    )> }
  )> }
);

export type UpdateStoreMutationVariables = Exact<{
  input?: Maybe<StoreInput>;
}>;


export type UpdateStoreMutation = (
  { __typename?: 'Mutation' }
  & { updateStore?: Maybe<(
    { __typename?: 'Store' }
    & Pick<Store, 'id' | 'name' | 'url' | 'userId' | 'storeId' | 'isActive' | 'marketplace'>
  )> }
);

export type UploadMarketplaceInvoiceMutationVariables = Exact<{
  file: Scalars['Upload'];
}>;


export type UploadMarketplaceInvoiceMutation = (
  { __typename?: 'Mutation' }
  & Pick<Mutation, 'uploadMarketplaceInvoice'>
);

export type GetAnalyticDataQueryVariables = Exact<{ [key: string]: never; }>;


export type GetAnalyticDataQuery = (
  { __typename?: 'Query' }
  & { getAnalyticData?: Maybe<(
    { __typename?: 'analyticData' }
    & Pick<AnalyticData, 'volume' | 'fee' | 'quantity'>
  )> }
);

export type GetBarcodeByParcelUuidQueryVariables = Exact<{
  uuid: Scalars['String'];
}>;


export type GetBarcodeByParcelUuidQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getBarcodeByParcelUuid'>
);

export type GetCitiesByCountryIsoQueryVariables = Exact<{
  iso: Scalars['String'];
}>;


export type GetCitiesByCountryIsoQuery = (
  { __typename?: 'Query' }
  & { getCitiesByCountryIso?: Maybe<Array<Maybe<(
    { __typename?: 'CityInfo' }
    & Pick<CityInfo, 'name'>
  )>>> }
);

export type GetConfigurationListQueryVariables = Exact<{ [key: string]: never; }>;


export type GetConfigurationListQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getConfigurationList'>
);

export type GetContactQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetContactQuery = (
  { __typename?: 'Query' }
  & { contact?: Maybe<(
    { __typename?: 'Contact' }
    & Pick<Contact, 'id' | 'name' | 'company' | 'address' | 'address2' | 'address3' | 'zipCode' | 'city' | 'state' | 'phone' | 'email' | 'eoriCode' | 'innKppCode' | 'remark' | 'contactName' | 'type'>
    & { country?: Maybe<(
      { __typename?: 'DeliveryCountry' }
      & FragmentDeliveryCountryFragment
    )> }
  )> }
);

export type GetContactsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetContactsQuery = (
  { __typename?: 'Query' }
  & { contacts?: Maybe<Array<Maybe<(
    { __typename?: 'Contact' }
    & Pick<Contact, 'id' | 'name' | 'company' | 'address' | 'address2' | 'address3' | 'zipCode' | 'city' | 'state' | 'phone' | 'email' | 'remark' | 'eoriCode' | 'type' | 'innKppCode' | 'contactName'>
    & { country?: Maybe<(
      { __typename?: 'DeliveryCountry' }
      & FragmentDeliveryCountryFragment
    )> }
  )>>> }
);

export type GetCurrentUserQueryVariables = Exact<{ [key: string]: never; }>;


export type GetCurrentUserQuery = (
  { __typename?: 'Query' }
  & { currentUser?: Maybe<(
    { __typename?: 'User' }
    & Pick<User, 'id' | 'email' | 'name' | 'company' | 'address' | 'address2' | 'address3' | 'zipCode' | 'city' | 'state' | 'phone' | 'eoriCode' | 'status' | 'createdAt' | 'updatedAt' | 'balance' | 'role' | 'fullAccess'>
    & { country?: Maybe<(
      { __typename?: 'DeliveryCountry' }
      & FragmentDeliveryCountryFragment
    )>, passportData?: Maybe<(
      { __typename?: 'UserPasport' }
      & FragmentUserPasportFragment
    )>, entityLegalData?: Maybe<(
      { __typename?: 'EntityLegalData' }
      & FragmentEntityLegalDataFragment
    )> }
  )> }
);

export type GetDeliveryCountriesQueryVariables = Exact<{ [key: string]: never; }>;


export type GetDeliveryCountriesQuery = (
  { __typename?: 'Query' }
  & { deliveryCountries?: Maybe<Array<Maybe<(
    { __typename?: 'DeliveryCountry' }
    & Pick<DeliveryCountry, 'id' | 'order' | 'name' | 'daysToDelivery' | 'zoneId' | 'nameEng' | 'iso' | 'customsInfo'>
  )>>> }
);

export type GetListOfDeliveryOptionsQueryVariables = Exact<{
  input?: Maybe<CalculationInput>;
}>;


export type GetListOfDeliveryOptionsQuery = (
  { __typename?: 'Query' }
  & { getListOfDeliveryOptions?: Maybe<(
    { __typename?: 'CalculatorResponse' }
    & Pick<CalculatorResponse, 'weight' | 'volume_weight' | 'weight_to_pay' | 'special_tax' | 'insurance'>
    & { data?: Maybe<Array<Maybe<(
      { __typename?: 'CalculatorResponseData' }
      & Pick<CalculatorResponseData, 'name' | 'success' | 'days_to_delivery' | 'summary_amount' | 'remoteArea' | 'typeDelivery' | 'fuelSurcharge' | 'dollarRate' | 'errors' | 'rateId' | 'shipmentId' | 'priceCurrency'>
      & { info?: Maybe<(
        { __typename?: 'VariantInfo' }
        & Pick<VariantInfo, 'warehouse_delivery_cost' | 'warehouse_proccesing_cost' | 'last_mile_label_cost'>
      )> }
    )>>>, userAccount?: Maybe<(
      { __typename?: 'QuoteResponseForCalculator' }
      & { rows?: Maybe<Array<Maybe<(
        { __typename?: 'QuoteForCalculator' }
        & Pick<QuoteForCalculator, 'TypeDeliveryName' | 'TypeDeliveryId' | 'accountName' | 'amount' | 'error' | 'day_to_delivery' | 'priceCurrency' | 'shipmentId' | 'rateId'>
      )>>> }
    )> }
  )> }
);

export type GetListOfReturnedShipmentsQueryVariables = Exact<{
  filter?: Maybe<WarehouseShipmentFilter>;
}>;


export type GetListOfReturnedShipmentsQuery = (
  { __typename?: 'Query' }
  & { getListOfReturnedShipments?: Maybe<(
    { __typename?: 'WarehouseShipmentsList' }
    & Pick<WarehouseShipmentsList, 'count'>
    & { rows?: Maybe<Array<Maybe<(
      { __typename?: 'WarehouseShipment' }
      & Pick<WarehouseShipment, 'id' | 'createdAt' | 'trackNumber' | 'carrierCode' | 'note'>
      & { warehouse?: Maybe<(
        { __typename?: 'Warehouse' }
        & Pick<Warehouse, 'id' | 'address' | 'code'>
      )>, user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'name' | 'address' | 'city'>
        & { country?: Maybe<(
          { __typename?: 'DeliveryCountry' }
          & Pick<DeliveryCountry, 'name'>
        )> }
      )>, deliveryStatus?: Maybe<(
        { __typename?: 'StatusDelivery' }
        & Pick<StatusDelivery, 'id' | 'name' | 'explain'>
      )>, events?: Maybe<Array<Maybe<(
        { __typename?: 'WarehouseShipmentEvent' }
        & Pick<WarehouseShipmentEvent, 'operation'>
        & { manager?: Maybe<(
          { __typename?: 'Manager' }
          & Pick<Manager, 'name'>
        )> }
      )>>> }
    )>>> }
  )> }
);

export type GetMyProductByIdQueryVariables = Exact<{
  productId: Scalars['Int'];
}>;


export type GetMyProductByIdQuery = (
  { __typename?: 'Query' }
  & { getMyProductById?: Maybe<(
    { __typename?: 'Product' }
    & Pick<Product, 'id' | 'name' | 'sku' | 'detailsDescriptionRU' | 'detailsDescriptionEN' | 'state' | 'trademark' | 'declaredValue' | 'netWeight' | 'code' | 'userId' | 'fnsku' | 'upc' | 'createdAt' | 'barcodeId' | 'isCustomBarcodeId' | 'currency'>
    & { country?: Maybe<(
      { __typename?: 'DeliveryCountry' }
      & Pick<DeliveryCountry, 'id' | 'nameEng'>
    )>, actualDimensions?: Maybe<(
      { __typename?: 'CargoPlace' }
      & Pick<CargoPlace, 'id' | 'warehouseShipmentId' | 'weight' | 'length' | 'width' | 'height'>
    )>, declaredDimensions?: Maybe<(
      { __typename?: 'CargoPlace' }
      & Pick<CargoPlace, 'id' | 'warehouseShipmentId' | 'weight' | 'length' | 'width' | 'height'>
    )>, productAvailabilityQuantity?: Maybe<(
      { __typename?: 'WarehouseProduct' }
      & Pick<WarehouseProduct, 'id' | 'inTransitQuantity' | 'reservedQuantity' | 'avaliableQuantity'>
    )> }
  )> }
);

export type GetMyProductListQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  warehouseId?: Maybe<Scalars['ID']>;
}>;


export type GetMyProductListQuery = (
  { __typename?: 'Query' }
  & { getMyProductList?: Maybe<(
    { __typename?: 'ProductList' }
    & Pick<ProductList, 'count'>
    & { rows?: Maybe<Array<Maybe<(
      { __typename?: 'Product' }
      & Pick<Product, 'id' | 'name' | 'sku' | 'detailsDescriptionRU' | 'detailsDescriptionEN' | 'state' | 'trademark' | 'declaredValue' | 'netWeight' | 'code' | 'userId' | 'fnsku' | 'upc' | 'barcodeId' | 'createdAt' | 'isCustomBarcodeId' | 'currency'>
      & { country?: Maybe<(
        { __typename?: 'DeliveryCountry' }
        & FragmentDeliveryCountryFragment
      )>, actualDimensions?: Maybe<(
        { __typename?: 'CargoPlace' }
        & Pick<CargoPlace, 'id' | 'warehouseShipmentId' | 'weight' | 'length' | 'width' | 'height'>
      )>, declaredDimensions?: Maybe<(
        { __typename?: 'CargoPlace' }
        & Pick<CargoPlace, 'id' | 'warehouseShipmentId' | 'weight' | 'length' | 'width' | 'height'>
      )>, productAvailabilityQuantity?: Maybe<(
        { __typename?: 'WarehouseProduct' }
        & Pick<WarehouseProduct, 'id' | 'inTransitQuantity' | 'reservedQuantity' | 'avaliableQuantity'>
      )> }
    )>>> }
  )> }
);

export type GetOrderByIdQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetOrderByIdQuery = (
  { __typename?: 'Query' }
  & { getOrderById?: Maybe<(
    { __typename?: 'Order' }
    & Pick<Order, 'id' | 'marketplace' | 'orderId' | 'createdAt' | 'isPaid' | 'userId' | 'country' | 'addressFirstLine' | 'addressSecondLine' | 'zip' | 'city' | 'state' | 'email' | 'deliveryType' | 'deliveryPrice' | 'isShipped' | 'itemId' | 'receiverName' | 'storeId' | 'phone' | 'shippingMethod'>
    & { orderProducts?: Maybe<Array<Maybe<(
      { __typename?: 'OrderProduct' }
      & Pick<OrderProduct, 'id' | 'title' | 'description' | 'quantity' | 'priceAmount' | 'priceCurrency' | 'shippingCostAmount' | 'shippingCostCurrency' | 'sku'>
    )>>>, package?: Maybe<(
      { __typename?: 'PackagingTemplate' }
      & Pick<PackagingTemplate, 'id' | 'name' | 'weight' | 'length' | 'width' | 'height' | 'userId'>
    )> }
  )> }
);

export type GetPackagingTemplatesListQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  name?: Maybe<Scalars['String']>;
}>;


export type GetPackagingTemplatesListQuery = (
  { __typename?: 'Query' }
  & { getPackagingTemplatesList?: Maybe<(
    { __typename?: 'PackagingTemplateList' }
    & Pick<PackagingTemplateList, 'count'>
    & { rows?: Maybe<Array<Maybe<(
      { __typename?: 'PackagingTemplate' }
      & Pick<PackagingTemplate, 'id' | 'name' | 'weight' | 'length' | 'width' | 'height' | 'userId'>
    )>>> }
  )> }
);

export type GetParcelQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetParcelQuery = (
  { __typename?: 'Query' }
  & { parcel: (
    { __typename?: 'Parcel' }
    & Pick<Parcel, 'id' | 'pathNameFile' | 'senderNotes' | 'senderMark' | 'additionalInsurance' | 'trackNumber' | 'carrierCode' | 'deliveryStatusId' | 'paymentStatusId' | 'actualAmount' | 'actualWeight' | 'declaredAmount' | 'declaredWeight' | 'currency' | 'sentToWarehouseId' | 'exportReason' | 'paymentOfTaxes' | 'vatKey' | 'vatID' | 'isConsolidationPiece' | 'insuranceAmount' | 'signature' | 'barcode' | 'orderCostCurrency' | 'createdAt'>
    & { sender: (
      { __typename?: 'ParcelContact' }
      & Pick<ParcelContact, 'id' | 'name' | 'company' | 'address' | 'address2' | 'address3' | 'zipCode' | 'city' | 'state' | 'phone' | 'email' | 'innKppCode' | 'remark' | 'eoriCode'>
      & { country?: Maybe<(
        { __typename?: 'DeliveryCountry' }
        & FragmentDeliveryCountryFragment
      )> }
    ), receiver: (
      { __typename?: 'ParcelContact' }
      & Pick<ParcelContact, 'id' | 'name' | 'company' | 'address' | 'address2' | 'address3' | 'zipCode' | 'city' | 'state' | 'phone' | 'email' | 'innKppCode' | 'remark' | 'eoriCode'>
      & { country?: Maybe<(
        { __typename?: 'DeliveryCountry' }
        & FragmentDeliveryCountryFragment
      )> }
    ), packages: Array<Maybe<(
      { __typename?: 'Package' }
      & Pick<Package, 'id' | 'quantity' | 'weightKg' | 'lengthCm' | 'widthCm' | 'heightCm'>
      & { units?: Maybe<Array<Maybe<(
        { __typename?: 'ParcelUnit' }
        & Pick<ParcelUnit, 'id' | 'nameEN' | 'nameRU' | 'tradeMark' | 'code' | 'quantity' | 'price' | 'netWeight' | 'state'>
        & { country?: Maybe<(
          { __typename?: 'DeliveryCountry' }
          & FragmentDeliveryCountryFragment
        )> }
      )>>> }
    )>>, typeDelivery?: Maybe<(
      { __typename?: 'TypeDelivery' }
      & Pick<TypeDelivery, 'id' | 'name' | 'serviceName' | 'isUserAccount'>
    )>, consolidationTrackNumber?: Maybe<(
      { __typename?: 'consolidationTrackNumberObject' }
      & Pick<ConsolidationTrackNumberObject, 'service' | 'consolidationTrackNumber'>
    )>, warehouse?: Maybe<(
      { __typename?: 'Warehouse' }
      & Pick<Warehouse, 'id' | 'code' | 'address' | 'address2' | 'company' | 'phone' | 'managerName' | 'city' | 'zipCode' | 'state' | 'email'>
      & { country?: Maybe<(
        { __typename?: 'DeliveryCountry' }
        & Pick<DeliveryCountry, 'name'>
      )> }
    )> }
  ) }
);

export type GetParcelsQueryVariables = Exact<{
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['Int']>;
  deliveryStatusId?: Maybe<Scalars['Int']>;
  paymentStatusId?: Maybe<Scalars['Int']>;
  convert_to_usd?: Maybe<Scalars['Boolean']>;
}>;


export type GetParcelsQuery = (
  { __typename?: 'Query' }
  & { parcels: (
    { __typename?: 'ParcelPaginationResponse' }
    & Pick<ParcelPaginationResponse, 'count'>
    & { rows: Array<Maybe<(
      { __typename?: 'Parcel' }
      & Pick<Parcel, 'id' | 'currency' | 'pathNameFile' | 'fileTokenInvoiceXlsx' | 'sentToWarehouseId' | 'isDiscountUsed' | 'isConsolidationPiece' | 'barcode' | 'senderNotes' | 'senderMark' | 'additionalInsurance' | 'declaredWeight' | 'actualWeight' | 'declaredAmount' | 'actualAmount' | 'trackNumber' | 'carrierCode' | 'deliveryStatusId' | 'paymentStatusId' | 'warehouseShipmentId' | 'orderCostCurrency' | 'createdAt'>
      & { typeDelivery?: Maybe<(
        { __typename?: 'TypeDelivery' }
        & Pick<TypeDelivery, 'id' | 'name' | 'serviceName' | 'isUserAccount'>
      )>, sender: (
        { __typename?: 'ParcelContact' }
        & Pick<ParcelContact, 'id' | 'name' | 'company' | 'address' | 'address2' | 'address3' | 'zipCode' | 'city' | 'state' | 'phone' | 'email' | 'innKppCode' | 'remark' | 'eoriCode'>
        & { country?: Maybe<(
          { __typename?: 'DeliveryCountry' }
          & FragmentDeliveryCountryFragment
        )> }
      ), receiver: (
        { __typename?: 'ParcelContact' }
        & Pick<ParcelContact, 'id' | 'name' | 'company' | 'address' | 'address2' | 'address3' | 'zipCode' | 'city' | 'state' | 'phone' | 'email' | 'innKppCode' | 'remark' | 'eoriCode'>
        & { country?: Maybe<(
          { __typename?: 'DeliveryCountry' }
          & FragmentDeliveryCountryFragment
        )> }
      ), packages: Array<Maybe<(
        { __typename?: 'Package' }
        & Pick<Package, 'id' | 'quantity' | 'weightKg' | 'lengthCm' | 'widthCm' | 'heightCm'>
        & { units?: Maybe<Array<Maybe<(
          { __typename?: 'ParcelUnit' }
          & Pick<ParcelUnit, 'id' | 'nameEN' | 'nameRU' | 'tradeMark' | 'code' | 'quantity' | 'price' | 'netWeight'>
          & { country?: Maybe<(
            { __typename?: 'DeliveryCountry' }
            & FragmentDeliveryCountryFragment
          )> }
        )>>> }
      )>>, consolidationTrackNumber?: Maybe<(
        { __typename?: 'consolidationTrackNumberObject' }
        & Pick<ConsolidationTrackNumberObject, 'consolidationTrackNumber' | 'service'>
      )> }
    )>> }
  ) }
);

export type GetParcelsByTypeQueryVariables = Exact<{
  offset?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
  search?: Maybe<Scalars['String']>;
  date?: Maybe<Scalars['Int']>;
  type?: Maybe<ParcelType>;
  deliveryStatusId?: Maybe<Scalars['Int']>;
  paymentStatusId?: Maybe<Scalars['Int']>;
}>;


export type GetParcelsByTypeQuery = (
  { __typename?: 'Query' }
  & { getParcelsByType?: Maybe<(
    { __typename?: 'ShipmentOrderList' }
    & Pick<ShipmentOrderList, 'count'>
    & { rows?: Maybe<Array<Maybe<(
      { __typename?: 'Parcel' }
      & Pick<Parcel, 'id' | 'currency' | 'sentToWarehouseId' | 'fileTokenInvoiceXlsx' | 'senderNotes' | 'senderMark' | 'exportReason' | 'paymentOfTaxes' | 'vatID' | 'additionalInsurance' | 'declaredWeight' | 'actualWeight' | 'declaredAmount' | 'actualAmount' | 'trackNumber' | 'carrierCode' | 'deliveryStatusId' | 'paymentStatusId' | 'pathNameFile' | 'isConsolidationPiece' | 'barcode' | 'orderCostCurrency' | 'createdAt'>
      & { user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'senderAccount'>
      )>, sender: (
        { __typename?: 'ParcelContact' }
        & Pick<ParcelContact, 'id' | 'name' | 'company' | 'address' | 'address2' | 'address3' | 'zipCode' | 'city' | 'state' | 'phone' | 'email' | 'innKppCode' | 'remark' | 'eoriCode'>
        & { country?: Maybe<(
          { __typename?: 'DeliveryCountry' }
          & FragmentDeliveryCountryFragment
        )> }
      ), receiver: (
        { __typename?: 'ParcelContact' }
        & Pick<ParcelContact, 'id' | 'name' | 'company' | 'address' | 'address2' | 'address3' | 'zipCode' | 'city' | 'state' | 'phone' | 'email' | 'innKppCode' | 'remark' | 'eoriCode'>
        & { country?: Maybe<(
          { __typename?: 'DeliveryCountry' }
          & FragmentDeliveryCountryFragment
        )> }
      ), packages: Array<Maybe<(
        { __typename?: 'Package' }
        & Pick<Package, 'id' | 'weightKg' | 'lengthCm' | 'widthCm' | 'heightCm'>
        & { units?: Maybe<Array<Maybe<(
          { __typename?: 'ParcelUnit' }
          & Pick<ParcelUnit, 'id' | 'nameRU' | 'nameEN' | 'tradeMark' | 'code' | 'quantity' | 'price' | 'netWeight'>
          & { country?: Maybe<(
            { __typename?: 'DeliveryCountry' }
            & FragmentDeliveryCountryFragment
          )> }
        )>>> }
      )>>, typeDelivery?: Maybe<(
        { __typename?: 'TypeDelivery' }
        & Pick<TypeDelivery, 'id' | 'name' | 'serviceName'>
      )>, warehouse?: Maybe<(
        { __typename?: 'Warehouse' }
        & Pick<Warehouse, 'code'>
      )> }
    )>>> }
  )> }
);

export type GetPaymentHistoryListByUserIdQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type GetPaymentHistoryListByUserIdQuery = (
  { __typename?: 'Query' }
  & { getPaymentHistoryListByUserId?: Maybe<(
    { __typename?: 'TransactionList' }
    & Pick<TransactionList, 'count'>
    & { rows?: Maybe<Array<Maybe<(
      { __typename?: 'Transaction' }
      & Pick<Transaction, 'id' | 'userId' | 'transactionId' | 'parcelId' | 'amount' | 'currency' | 'confirmationUrl' | 'paymentType' | 'status' | 'createdAt' | 'updatedAt' | 'cancellationReason' | 'cdekParcelId' | 'reason' | 'balance' | 'paymentStatus'>
      & { warehouseShipmentOrder?: Maybe<(
        { __typename?: 'WarehouseShipmentOrder' }
        & Pick<WarehouseShipmentOrder, 'id' | 'barcodeId'>
      )>, parcel?: Maybe<(
        { __typename?: 'Parcel' }
        & Pick<Parcel, 'barcode'>
      )> }
    )>>> }
  )> }
);

export type GetPublicConfigurationsQueryVariables = Exact<{ [key: string]: never; }>;


export type GetPublicConfigurationsQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getPublicConfigurations'>
);

export type GetReportsQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type GetReportsQuery = (
  { __typename?: 'Query' }
  & { getReports?: Maybe<(
    { __typename?: 'ReportList' }
    & Pick<ReportList, 'count'>
    & { rows: Array<Maybe<(
      { __typename?: 'Report' }
      & Pick<Report, 'id' | 'userId' | 'type' | 'from' | 'to' | 'createdAt' | 'fileToken' | 'productBarcode' | 'warehouseCode'>
    )>> }
  )> }
);

export type GetSenderAccountByIdQueryVariables = Exact<{
  id: Scalars['Int'];
}>;


export type GetSenderAccountByIdQuery = (
  { __typename?: 'Query' }
  & { getSenderAccountById?: Maybe<(
    { __typename?: 'SenderAccount' }
    & Pick<SenderAccount, 'id' | 'accountName' | 'status' | 'accountNumber' | 'password' | 'siteId' | 'TaxIdentificationNumber' | 'attentionName' | 'clientIdFEDEX' | 'clientSecretFEDEX' | 'userNameUPS' | 'userPasswordUPS' | 'accessLicenseNumberUPS' | 'requestorName' | 'requestorPhone' | 'requestorAddress' | 'requestorCity' | 'requestorCountryCode' | 'requestorPostalCode' | 'serviceName' | 'isTestMode' | 'easypostTestApiKey' | 'easypostProdApiKey'>
    & { user?: Maybe<(
      { __typename?: 'User' }
      & Pick<User, 'id' | 'email' | 'name' | 'company' | 'address' | 'address2' | 'address3' | 'zipCode' | 'city' | 'state' | 'phone' | 'eoriCode' | 'status' | 'createdAt' | 'updatedAt' | 'balance' | 'discount'>
      & { country?: Maybe<(
        { __typename?: 'DeliveryCountry' }
        & FragmentDeliveryCountryFragment
      )> }
    )> }
  )> }
);

export type GetSenderAccountListQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type GetSenderAccountListQuery = (
  { __typename?: 'Query' }
  & { getSenderAccountList?: Maybe<(
    { __typename?: 'ListOfSenderAccounts' }
    & Pick<ListOfSenderAccounts, 'count'>
    & { rows?: Maybe<Array<Maybe<(
      { __typename?: 'SenderAccount' }
      & Pick<SenderAccount, 'id' | 'accountName' | 'status' | 'accountNumber' | 'password' | 'siteId' | 'TaxIdentificationNumber' | 'attentionName' | 'clientIdFEDEX' | 'clientSecretFEDEX' | 'userNameUPS' | 'userPasswordUPS' | 'accessLicenseNumberUPS' | 'requestorName' | 'requestorPhone' | 'requestorAddress' | 'requestorCity' | 'requestorCountryCode' | 'requestorPostalCode' | 'serviceName' | 'isTestMode'>
      & { user?: Maybe<(
        { __typename?: 'User' }
        & Pick<User, 'id' | 'email' | 'name' | 'company' | 'address' | 'address2' | 'address3' | 'zipCode' | 'city' | 'state' | 'phone' | 'eoriCode' | 'status' | 'createdAt' | 'updatedAt' | 'balance' | 'discount'>
        & { country?: Maybe<(
          { __typename?: 'DeliveryCountry' }
          & FragmentDeliveryCountryFragment
        )> }
      )> }
    )>>> }
  )> }
);

export type GetShipmentBarcodeListQueryVariables = Exact<{
  id: Scalars['ID'];
}>;


export type GetShipmentBarcodeListQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'getShipmentBarcodeList'>
);

export type GetStatusesListQueryVariables = Exact<{ [key: string]: never; }>;


export type GetStatusesListQuery = (
  { __typename?: 'Query' }
  & { getStatusesList?: Maybe<(
    { __typename?: 'Statuses' }
    & { DeliveryStatusList?: Maybe<Array<Maybe<(
      { __typename?: 'StatusDelivery' }
      & Pick<StatusDelivery, 'id' | 'name' | 'explain'>
    )>>>, PaymentStatusList?: Maybe<Array<Maybe<(
      { __typename?: 'StatusPayment' }
      & Pick<StatusPayment, 'id' | 'name' | 'explain'>
    )>>> }
  )> }
);

export type GetTariffsByParcelQueryVariables = Exact<{
  getTariffsByParcelCalculationInput?: Maybe<CdekCalculationByTariffCodeInput>;
}>;


export type GetTariffsByParcelQuery = (
  { __typename?: 'Query' }
  & { getTariffsByParcel?: Maybe<Array<Maybe<(
    { __typename?: 'CDEKCalculationByTariffCodeResponse' }
    & Pick<CdekCalculationByTariffCodeResponse, 'delivery_sum' | 'period_min' | 'period_max' | 'weight_calc' | 'total_sum' | 'currency'>
    & { errors?: Maybe<Array<Maybe<(
      { __typename?: 'CDEKError' }
      & Pick<CdekError, 'code' | 'message'>
    )>>>, tariff?: Maybe<(
      { __typename?: 'CDEKTariffInfo' }
      & Pick<CdekTariffInfo, 'code' | 'name' | 'mode' | 'weight_restrictions' | 'service' | 'description'>
    )> }
  )>>> }
);

export type GetTheExchangeRateQueryVariables = Exact<{ [key: string]: never; }>;


export type GetTheExchangeRateQuery = (
  { __typename?: 'Query' }
  & { getTheExchangeRate?: Maybe<(
    { __typename?: 'ExchangeRateResponse' }
    & Pick<ExchangeRateResponse, 'lastUpdate'>
    & { exchangeRate?: Maybe<Array<Maybe<(
      { __typename?: 'ExchangeRate' }
      & Pick<ExchangeRate, 'code' | 'nominal' | 'value'>
    )>>> }
  )> }
);

export type RecoveryPasswordQueryVariables = Exact<{
  email: Scalars['String'];
}>;


export type RecoveryPasswordQuery = (
  { __typename?: 'Query' }
  & { recoveryPassword: (
    { __typename?: 'Status' }
    & Pick<Status, 'status'>
  ) }
);

export type RepeatConfirmLetterQueryVariables = Exact<{ [key: string]: never; }>;


export type RepeatConfirmLetterQuery = (
  { __typename?: 'Query' }
  & { repeatConfirmLetter: (
    { __typename?: 'Status' }
    & Pick<Status, 'status'>
  ) }
);

export type TrackParcelQueryVariables = Exact<{
  carrier_code?: Maybe<Scalars['String']>;
  barcode?: Maybe<Scalars['String']>;
}>;


export type TrackParcelQuery = (
  { __typename?: 'Query' }
  & { trackParcel?: Maybe<(
    { __typename?: 'trackParcelResponse' }
    & Pick<TrackParcelResponse, 'carrier_code' | 'track_number' | 'message' | 'error' | 'statusDescription' | 'city' | 'countryISO' | 'currentStep' | 'senderCity' | 'senderCountryISO' | 'senderStateOrProvinceCode' | 'receiverCity' | 'receiverCountryISO' | 'receiverStateOrProvinceCode'>
    & { history?: Maybe<Array<Maybe<(
      { __typename?: 'trackParcelHistory' }
      & Pick<TrackParcelHistory, 'eventName' | 'timestamp'>
    )>>> }
  )> }
);

export type ValidationAddressQueryVariables = Exact<{
  input?: Maybe<ValidationAddress>;
}>;


export type ValidationAddressQuery = (
  { __typename?: 'Query' }
  & { validationAddress?: Maybe<(
    { __typename?: 'ValidationAddressResponse' }
    & { address?: Maybe<(
      { __typename?: 'ValidationField' }
      & Pick<ValidationField, 'inputValue' | 'returnValue'>
    )>, city?: Maybe<(
      { __typename?: 'ValidationField' }
      & Pick<ValidationField, 'inputValue' | 'returnValue'>
    )>, state?: Maybe<(
      { __typename?: 'ValidationField' }
      & Pick<ValidationField, 'inputValue' | 'returnValue'>
    )>, postalCode?: Maybe<(
      { __typename?: 'ValidationField' }
      & Pick<ValidationField, 'inputValue' | 'returnValue'>
    )> }
  )> }
);

export type WeigtToPayValidatorQueryVariables = Exact<{
  parcelIds?: Maybe<Array<Scalars['Int']> | Scalars['Int']>;
  packages?: Maybe<Array<CargoPlaceInput> | CargoPlaceInput>;
}>;


export type WeigtToPayValidatorQuery = (
  { __typename?: 'Query' }
  & Pick<Query, 'weigtToPayValidator'>
);

export const FragmentDeliveryCountryFragmentDoc = gql`
    fragment FragmentDeliveryCountry on DeliveryCountry {
  id
  order
  name
  daysToDelivery
  zoneId
  nameEng
  iso
  phoneCode
  phoneMin
  phoneMax
  customsInfo
}
    `;
export const FragmentEntityLegalDataFragmentDoc = gql`
    fragment FragmentEntityLegalData on EntityLegalData {
  id
  company
  inn
  kpp
  MSRN
  generalManager
  legalAddress
  nameOfTheBank
  BIK
  paymentAccount
  correspondentAccount
  fileName
  userId
  isDataVerified
}
    `;
export const FragmentUserPasportFragmentDoc = gql`
    fragment FragmentUserPasport on UserPasport {
  id
  firstName
  lastName
  middleName
  birthDay
  seriesAndNumberOfPassport
  dateOfIssueOfTheDocument
  inn
  scanPath
  userId
  isDataVerified
}
    `;
export const AddEmailDocument = gql`
    mutation addEmail($email: String!) {
  addEmail(email: $email) {
    id
    email
  }
}
    `;
export type AddEmailMutationFn = Apollo.MutationFunction<AddEmailMutation, AddEmailMutationVariables>;

/**
 * __useAddEmailMutation__
 *
 * To run a mutation, you first call `useAddEmailMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddEmailMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addEmailMutation, { data, loading, error }] = useAddEmailMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useAddEmailMutation(baseOptions?: Apollo.MutationHookOptions<AddEmailMutation, AddEmailMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddEmailMutation, AddEmailMutationVariables>(AddEmailDocument, options);
      }
export type AddEmailMutationHookResult = ReturnType<typeof useAddEmailMutation>;
export type AddEmailMutationResult = Apollo.MutationResult<AddEmailMutation>;
export type AddEmailMutationOptions = Apollo.BaseMutationOptions<AddEmailMutation, AddEmailMutationVariables>;
export const AddEntityLegalDataDocument = gql`
    mutation addEntityLegalData($input: EntityLegalDataInput, $file: Upload) {
  addEntityLegalData(input: $input, file: $file) {
    id
  }
}
    `;
export type AddEntityLegalDataMutationFn = Apollo.MutationFunction<AddEntityLegalDataMutation, AddEntityLegalDataMutationVariables>;

/**
 * __useAddEntityLegalDataMutation__
 *
 * To run a mutation, you first call `useAddEntityLegalDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddEntityLegalDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addEntityLegalDataMutation, { data, loading, error }] = useAddEntityLegalDataMutation({
 *   variables: {
 *      input: // value for 'input'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useAddEntityLegalDataMutation(baseOptions?: Apollo.MutationHookOptions<AddEntityLegalDataMutation, AddEntityLegalDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddEntityLegalDataMutation, AddEntityLegalDataMutationVariables>(AddEntityLegalDataDocument, options);
      }
export type AddEntityLegalDataMutationHookResult = ReturnType<typeof useAddEntityLegalDataMutation>;
export type AddEntityLegalDataMutationResult = Apollo.MutationResult<AddEntityLegalDataMutation>;
export type AddEntityLegalDataMutationOptions = Apollo.BaseMutationOptions<AddEntityLegalDataMutation, AddEntityLegalDataMutationVariables>;
export const AddEtsyTrackNumberDocument = gql`
    mutation AddEtsyTrackNumber($storeId: Int!, $carrierName: String!, $receiptId: String!, $trackingCode: String!, $sendBcc: Boolean) {
  addEtsyTrackNumber(
    storeId: $storeId
    carrier_name: $carrierName
    receipt_id: $receiptId
    tracking_code: $trackingCode
    send_bcc: $sendBcc
  )
}
    `;
export type AddEtsyTrackNumberMutationFn = Apollo.MutationFunction<AddEtsyTrackNumberMutation, AddEtsyTrackNumberMutationVariables>;

/**
 * __useAddEtsyTrackNumberMutation__
 *
 * To run a mutation, you first call `useAddEtsyTrackNumberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddEtsyTrackNumberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addEtsyTrackNumberMutation, { data, loading, error }] = useAddEtsyTrackNumberMutation({
 *   variables: {
 *      storeId: // value for 'storeId'
 *      carrierName: // value for 'carrierName'
 *      receiptId: // value for 'receiptId'
 *      trackingCode: // value for 'trackingCode'
 *      sendBcc: // value for 'sendBcc'
 *   },
 * });
 */
export function useAddEtsyTrackNumberMutation(baseOptions?: Apollo.MutationHookOptions<AddEtsyTrackNumberMutation, AddEtsyTrackNumberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddEtsyTrackNumberMutation, AddEtsyTrackNumberMutationVariables>(AddEtsyTrackNumberDocument, options);
      }
export type AddEtsyTrackNumberMutationHookResult = ReturnType<typeof useAddEtsyTrackNumberMutation>;
export type AddEtsyTrackNumberMutationResult = Apollo.MutationResult<AddEtsyTrackNumberMutation>;
export type AddEtsyTrackNumberMutationOptions = Apollo.BaseMutationOptions<AddEtsyTrackNumberMutation, AddEtsyTrackNumberMutationVariables>;
export const AddPackageToOrderDocument = gql`
    mutation addPackageToOrder($packageId: Int, $orderId: Int!, $weight: Float, $height: Float, $length: Float, $width: Float) {
  addPackageToOrder(
    packageId: $packageId
    orderId: $orderId
    weight: $weight
    height: $height
    length: $length
    width: $width
  ) {
    id
  }
}
    `;
export type AddPackageToOrderMutationFn = Apollo.MutationFunction<AddPackageToOrderMutation, AddPackageToOrderMutationVariables>;

/**
 * __useAddPackageToOrderMutation__
 *
 * To run a mutation, you first call `useAddPackageToOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPackageToOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPackageToOrderMutation, { data, loading, error }] = useAddPackageToOrderMutation({
 *   variables: {
 *      packageId: // value for 'packageId'
 *      orderId: // value for 'orderId'
 *      weight: // value for 'weight'
 *      height: // value for 'height'
 *      length: // value for 'length'
 *      width: // value for 'width'
 *   },
 * });
 */
export function useAddPackageToOrderMutation(baseOptions?: Apollo.MutationHookOptions<AddPackageToOrderMutation, AddPackageToOrderMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddPackageToOrderMutation, AddPackageToOrderMutationVariables>(AddPackageToOrderDocument, options);
      }
export type AddPackageToOrderMutationHookResult = ReturnType<typeof useAddPackageToOrderMutation>;
export type AddPackageToOrderMutationResult = Apollo.MutationResult<AddPackageToOrderMutation>;
export type AddPackageToOrderMutationOptions = Apollo.BaseMutationOptions<AddPackageToOrderMutation, AddPackageToOrderMutationVariables>;
export const AddPassportDataDocument = gql`
    mutation addPassportData($input: UserPassportDataInput, $file: Upload) {
  addPassportData(input: $input, file: $file) {
    ...FragmentUserPasport
  }
}
    ${FragmentUserPasportFragmentDoc}`;
export type AddPassportDataMutationFn = Apollo.MutationFunction<AddPassportDataMutation, AddPassportDataMutationVariables>;

/**
 * __useAddPassportDataMutation__
 *
 * To run a mutation, you first call `useAddPassportDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPassportDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPassportDataMutation, { data, loading, error }] = useAddPassportDataMutation({
 *   variables: {
 *      input: // value for 'input'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useAddPassportDataMutation(baseOptions?: Apollo.MutationHookOptions<AddPassportDataMutation, AddPassportDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddPassportDataMutation, AddPassportDataMutationVariables>(AddPassportDataDocument, options);
      }
export type AddPassportDataMutationHookResult = ReturnType<typeof useAddPassportDataMutation>;
export type AddPassportDataMutationResult = Apollo.MutationResult<AddPassportDataMutation>;
export type AddPassportDataMutationOptions = Apollo.BaseMutationOptions<AddPassportDataMutation, AddPassportDataMutationVariables>;
export const AddTrackNumberDocument = gql`
    mutation addTrackNumber($shipmentId: Int!, $trackNumber: String!, $carrierCode: String!) {
  addTrackNumber(
    shipmentId: $shipmentId
    trackNumber: $trackNumber
    carrierCode: $carrierCode
  ) {
    id
  }
}
    `;
export type AddTrackNumberMutationFn = Apollo.MutationFunction<AddTrackNumberMutation, AddTrackNumberMutationVariables>;

/**
 * __useAddTrackNumberMutation__
 *
 * To run a mutation, you first call `useAddTrackNumberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddTrackNumberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addTrackNumberMutation, { data, loading, error }] = useAddTrackNumberMutation({
 *   variables: {
 *      shipmentId: // value for 'shipmentId'
 *      trackNumber: // value for 'trackNumber'
 *      carrierCode: // value for 'carrierCode'
 *   },
 * });
 */
export function useAddTrackNumberMutation(baseOptions?: Apollo.MutationHookOptions<AddTrackNumberMutation, AddTrackNumberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AddTrackNumberMutation, AddTrackNumberMutationVariables>(AddTrackNumberDocument, options);
      }
export type AddTrackNumberMutationHookResult = ReturnType<typeof useAddTrackNumberMutation>;
export type AddTrackNumberMutationResult = Apollo.MutationResult<AddTrackNumberMutation>;
export type AddTrackNumberMutationOptions = Apollo.BaseMutationOptions<AddTrackNumberMutation, AddTrackNumberMutationVariables>;
export const AuthFacebookDocument = gql`
    mutation authFacebook($accessToken: String!) {
  authFacebook(accessToken: $accessToken) {
    token
  }
}
    `;
export type AuthFacebookMutationFn = Apollo.MutationFunction<AuthFacebookMutation, AuthFacebookMutationVariables>;

/**
 * __useAuthFacebookMutation__
 *
 * To run a mutation, you first call `useAuthFacebookMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthFacebookMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authFacebookMutation, { data, loading, error }] = useAuthFacebookMutation({
 *   variables: {
 *      accessToken: // value for 'accessToken'
 *   },
 * });
 */
export function useAuthFacebookMutation(baseOptions?: Apollo.MutationHookOptions<AuthFacebookMutation, AuthFacebookMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AuthFacebookMutation, AuthFacebookMutationVariables>(AuthFacebookDocument, options);
      }
export type AuthFacebookMutationHookResult = ReturnType<typeof useAuthFacebookMutation>;
export type AuthFacebookMutationResult = Apollo.MutationResult<AuthFacebookMutation>;
export type AuthFacebookMutationOptions = Apollo.BaseMutationOptions<AuthFacebookMutation, AuthFacebookMutationVariables>;
export const AuthGoogleDocument = gql`
    mutation authGoogle($accessToken: String!) {
  authGoogle(accessToken: $accessToken) {
    token
  }
}
    `;
export type AuthGoogleMutationFn = Apollo.MutationFunction<AuthGoogleMutation, AuthGoogleMutationVariables>;

/**
 * __useAuthGoogleMutation__
 *
 * To run a mutation, you first call `useAuthGoogleMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthGoogleMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authGoogleMutation, { data, loading, error }] = useAuthGoogleMutation({
 *   variables: {
 *      accessToken: // value for 'accessToken'
 *   },
 * });
 */
export function useAuthGoogleMutation(baseOptions?: Apollo.MutationHookOptions<AuthGoogleMutation, AuthGoogleMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AuthGoogleMutation, AuthGoogleMutationVariables>(AuthGoogleDocument, options);
      }
export type AuthGoogleMutationHookResult = ReturnType<typeof useAuthGoogleMutation>;
export type AuthGoogleMutationResult = Apollo.MutationResult<AuthGoogleMutation>;
export type AuthGoogleMutationOptions = Apollo.BaseMutationOptions<AuthGoogleMutation, AuthGoogleMutationVariables>;
export const AuthVkontakteDocument = gql`
    mutation authVkontakte($accessToken: String!) {
  authVkontakte(accessToken: $accessToken) {
    token
  }
}
    `;
export type AuthVkontakteMutationFn = Apollo.MutationFunction<AuthVkontakteMutation, AuthVkontakteMutationVariables>;

/**
 * __useAuthVkontakteMutation__
 *
 * To run a mutation, you first call `useAuthVkontakteMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAuthVkontakteMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [authVkontakteMutation, { data, loading, error }] = useAuthVkontakteMutation({
 *   variables: {
 *      accessToken: // value for 'accessToken'
 *   },
 * });
 */
export function useAuthVkontakteMutation(baseOptions?: Apollo.MutationHookOptions<AuthVkontakteMutation, AuthVkontakteMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<AuthVkontakteMutation, AuthVkontakteMutationVariables>(AuthVkontakteDocument, options);
      }
export type AuthVkontakteMutationHookResult = ReturnType<typeof useAuthVkontakteMutation>;
export type AuthVkontakteMutationResult = Apollo.MutationResult<AuthVkontakteMutation>;
export type AuthVkontakteMutationOptions = Apollo.BaseMutationOptions<AuthVkontakteMutation, AuthVkontakteMutationVariables>;
export const CancelParcelDocument = gql`
    mutation cancelParcel($id: ID!) {
  cancelParcel(id: $id) {
    id
    deliveryStatusId
    paymentStatusId
  }
}
    `;
export type CancelParcelMutationFn = Apollo.MutationFunction<CancelParcelMutation, CancelParcelMutationVariables>;

/**
 * __useCancelParcelMutation__
 *
 * To run a mutation, you first call `useCancelParcelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelParcelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelParcelMutation, { data, loading, error }] = useCancelParcelMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useCancelParcelMutation(baseOptions?: Apollo.MutationHookOptions<CancelParcelMutation, CancelParcelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CancelParcelMutation, CancelParcelMutationVariables>(CancelParcelDocument, options);
      }
export type CancelParcelMutationHookResult = ReturnType<typeof useCancelParcelMutation>;
export type CancelParcelMutationResult = Apollo.MutationResult<CancelParcelMutation>;
export type CancelParcelMutationOptions = Apollo.BaseMutationOptions<CancelParcelMutation, CancelParcelMutationVariables>;
export const ChangePasswordDocument = gql`
    mutation ChangePassword($oldPassword: String!, $newPassword: String!) {
  changePassword(oldPassword: $oldPassword, newPassword: $newPassword)
}
    `;
export type ChangePasswordMutationFn = Apollo.MutationFunction<ChangePasswordMutation, ChangePasswordMutationVariables>;

/**
 * __useChangePasswordMutation__
 *
 * To run a mutation, you first call `useChangePasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useChangePasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [changePasswordMutation, { data, loading, error }] = useChangePasswordMutation({
 *   variables: {
 *      oldPassword: // value for 'oldPassword'
 *      newPassword: // value for 'newPassword'
 *   },
 * });
 */
export function useChangePasswordMutation(baseOptions?: Apollo.MutationHookOptions<ChangePasswordMutation, ChangePasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ChangePasswordMutation, ChangePasswordMutationVariables>(ChangePasswordDocument, options);
      }
export type ChangePasswordMutationHookResult = ReturnType<typeof useChangePasswordMutation>;
export type ChangePasswordMutationResult = Apollo.MutationResult<ChangePasswordMutation>;
export type ChangePasswordMutationOptions = Apollo.BaseMutationOptions<ChangePasswordMutation, ChangePasswordMutationVariables>;
export const CreateContactDocument = gql`
    mutation createContact($input: ContactInput!) {
  createContact(input: $input) {
    id
    email
    remark
    name
    company
    country {
      ...FragmentDeliveryCountry
    }
    address
    address2
    address3
    zipCode
    city
    state
    phone
    eoriCode
    innKppCode
    contactName
  }
}
    ${FragmentDeliveryCountryFragmentDoc}`;
export type CreateContactMutationFn = Apollo.MutationFunction<CreateContactMutation, CreateContactMutationVariables>;

/**
 * __useCreateContactMutation__
 *
 * To run a mutation, you first call `useCreateContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createContactMutation, { data, loading, error }] = useCreateContactMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateContactMutation(baseOptions?: Apollo.MutationHookOptions<CreateContactMutation, CreateContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateContactMutation, CreateContactMutationVariables>(CreateContactDocument, options);
      }
export type CreateContactMutationHookResult = ReturnType<typeof useCreateContactMutation>;
export type CreateContactMutationResult = Apollo.MutationResult<CreateContactMutation>;
export type CreateContactMutationOptions = Apollo.BaseMutationOptions<CreateContactMutation, CreateContactMutationVariables>;
export const CreatePackagingTemplateDocument = gql`
    mutation createPackagingTemplate($input: PackagingTemplateInput) {
  createPackagingTemplate(input: $input) {
    id
    name
    weight
    length
    width
    height
    userId
  }
}
    `;
export type CreatePackagingTemplateMutationFn = Apollo.MutationFunction<CreatePackagingTemplateMutation, CreatePackagingTemplateMutationVariables>;

/**
 * __useCreatePackagingTemplateMutation__
 *
 * To run a mutation, you first call `useCreatePackagingTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePackagingTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPackagingTemplateMutation, { data, loading, error }] = useCreatePackagingTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreatePackagingTemplateMutation(baseOptions?: Apollo.MutationHookOptions<CreatePackagingTemplateMutation, CreatePackagingTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePackagingTemplateMutation, CreatePackagingTemplateMutationVariables>(CreatePackagingTemplateDocument, options);
      }
export type CreatePackagingTemplateMutationHookResult = ReturnType<typeof useCreatePackagingTemplateMutation>;
export type CreatePackagingTemplateMutationResult = Apollo.MutationResult<CreatePackagingTemplateMutation>;
export type CreatePackagingTemplateMutationOptions = Apollo.BaseMutationOptions<CreatePackagingTemplateMutation, CreatePackagingTemplateMutationVariables>;
export const CreateParcelDocument = gql`
    mutation createParcel($input: ParcelInput!) {
  createParcel(input: $input) {
    id
    sender {
      id
      name
      company
      country {
        ...FragmentDeliveryCountry
      }
      address
      address2
      address3
      zipCode
      city
      state
      phone
      email
      innKppCode
      eoriCode
      remark
    }
    receiver {
      id
      name
      company
      country {
        ...FragmentDeliveryCountry
      }
      address
      address2
      address3
      zipCode
      city
      state
      phone
      email
      innKppCode
      eoriCode
      remark
    }
    typeDelivery {
      id
      name
      serviceName
    }
    senderNotes
    senderMark
    additionalInsurance
    declaredWeight
    declaredAmount
    deliveryStatusId
    paymentStatusId
    vatKey
    trackNumber
    carrierCode
  }
}
    ${FragmentDeliveryCountryFragmentDoc}`;
export type CreateParcelMutationFn = Apollo.MutationFunction<CreateParcelMutation, CreateParcelMutationVariables>;

/**
 * __useCreateParcelMutation__
 *
 * To run a mutation, you first call `useCreateParcelMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateParcelMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createParcelMutation, { data, loading, error }] = useCreateParcelMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateParcelMutation(baseOptions?: Apollo.MutationHookOptions<CreateParcelMutation, CreateParcelMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateParcelMutation, CreateParcelMutationVariables>(CreateParcelDocument, options);
      }
export type CreateParcelMutationHookResult = ReturnType<typeof useCreateParcelMutation>;
export type CreateParcelMutationResult = Apollo.MutationResult<CreateParcelMutation>;
export type CreateParcelMutationOptions = Apollo.BaseMutationOptions<CreateParcelMutation, CreateParcelMutationVariables>;
export const CreatePaymentDocument = gql`
    mutation CreatePayment($value: String, $currency: String, $parcelId: Int) {
  createPayment(value: $value, currency: $currency, parcelId: $parcelId)
}
    `;
export type CreatePaymentMutationFn = Apollo.MutationFunction<CreatePaymentMutation, CreatePaymentMutationVariables>;

/**
 * __useCreatePaymentMutation__
 *
 * To run a mutation, you first call `useCreatePaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreatePaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createPaymentMutation, { data, loading, error }] = useCreatePaymentMutation({
 *   variables: {
 *      value: // value for 'value'
 *      currency: // value for 'currency'
 *      parcelId: // value for 'parcelId'
 *   },
 * });
 */
export function useCreatePaymentMutation(baseOptions?: Apollo.MutationHookOptions<CreatePaymentMutation, CreatePaymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreatePaymentMutation, CreatePaymentMutationVariables>(CreatePaymentDocument, options);
      }
export type CreatePaymentMutationHookResult = ReturnType<typeof useCreatePaymentMutation>;
export type CreatePaymentMutationResult = Apollo.MutationResult<CreatePaymentMutation>;
export type CreatePaymentMutationOptions = Apollo.BaseMutationOptions<CreatePaymentMutation, CreatePaymentMutationVariables>;
export const CreateProductDocument = gql`
    mutation createProduct($product: ProductInput) {
  createProduct(product: $product) {
    id
    name
    sku
    detailsDescriptionRU
    detailsDescriptionEN
    state
    trademark
    declaredValue
    country {
      ...FragmentDeliveryCountry
    }
    netWeight
    code
    userId
    fnsku
    upc
    actualDimensions {
      id
      warehouseShipmentId
      weight
      length
      width
      height
    }
    declaredDimensions {
      id
      warehouseShipmentId
      weight
      length
      width
      height
    }
  }
}
    ${FragmentDeliveryCountryFragmentDoc}`;
export type CreateProductMutationFn = Apollo.MutationFunction<CreateProductMutation, CreateProductMutationVariables>;

/**
 * __useCreateProductMutation__
 *
 * To run a mutation, you first call `useCreateProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createProductMutation, { data, loading, error }] = useCreateProductMutation({
 *   variables: {
 *      product: // value for 'product'
 *   },
 * });
 */
export function useCreateProductMutation(baseOptions?: Apollo.MutationHookOptions<CreateProductMutation, CreateProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateProductMutation, CreateProductMutationVariables>(CreateProductDocument, options);
      }
export type CreateProductMutationHookResult = ReturnType<typeof useCreateProductMutation>;
export type CreateProductMutationResult = Apollo.MutationResult<CreateProductMutation>;
export type CreateProductMutationOptions = Apollo.BaseMutationOptions<CreateProductMutation, CreateProductMutationVariables>;
export const CreateQrPaymentDocument = gql`
    mutation createQRPayment($value: Float!) {
  createQRPayment(value: $value)
}
    `;
export type CreateQrPaymentMutationFn = Apollo.MutationFunction<CreateQrPaymentMutation, CreateQrPaymentMutationVariables>;

/**
 * __useCreateQrPaymentMutation__
 *
 * To run a mutation, you first call `useCreateQrPaymentMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateQrPaymentMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createQrPaymentMutation, { data, loading, error }] = useCreateQrPaymentMutation({
 *   variables: {
 *      value: // value for 'value'
 *   },
 * });
 */
export function useCreateQrPaymentMutation(baseOptions?: Apollo.MutationHookOptions<CreateQrPaymentMutation, CreateQrPaymentMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateQrPaymentMutation, CreateQrPaymentMutationVariables>(CreateQrPaymentDocument, options);
      }
export type CreateQrPaymentMutationHookResult = ReturnType<typeof useCreateQrPaymentMutation>;
export type CreateQrPaymentMutationResult = Apollo.MutationResult<CreateQrPaymentMutation>;
export type CreateQrPaymentMutationOptions = Apollo.BaseMutationOptions<CreateQrPaymentMutation, CreateQrPaymentMutationVariables>;
export const CreateReportByShipmentsDocument = gql`
    mutation createReportByShipments($input: ReportRangeInput) {
  createReportByShipments(input: $input) {
    filename
  }
}
    `;
export type CreateReportByShipmentsMutationFn = Apollo.MutationFunction<CreateReportByShipmentsMutation, CreateReportByShipmentsMutationVariables>;

/**
 * __useCreateReportByShipmentsMutation__
 *
 * To run a mutation, you first call `useCreateReportByShipmentsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReportByShipmentsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReportByShipmentsMutation, { data, loading, error }] = useCreateReportByShipmentsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateReportByShipmentsMutation(baseOptions?: Apollo.MutationHookOptions<CreateReportByShipmentsMutation, CreateReportByShipmentsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateReportByShipmentsMutation, CreateReportByShipmentsMutationVariables>(CreateReportByShipmentsDocument, options);
      }
export type CreateReportByShipmentsMutationHookResult = ReturnType<typeof useCreateReportByShipmentsMutation>;
export type CreateReportByShipmentsMutationResult = Apollo.MutationResult<CreateReportByShipmentsMutation>;
export type CreateReportByShipmentsMutationOptions = Apollo.BaseMutationOptions<CreateReportByShipmentsMutation, CreateReportByShipmentsMutationVariables>;
export const CreateReportByTransactionsDocument = gql`
    mutation createReportByTransactions($input: ReportRangeInput) {
  createReportByTransactions(input: $input) {
    filename
  }
}
    `;
export type CreateReportByTransactionsMutationFn = Apollo.MutationFunction<CreateReportByTransactionsMutation, CreateReportByTransactionsMutationVariables>;

/**
 * __useCreateReportByTransactionsMutation__
 *
 * To run a mutation, you first call `useCreateReportByTransactionsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateReportByTransactionsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createReportByTransactionsMutation, { data, loading, error }] = useCreateReportByTransactionsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateReportByTransactionsMutation(baseOptions?: Apollo.MutationHookOptions<CreateReportByTransactionsMutation, CreateReportByTransactionsMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateReportByTransactionsMutation, CreateReportByTransactionsMutationVariables>(CreateReportByTransactionsDocument, options);
      }
export type CreateReportByTransactionsMutationHookResult = ReturnType<typeof useCreateReportByTransactionsMutation>;
export type CreateReportByTransactionsMutationResult = Apollo.MutationResult<CreateReportByTransactionsMutation>;
export type CreateReportByTransactionsMutationOptions = Apollo.BaseMutationOptions<CreateReportByTransactionsMutation, CreateReportByTransactionsMutationVariables>;
export const CreateSenderAccountDocument = gql`
    mutation createSenderAccount($input: SenderAccountInput) {
  createSenderAccount(input: $input) {
    id
    accountName
    status
    accountNumber
    password
    siteId
    requestorName
    requestorAddress
    requestorCity
    requestorCountryCode
    requestorPostalCode
    serviceName
    isTestMode
    user {
      id
      email
      name
      company
      country {
        ...FragmentDeliveryCountry
      }
      address
      address2
      address3
      zipCode
      city
      state
      phone
      eoriCode
      status
      createdAt
      updatedAt
      balance
      discount
    }
  }
}
    ${FragmentDeliveryCountryFragmentDoc}`;
export type CreateSenderAccountMutationFn = Apollo.MutationFunction<CreateSenderAccountMutation, CreateSenderAccountMutationVariables>;

/**
 * __useCreateSenderAccountMutation__
 *
 * To run a mutation, you first call `useCreateSenderAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateSenderAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createSenderAccountMutation, { data, loading, error }] = useCreateSenderAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateSenderAccountMutation(baseOptions?: Apollo.MutationHookOptions<CreateSenderAccountMutation, CreateSenderAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<CreateSenderAccountMutation, CreateSenderAccountMutationVariables>(CreateSenderAccountDocument, options);
      }
export type CreateSenderAccountMutationHookResult = ReturnType<typeof useCreateSenderAccountMutation>;
export type CreateSenderAccountMutationResult = Apollo.MutationResult<CreateSenderAccountMutation>;
export type CreateSenderAccountMutationOptions = Apollo.BaseMutationOptions<CreateSenderAccountMutation, CreateSenderAccountMutationVariables>;
export const EditCurrentUserDocument = gql`
    mutation editCurrentUser($name: String!, $company: String, $countryId: Int!, $address: String, $address2: String, $address3: String, $zipCode: String, $city: String, $state: String, $phone: String, $eoriCode: String) {
  editCurrentUser(
    name: $name
    company: $company
    countryId: $countryId
    address: $address
    address2: $address2
    address3: $address3
    zipCode: $zipCode
    city: $city
    state: $state
    phone: $phone
    eoriCode: $eoriCode
  ) {
    name
    company
    country {
      ...FragmentDeliveryCountry
    }
    address
    address2
    address3
    zipCode
    city
    state
    phone
    eoriCode
  }
}
    ${FragmentDeliveryCountryFragmentDoc}`;
export type EditCurrentUserMutationFn = Apollo.MutationFunction<EditCurrentUserMutation, EditCurrentUserMutationVariables>;

/**
 * __useEditCurrentUserMutation__
 *
 * To run a mutation, you first call `useEditCurrentUserMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useEditCurrentUserMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [editCurrentUserMutation, { data, loading, error }] = useEditCurrentUserMutation({
 *   variables: {
 *      name: // value for 'name'
 *      company: // value for 'company'
 *      countryId: // value for 'countryId'
 *      address: // value for 'address'
 *      address2: // value for 'address2'
 *      address3: // value for 'address3'
 *      zipCode: // value for 'zipCode'
 *      city: // value for 'city'
 *      state: // value for 'state'
 *      phone: // value for 'phone'
 *      eoriCode: // value for 'eoriCode'
 *   },
 * });
 */
export function useEditCurrentUserMutation(baseOptions?: Apollo.MutationHookOptions<EditCurrentUserMutation, EditCurrentUserMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<EditCurrentUserMutation, EditCurrentUserMutationVariables>(EditCurrentUserDocument, options);
      }
export type EditCurrentUserMutationHookResult = ReturnType<typeof useEditCurrentUserMutation>;
export type EditCurrentUserMutationResult = Apollo.MutationResult<EditCurrentUserMutation>;
export type EditCurrentUserMutationOptions = Apollo.BaseMutationOptions<EditCurrentUserMutation, EditCurrentUserMutationVariables>;
export const ImportProductListFromFileDocument = gql`
    mutation importProductListFromFile($file: Upload!) {
  importProductListFromFile(file: $file) {
    products {
      count
      rows {
        id
      }
    }
    messages
  }
}
    `;
export type ImportProductListFromFileMutationFn = Apollo.MutationFunction<ImportProductListFromFileMutation, ImportProductListFromFileMutationVariables>;

/**
 * __useImportProductListFromFileMutation__
 *
 * To run a mutation, you first call `useImportProductListFromFileMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useImportProductListFromFileMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [importProductListFromFileMutation, { data, loading, error }] = useImportProductListFromFileMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useImportProductListFromFileMutation(baseOptions?: Apollo.MutationHookOptions<ImportProductListFromFileMutation, ImportProductListFromFileMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<ImportProductListFromFileMutation, ImportProductListFromFileMutationVariables>(ImportProductListFromFileDocument, options);
      }
export type ImportProductListFromFileMutationHookResult = ReturnType<typeof useImportProductListFromFileMutation>;
export type ImportProductListFromFileMutationResult = Apollo.MutationResult<ImportProductListFromFileMutation>;
export type ImportProductListFromFileMutationOptions = Apollo.BaseMutationOptions<ImportProductListFromFileMutation, ImportProductListFromFileMutationVariables>;
export const RemoveContactDocument = gql`
    mutation removeContact($id: ID!) {
  removeContact(id: $id) {
    status
  }
}
    `;
export type RemoveContactMutationFn = Apollo.MutationFunction<RemoveContactMutation, RemoveContactMutationVariables>;

/**
 * __useRemoveContactMutation__
 *
 * To run a mutation, you first call `useRemoveContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeContactMutation, { data, loading, error }] = useRemoveContactMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveContactMutation(baseOptions?: Apollo.MutationHookOptions<RemoveContactMutation, RemoveContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveContactMutation, RemoveContactMutationVariables>(RemoveContactDocument, options);
      }
export type RemoveContactMutationHookResult = ReturnType<typeof useRemoveContactMutation>;
export type RemoveContactMutationResult = Apollo.MutationResult<RemoveContactMutation>;
export type RemoveContactMutationOptions = Apollo.BaseMutationOptions<RemoveContactMutation, RemoveContactMutationVariables>;
export const RemovePackagingTemplateDocument = gql`
    mutation removePackagingTemplate($id: Int!) {
  removePackagingTemplate(id: $id)
}
    `;
export type RemovePackagingTemplateMutationFn = Apollo.MutationFunction<RemovePackagingTemplateMutation, RemovePackagingTemplateMutationVariables>;

/**
 * __useRemovePackagingTemplateMutation__
 *
 * To run a mutation, you first call `useRemovePackagingTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemovePackagingTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removePackagingTemplateMutation, { data, loading, error }] = useRemovePackagingTemplateMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemovePackagingTemplateMutation(baseOptions?: Apollo.MutationHookOptions<RemovePackagingTemplateMutation, RemovePackagingTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemovePackagingTemplateMutation, RemovePackagingTemplateMutationVariables>(RemovePackagingTemplateDocument, options);
      }
export type RemovePackagingTemplateMutationHookResult = ReturnType<typeof useRemovePackagingTemplateMutation>;
export type RemovePackagingTemplateMutationResult = Apollo.MutationResult<RemovePackagingTemplateMutation>;
export type RemovePackagingTemplateMutationOptions = Apollo.BaseMutationOptions<RemovePackagingTemplateMutation, RemovePackagingTemplateMutationVariables>;
export const RemoveProductDocument = gql`
    mutation removeProduct($productId: Int!) {
  removeProduct(productId: $productId)
}
    `;
export type RemoveProductMutationFn = Apollo.MutationFunction<RemoveProductMutation, RemoveProductMutationVariables>;

/**
 * __useRemoveProductMutation__
 *
 * To run a mutation, you first call `useRemoveProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeProductMutation, { data, loading, error }] = useRemoveProductMutation({
 *   variables: {
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useRemoveProductMutation(baseOptions?: Apollo.MutationHookOptions<RemoveProductMutation, RemoveProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveProductMutation, RemoveProductMutationVariables>(RemoveProductDocument, options);
      }
export type RemoveProductMutationHookResult = ReturnType<typeof useRemoveProductMutation>;
export type RemoveProductMutationResult = Apollo.MutationResult<RemoveProductMutation>;
export type RemoveProductMutationOptions = Apollo.BaseMutationOptions<RemoveProductMutation, RemoveProductMutationVariables>;
export const RemoveSenderAccountDocument = gql`
    mutation removeSenderAccount($id: Int!) {
  removeSenderAccount(id: $id)
}
    `;
export type RemoveSenderAccountMutationFn = Apollo.MutationFunction<RemoveSenderAccountMutation, RemoveSenderAccountMutationVariables>;

/**
 * __useRemoveSenderAccountMutation__
 *
 * To run a mutation, you first call `useRemoveSenderAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveSenderAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeSenderAccountMutation, { data, loading, error }] = useRemoveSenderAccountMutation({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useRemoveSenderAccountMutation(baseOptions?: Apollo.MutationHookOptions<RemoveSenderAccountMutation, RemoveSenderAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveSenderAccountMutation, RemoveSenderAccountMutationVariables>(RemoveSenderAccountDocument, options);
      }
export type RemoveSenderAccountMutationHookResult = ReturnType<typeof useRemoveSenderAccountMutation>;
export type RemoveSenderAccountMutationResult = Apollo.MutationResult<RemoveSenderAccountMutation>;
export type RemoveSenderAccountMutationOptions = Apollo.BaseMutationOptions<RemoveSenderAccountMutation, RemoveSenderAccountMutationVariables>;
export const RemoveStoreDocument = gql`
    mutation removeStore($marketplaceId: Int!) {
  removeStore(marketplaceId: $marketplaceId) {
    id
    name
    url
    userId
    storeId
    isActive
    marketplace
  }
}
    `;
export type RemoveStoreMutationFn = Apollo.MutationFunction<RemoveStoreMutation, RemoveStoreMutationVariables>;

/**
 * __useRemoveStoreMutation__
 *
 * To run a mutation, you first call `useRemoveStoreMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveStoreMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeStoreMutation, { data, loading, error }] = useRemoveStoreMutation({
 *   variables: {
 *      marketplaceId: // value for 'marketplaceId'
 *   },
 * });
 */
export function useRemoveStoreMutation(baseOptions?: Apollo.MutationHookOptions<RemoveStoreMutation, RemoveStoreMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveStoreMutation, RemoveStoreMutationVariables>(RemoveStoreDocument, options);
      }
export type RemoveStoreMutationHookResult = ReturnType<typeof useRemoveStoreMutation>;
export type RemoveStoreMutationResult = Apollo.MutationResult<RemoveStoreMutation>;
export type RemoveStoreMutationOptions = Apollo.BaseMutationOptions<RemoveStoreMutation, RemoveStoreMutationVariables>;
export const RemoveTrackNumberDocument = gql`
    mutation removeTrackNumber($shipmentId: Int!) {
  removeTrackNumber(shipmentId: $shipmentId) {
    id
  }
}
    `;
export type RemoveTrackNumberMutationFn = Apollo.MutationFunction<RemoveTrackNumberMutation, RemoveTrackNumberMutationVariables>;

/**
 * __useRemoveTrackNumberMutation__
 *
 * To run a mutation, you first call `useRemoveTrackNumberMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveTrackNumberMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeTrackNumberMutation, { data, loading, error }] = useRemoveTrackNumberMutation({
 *   variables: {
 *      shipmentId: // value for 'shipmentId'
 *   },
 * });
 */
export function useRemoveTrackNumberMutation(baseOptions?: Apollo.MutationHookOptions<RemoveTrackNumberMutation, RemoveTrackNumberMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<RemoveTrackNumberMutation, RemoveTrackNumberMutationVariables>(RemoveTrackNumberDocument, options);
      }
export type RemoveTrackNumberMutationHookResult = ReturnType<typeof useRemoveTrackNumberMutation>;
export type RemoveTrackNumberMutationResult = Apollo.MutationResult<RemoveTrackNumberMutation>;
export type RemoveTrackNumberMutationOptions = Apollo.BaseMutationOptions<RemoveTrackNumberMutation, RemoveTrackNumberMutationVariables>;
export const SaveNewPasswordDocument = gql`
    mutation saveNewPassword($token: String!, $password: String!) {
  saveNewPassword(token: $token, password: $password) {
    status
  }
}
    `;
export type SaveNewPasswordMutationFn = Apollo.MutationFunction<SaveNewPasswordMutation, SaveNewPasswordMutationVariables>;

/**
 * __useSaveNewPasswordMutation__
 *
 * To run a mutation, you first call `useSaveNewPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSaveNewPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [saveNewPasswordMutation, { data, loading, error }] = useSaveNewPasswordMutation({
 *   variables: {
 *      token: // value for 'token'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useSaveNewPasswordMutation(baseOptions?: Apollo.MutationHookOptions<SaveNewPasswordMutation, SaveNewPasswordMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SaveNewPasswordMutation, SaveNewPasswordMutationVariables>(SaveNewPasswordDocument, options);
      }
export type SaveNewPasswordMutationHookResult = ReturnType<typeof useSaveNewPasswordMutation>;
export type SaveNewPasswordMutationResult = Apollo.MutationResult<SaveNewPasswordMutation>;
export type SaveNewPasswordMutationOptions = Apollo.BaseMutationOptions<SaveNewPasswordMutation, SaveNewPasswordMutationVariables>;
export const SignInDocument = gql`
    mutation signIn($email: String!, $password: String!, $rememberMe: Boolean) {
  signIn(email: $email, password: $password, rememberMe: $rememberMe) {
    token
    message
  }
}
    `;
export type SignInMutationFn = Apollo.MutationFunction<SignInMutation, SignInMutationVariables>;

/**
 * __useSignInMutation__
 *
 * To run a mutation, you first call `useSignInMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignInMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signInMutation, { data, loading, error }] = useSignInMutation({
 *   variables: {
 *      email: // value for 'email'
 *      password: // value for 'password'
 *      rememberMe: // value for 'rememberMe'
 *   },
 * });
 */
export function useSignInMutation(baseOptions?: Apollo.MutationHookOptions<SignInMutation, SignInMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignInMutation, SignInMutationVariables>(SignInDocument, options);
      }
export type SignInMutationHookResult = ReturnType<typeof useSignInMutation>;
export type SignInMutationResult = Apollo.MutationResult<SignInMutation>;
export type SignInMutationOptions = Apollo.BaseMutationOptions<SignInMutation, SignInMutationVariables>;
export const SignUpDocument = gql`
    mutation signUp($name: String!, $email: String!, $password: String!) {
  signUp(name: $name, email: $email, password: $password) {
    token
    message
  }
}
    `;
export type SignUpMutationFn = Apollo.MutationFunction<SignUpMutation, SignUpMutationVariables>;

/**
 * __useSignUpMutation__
 *
 * To run a mutation, you first call `useSignUpMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useSignUpMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [signUpMutation, { data, loading, error }] = useSignUpMutation({
 *   variables: {
 *      name: // value for 'name'
 *      email: // value for 'email'
 *      password: // value for 'password'
 *   },
 * });
 */
export function useSignUpMutation(baseOptions?: Apollo.MutationHookOptions<SignUpMutation, SignUpMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<SignUpMutation, SignUpMutationVariables>(SignUpDocument, options);
      }
export type SignUpMutationHookResult = ReturnType<typeof useSignUpMutation>;
export type SignUpMutationResult = Apollo.MutationResult<SignUpMutation>;
export type SignUpMutationOptions = Apollo.BaseMutationOptions<SignUpMutation, SignUpMutationVariables>;
export const UpdateContactDocument = gql`
    mutation updateContact($id: ID!, $input: ContactInput!) {
  updateContact(id: $id, input: $input) {
    id
    email
    remark
    name
    company
    country {
      ...FragmentDeliveryCountry
    }
    address
    address2
    address3
    zipCode
    city
    state
    phone
    eoriCode
    innKppCode
    contactName
  }
}
    ${FragmentDeliveryCountryFragmentDoc}`;
export type UpdateContactMutationFn = Apollo.MutationFunction<UpdateContactMutation, UpdateContactMutationVariables>;

/**
 * __useUpdateContactMutation__
 *
 * To run a mutation, you first call `useUpdateContactMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateContactMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateContactMutation, { data, loading, error }] = useUpdateContactMutation({
 *   variables: {
 *      id: // value for 'id'
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateContactMutation(baseOptions?: Apollo.MutationHookOptions<UpdateContactMutation, UpdateContactMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateContactMutation, UpdateContactMutationVariables>(UpdateContactDocument, options);
      }
export type UpdateContactMutationHookResult = ReturnType<typeof useUpdateContactMutation>;
export type UpdateContactMutationResult = Apollo.MutationResult<UpdateContactMutation>;
export type UpdateContactMutationOptions = Apollo.BaseMutationOptions<UpdateContactMutation, UpdateContactMutationVariables>;
export const UpdateEntityLegalDataDocument = gql`
    mutation updateEntityLegalData($input: EntityLegalUpdateDataInput, $file: Upload!) {
  updateEntityLegalData(input: $input, file: $file) {
    id
    company
    inn
    kpp
    MSRN
    generalManager
    legalAddress
    nameOfTheBank
    BIK
    paymentAccount
    correspondentAccount
    fileName
    userId
    isDataVerified
  }
}
    `;
export type UpdateEntityLegalDataMutationFn = Apollo.MutationFunction<UpdateEntityLegalDataMutation, UpdateEntityLegalDataMutationVariables>;

/**
 * __useUpdateEntityLegalDataMutation__
 *
 * To run a mutation, you first call `useUpdateEntityLegalDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateEntityLegalDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateEntityLegalDataMutation, { data, loading, error }] = useUpdateEntityLegalDataMutation({
 *   variables: {
 *      input: // value for 'input'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUpdateEntityLegalDataMutation(baseOptions?: Apollo.MutationHookOptions<UpdateEntityLegalDataMutation, UpdateEntityLegalDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateEntityLegalDataMutation, UpdateEntityLegalDataMutationVariables>(UpdateEntityLegalDataDocument, options);
      }
export type UpdateEntityLegalDataMutationHookResult = ReturnType<typeof useUpdateEntityLegalDataMutation>;
export type UpdateEntityLegalDataMutationResult = Apollo.MutationResult<UpdateEntityLegalDataMutation>;
export type UpdateEntityLegalDataMutationOptions = Apollo.BaseMutationOptions<UpdateEntityLegalDataMutation, UpdateEntityLegalDataMutationVariables>;
export const UpdatePackagingTemplateDocument = gql`
    mutation updatePackagingTemplate($input: PackagingTemplateInput) {
  updatePackagingTemplate(input: $input) {
    id
    name
    weight
    length
    width
    height
    userId
  }
}
    `;
export type UpdatePackagingTemplateMutationFn = Apollo.MutationFunction<UpdatePackagingTemplateMutation, UpdatePackagingTemplateMutationVariables>;

/**
 * __useUpdatePackagingTemplateMutation__
 *
 * To run a mutation, you first call `useUpdatePackagingTemplateMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePackagingTemplateMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePackagingTemplateMutation, { data, loading, error }] = useUpdatePackagingTemplateMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePackagingTemplateMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePackagingTemplateMutation, UpdatePackagingTemplateMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePackagingTemplateMutation, UpdatePackagingTemplateMutationVariables>(UpdatePackagingTemplateDocument, options);
      }
export type UpdatePackagingTemplateMutationHookResult = ReturnType<typeof useUpdatePackagingTemplateMutation>;
export type UpdatePackagingTemplateMutationResult = Apollo.MutationResult<UpdatePackagingTemplateMutation>;
export type UpdatePackagingTemplateMutationOptions = Apollo.BaseMutationOptions<UpdatePackagingTemplateMutation, UpdatePackagingTemplateMutationVariables>;
export const UpdatePassportDataDocument = gql`
    mutation updatePassportData($input: UserPassportUpdateDataInput, $file: Upload) {
  updatePassportData(input: $input, file: $file) {
    ...FragmentUserPasport
  }
}
    ${FragmentUserPasportFragmentDoc}`;
export type UpdatePassportDataMutationFn = Apollo.MutationFunction<UpdatePassportDataMutation, UpdatePassportDataMutationVariables>;

/**
 * __useUpdatePassportDataMutation__
 *
 * To run a mutation, you first call `useUpdatePassportDataMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePassportDataMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePassportDataMutation, { data, loading, error }] = useUpdatePassportDataMutation({
 *   variables: {
 *      input: // value for 'input'
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUpdatePassportDataMutation(baseOptions?: Apollo.MutationHookOptions<UpdatePassportDataMutation, UpdatePassportDataMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdatePassportDataMutation, UpdatePassportDataMutationVariables>(UpdatePassportDataDocument, options);
      }
export type UpdatePassportDataMutationHookResult = ReturnType<typeof useUpdatePassportDataMutation>;
export type UpdatePassportDataMutationResult = Apollo.MutationResult<UpdatePassportDataMutation>;
export type UpdatePassportDataMutationOptions = Apollo.BaseMutationOptions<UpdatePassportDataMutation, UpdatePassportDataMutationVariables>;
export const UpdateProductDocument = gql`
    mutation updateProduct($product: ProductInput) {
  updateProduct(product: $product) {
    id
    name
    sku
    detailsDescriptionRU
    detailsDescriptionEN
    state
    trademark
    declaredValue
    country {
      ...FragmentDeliveryCountry
    }
    netWeight
    code
    userId
  }
}
    ${FragmentDeliveryCountryFragmentDoc}`;
export type UpdateProductMutationFn = Apollo.MutationFunction<UpdateProductMutation, UpdateProductMutationVariables>;

/**
 * __useUpdateProductMutation__
 *
 * To run a mutation, you first call `useUpdateProductMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateProductMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateProductMutation, { data, loading, error }] = useUpdateProductMutation({
 *   variables: {
 *      product: // value for 'product'
 *   },
 * });
 */
export function useUpdateProductMutation(baseOptions?: Apollo.MutationHookOptions<UpdateProductMutation, UpdateProductMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateProductMutation, UpdateProductMutationVariables>(UpdateProductDocument, options);
      }
export type UpdateProductMutationHookResult = ReturnType<typeof useUpdateProductMutation>;
export type UpdateProductMutationResult = Apollo.MutationResult<UpdateProductMutation>;
export type UpdateProductMutationOptions = Apollo.BaseMutationOptions<UpdateProductMutation, UpdateProductMutationVariables>;
export const UpdateSenderAccountDocument = gql`
    mutation updateSenderAccount($input: SenderAccountInput!) {
  updateSenderAccount(input: $input) {
    id
    accountName
    status
    accountNumber
    password
    siteId
    requestorName
    requestorPhone
    requestorAddress
    requestorCity
    requestorCountryCode
    requestorPostalCode
    serviceName
    isTestMode
    user {
      id
      email
      name
      company
      country {
        ...FragmentDeliveryCountry
      }
      address
      address2
      address3
      zipCode
      city
      state
      phone
      eoriCode
      status
      createdAt
      updatedAt
      balance
      discount
    }
  }
}
    ${FragmentDeliveryCountryFragmentDoc}`;
export type UpdateSenderAccountMutationFn = Apollo.MutationFunction<UpdateSenderAccountMutation, UpdateSenderAccountMutationVariables>;

/**
 * __useUpdateSenderAccountMutation__
 *
 * To run a mutation, you first call `useUpdateSenderAccountMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSenderAccountMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSenderAccountMutation, { data, loading, error }] = useUpdateSenderAccountMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSenderAccountMutation(baseOptions?: Apollo.MutationHookOptions<UpdateSenderAccountMutation, UpdateSenderAccountMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateSenderAccountMutation, UpdateSenderAccountMutationVariables>(UpdateSenderAccountDocument, options);
      }
export type UpdateSenderAccountMutationHookResult = ReturnType<typeof useUpdateSenderAccountMutation>;
export type UpdateSenderAccountMutationResult = Apollo.MutationResult<UpdateSenderAccountMutation>;
export type UpdateSenderAccountMutationOptions = Apollo.BaseMutationOptions<UpdateSenderAccountMutation, UpdateSenderAccountMutationVariables>;
export const UpdateStoreDocument = gql`
    mutation updateStore($input: StoreInput) {
  updateStore(input: $input) {
    id
    name
    url
    userId
    storeId
    isActive
    marketplace
  }
}
    `;
export type UpdateStoreMutationFn = Apollo.MutationFunction<UpdateStoreMutation, UpdateStoreMutationVariables>;

/**
 * __useUpdateStoreMutation__
 *
 * To run a mutation, you first call `useUpdateStoreMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateStoreMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateStoreMutation, { data, loading, error }] = useUpdateStoreMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateStoreMutation(baseOptions?: Apollo.MutationHookOptions<UpdateStoreMutation, UpdateStoreMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateStoreMutation, UpdateStoreMutationVariables>(UpdateStoreDocument, options);
      }
export type UpdateStoreMutationHookResult = ReturnType<typeof useUpdateStoreMutation>;
export type UpdateStoreMutationResult = Apollo.MutationResult<UpdateStoreMutation>;
export type UpdateStoreMutationOptions = Apollo.BaseMutationOptions<UpdateStoreMutation, UpdateStoreMutationVariables>;
export const UploadMarketplaceInvoiceDocument = gql`
    mutation uploadMarketplaceInvoice($file: Upload!) {
  uploadMarketplaceInvoice(file: $file)
}
    `;
export type UploadMarketplaceInvoiceMutationFn = Apollo.MutationFunction<UploadMarketplaceInvoiceMutation, UploadMarketplaceInvoiceMutationVariables>;

/**
 * __useUploadMarketplaceInvoiceMutation__
 *
 * To run a mutation, you first call `useUploadMarketplaceInvoiceMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUploadMarketplaceInvoiceMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [uploadMarketplaceInvoiceMutation, { data, loading, error }] = useUploadMarketplaceInvoiceMutation({
 *   variables: {
 *      file: // value for 'file'
 *   },
 * });
 */
export function useUploadMarketplaceInvoiceMutation(baseOptions?: Apollo.MutationHookOptions<UploadMarketplaceInvoiceMutation, UploadMarketplaceInvoiceMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UploadMarketplaceInvoiceMutation, UploadMarketplaceInvoiceMutationVariables>(UploadMarketplaceInvoiceDocument, options);
      }
export type UploadMarketplaceInvoiceMutationHookResult = ReturnType<typeof useUploadMarketplaceInvoiceMutation>;
export type UploadMarketplaceInvoiceMutationResult = Apollo.MutationResult<UploadMarketplaceInvoiceMutation>;
export type UploadMarketplaceInvoiceMutationOptions = Apollo.BaseMutationOptions<UploadMarketplaceInvoiceMutation, UploadMarketplaceInvoiceMutationVariables>;
export const GetAnalyticDataDocument = gql`
    query getAnalyticData {
  getAnalyticData {
    volume
    fee
    quantity
  }
}
    `;

/**
 * __useGetAnalyticDataQuery__
 *
 * To run a query within a React component, call `useGetAnalyticDataQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetAnalyticDataQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetAnalyticDataQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetAnalyticDataQuery(baseOptions?: Apollo.QueryHookOptions<GetAnalyticDataQuery, GetAnalyticDataQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetAnalyticDataQuery, GetAnalyticDataQueryVariables>(GetAnalyticDataDocument, options);
      }
export function useGetAnalyticDataLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetAnalyticDataQuery, GetAnalyticDataQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetAnalyticDataQuery, GetAnalyticDataQueryVariables>(GetAnalyticDataDocument, options);
        }
export type GetAnalyticDataQueryHookResult = ReturnType<typeof useGetAnalyticDataQuery>;
export type GetAnalyticDataLazyQueryHookResult = ReturnType<typeof useGetAnalyticDataLazyQuery>;
export type GetAnalyticDataQueryResult = Apollo.QueryResult<GetAnalyticDataQuery, GetAnalyticDataQueryVariables>;
export const GetBarcodeByParcelUuidDocument = gql`
    query getBarcodeByParcelUuid($uuid: String!) {
  getBarcodeByParcelUuid(uuid: $uuid)
}
    `;

/**
 * __useGetBarcodeByParcelUuidQuery__
 *
 * To run a query within a React component, call `useGetBarcodeByParcelUuidQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetBarcodeByParcelUuidQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetBarcodeByParcelUuidQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useGetBarcodeByParcelUuidQuery(baseOptions: Apollo.QueryHookOptions<GetBarcodeByParcelUuidQuery, GetBarcodeByParcelUuidQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetBarcodeByParcelUuidQuery, GetBarcodeByParcelUuidQueryVariables>(GetBarcodeByParcelUuidDocument, options);
      }
export function useGetBarcodeByParcelUuidLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetBarcodeByParcelUuidQuery, GetBarcodeByParcelUuidQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetBarcodeByParcelUuidQuery, GetBarcodeByParcelUuidQueryVariables>(GetBarcodeByParcelUuidDocument, options);
        }
export type GetBarcodeByParcelUuidQueryHookResult = ReturnType<typeof useGetBarcodeByParcelUuidQuery>;
export type GetBarcodeByParcelUuidLazyQueryHookResult = ReturnType<typeof useGetBarcodeByParcelUuidLazyQuery>;
export type GetBarcodeByParcelUuidQueryResult = Apollo.QueryResult<GetBarcodeByParcelUuidQuery, GetBarcodeByParcelUuidQueryVariables>;
export const GetCitiesByCountryIsoDocument = gql`
    query getCitiesByCountryIso($iso: String!) {
  getCitiesByCountryIso(iso: $iso) {
    name
  }
}
    `;

/**
 * __useGetCitiesByCountryIsoQuery__
 *
 * To run a query within a React component, call `useGetCitiesByCountryIsoQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCitiesByCountryIsoQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCitiesByCountryIsoQuery({
 *   variables: {
 *      iso: // value for 'iso'
 *   },
 * });
 */
export function useGetCitiesByCountryIsoQuery(baseOptions: Apollo.QueryHookOptions<GetCitiesByCountryIsoQuery, GetCitiesByCountryIsoQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCitiesByCountryIsoQuery, GetCitiesByCountryIsoQueryVariables>(GetCitiesByCountryIsoDocument, options);
      }
export function useGetCitiesByCountryIsoLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCitiesByCountryIsoQuery, GetCitiesByCountryIsoQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCitiesByCountryIsoQuery, GetCitiesByCountryIsoQueryVariables>(GetCitiesByCountryIsoDocument, options);
        }
export type GetCitiesByCountryIsoQueryHookResult = ReturnType<typeof useGetCitiesByCountryIsoQuery>;
export type GetCitiesByCountryIsoLazyQueryHookResult = ReturnType<typeof useGetCitiesByCountryIsoLazyQuery>;
export type GetCitiesByCountryIsoQueryResult = Apollo.QueryResult<GetCitiesByCountryIsoQuery, GetCitiesByCountryIsoQueryVariables>;
export const GetConfigurationListDocument = gql`
    query getConfigurationList {
  getConfigurationList
}
    `;

/**
 * __useGetConfigurationListQuery__
 *
 * To run a query within a React component, call `useGetConfigurationListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetConfigurationListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetConfigurationListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetConfigurationListQuery(baseOptions?: Apollo.QueryHookOptions<GetConfigurationListQuery, GetConfigurationListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetConfigurationListQuery, GetConfigurationListQueryVariables>(GetConfigurationListDocument, options);
      }
export function useGetConfigurationListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetConfigurationListQuery, GetConfigurationListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetConfigurationListQuery, GetConfigurationListQueryVariables>(GetConfigurationListDocument, options);
        }
export type GetConfigurationListQueryHookResult = ReturnType<typeof useGetConfigurationListQuery>;
export type GetConfigurationListLazyQueryHookResult = ReturnType<typeof useGetConfigurationListLazyQuery>;
export type GetConfigurationListQueryResult = Apollo.QueryResult<GetConfigurationListQuery, GetConfigurationListQueryVariables>;
export const GetContactDocument = gql`
    query getContact($id: ID!) {
  contact(id: $id) {
    id
    name
    company
    country {
      ...FragmentDeliveryCountry
    }
    address
    address2
    address3
    zipCode
    city
    city
    state
    phone
    email
    eoriCode
    innKppCode
    remark
    contactName
    type
  }
}
    ${FragmentDeliveryCountryFragmentDoc}`;

/**
 * __useGetContactQuery__
 *
 * To run a query within a React component, call `useGetContactQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetContactQuery(baseOptions: Apollo.QueryHookOptions<GetContactQuery, GetContactQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetContactQuery, GetContactQueryVariables>(GetContactDocument, options);
      }
export function useGetContactLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContactQuery, GetContactQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetContactQuery, GetContactQueryVariables>(GetContactDocument, options);
        }
export type GetContactQueryHookResult = ReturnType<typeof useGetContactQuery>;
export type GetContactLazyQueryHookResult = ReturnType<typeof useGetContactLazyQuery>;
export type GetContactQueryResult = Apollo.QueryResult<GetContactQuery, GetContactQueryVariables>;
export const GetContactsDocument = gql`
    query getContacts {
  contacts {
    id
    name
    company
    country {
      ...FragmentDeliveryCountry
    }
    address
    address2
    address3
    zipCode
    city
    city
    state
    phone
    email
    remark
    eoriCode
    type
    innKppCode
    contactName
  }
}
    ${FragmentDeliveryCountryFragmentDoc}`;

/**
 * __useGetContactsQuery__
 *
 * To run a query within a React component, call `useGetContactsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetContactsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetContactsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetContactsQuery(baseOptions?: Apollo.QueryHookOptions<GetContactsQuery, GetContactsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetContactsQuery, GetContactsQueryVariables>(GetContactsDocument, options);
      }
export function useGetContactsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetContactsQuery, GetContactsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetContactsQuery, GetContactsQueryVariables>(GetContactsDocument, options);
        }
export type GetContactsQueryHookResult = ReturnType<typeof useGetContactsQuery>;
export type GetContactsLazyQueryHookResult = ReturnType<typeof useGetContactsLazyQuery>;
export type GetContactsQueryResult = Apollo.QueryResult<GetContactsQuery, GetContactsQueryVariables>;
export const GetCurrentUserDocument = gql`
    query getCurrentUser {
  currentUser {
    id
    email
    name
    company
    country {
      ...FragmentDeliveryCountry
    }
    address
    address2
    address3
    zipCode
    city
    state
    phone
    eoriCode
    status
    createdAt
    updatedAt
    balance
    passportData {
      ...FragmentUserPasport
    }
    entityLegalData {
      ...FragmentEntityLegalData
    }
    role
    fullAccess
  }
}
    ${FragmentDeliveryCountryFragmentDoc}
${FragmentUserPasportFragmentDoc}
${FragmentEntityLegalDataFragmentDoc}`;

/**
 * __useGetCurrentUserQuery__
 *
 * To run a query within a React component, call `useGetCurrentUserQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetCurrentUserQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetCurrentUserQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetCurrentUserQuery(baseOptions?: Apollo.QueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(GetCurrentUserDocument, options);
      }
export function useGetCurrentUserLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetCurrentUserQuery, GetCurrentUserQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetCurrentUserQuery, GetCurrentUserQueryVariables>(GetCurrentUserDocument, options);
        }
export type GetCurrentUserQueryHookResult = ReturnType<typeof useGetCurrentUserQuery>;
export type GetCurrentUserLazyQueryHookResult = ReturnType<typeof useGetCurrentUserLazyQuery>;
export type GetCurrentUserQueryResult = Apollo.QueryResult<GetCurrentUserQuery, GetCurrentUserQueryVariables>;
export const GetDeliveryCountriesDocument = gql`
    query getDeliveryCountries {
  deliveryCountries {
    id
    order
    name
    daysToDelivery
    zoneId
    nameEng
    iso
    customsInfo
  }
}
    `;

/**
 * __useGetDeliveryCountriesQuery__
 *
 * To run a query within a React component, call `useGetDeliveryCountriesQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetDeliveryCountriesQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetDeliveryCountriesQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetDeliveryCountriesQuery(baseOptions?: Apollo.QueryHookOptions<GetDeliveryCountriesQuery, GetDeliveryCountriesQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetDeliveryCountriesQuery, GetDeliveryCountriesQueryVariables>(GetDeliveryCountriesDocument, options);
      }
export function useGetDeliveryCountriesLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetDeliveryCountriesQuery, GetDeliveryCountriesQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetDeliveryCountriesQuery, GetDeliveryCountriesQueryVariables>(GetDeliveryCountriesDocument, options);
        }
export type GetDeliveryCountriesQueryHookResult = ReturnType<typeof useGetDeliveryCountriesQuery>;
export type GetDeliveryCountriesLazyQueryHookResult = ReturnType<typeof useGetDeliveryCountriesLazyQuery>;
export type GetDeliveryCountriesQueryResult = Apollo.QueryResult<GetDeliveryCountriesQuery, GetDeliveryCountriesQueryVariables>;
export const GetListOfDeliveryOptionsDocument = gql`
    query getListOfDeliveryOptions($input: CalculationInput) {
  getListOfDeliveryOptions(input: $input) {
    weight
    volume_weight
    weight_to_pay
    special_tax
    insurance
    data {
      name
      success
      days_to_delivery
      summary_amount
      remoteArea
      typeDelivery
      fuelSurcharge
      dollarRate
      errors
      rateId
      shipmentId
      info {
        warehouse_delivery_cost
        warehouse_proccesing_cost
        last_mile_label_cost
      }
      priceCurrency
    }
    userAccount {
      rows {
        TypeDeliveryName
        TypeDeliveryId
        accountName
        amount
        error
        day_to_delivery
        priceCurrency
        shipmentId
        rateId
      }
    }
  }
}
    `;

/**
 * __useGetListOfDeliveryOptionsQuery__
 *
 * To run a query within a React component, call `useGetListOfDeliveryOptionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetListOfDeliveryOptionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetListOfDeliveryOptionsQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useGetListOfDeliveryOptionsQuery(baseOptions?: Apollo.QueryHookOptions<GetListOfDeliveryOptionsQuery, GetListOfDeliveryOptionsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetListOfDeliveryOptionsQuery, GetListOfDeliveryOptionsQueryVariables>(GetListOfDeliveryOptionsDocument, options);
      }
export function useGetListOfDeliveryOptionsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetListOfDeliveryOptionsQuery, GetListOfDeliveryOptionsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetListOfDeliveryOptionsQuery, GetListOfDeliveryOptionsQueryVariables>(GetListOfDeliveryOptionsDocument, options);
        }
export type GetListOfDeliveryOptionsQueryHookResult = ReturnType<typeof useGetListOfDeliveryOptionsQuery>;
export type GetListOfDeliveryOptionsLazyQueryHookResult = ReturnType<typeof useGetListOfDeliveryOptionsLazyQuery>;
export type GetListOfDeliveryOptionsQueryResult = Apollo.QueryResult<GetListOfDeliveryOptionsQuery, GetListOfDeliveryOptionsQueryVariables>;
export const GetListOfReturnedShipmentsDocument = gql`
    query getListOfReturnedShipments($filter: WarehouseShipmentFilter) {
  getListOfReturnedShipments(filter: $filter) {
    count
    rows {
      id
      createdAt
      warehouse {
        id
        address
        code
      }
      trackNumber
      carrierCode
      user {
        name
        address
        city
        country {
          name
        }
      }
      deliveryStatus {
        id
        name
        explain
      }
      events {
        operation
        manager {
          name
        }
      }
      note
    }
  }
}
    `;

/**
 * __useGetListOfReturnedShipmentsQuery__
 *
 * To run a query within a React component, call `useGetListOfReturnedShipmentsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetListOfReturnedShipmentsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetListOfReturnedShipmentsQuery({
 *   variables: {
 *      filter: // value for 'filter'
 *   },
 * });
 */
export function useGetListOfReturnedShipmentsQuery(baseOptions?: Apollo.QueryHookOptions<GetListOfReturnedShipmentsQuery, GetListOfReturnedShipmentsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetListOfReturnedShipmentsQuery, GetListOfReturnedShipmentsQueryVariables>(GetListOfReturnedShipmentsDocument, options);
      }
export function useGetListOfReturnedShipmentsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetListOfReturnedShipmentsQuery, GetListOfReturnedShipmentsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetListOfReturnedShipmentsQuery, GetListOfReturnedShipmentsQueryVariables>(GetListOfReturnedShipmentsDocument, options);
        }
export type GetListOfReturnedShipmentsQueryHookResult = ReturnType<typeof useGetListOfReturnedShipmentsQuery>;
export type GetListOfReturnedShipmentsLazyQueryHookResult = ReturnType<typeof useGetListOfReturnedShipmentsLazyQuery>;
export type GetListOfReturnedShipmentsQueryResult = Apollo.QueryResult<GetListOfReturnedShipmentsQuery, GetListOfReturnedShipmentsQueryVariables>;
export const GetMyProductByIdDocument = gql`
    query getMyProductById($productId: Int!) {
  getMyProductById(productId: $productId) {
    id
    name
    sku
    detailsDescriptionRU
    detailsDescriptionEN
    state
    trademark
    declaredValue
    country {
      id
      nameEng
    }
    netWeight
    code
    userId
    fnsku
    upc
    actualDimensions {
      id
      warehouseShipmentId
      weight
      length
      width
      height
    }
    declaredDimensions {
      id
      warehouseShipmentId
      weight
      length
      width
      height
    }
    productAvailabilityQuantity {
      id
      inTransitQuantity
      reservedQuantity
      avaliableQuantity
    }
    createdAt
    barcodeId
    isCustomBarcodeId
    currency
  }
}
    `;

/**
 * __useGetMyProductByIdQuery__
 *
 * To run a query within a React component, call `useGetMyProductByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyProductByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyProductByIdQuery({
 *   variables: {
 *      productId: // value for 'productId'
 *   },
 * });
 */
export function useGetMyProductByIdQuery(baseOptions: Apollo.QueryHookOptions<GetMyProductByIdQuery, GetMyProductByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyProductByIdQuery, GetMyProductByIdQueryVariables>(GetMyProductByIdDocument, options);
      }
export function useGetMyProductByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyProductByIdQuery, GetMyProductByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyProductByIdQuery, GetMyProductByIdQueryVariables>(GetMyProductByIdDocument, options);
        }
export type GetMyProductByIdQueryHookResult = ReturnType<typeof useGetMyProductByIdQuery>;
export type GetMyProductByIdLazyQueryHookResult = ReturnType<typeof useGetMyProductByIdLazyQuery>;
export type GetMyProductByIdQueryResult = Apollo.QueryResult<GetMyProductByIdQuery, GetMyProductByIdQueryVariables>;
export const GetMyProductListDocument = gql`
    query getMyProductList($limit: Int, $offset: Int, $search: String, $warehouseId: ID) {
  getMyProductList(
    limit: $limit
    offset: $offset
    search: $search
    warehouseId: $warehouseId
  ) {
    count
    rows {
      id
      name
      sku
      detailsDescriptionRU
      detailsDescriptionEN
      state
      trademark
      declaredValue
      country {
        ...FragmentDeliveryCountry
      }
      netWeight
      code
      userId
      fnsku
      upc
      actualDimensions {
        id
        warehouseShipmentId
        weight
        length
        width
        height
      }
      declaredDimensions {
        id
        warehouseShipmentId
        weight
        length
        width
        height
      }
      barcodeId
      productAvailabilityQuantity {
        id
        inTransitQuantity
        reservedQuantity
        avaliableQuantity
      }
      createdAt
      isCustomBarcodeId
      currency
    }
  }
}
    ${FragmentDeliveryCountryFragmentDoc}`;

/**
 * __useGetMyProductListQuery__
 *
 * To run a query within a React component, call `useGetMyProductListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyProductListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyProductListQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      search: // value for 'search'
 *      warehouseId: // value for 'warehouseId'
 *   },
 * });
 */
export function useGetMyProductListQuery(baseOptions?: Apollo.QueryHookOptions<GetMyProductListQuery, GetMyProductListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetMyProductListQuery, GetMyProductListQueryVariables>(GetMyProductListDocument, options);
      }
export function useGetMyProductListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMyProductListQuery, GetMyProductListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetMyProductListQuery, GetMyProductListQueryVariables>(GetMyProductListDocument, options);
        }
export type GetMyProductListQueryHookResult = ReturnType<typeof useGetMyProductListQuery>;
export type GetMyProductListLazyQueryHookResult = ReturnType<typeof useGetMyProductListLazyQuery>;
export type GetMyProductListQueryResult = Apollo.QueryResult<GetMyProductListQuery, GetMyProductListQueryVariables>;
export const GetOrderByIdDocument = gql`
    query getOrderById($id: Int!) {
  getOrderById(id: $id) {
    id
    marketplace
    orderId
    createdAt
    isPaid
    userId
    country
    addressFirstLine
    addressSecondLine
    zip
    city
    state
    email
    deliveryType
    deliveryPrice
    isShipped
    orderProducts {
      id
      title
      description
      quantity
      priceAmount
      priceCurrency
      shippingCostAmount
      shippingCostCurrency
      sku
    }
    package {
      id
      name
      weight
      length
      width
      height
      userId
    }
    itemId
    receiverName
    storeId
    phone
    shippingMethod
  }
}
    `;

/**
 * __useGetOrderByIdQuery__
 *
 * To run a query within a React component, call `useGetOrderByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrderByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrderByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetOrderByIdQuery(baseOptions: Apollo.QueryHookOptions<GetOrderByIdQuery, GetOrderByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetOrderByIdQuery, GetOrderByIdQueryVariables>(GetOrderByIdDocument, options);
      }
export function useGetOrderByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetOrderByIdQuery, GetOrderByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetOrderByIdQuery, GetOrderByIdQueryVariables>(GetOrderByIdDocument, options);
        }
export type GetOrderByIdQueryHookResult = ReturnType<typeof useGetOrderByIdQuery>;
export type GetOrderByIdLazyQueryHookResult = ReturnType<typeof useGetOrderByIdLazyQuery>;
export type GetOrderByIdQueryResult = Apollo.QueryResult<GetOrderByIdQuery, GetOrderByIdQueryVariables>;
export const GetPackagingTemplatesListDocument = gql`
    query getPackagingTemplatesList($limit: Int, $offset: Int, $name: String) {
  getPackagingTemplatesList(limit: $limit, offset: $offset, name: $name) {
    count
    rows {
      id
      name
      weight
      length
      width
      height
      userId
    }
  }
}
    `;

/**
 * __useGetPackagingTemplatesListQuery__
 *
 * To run a query within a React component, call `useGetPackagingTemplatesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPackagingTemplatesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPackagingTemplatesListQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *      name: // value for 'name'
 *   },
 * });
 */
export function useGetPackagingTemplatesListQuery(baseOptions?: Apollo.QueryHookOptions<GetPackagingTemplatesListQuery, GetPackagingTemplatesListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPackagingTemplatesListQuery, GetPackagingTemplatesListQueryVariables>(GetPackagingTemplatesListDocument, options);
      }
export function useGetPackagingTemplatesListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPackagingTemplatesListQuery, GetPackagingTemplatesListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPackagingTemplatesListQuery, GetPackagingTemplatesListQueryVariables>(GetPackagingTemplatesListDocument, options);
        }
export type GetPackagingTemplatesListQueryHookResult = ReturnType<typeof useGetPackagingTemplatesListQuery>;
export type GetPackagingTemplatesListLazyQueryHookResult = ReturnType<typeof useGetPackagingTemplatesListLazyQuery>;
export type GetPackagingTemplatesListQueryResult = Apollo.QueryResult<GetPackagingTemplatesListQuery, GetPackagingTemplatesListQueryVariables>;
export const GetParcelDocument = gql`
    query getParcel($id: ID!) {
  parcel(id: $id) {
    id
    sender {
      id
      name
      company
      country {
        ...FragmentDeliveryCountry
      }
      address
      address2
      address3
      zipCode
      city
      state
      phone
      email
      innKppCode
      remark
      eoriCode
    }
    receiver {
      id
      name
      company
      country {
        ...FragmentDeliveryCountry
      }
      address
      address2
      address3
      zipCode
      city
      state
      phone
      email
      innKppCode
      remark
      eoriCode
    }
    packages {
      id
      quantity
      weightKg
      lengthCm
      widthCm
      heightCm
      units {
        id
        nameEN
        nameRU
        tradeMark
        code
        quantity
        price
        country {
          ...FragmentDeliveryCountry
        }
        netWeight
        state
      }
    }
    typeDelivery {
      id
      name
      serviceName
      isUserAccount
    }
    pathNameFile
    senderNotes
    senderMark
    additionalInsurance
    trackNumber
    carrierCode
    deliveryStatusId
    paymentStatusId
    actualAmount
    actualWeight
    declaredAmount
    declaredWeight
    currency
    sentToWarehouseId
    exportReason
    paymentOfTaxes
    vatKey
    vatID
    isConsolidationPiece
    insuranceAmount
    signature
    barcode
    consolidationTrackNumber {
      service
      consolidationTrackNumber
    }
    orderCostCurrency
    createdAt
    warehouse {
      id
      code
      address
      address2
      company
      phone
      country {
        name
      }
      managerName
      city
      zipCode
      state
      email
    }
  }
}
    ${FragmentDeliveryCountryFragmentDoc}`;

/**
 * __useGetParcelQuery__
 *
 * To run a query within a React component, call `useGetParcelQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetParcelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetParcelQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetParcelQuery(baseOptions: Apollo.QueryHookOptions<GetParcelQuery, GetParcelQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetParcelQuery, GetParcelQueryVariables>(GetParcelDocument, options);
      }
export function useGetParcelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetParcelQuery, GetParcelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetParcelQuery, GetParcelQueryVariables>(GetParcelDocument, options);
        }
export type GetParcelQueryHookResult = ReturnType<typeof useGetParcelQuery>;
export type GetParcelLazyQueryHookResult = ReturnType<typeof useGetParcelLazyQuery>;
export type GetParcelQueryResult = Apollo.QueryResult<GetParcelQuery, GetParcelQueryVariables>;
export const GetParcelsDocument = gql`
    query getParcels($offset: Int, $limit: Int, $search: String, $date: Int, $deliveryStatusId: Int, $paymentStatusId: Int, $convert_to_usd: Boolean) {
  parcels(
    offset: $offset
    limit: $limit
    search: $search
    date: $date
    deliveryStatusId: $deliveryStatusId
    paymentStatusId: $paymentStatusId
    convert_to_usd: $convert_to_usd
  ) {
    count
    rows {
      id
      currency
      pathNameFile
      fileTokenInvoiceXlsx
      sentToWarehouseId
      isDiscountUsed
      isConsolidationPiece
      barcode
      typeDelivery {
        id
        name
        serviceName
        isUserAccount
      }
      senderNotes
      senderMark
      additionalInsurance
      declaredWeight
      actualWeight
      declaredAmount
      actualAmount
      trackNumber
      carrierCode
      deliveryStatusId
      paymentStatusId
      sender {
        id
        name
        company
        country {
          ...FragmentDeliveryCountry
        }
        address
        address2
        address3
        zipCode
        city
        state
        phone
        email
        innKppCode
        remark
        eoriCode
      }
      receiver {
        id
        name
        company
        country {
          ...FragmentDeliveryCountry
        }
        address
        address2
        address3
        zipCode
        city
        state
        phone
        email
        innKppCode
        remark
        eoriCode
      }
      warehouseShipmentId
      packages {
        id
        quantity
        weightKg
        lengthCm
        widthCm
        heightCm
        units {
          id
          nameEN
          nameRU
          tradeMark
          code
          quantity
          price
          country {
            ...FragmentDeliveryCountry
          }
          netWeight
        }
      }
      consolidationTrackNumber {
        consolidationTrackNumber
        service
      }
      orderCostCurrency
      createdAt
    }
  }
}
    ${FragmentDeliveryCountryFragmentDoc}`;

/**
 * __useGetParcelsQuery__
 *
 * To run a query within a React component, call `useGetParcelsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetParcelsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetParcelsQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      search: // value for 'search'
 *      date: // value for 'date'
 *      deliveryStatusId: // value for 'deliveryStatusId'
 *      paymentStatusId: // value for 'paymentStatusId'
 *      convert_to_usd: // value for 'convert_to_usd'
 *   },
 * });
 */
export function useGetParcelsQuery(baseOptions?: Apollo.QueryHookOptions<GetParcelsQuery, GetParcelsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetParcelsQuery, GetParcelsQueryVariables>(GetParcelsDocument, options);
      }
export function useGetParcelsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetParcelsQuery, GetParcelsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetParcelsQuery, GetParcelsQueryVariables>(GetParcelsDocument, options);
        }
export type GetParcelsQueryHookResult = ReturnType<typeof useGetParcelsQuery>;
export type GetParcelsLazyQueryHookResult = ReturnType<typeof useGetParcelsLazyQuery>;
export type GetParcelsQueryResult = Apollo.QueryResult<GetParcelsQuery, GetParcelsQueryVariables>;
export const GetParcelsByTypeDocument = gql`
    query GetParcelsByType($offset: Int, $limit: Int, $search: String, $date: Int, $type: ParcelType, $deliveryStatusId: Int, $paymentStatusId: Int) {
  getParcelsByType(
    offset: $offset
    limit: $limit
    search: $search
    date: $date
    type: $type
    deliveryStatusId: $deliveryStatusId
    paymentStatusId: $paymentStatusId
  ) {
    count
    rows {
      user {
        id
        senderAccount
      }
      id
      currency
      sender {
        id
        name
        company
        country {
          ...FragmentDeliveryCountry
        }
        address
        address2
        address3
        zipCode
        city
        state
        phone
        email
        innKppCode
        remark
        eoriCode
      }
      receiver {
        id
        name
        company
        country {
          ...FragmentDeliveryCountry
        }
        address
        address2
        address3
        zipCode
        city
        state
        phone
        email
        innKppCode
        remark
        eoriCode
      }
      packages {
        id
        weightKg
        lengthCm
        widthCm
        heightCm
        units {
          id
          nameRU
          nameEN
          tradeMark
          code
          quantity
          price
          country {
            ...FragmentDeliveryCountry
          }
          netWeight
        }
      }
      sentToWarehouseId
      typeDelivery {
        id
        name
        serviceName
      }
      fileTokenInvoiceXlsx
      senderNotes
      senderMark
      exportReason
      paymentOfTaxes
      vatID
      additionalInsurance
      declaredWeight
      actualWeight
      declaredAmount
      actualAmount
      trackNumber
      carrierCode
      deliveryStatusId
      paymentStatusId
      pathNameFile
      isConsolidationPiece
      barcode
      orderCostCurrency
      createdAt
      warehouse {
        code
      }
    }
  }
}
    ${FragmentDeliveryCountryFragmentDoc}`;

/**
 * __useGetParcelsByTypeQuery__
 *
 * To run a query within a React component, call `useGetParcelsByTypeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetParcelsByTypeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetParcelsByTypeQuery({
 *   variables: {
 *      offset: // value for 'offset'
 *      limit: // value for 'limit'
 *      search: // value for 'search'
 *      date: // value for 'date'
 *      type: // value for 'type'
 *      deliveryStatusId: // value for 'deliveryStatusId'
 *      paymentStatusId: // value for 'paymentStatusId'
 *   },
 * });
 */
export function useGetParcelsByTypeQuery(baseOptions?: Apollo.QueryHookOptions<GetParcelsByTypeQuery, GetParcelsByTypeQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetParcelsByTypeQuery, GetParcelsByTypeQueryVariables>(GetParcelsByTypeDocument, options);
      }
export function useGetParcelsByTypeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetParcelsByTypeQuery, GetParcelsByTypeQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetParcelsByTypeQuery, GetParcelsByTypeQueryVariables>(GetParcelsByTypeDocument, options);
        }
export type GetParcelsByTypeQueryHookResult = ReturnType<typeof useGetParcelsByTypeQuery>;
export type GetParcelsByTypeLazyQueryHookResult = ReturnType<typeof useGetParcelsByTypeLazyQuery>;
export type GetParcelsByTypeQueryResult = Apollo.QueryResult<GetParcelsByTypeQuery, GetParcelsByTypeQueryVariables>;
export const GetPaymentHistoryListByUserIdDocument = gql`
    query getPaymentHistoryListByUserId($limit: Int, $offset: Int) {
  getPaymentHistoryListByUserId(limit: $limit, offset: $offset) {
    count
    rows {
      id
      userId
      transactionId
      parcelId
      amount
      currency
      confirmationUrl
      paymentType
      status
      createdAt
      updatedAt
      cancellationReason
      cdekParcelId
      reason
      balance
      paymentStatus
      warehouseShipmentOrder {
        id
        barcodeId
      }
      parcel {
        barcode
      }
    }
  }
}
    `;

/**
 * __useGetPaymentHistoryListByUserIdQuery__
 *
 * To run a query within a React component, call `useGetPaymentHistoryListByUserIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPaymentHistoryListByUserIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPaymentHistoryListByUserIdQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetPaymentHistoryListByUserIdQuery(baseOptions?: Apollo.QueryHookOptions<GetPaymentHistoryListByUserIdQuery, GetPaymentHistoryListByUserIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPaymentHistoryListByUserIdQuery, GetPaymentHistoryListByUserIdQueryVariables>(GetPaymentHistoryListByUserIdDocument, options);
      }
export function useGetPaymentHistoryListByUserIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPaymentHistoryListByUserIdQuery, GetPaymentHistoryListByUserIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPaymentHistoryListByUserIdQuery, GetPaymentHistoryListByUserIdQueryVariables>(GetPaymentHistoryListByUserIdDocument, options);
        }
export type GetPaymentHistoryListByUserIdQueryHookResult = ReturnType<typeof useGetPaymentHistoryListByUserIdQuery>;
export type GetPaymentHistoryListByUserIdLazyQueryHookResult = ReturnType<typeof useGetPaymentHistoryListByUserIdLazyQuery>;
export type GetPaymentHistoryListByUserIdQueryResult = Apollo.QueryResult<GetPaymentHistoryListByUserIdQuery, GetPaymentHistoryListByUserIdQueryVariables>;
export const GetPublicConfigurationsDocument = gql`
    query getPublicConfigurations {
  getPublicConfigurations
}
    `;

/**
 * __useGetPublicConfigurationsQuery__
 *
 * To run a query within a React component, call `useGetPublicConfigurationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPublicConfigurationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPublicConfigurationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPublicConfigurationsQuery(baseOptions?: Apollo.QueryHookOptions<GetPublicConfigurationsQuery, GetPublicConfigurationsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetPublicConfigurationsQuery, GetPublicConfigurationsQueryVariables>(GetPublicConfigurationsDocument, options);
      }
export function useGetPublicConfigurationsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPublicConfigurationsQuery, GetPublicConfigurationsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetPublicConfigurationsQuery, GetPublicConfigurationsQueryVariables>(GetPublicConfigurationsDocument, options);
        }
export type GetPublicConfigurationsQueryHookResult = ReturnType<typeof useGetPublicConfigurationsQuery>;
export type GetPublicConfigurationsLazyQueryHookResult = ReturnType<typeof useGetPublicConfigurationsLazyQuery>;
export type GetPublicConfigurationsQueryResult = Apollo.QueryResult<GetPublicConfigurationsQuery, GetPublicConfigurationsQueryVariables>;
export const GetReportsDocument = gql`
    query getReports($limit: Int, $offset: Int) {
  getReports(limit: $limit, offset: $offset) {
    rows {
      id
      userId
      type
      from
      to
      createdAt
      fileToken
      productBarcode
      warehouseCode
    }
    count
  }
}
    `;

/**
 * __useGetReportsQuery__
 *
 * To run a query within a React component, call `useGetReportsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetReportsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetReportsQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetReportsQuery(baseOptions?: Apollo.QueryHookOptions<GetReportsQuery, GetReportsQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetReportsQuery, GetReportsQueryVariables>(GetReportsDocument, options);
      }
export function useGetReportsLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetReportsQuery, GetReportsQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetReportsQuery, GetReportsQueryVariables>(GetReportsDocument, options);
        }
export type GetReportsQueryHookResult = ReturnType<typeof useGetReportsQuery>;
export type GetReportsLazyQueryHookResult = ReturnType<typeof useGetReportsLazyQuery>;
export type GetReportsQueryResult = Apollo.QueryResult<GetReportsQuery, GetReportsQueryVariables>;
export const GetSenderAccountByIdDocument = gql`
    query getSenderAccountById($id: Int!) {
  getSenderAccountById(id: $id) {
    id
    accountName
    status
    accountNumber
    password
    siteId
    TaxIdentificationNumber
    attentionName
    clientIdFEDEX
    clientSecretFEDEX
    userNameUPS
    userPasswordUPS
    accessLicenseNumberUPS
    requestorName
    requestorPhone
    requestorAddress
    requestorCity
    requestorCountryCode
    requestorPostalCode
    serviceName
    isTestMode
    user {
      id
      email
      name
      company
      country {
        ...FragmentDeliveryCountry
      }
      address
      address2
      address3
      zipCode
      city
      state
      phone
      eoriCode
      status
      createdAt
      updatedAt
      balance
      discount
    }
    easypostTestApiKey
    easypostProdApiKey
  }
}
    ${FragmentDeliveryCountryFragmentDoc}`;

/**
 * __useGetSenderAccountByIdQuery__
 *
 * To run a query within a React component, call `useGetSenderAccountByIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSenderAccountByIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSenderAccountByIdQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetSenderAccountByIdQuery(baseOptions: Apollo.QueryHookOptions<GetSenderAccountByIdQuery, GetSenderAccountByIdQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSenderAccountByIdQuery, GetSenderAccountByIdQueryVariables>(GetSenderAccountByIdDocument, options);
      }
export function useGetSenderAccountByIdLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSenderAccountByIdQuery, GetSenderAccountByIdQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSenderAccountByIdQuery, GetSenderAccountByIdQueryVariables>(GetSenderAccountByIdDocument, options);
        }
export type GetSenderAccountByIdQueryHookResult = ReturnType<typeof useGetSenderAccountByIdQuery>;
export type GetSenderAccountByIdLazyQueryHookResult = ReturnType<typeof useGetSenderAccountByIdLazyQuery>;
export type GetSenderAccountByIdQueryResult = Apollo.QueryResult<GetSenderAccountByIdQuery, GetSenderAccountByIdQueryVariables>;
export const GetSenderAccountListDocument = gql`
    query getSenderAccountList($limit: Int, $offset: Int) {
  getSenderAccountList(limit: $limit, offset: $offset) {
    count
    rows {
      id
      accountName
      status
      accountNumber
      password
      siteId
      TaxIdentificationNumber
      attentionName
      clientIdFEDEX
      clientSecretFEDEX
      userNameUPS
      userPasswordUPS
      accessLicenseNumberUPS
      requestorName
      requestorPhone
      requestorAddress
      requestorCity
      requestorCountryCode
      requestorPostalCode
      serviceName
      isTestMode
      user {
        id
        email
        name
        company
        country {
          ...FragmentDeliveryCountry
        }
        address
        address2
        address3
        zipCode
        city
        state
        phone
        eoriCode
        status
        createdAt
        updatedAt
        balance
        discount
      }
    }
  }
}
    ${FragmentDeliveryCountryFragmentDoc}`;

/**
 * __useGetSenderAccountListQuery__
 *
 * To run a query within a React component, call `useGetSenderAccountListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSenderAccountListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSenderAccountListQuery({
 *   variables: {
 *      limit: // value for 'limit'
 *      offset: // value for 'offset'
 *   },
 * });
 */
export function useGetSenderAccountListQuery(baseOptions?: Apollo.QueryHookOptions<GetSenderAccountListQuery, GetSenderAccountListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetSenderAccountListQuery, GetSenderAccountListQueryVariables>(GetSenderAccountListDocument, options);
      }
export function useGetSenderAccountListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetSenderAccountListQuery, GetSenderAccountListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetSenderAccountListQuery, GetSenderAccountListQueryVariables>(GetSenderAccountListDocument, options);
        }
export type GetSenderAccountListQueryHookResult = ReturnType<typeof useGetSenderAccountListQuery>;
export type GetSenderAccountListLazyQueryHookResult = ReturnType<typeof useGetSenderAccountListLazyQuery>;
export type GetSenderAccountListQueryResult = Apollo.QueryResult<GetSenderAccountListQuery, GetSenderAccountListQueryVariables>;
export const GetShipmentBarcodeListDocument = gql`
    query getShipmentBarcodeList($id: ID!) {
  getShipmentBarcodeList(id: $id)
}
    `;

/**
 * __useGetShipmentBarcodeListQuery__
 *
 * To run a query within a React component, call `useGetShipmentBarcodeListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetShipmentBarcodeListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetShipmentBarcodeListQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useGetShipmentBarcodeListQuery(baseOptions: Apollo.QueryHookOptions<GetShipmentBarcodeListQuery, GetShipmentBarcodeListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetShipmentBarcodeListQuery, GetShipmentBarcodeListQueryVariables>(GetShipmentBarcodeListDocument, options);
      }
export function useGetShipmentBarcodeListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetShipmentBarcodeListQuery, GetShipmentBarcodeListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetShipmentBarcodeListQuery, GetShipmentBarcodeListQueryVariables>(GetShipmentBarcodeListDocument, options);
        }
export type GetShipmentBarcodeListQueryHookResult = ReturnType<typeof useGetShipmentBarcodeListQuery>;
export type GetShipmentBarcodeListLazyQueryHookResult = ReturnType<typeof useGetShipmentBarcodeListLazyQuery>;
export type GetShipmentBarcodeListQueryResult = Apollo.QueryResult<GetShipmentBarcodeListQuery, GetShipmentBarcodeListQueryVariables>;
export const GetStatusesListDocument = gql`
    query getStatusesList {
  getStatusesList {
    DeliveryStatusList {
      id
      name
      explain
    }
    PaymentStatusList {
      id
      name
      explain
    }
  }
}
    `;

/**
 * __useGetStatusesListQuery__
 *
 * To run a query within a React component, call `useGetStatusesListQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetStatusesListQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetStatusesListQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetStatusesListQuery(baseOptions?: Apollo.QueryHookOptions<GetStatusesListQuery, GetStatusesListQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetStatusesListQuery, GetStatusesListQueryVariables>(GetStatusesListDocument, options);
      }
export function useGetStatusesListLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetStatusesListQuery, GetStatusesListQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetStatusesListQuery, GetStatusesListQueryVariables>(GetStatusesListDocument, options);
        }
export type GetStatusesListQueryHookResult = ReturnType<typeof useGetStatusesListQuery>;
export type GetStatusesListLazyQueryHookResult = ReturnType<typeof useGetStatusesListLazyQuery>;
export type GetStatusesListQueryResult = Apollo.QueryResult<GetStatusesListQuery, GetStatusesListQueryVariables>;
export const GetTariffsByParcelDocument = gql`
    query getTariffsByParcel($getTariffsByParcelCalculationInput: CDEKCalculationByTariffCodeInput) {
  getTariffsByParcel(calculationInput: $getTariffsByParcelCalculationInput) {
    delivery_sum
    period_min
    period_max
    weight_calc
    total_sum
    currency
    errors {
      code
      message
    }
    tariff {
      code
      name
      mode
      weight_restrictions
      service
      description
    }
  }
}
    `;

/**
 * __useGetTariffsByParcelQuery__
 *
 * To run a query within a React component, call `useGetTariffsByParcelQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTariffsByParcelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTariffsByParcelQuery({
 *   variables: {
 *      getTariffsByParcelCalculationInput: // value for 'getTariffsByParcelCalculationInput'
 *   },
 * });
 */
export function useGetTariffsByParcelQuery(baseOptions?: Apollo.QueryHookOptions<GetTariffsByParcelQuery, GetTariffsByParcelQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTariffsByParcelQuery, GetTariffsByParcelQueryVariables>(GetTariffsByParcelDocument, options);
      }
export function useGetTariffsByParcelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTariffsByParcelQuery, GetTariffsByParcelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTariffsByParcelQuery, GetTariffsByParcelQueryVariables>(GetTariffsByParcelDocument, options);
        }
export type GetTariffsByParcelQueryHookResult = ReturnType<typeof useGetTariffsByParcelQuery>;
export type GetTariffsByParcelLazyQueryHookResult = ReturnType<typeof useGetTariffsByParcelLazyQuery>;
export type GetTariffsByParcelQueryResult = Apollo.QueryResult<GetTariffsByParcelQuery, GetTariffsByParcelQueryVariables>;
export const GetTheExchangeRateDocument = gql`
    query getTheExchangeRate {
  getTheExchangeRate {
    exchangeRate {
      code
      nominal
      value
    }
    lastUpdate
  }
}
    `;

/**
 * __useGetTheExchangeRateQuery__
 *
 * To run a query within a React component, call `useGetTheExchangeRateQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetTheExchangeRateQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetTheExchangeRateQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetTheExchangeRateQuery(baseOptions?: Apollo.QueryHookOptions<GetTheExchangeRateQuery, GetTheExchangeRateQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<GetTheExchangeRateQuery, GetTheExchangeRateQueryVariables>(GetTheExchangeRateDocument, options);
      }
export function useGetTheExchangeRateLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetTheExchangeRateQuery, GetTheExchangeRateQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<GetTheExchangeRateQuery, GetTheExchangeRateQueryVariables>(GetTheExchangeRateDocument, options);
        }
export type GetTheExchangeRateQueryHookResult = ReturnType<typeof useGetTheExchangeRateQuery>;
export type GetTheExchangeRateLazyQueryHookResult = ReturnType<typeof useGetTheExchangeRateLazyQuery>;
export type GetTheExchangeRateQueryResult = Apollo.QueryResult<GetTheExchangeRateQuery, GetTheExchangeRateQueryVariables>;
export const RecoveryPasswordDocument = gql`
    query recoveryPassword($email: String!) {
  recoveryPassword(email: $email) {
    status
  }
}
    `;

/**
 * __useRecoveryPasswordQuery__
 *
 * To run a query within a React component, call `useRecoveryPasswordQuery` and pass it any options that fit your needs.
 * When your component renders, `useRecoveryPasswordQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRecoveryPasswordQuery({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useRecoveryPasswordQuery(baseOptions: Apollo.QueryHookOptions<RecoveryPasswordQuery, RecoveryPasswordQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RecoveryPasswordQuery, RecoveryPasswordQueryVariables>(RecoveryPasswordDocument, options);
      }
export function useRecoveryPasswordLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RecoveryPasswordQuery, RecoveryPasswordQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RecoveryPasswordQuery, RecoveryPasswordQueryVariables>(RecoveryPasswordDocument, options);
        }
export type RecoveryPasswordQueryHookResult = ReturnType<typeof useRecoveryPasswordQuery>;
export type RecoveryPasswordLazyQueryHookResult = ReturnType<typeof useRecoveryPasswordLazyQuery>;
export type RecoveryPasswordQueryResult = Apollo.QueryResult<RecoveryPasswordQuery, RecoveryPasswordQueryVariables>;
export const RepeatConfirmLetterDocument = gql`
    query repeatConfirmLetter {
  repeatConfirmLetter {
    status
  }
}
    `;

/**
 * __useRepeatConfirmLetterQuery__
 *
 * To run a query within a React component, call `useRepeatConfirmLetterQuery` and pass it any options that fit your needs.
 * When your component renders, `useRepeatConfirmLetterQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useRepeatConfirmLetterQuery({
 *   variables: {
 *   },
 * });
 */
export function useRepeatConfirmLetterQuery(baseOptions?: Apollo.QueryHookOptions<RepeatConfirmLetterQuery, RepeatConfirmLetterQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<RepeatConfirmLetterQuery, RepeatConfirmLetterQueryVariables>(RepeatConfirmLetterDocument, options);
      }
export function useRepeatConfirmLetterLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<RepeatConfirmLetterQuery, RepeatConfirmLetterQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<RepeatConfirmLetterQuery, RepeatConfirmLetterQueryVariables>(RepeatConfirmLetterDocument, options);
        }
export type RepeatConfirmLetterQueryHookResult = ReturnType<typeof useRepeatConfirmLetterQuery>;
export type RepeatConfirmLetterLazyQueryHookResult = ReturnType<typeof useRepeatConfirmLetterLazyQuery>;
export type RepeatConfirmLetterQueryResult = Apollo.QueryResult<RepeatConfirmLetterQuery, RepeatConfirmLetterQueryVariables>;
export const TrackParcelDocument = gql`
    query trackParcel($carrier_code: String, $barcode: String) {
  trackParcel(carrier_code: $carrier_code, barcode: $barcode) {
    carrier_code
    track_number
    message
    error
    statusDescription
    city
    countryISO
    history {
      eventName
      timestamp
    }
    currentStep
    senderCity
    senderCountryISO
    senderStateOrProvinceCode
    receiverCity
    receiverCountryISO
    receiverStateOrProvinceCode
  }
}
    `;

/**
 * __useTrackParcelQuery__
 *
 * To run a query within a React component, call `useTrackParcelQuery` and pass it any options that fit your needs.
 * When your component renders, `useTrackParcelQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useTrackParcelQuery({
 *   variables: {
 *      carrier_code: // value for 'carrier_code'
 *      barcode: // value for 'barcode'
 *   },
 * });
 */
export function useTrackParcelQuery(baseOptions?: Apollo.QueryHookOptions<TrackParcelQuery, TrackParcelQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<TrackParcelQuery, TrackParcelQueryVariables>(TrackParcelDocument, options);
      }
export function useTrackParcelLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<TrackParcelQuery, TrackParcelQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<TrackParcelQuery, TrackParcelQueryVariables>(TrackParcelDocument, options);
        }
export type TrackParcelQueryHookResult = ReturnType<typeof useTrackParcelQuery>;
export type TrackParcelLazyQueryHookResult = ReturnType<typeof useTrackParcelLazyQuery>;
export type TrackParcelQueryResult = Apollo.QueryResult<TrackParcelQuery, TrackParcelQueryVariables>;
export const ValidationAddressDocument = gql`
    query validationAddress($input: ValidationAddress) {
  validationAddress(input: $input) {
    address {
      inputValue
      returnValue
    }
    city {
      inputValue
      returnValue
    }
    state {
      inputValue
      returnValue
    }
    postalCode {
      inputValue
      returnValue
    }
  }
}
    `;

/**
 * __useValidationAddressQuery__
 *
 * To run a query within a React component, call `useValidationAddressQuery` and pass it any options that fit your needs.
 * When your component renders, `useValidationAddressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useValidationAddressQuery({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useValidationAddressQuery(baseOptions?: Apollo.QueryHookOptions<ValidationAddressQuery, ValidationAddressQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<ValidationAddressQuery, ValidationAddressQueryVariables>(ValidationAddressDocument, options);
      }
export function useValidationAddressLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<ValidationAddressQuery, ValidationAddressQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<ValidationAddressQuery, ValidationAddressQueryVariables>(ValidationAddressDocument, options);
        }
export type ValidationAddressQueryHookResult = ReturnType<typeof useValidationAddressQuery>;
export type ValidationAddressLazyQueryHookResult = ReturnType<typeof useValidationAddressLazyQuery>;
export type ValidationAddressQueryResult = Apollo.QueryResult<ValidationAddressQuery, ValidationAddressQueryVariables>;
export const WeigtToPayValidatorDocument = gql`
    query weigtToPayValidator($parcelIds: [Int!], $packages: [CargoPlaceInput!]) {
  weigtToPayValidator(parcelIds: $parcelIds, packages: $packages)
}
    `;

/**
 * __useWeigtToPayValidatorQuery__
 *
 * To run a query within a React component, call `useWeigtToPayValidatorQuery` and pass it any options that fit your needs.
 * When your component renders, `useWeigtToPayValidatorQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useWeigtToPayValidatorQuery({
 *   variables: {
 *      parcelIds: // value for 'parcelIds'
 *      packages: // value for 'packages'
 *   },
 * });
 */
export function useWeigtToPayValidatorQuery(baseOptions?: Apollo.QueryHookOptions<WeigtToPayValidatorQuery, WeigtToPayValidatorQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<WeigtToPayValidatorQuery, WeigtToPayValidatorQueryVariables>(WeigtToPayValidatorDocument, options);
      }
export function useWeigtToPayValidatorLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<WeigtToPayValidatorQuery, WeigtToPayValidatorQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<WeigtToPayValidatorQuery, WeigtToPayValidatorQueryVariables>(WeigtToPayValidatorDocument, options);
        }
export type WeigtToPayValidatorQueryHookResult = ReturnType<typeof useWeigtToPayValidatorQuery>;
export type WeigtToPayValidatorLazyQueryHookResult = ReturnType<typeof useWeigtToPayValidatorLazyQuery>;
export type WeigtToPayValidatorQueryResult = Apollo.QueryResult<WeigtToPayValidatorQuery, WeigtToPayValidatorQueryVariables>;