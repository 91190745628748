import { TRoutes } from '../../../../../utils/helpers';
import { useLocation, useParams, useRouteMatch } from 'react-router-dom';

export const useRouterWrapper = () => {
  const { id } = useParams<{ id?: string }>();

  let isCreation = useRouteMatch(TRoutes.SHIPMENT_CREATE);

  let isCopying = useRouteMatch(TRoutes.SHIPMENT_EDIT_COPY);

  const location = useLocation();

  const urlSearchParams = new URLSearchParams(location.search);

  return { id, isCreation, isCopying, urlSearchParams };
};
