import React, { useEffect } from 'react';
import { BoxCentered } from '../BoxCentered/BoxCentered';
import { TRoutes } from '../../utils/helpers';
import { ShowLoadingText } from '../../utils/helperComponents';
import { Link as RouterLink, Redirect } from 'react-router-dom';
import { useSnackbar } from 'notistack';
import { COLORS } from '../../utils/constants';
import { Box } from '@material-ui/core';
import { CardWarning } from '../CardWarning/CardWarning';
import { User, GetParcelQuery } from '../../generated/graphql';
import { match } from 'react-router';
import { ApolloError } from '@apollo/client/errors';
import ShipmentCreatedSuccessfully from '../ShipmentCreatedSuccessfully/ShipmentCreatedSuccessfully';
import { useTranslation } from 'react-i18next';

type ShipmentsErrorHandlerType = {
  id?: string;
  isValidId?: boolean | string;
  dataLazyParcel?: GetParcelQuery;
  children: React.ReactNode;
  isCreation?: match<{}> | null;
  errorContacts?: ApolloError;
  errorCurrentUser?: ApolloError;
  errorLazyParcel?: ApolloError;
  errorCreateParcelMutation?: ApolloError;
  errorUpdateParcelDraftMutation?: ApolloError;
  currentUser?: User;
  savedParcelResponseId?: any;
  errorWarehouses?: ApolloError;
  orderByIdError?: ApolloError;
};

export const ShipmentsErrorHandler = ({
  id,
  isValidId,
  dataLazyParcel,
  children,
  isCreation,
  errorContacts,
  errorCurrentUser,
  errorLazyParcel,
  errorCreateParcelMutation,
  errorUpdateParcelDraftMutation,
  currentUser,
  savedParcelResponseId,
  errorWarehouses,
  orderByIdError,
}: ShipmentsErrorHandlerType) => {
  const { enqueueSnackbar } = useSnackbar();

  const { t } = useTranslation();

  useEffect(() => {
    if (errorCreateParcelMutation) {
      enqueueSnackbar(errorCreateParcelMutation.message, { variant: 'error' });
    }
    // eslint-disable-next-line
  }, [errorCreateParcelMutation]);

  useEffect(() => {
    if (errorUpdateParcelDraftMutation) {
      enqueueSnackbar(errorUpdateParcelDraftMutation.message, {
        variant: 'error',
      });
    }
    // eslint-disable-next-line
  }, [errorUpdateParcelDraftMutation]);

  useEffect(() => {
    if (errorContacts) {
      enqueueSnackbar(errorContacts.message, { variant: 'error' });
    }
    // eslint-disable-next-line
  }, [errorContacts]);

  useEffect(() => {
    if (errorCurrentUser) {
      enqueueSnackbar(errorCurrentUser.message, { variant: 'error' });
    }
    // eslint-disable-next-line
  }, [errorCurrentUser]);

  useEffect(() => {
    if (errorLazyParcel) {
      enqueueSnackbar(errorLazyParcel.message, { variant: 'error' });
    }
    // eslint-disable-next-line
  }, [errorLazyParcel]);
  useEffect(() => {
    if (errorWarehouses) {
      enqueueSnackbar(errorWarehouses.message, { variant: 'error' });
    }
    // eslint-disable-next-line
  }, [errorWarehouses]);
  useEffect(() => {
    if (orderByIdError) {
      enqueueSnackbar(orderByIdError.message, { variant: 'error' });
    }
    // eslint-disable-next-line
  }, [orderByIdError]);

  if (id && isValidId && !dataLazyParcel)
    return (
      <BoxCentered>
        <ShowLoadingText name={t('app.existingShipment')} />
      </BoxCentered>
    );
  if (!isCreation && !isValidId) {
    return <Redirect to={TRoutes.NOT_FOUND} />;
  }

  if (
    !currentUser?.name ||
    !currentUser?.company ||
    !currentUser?.country ||
    !currentUser?.address ||
    !currentUser?.zipCode ||
    !currentUser?.city ||
    !currentUser?.state ||
    !currentUser?.phone
  ) {
    return (
      <BoxCentered color={COLORS.RED}>
        <CardWarning title={t('app.insufficientData')}>
          <Box>{t('app.profileNotice')}</Box>
          <br />
          <RouterLink to={TRoutes.PERSONAl_VERIFY}>
            {t('app.profileLink')}
          </RouterLink>
          <br />
          <br />
          <Box>
            {t('app.addressNotice1')}{' '}
            <RouterLink to={TRoutes.CONTACTS}>
              {t('app.addressNotice2')}
            </RouterLink>
            {t('app.addressNotice3')}
          </Box>
        </CardWarning>
      </BoxCentered>
    );
  }
  if (savedParcelResponseId) {
    return (
      <ShipmentCreatedSuccessfully
        id={savedParcelResponseId}
        user={currentUser}
      />
    );
  }

  return <>{children}</>;
};
