import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export const styles = (theme: Theme) =>
  createStyles({
    mainTitle: {
      textAlign: 'center',
      marginBottom: 32,

      [theme.breakpoints.down('sm')]: {
        fontSize: 23,
        marginBottom: 15,
      },
    },
    inputLabel: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    input: { maxWidth: '280px', marginRight: '30px' },
    root: {
      marginTop: 30,
      paddingTop: 30,
      borderTop: '1px solid #e0e0e0',

      '&:first-child': {
        marginTop: 0,
        paddingTop: 0,
        border: 'none',
      },
    },
  });

export default makeStyles(styles);
