import React from 'react';
import {
  Box,
  Checkbox,
  Divider,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  IconButton,
  Input,
  InputAdornment,
  InputLabel,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import { Field, FieldProps, useFormikContext } from 'formik';
import useStyles from './styles';
import { Parcel } from '../../../generated/graphql';
import InfoIcon from '@material-ui/icons/Info';
import { TFormikSetFieldValueDebounce } from '../../../interfaces';
import { useTranslation } from 'react-i18next';
import { ShipmentStep4ValuesName } from '../../../utils/constants';

const ShipmentsFormStep4: React.FC<{
  setFieldValueDebounce: TFormikSetFieldValueDebounce;
}> = ({ setFieldValueDebounce }) => {
  const classes = useStyles();
  const theme = useTheme();
  const hidden = useMediaQuery(theme.breakpoints.down('md'));
  const { t } = useTranslation();
  const { values } = useFormikContext<Parcel>();

  const handleChangeInput =
    (key: string) =>
    (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
      setFieldValueDebounce(key, e.target.value);
    };

  const handleChangeChekcbox =
    (key: string) => (e: React.ChangeEvent<HTMLInputElement>) => {
      setFieldValueDebounce(key, e.target.checked);
    };

  return (
    <>
      <div className={classes.wrapper}>
        <Field name={ShipmentStep4ValuesName.SENDER_MARK}>
          {({ field: { value, ...field }, meta }: FieldProps) => (
            <FormControl
              error={meta.touched && !!meta.error}
              style={{ maxWidth: 261 }}
            >
              <Grid container justify='space-between'>
                <InputLabel shrink={false} htmlFor='sender-mark'>
                  Номер заказа
                </InputLabel>
                {hidden ? null : (
                  <Tooltip
                    style={{ padding: 0 }}
                    title={t('app.orderNumberExplanation')}
                  >
                    <IconButton>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                )}
              </Grid>
              <Input
                disableUnderline
                id='sender-mark'
                {...field}
                defaultValue={value}
                onChange={handleChangeInput(
                  ShipmentStep4ValuesName.SENDER_MARK,
                )}
              />
              {meta.touched && meta.error && (
                <FormHelperText>{meta.error}</FormHelperText>
              )}
            </FormControl>
          )}
        </Field>
        <Box mt={3.25} mb={3.25}>
          <Divider />
        </Box>
        <Field name={ShipmentStep4ValuesName.ADDITIONAL_INSURANCE}>
          {() => (
            <FormControlLabel
              className={classes.labelRow}
              control={
                <Checkbox
                  className={classes.radio}
                  onChange={handleChangeChekcbox(
                    ShipmentStep4ValuesName.ADDITIONAL_INSURANCE,
                  )}
                  checked={!!values?.additionalInsurance}
                  color='primary'
                />
              }
              label={
                <>
                  <span className={classes.labelRowText}>
                    {t('app.insurance')}
                  </span>
                  <Tooltip
                    className={classes.tooltipIconButton}
                    placement='top'
                    title={t('app.paidService')}
                  >
                    <IconButton>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </>
              }
            />
          )}
        </Field>
        {!!values.additionalInsurance && (
          <Box maxWidth={280}>
            <Field name={ShipmentStep4ValuesName.INSURANCE_AMOUNT}>
              {({ field: { value }, meta }: FieldProps) => {
                return (
                  <FormControl error={!!(meta.touched && meta.error)}>
                    <InputLabel
                      shrink={false}
                      htmlFor='additional-insurance'
                      style={{ marginTop: '24px' }}
                    >
                      {t('app.specifyCost')}
                    </InputLabel>
                    <Input
                      disableUnderline
                      id='additional-insurance'
                      defaultValue={value}
                      startAdornment={
                        <InputAdornment
                          style={{ paddingLeft: '10px' }}
                          position='start'
                        >
                          $
                        </InputAdornment>
                      }
                      inputProps={{
                        type: 'number',
                        min: 0,
                        step: 10,
                      }}
                      onChange={handleChangeInput(
                        ShipmentStep4ValuesName.INSURANCE_AMOUNT,
                      )}
                    />
                    {meta.touched && meta.error && (
                      <FormHelperText>{meta.error}</FormHelperText>
                    )}
                  </FormControl>
                );
              }}
            </Field>
          </Box>
        )}
        <Field name={ShipmentStep4ValuesName.SIGNATURE}>
          {() => (
            <FormControlLabel
              className={classes.labelRow}
              style={{ marginTop: '20px' }}
              control={
                <Checkbox
                  className={classes.radio}
                  color='primary'
                  onChange={handleChangeChekcbox(
                    ShipmentStep4ValuesName.SIGNATURE,
                  )}
                />
              }
              label={
                <>
                  <span className={classes.labelRowText}>
                    {t('app.recipientSignature')}
                  </span>
                  <Tooltip
                    className={classes.tooltipIconButton}
                    placement='top'
                    title={t('app.paidService')}
                  >
                    <IconButton>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </>
              }
            />
          )}
        </Field>
      </div>
    </>
  );
};

export default ShipmentsFormStep4;
