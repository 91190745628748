import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';

export const styles = (theme: Theme) =>
  createStyles({
    mainTitle: {
      marginBottom: 32,

      [theme.breakpoints.down('sm')]: {
        fontSize: 23,
        marginBottom: 15,
      },
    },
    selectContactsWrap: {
      display: 'flex',
    },
    selectSenderContactsControl: {
      alignItems: 'start-end',
    },
    selectReceiverContactsControl: {
      alignItems: 'flex-end',
    },
    title: {
      marginTop: 24,
      marginBottom: 24,
      paddingLeft: 32,

      [theme.breakpoints.down('sm')]: {
        paddingLeft: 10,
        fontSize: 16,
      },
    },
    accordion: {
      marginBottom: 40,
    },
    accordionSummary: {
      margin: 0,
      padding: 0,

      '& .MuiAccordionSummary-content': {
        margin: 0,
      },
    },
    accordionDetails: {
      padding: 0,

      '& > div': {
        width: '100%',
      },
    },
    wrapper: {
      borderTop: '1px solid #e0e0e0',
      paddingLeft: 40,
      paddingRight: 40,
      paddingTop: 24,
      width: '100%',

      [theme.breakpoints.down('sm')]: {
        paddingLeft: 20,
        paddingRight: 20,
      },
    },
    selectContacts: {
      maxWidth: 204,
      marginBottom: 24,
      width: '100%',

      '& .MuiSelect-select': {
        height: '41px',
        paddingRight: 45,
        boxSizing: 'border-box',
      },

      '& .MuiSelect-icon': {
        right: 14,
      },
    },
    contactFields: {
      maxWidth: 344,

      '& .MuiFormControl-root': {
        marginBottom: 24,
      },

      '& .MuiFormControl-root:last-child': {
        marginBottom: 0,
      },

      [theme.breakpoints.down('sm')]: {
        padding: 0,
        marginBottom: 50,
        maxWidth: 'unset',

        '& .MuiFormControl-root': {
          maxWidth: 'unset',
          width: '100%',
        },

        '&:last-child': {
          marginBottom: 0,
        },
      },
    },
    contactFieldsTitle: {
      marginBottom: 17,
    },
    departureDatePicker: {
      maxWidth: 261,

      '& .MuiInput-root': {
        '&:after': {
          content: 'none',
        },
        '&:before': {
          content: 'none',
        },
      },
    },
    textarea: {
      maxWidth: 528,
      minHeight: 160,
      width: '100%',
      fontFamily: 'inherit',
      fontSize: 16,
      padding: 10,
    },
    infoDivider: {
      paddingLeft: 40,
      paddingRight: 40,

      [theme.breakpoints.down('xs')]: {
        paddingLeft: 20,
        paddingRight: 20,
      },
    },
    infoText: {
      fontSize: '16px',
      paddingLeft: 40,
      paddingRight: 40,
      lineHeight: '24px',

      '& .MuiLink-root': {
        color: 'inherit',
      },

      [theme.breakpoints.down('xs')]: {
        paddingLeft: 20,
        paddingRight: 20,
      },
    },
    confirmText: {
      fontSize: '16px',
      paddingLeft: 40,
      paddingRight: 40,
      lineHeight: '24px',
      maxWidth: 534,

      '& .MuiLink-root': {
        color: 'inherit',
      },

      [theme.breakpoints.down('xs')]: {
        paddingLeft: 20,
        paddingRight: 20,
      },
    },
    actions: {
      paddingLeft: 31,
      marginTop: 24,
      marginBottom: 63,

      [theme.breakpoints.down('xs')]: {
        paddingLeft: 20,
        paddingRight: 20,
      },
    },
    arrangeAction: {
      marginRight: 16,
    },
    action: {
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        marginBottom: 10,
      },
    },
    errorsMessage: {
      color: '#f44336',
    },
    labelRow: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: 0,
      marginRight: 0,
    },
    tooltipIconButton: {
      padding: 0,
    },
    labelRowText: {
      fontWeight: 'bold',
      marginLeft: 10,
      marginRight: 10,
    },
    radio: {
      padding: 0,
    },
  });

export default makeStyles(styles);
