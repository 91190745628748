import React from 'react';
import { Box, Divider } from '@material-ui/core';
import { FormikErrors } from 'formik';

import CalculationResults from '../../../components/CalculationResults/CalculationResults';

import useStyles from './styles';
import { Parcel } from '../../../generated/graphql';
import {
  TFormikSetFieldValueDebounce,
  TSetStateBoolean,
  TSetStateNumber,
  TSetStateString,
} from '../../../interfaces';
import { When } from 'react-if';
import { useTranslation } from 'react-i18next';

const ShipmentsFormStep5: React.FC<{
  values: Parcel;
  setFieldValueDebounce: TFormikSetFieldValueDebounce;
  errors: FormikErrors<Parcel>;
  setDeclaredWeight: TSetStateNumber;
  setDeclaredAmount: TSetStateNumber;
  setIsFetchCalculated: TSetStateBoolean;
  isFetchCalculated: boolean;
  setLoading: TSetStateBoolean;
  isLoading: boolean;
  setGlobalProductCode: TSetStateString;
}> = ({
  values,
  setFieldValueDebounce,
  errors,
  setDeclaredAmount,
  setDeclaredWeight,
  setIsFetchCalculated,
  isFetchCalculated,
  isLoading,
  setLoading,
  setGlobalProductCode,
}) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      <div className={classes.wrapper}>
        <CalculationResults
          errors={errors}
          values={values}
          setLoading={setLoading}
          isLoading={isLoading}
          setFieldValue={setFieldValueDebounce}
          setDeclaredWeight={setDeclaredWeight}
          setDeclaredAmount={setDeclaredAmount}
          setIsFetchCalculated={setIsFetchCalculated}
          isFetchCalculated={isFetchCalculated}
          isCalculatorPage={false}
          setGlobalProductCode={setGlobalProductCode}
        />
        <Box mt={6.875} mb={3.875}>
          <Divider />
        </Box>
      </div>
      <Box mt={2} mb={2} className={classes.infoDivider}>
        <Divider />
      </Box>
      <When
        condition={!Boolean(Object.values(errors).length) && !isFetchCalculated}
      >
        <p className={classes.errorsMessage}>{t('app.calculateCost3')}</p>
      </When>
    </>
  );
};

export default ShipmentsFormStep5;
