import {
  GetOrderByIdQuery,
  ParcelInput,
  Vatkey,
} from '../../../../../generated/graphql';
import { match } from 'react-router';
import { PARCEL_FIELDS } from '../../../../../utils/constants';
import moment from 'moment';
import { pickPackages } from './pickPackages';
import { SnackbarMessage, OptionsObject, SnackbarKey } from 'notistack';
import { t } from 'i18next';

export type ParcelInputUpdate = {
  typeDeliveryId: {
    typeDeliveryId: number;
    indexValue: number;
  };
};

export type mutateCreateType = {
  data: ParcelInput & ParcelInputUpdate;
  urlSearchParams: URLSearchParams;
  formValue: any;
  globalProductCode: string;
  isCreation: match<{}> | null;
  isCopying: match<{}> | null;
  createParcelMutation: any;
  setSavedParcelResponseId: any;
  id?: string;
  setSubmitting: any;
  enqueueSnackbar: (
    message: SnackbarMessage,
    options?: OptionsObject | undefined,
  ) => SnackbarKey;
  setLoading: (value: boolean) => void;
  addEtsyTrackNumberMutation: any;
  orderByIdData?: GetOrderByIdQuery;
  isVatInformation: boolean;
};

export const mutateCreate = ({
  data,
  urlSearchParams,
  formValue,
  globalProductCode,
  isCreation,
  isCopying,
  createParcelMutation,
  setSavedParcelResponseId,
  id,
  setSubmitting,
  enqueueSnackbar,
  setLoading,
  addEtsyTrackNumberMutation,
  orderByIdData,
  isVatInformation,
}: mutateCreateType) => {
  const getIdUrl = () => {
    let urlId = Number(urlSearchParams.get(PARCEL_FIELDS.OTHER.ID));
    if (urlId === 0) {
      return null;
    }
    return urlId;
  };
  return new Promise((resolve, reject) => {
    const typeDeliveryId = data?.typeDeliveryId?.typeDeliveryId
      ? Math.floor(data?.typeDeliveryId?.typeDeliveryId)
      : undefined;

    let parcelInput: ParcelInput = {
      //@ts-ignore
      labelDataEasyPost: data?.typeDeliveryId?.labelDataEasyPost,

      fuelSurcharge: formValue.fuelSurcharge,
      dollarRate: formValue.dollarRate,
      additionalInsurance: data.additionalInsurance,
      insuranceAmount: Number(data.insuranceAmount),
      signature: data.signature,
      senderNotes: data.senderNotes,
      senderMark: data.senderMark,
      typeDeliveryId: typeDeliveryId,
      declaredWeight: data.declaredWeight,
      declaredAmount: data.declaredAmount,
      carrierCode: data.carrierCode,
      //@ts-ignore
      currency: data.currency.toUpperCase(),
      sender: {
        name: data.sender.name,
        company: data.sender.company,
        //@ts-ignore
        countryId: data.sender.country.id,
        address: data.sender.address,
        address2: data.sender.address2,
        address3: data.sender.address3,
        zipCode: data.sender.zipCode,
        city: data.sender.city,
        state: data.sender.state,
        phone: data.sender.phone,
        email: data.sender.email,
        innKppCode: data.sender.innKppCode,
        remark: data.sender.remark,
        eoriCode: data.sender.eoriCode,
      },
      receiver: {
        name: data.receiver.name,
        company: data.receiver.company,
        //@ts-ignore
        countryId: data.receiver?.country?.id,
        address: data.receiver.address,
        address2: data.receiver.address2,
        address3: data.receiver.address3,
        zipCode: data.receiver.zipCode,
        city: data.receiver.city,
        state: data.receiver.state,
        phone: data.receiver.phone,
        email: data.receiver.email,
        innKppCode: data.receiver.innKppCode,
        remark: data.receiver.remark,
        eoriCode: data.receiver.eoriCode,
      },
      intake_date: data.intake_date
        ? moment(data.intake_date).format('yyyy-MM-DD')
        : '',
      intake_time_from: data.intake_time_from
        ? moment(data.intake_time_from).format('HH:mm')
        : '',
      intake_time_to: data.intake_time_to
        ? moment(data.intake_time_to).format('HH:mm')
        : '',
      name: data.name,
      houseNumber: data.houseNumber,
      apartmentNumber: data.apartmentNumber,
      street: data.street,
      orderId: getIdUrl(),
      packages: pickPackages(data.packages),
      exportReason: data.exportReason,
      paymentOfTaxes: data.paymentOfTaxes,
      vatKey: isVatInformation ? data.vatKey : Vatkey.Empty,
      vatValue: isVatInformation ? data.vatValue : '',
      globalProductCode: globalProductCode,
      //@ts-ignore
      orderCostCurrency: data.typeDeliveryId.orderCostCurrency,
      //@ts-ignore
      handlingFee: data.typeDeliveryId.handlingFee,
    };

    if (isCreation || isCopying) {
      createParcelMutation({
        variables: {
          input: parcelInput,
        },
      })
        .then((result: any) => {
          // For print/ok modal
          if (result?.data?.createParcel?.id) {
            setSavedParcelResponseId(result.data.createParcel.id);
          }

          if (
            orderByIdData &&
            orderByIdData?.getOrderById?.marketplace === 'etsy'
          ) {
            addEtsyTrackNumberMutation({
              variables: {
                storeId: Number(orderByIdData?.getOrderById?.storeId),
                carrierName: result.data.createParcel.carrierCode,
                receiptId: orderByIdData?.getOrderById?.orderId,
                sendBcc: false,
                trackingCode: result.data.createParcel.trackNumber,
              },
            });
          }

          resolve(t('app.shipmentSuccessfullySaved'));
        })
        .catch((reason: any) => {
          reason?.message &&
            enqueueSnackbar(reason.message, { variant: 'error' });
        })
        .finally(() => {
          setSubmitting(false);
          setLoading(false);
        });
    }
  });
};
