import { makeStyles, createStyles } from '@material-ui/core/styles';
import { COLORS } from '../../utils/constants';

export default makeStyles(() =>
  createStyles({
    items: {
      padding: 0,
      margin: 0,
      listStyle: 'none',
    },
    item: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      minHeight: 48,
    },
    itemResult: {
      fontWeight: 'bold',
      fontSize: 16,
    },
    itemCalculate: {
      flexDirection: 'column',
    },
    isShowError: {
      display: 'flex',
      alignItems: 'flex-end',
      alignSelf: 'flex-start',
      marginTop: '10px',
      color: 'red',
    },
    isFetchCalculated: {
      display: 'flex',
      alignItems: 'flex-end',
      alignSelf: 'flex-start',
      marginTop: '10px',
      color: 'green',
    },
    calculateButton: {
      marginRight: '20px',
    },
    buttonWrapper: { marginTop: '20px' },
    radio: {
      padding: 0,
    },
    tableCell: {
      paddingLeft: 0,
    },
    circularProgress: {
      overflow: 'hidden',
    },
    labelRow: {
      display: 'flex',
      alignItems: 'center',
      marginLeft: 0,
      marginRight: 0,
    },
    tooltipIconButton: {
      padding: 0,
    },
    labelRowText: {
      fontWeight: 'bold',
      marginLeft: 10,
      marginRight: 10,
    },
    logoImg: {
      width: 50,
      height: 'auto',
    },
    tooltipList: {
      paddingLeft: 15,
    },
    tooltipListItem: {
      paddingBottom: 5,
    },
    errorIcon: {
      color: COLORS.RED,
    },
  }),
);
