import {
  Maybe,
  PackageInput,
  ParcelUnitInput,
} from '../../../../../generated/graphql';
import { UNIT_STATES } from '../../../../../utils/constants';

export const pickPackages = (from: Maybe<PackageInput>[]) => {
  const packages: PackageInput[] = [];
  // eslint-disable-next-line array-callback-return
  from?.map((pack: any) => {
    const units: ParcelUnitInput[] = [];
    if (pack) {
      pack?.units?.map((u: any) => {
        // eslint-disable-next-line array-callback-return
        if (!u?.country?.id) return;
        return units.push({
          code: u.code ? +u.code : null,
          //@ts-ignore
          countryId: u?.country?.id,
          nameRU: u?.nameRU,
          nameEN: u?.nameEN,
          tradeMark: u?.tradeMark || '',
          netWeight: u?.netWeight ? +u?.netWeight : 0,
          price: u?.price ? +u?.price : 0,
          quantity: u?.quantity ? +u?.quantity : 0,
          state: u?.state || UNIT_STATES.NEW,
        });
      });

      packages.push({
        heightCm: pack.heightCm ? +pack.heightCm : 0,
        lengthCm: pack.lengthCm ? +pack.lengthCm : 0,
        weightKg: pack.weightKg ? +pack.weightKg : 0,
        widthCm: pack.widthCm ? +pack.widthCm : 0,
        units: units ? units : [],
      });
    }
  });
  return packages;
};
