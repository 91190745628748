import {
  Box,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
} from '@material-ui/core';
import { FastField, Field, FieldProps } from 'formik';
import LaunchIcon from '@material-ui/icons/Launch';
import { COLORS, UNIT_STATES } from '../../../utils/constants';
import React from 'react';
import { TFormikSetFieldValueDebounce } from '../../../interfaces';
import styles from './styles';
import { useTranslation } from 'react-i18next';

const Description: React.FC<{
  editingUnitSelector?: string;
  setFieldValue: TFormikSetFieldValueDebounce;
}> = ({ editingUnitSelector, setFieldValue }) => {
  const classes = styles();
  const { t } = useTranslation();

  return (
    <>
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <FastField
            name={
              editingUnitSelector
                ? `${editingUnitSelector}.nameRU`
                : 'detailsDescriptionRU'
            }
          >
            {({ field: { value, ...field }, meta }: FieldProps) => (
              <FormControl error={!!meta.error && meta.touched}>
                <Box display='flex' alignItems='center'>
                  <InputLabel htmlFor='input-unit-nameRU'>
                    {t('app.detailedDescription')}
                  </InputLabel>
                  <Tooltip title={t('app.readInstructions')}>
                    <a
                      href={`${process.env.REACT_APP_API_DOMAIN}description.php`}
                      target='_blank'
                      rel='noopener noreferrer'
                      className={classes.tooltipIconButton}
                    >
                      <LaunchIcon />
                    </a>
                  </Tooltip>
                </Box>

                <Input
                  disableUnderline
                  fullWidth
                  {...field}
                  id='input-unit-nameRU'
                  defaultValue={value || ''}
                  onChange={(e) => {
                    setFieldValue(
                      editingUnitSelector
                        ? `${editingUnitSelector}.nameRU`
                        : 'detailsDescriptionRU',
                      e.target.value,
                    );
                  }}
                />
                {!!meta.error && meta.touched && (
                  <FormHelperText style={{ color: COLORS.RED }}>
                    {meta.error}
                  </FormHelperText>
                )}
              </FormControl>
            )}
          </FastField>
        </Grid>
      </Grid>

      <Grid container spacing={1}>
        <Grid item xs={12}>
          <FastField
            name={
              editingUnitSelector
                ? `${editingUnitSelector}.nameEN`
                : 'detailsDescriptionEN'
            }
          >
            {({ field: { value, ...field }, meta }: FieldProps) => (
              <FormControl error={!!meta.error && meta.touched}>
                <InputLabel htmlFor='input-unit-nameEN'>
                  {t('app.descriptionEnglish')}
                </InputLabel>
                <Input
                  disableUnderline
                  fullWidth
                  {...field}
                  id='input-unit-nameEN'
                  defaultValue={value || ''}
                  onChange={(e) => {
                    setFieldValue(
                      editingUnitSelector
                        ? `${editingUnitSelector}.nameEN`
                        : 'detailsDescriptionEN',
                      e.target.value,
                    );
                  }}
                />
                {!!meta.error && meta.touched && (
                  <FormHelperText style={{ color: COLORS.RED }}>
                    {meta.error}
                  </FormHelperText>
                )}
              </FormControl>
            )}
          </FastField>
        </Grid>
      </Grid>

      <Grid container spacing={2}>
        <Grid item xs={12} sm={4}>
          <FastField
            name={
              editingUnitSelector ? `${editingUnitSelector}.state` : 'state'
            }
          >
            {({ field: { value }, meta }: FieldProps) => (
              <FormControl error={meta.touched && !!meta.error}>
                <InputLabel>Состояние</InputLabel>
                <Select
                  disableUnderline
                  displayEmpty
                  value={value || ''}
                  onChange={(event) => {
                    setFieldValue(
                      editingUnitSelector
                        ? `${editingUnitSelector}.state`
                        : 'state',
                      event.target.value || '',
                    );
                  }}
                  renderValue={
                    value !== ''
                      ? undefined
                      : () => <div>{t('app.chooseCondition')}</div>
                  }
                >
                  <MenuItem value={UNIT_STATES.UNKNOWN}>
                    {t('app.notSelected')}
                  </MenuItem>
                  <MenuItem value={UNIT_STATES.NEW}>{t('app.new')}</MenuItem>
                  <MenuItem value={UNIT_STATES.USED}>{t('app.used')}</MenuItem>
                </Select>

                {meta.touched && !!meta.error && (
                  <FormHelperText style={{ color: COLORS.RED }}>
                    {meta.error}
                  </FormHelperText>
                )}
              </FormControl>
            )}
          </FastField>
        </Grid>
        <Grid item xs={12} sm={8} style={{ alignSelf: 'center' }}>
          <Field
            name={
              editingUnitSelector
                ? `${editingUnitSelector}.tradeMark`
                : 'trademark'
            }
          >
            {({ field: { value, ...field }, meta }: FieldProps) => (
              <FormControl>
                <InputLabel htmlFor={`input-${editingUnitSelector}-tradeMark`}>
                  {t('app.trademarkBrand')}
                </InputLabel>
                <Input
                  disableUnderline
                  fullWidth
                  {...field}
                  placeholder={t('app.trademarkBrand')}
                  id={`input-${editingUnitSelector}-tradeMark`}
                  defaultValue={value || ''}
                  onChange={(e) => {
                    setFieldValue(
                      editingUnitSelector
                        ? `${editingUnitSelector}.tradeMark`
                        : 'trademark',
                      e.target.value,
                    );
                  }}
                />
                {meta.touched && !!meta.error && (
                  <FormHelperText style={{ color: COLORS.RED }}>
                    {meta.error}
                  </FormHelperText>
                )}
              </FormControl>
            )}
          </Field>
        </Grid>
      </Grid>
    </>
  );
};

export default Description;
