import { GetParcelQuery } from '../../../../../generated/graphql';
import { useEffect, useState } from 'react';

type useUpdateViewType = {
  dataLazyParcel: GetParcelQuery | undefined;
};

export const useUpdateView = ({ dataLazyParcel }: useUpdateViewType) => {
  const [updateView, setUpdateView] = useState(0);

  useEffect(() => {
    setUpdateView((prevUpdateView) => {
      return ++prevUpdateView;
    });
  }, [dataLazyParcel]);

  return { updateView, setUpdateView };
};
