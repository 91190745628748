import { t } from 'i18next';
import * as Yup from 'yup';
import {
  WAREHOUSE_TARIFFS,
  ERRORS,
  MAX_PARCEL_WEIGHT,
  MAX_PARCEL_DIMENSION,
} from './constants';
import { checkAllowedCharacters } from './helpers';
import { messages } from './messages';

export const signIn = () =>
  Yup.object()
    .shape({
      email: Yup.string()
        .email(t('app.invalidEmail'))
        .required(t(ERRORS.FIELD_REQUIRED))
        .defined(),
      password: Yup.string()
        .trim()
        .required(t(ERRORS.FIELD_REQUIRED))
        .min(8, t('app.passwordLength', { value: 8 }))
        .defined(),
    })
    .defined();

export const signUp = () =>
  Yup.object().shape({
    email: Yup.string()
      .email(t('app.invalidEmail'))
      .required(t(ERRORS.FIELD_REQUIRED)),
    password: Yup.string()
      .trim()
      .required(t('app.fillPassword'))
      .min(8, t('app.passwordLength', { value: 8 })),
    name: Yup.string().trim().required(t(ERRORS.FIELD_REQUIRED)),
    agreePersonalData: Yup.boolean().oneOf([true], t('app.agreeTerms')),
  });

export const formContactNotPersonalShema = () =>
  Yup.object({
    name: Yup.string()
      .max(255, t(ERRORS.MAX_SYMBOLS))
      .required(t(ERRORS.FIELD_REQUIRED))
      .defined(),
    company: Yup.string().nullable().default(''),
    country: Yup.string().required(t(ERRORS.FIELD_REQUIRED)).defined(),
    address: Yup.string()
      .nullable()
      .required(t(ERRORS.FIELD_REQUIRED))
      .defined(),
    address2: Yup.string().nullable().default(''),
    address3: Yup.string().nullable().default(''),
    zipCode: Yup.string().nullable().default(''),
    city: Yup.string().required(t(ERRORS.FIELD_REQUIRED)).defined(),
    state: Yup.string().nullable(true),
    phone: Yup.string().nullable().required(t(ERRORS.FIELD_REQUIRED)).defined(),
    email: Yup.string().nullable().email(t('app.invalidEmail')).default(''),

    remark: Yup.string().nullable().default(''),
  }).defined();

export const formContactPersonalShema = () =>
  Yup.object({
    name: Yup.string()
      .max(255, t(ERRORS.MAX_SYMBOLS))
      .required(t(ERRORS.FIELD_REQUIRED))
      .defined(),
    company: Yup.string().nullable().default(''),
    country: Yup.string().required(t(ERRORS.FIELD_REQUIRED)).defined(),
    address: Yup.string()
      .nullable()
      .required(t(ERRORS.FIELD_REQUIRED))
      .defined(),
    address2: Yup.string().nullable().default(''),
    address3: Yup.string().nullable().default(''),
    zipCode: Yup.string()
      .nullable()
      .required(t(ERRORS.FIELD_REQUIRED))
      .defined(),
    city: Yup.string().required(t(ERRORS.FIELD_REQUIRED)).defined(),
    state: Yup.string().nullable(true),
    phone: Yup.string().nullable().required(t(ERRORS.FIELD_REQUIRED)).defined(),
  }).defined();

export const formContactPersonalShemaRus = () =>
  Yup.object({
    name: Yup.string()
      .max(255, t(ERRORS.MAX_SYMBOLS))
      .required(t(ERRORS.FIELD_REQUIRED))
      .defined(),
    company: Yup.string()
      .nullable()
      .required(t(ERRORS.FIELD_REQUIRED))
      .defined(),
    country: Yup.string().required(t(ERRORS.FIELD_REQUIRED)).defined(),
    address: Yup.string()
      .nullable()
      .required(t(ERRORS.FIELD_REQUIRED))
      .defined(),
    address2: Yup.string().nullable(),

    zipCode: Yup.string()
      .nullable()
      .required(t(ERRORS.FIELD_REQUIRED))
      .defined(),
    city: Yup.string().required(t(ERRORS.FIELD_REQUIRED)).defined(),
    state: Yup.string().nullable(true),

    phone: Yup.string().nullable().required(t(ERRORS.FIELD_REQUIRED)).defined(),
  }).defined();

export const directCalculatorForm = () =>
  Yup.object({
    currency: Yup.string().required(t(ERRORS.FIELD_REQUIRED)),
    packages: Yup.array()
      .defined()
      .of(
        Yup.object({
          weightKg: Yup.number()
            .max(MAX_PARCEL_WEIGHT, t(ERRORS.MAX_WEIGHT))
            .typeError(t('app.mustBeNumber2'))
            .positive(t(ERRORS.POSITIVE_NUMBER))
            .required(t(ERRORS.FIELD_REQUIRED)),
          lengthCm: Yup.number()
            .max(MAX_PARCEL_DIMENSION, t(ERRORS.MAX_DIMENSION))
            .typeError(t('app.mustBeNumber2'))
            .positive(t(ERRORS.POSITIVE_NUMBER))
            .required(t(ERRORS.FIELD_REQUIRED)),
          widthCm: Yup.number()
            .max(MAX_PARCEL_DIMENSION, t(ERRORS.MAX_DIMENSION))
            .typeError(t('app.mustBeNumber2'))
            .positive(t(ERRORS.POSITIVE_NUMBER))
            .required(t(ERRORS.FIELD_REQUIRED)),
          heightCm: Yup.number()
            .max(MAX_PARCEL_DIMENSION, t(ERRORS.MAX_DIMENSION))
            .typeError(t('app.mustBeNumber2'))
            .positive(t(ERRORS.POSITIVE_NUMBER))
            .required(t(ERRORS.FIELD_REQUIRED)),
        }).defined(),
      ),
    receiver: Yup.object({
      country: Yup.string()
        .nullable()
        .required(t(ERRORS.FIELD_REQUIRED))
        .defined(),
    }),
    additionalInsurance: Yup.boolean().oneOf([true, false]),
    insuranceAmount: Yup.number()
      .positive(t(ERRORS.POSITIVE_NUMBER))
      .required(t(ERRORS.FIELD_REQUIRED))
      .defined(),
    exportReason: Yup.string().nullable(),
    paymentOfTaxes: Yup.string().nullable(),
    vatKey: Yup.string().nullable(),
    vatValue: Yup.string().nullable(),
  });

export const fullfilmentCalculatorForm = () =>
  Yup.object({
    receiver: Yup.object({
      country: Yup.string().required(t(ERRORS.FIELD_REQUIRED)).defined(),
      zipCode: Yup.string()
        .nullable()
        .required(t(ERRORS.FIELD_REQUIRED))
        .defined(),
      city: Yup.string().required(t(ERRORS.FIELD_REQUIRED)).defined(),
      state: Yup.string().required(t(ERRORS.FIELD_REQUIRED)).defined(),
    }).defined(),
    warehouseId: Yup.number().required(t(ERRORS.FIELD_REQUIRED)),
    dimensions: Yup.object({
      weight: Yup.number()
        .typeError(t('app.mustBeNumber2'))
        .positive(t(ERRORS.POSITIVE_NUMBER))
        .required(t(ERRORS.FIELD_REQUIRED)),
      length: Yup.number()
        .typeError(t('app.mustBeNumber2'))
        .positive(t(ERRORS.POSITIVE_NUMBER))
        .required(t(ERRORS.FIELD_REQUIRED)),
      width: Yup.number()
        .typeError(t('app.mustBeNumber2'))
        .positive(t(ERRORS.POSITIVE_NUMBER))
        .required(t(ERRORS.FIELD_REQUIRED)),
      height: Yup.number()
        .typeError(t('app.mustBeNumber2'))
        .positive(t(ERRORS.POSITIVE_NUMBER))
        .required(t(ERRORS.FIELD_REQUIRED)),
    }).defined(),
  }).defined();

export const SenderAccountSchema = () =>
  Yup.object({
    serviceName: Yup.string().nullable(),
    siteId: Yup.string().nullable(),
    accountNumber: Yup.string().when('serviceName', {
      is: (val) => val !== 'EASYPOST',
      then: Yup.string().required(t(ERRORS.FIELD_REQUIRED)),
      otherwise: Yup.string().nullable(),
    }),
    accountName: Yup.string().required(t(ERRORS.FIELD_REQUIRED)),
    password: Yup.string().nullable(),
    status: Yup.bool().default(true),
    requestorPostalCode: Yup.string().nullable(),
    requestorPhone: Yup.string().nullable(),
    requestorName: Yup.string().nullable(),
    requestorCountryCode: Yup.string().nullable(),
    requestorCity: Yup.string().nullable(),
    requestorAddress: Yup.string().nullable(),
    isTestMode: Yup.bool().default(true),
    easypostProdApiKey: Yup.string().when('serviceName', {
      is: (val) => val === 'EASYPOST',
      then: Yup.string().required(t(ERRORS.FIELD_REQUIRED)),
      otherwise: Yup.string().nullable(),
    }),
    easypostTestApiKey: Yup.string().when('serviceName', {
      is: (val) => val === 'EASYPOST',
      then: Yup.string().required(t(ERRORS.FIELD_REQUIRED)),
      otherwise: Yup.string().nullable(),
    }),
  });

export const shipmentSchemaStep1 = () =>
  Yup.object({
    sender: formContactPersonalShema(),
    receiver: formContactNotPersonalShema(),
  }).defined();

export const shipmentSchemaStep2 = () =>
  Yup.object({
    packages: Yup.array()
      .defined()
      .of(
        Yup.object({
          weightKg: Yup.number()
            .typeError(t('app.mustBeNumber2'))
            .positive(t(ERRORS.POSITIVE_NUMBER))
            .required(t(ERRORS.FIELD_REQUIRED)),
          lengthCm: Yup.number()
            .typeError(t('app.mustBeNumber2'))
            .positive(t(ERRORS.POSITIVE_NUMBER))
            .required(t(ERRORS.FIELD_REQUIRED)),
          widthCm: Yup.number()
            .typeError(t('app.mustBeNumber2'))
            .positive(t(ERRORS.POSITIVE_NUMBER))
            .required(t(ERRORS.FIELD_REQUIRED)),
          heightCm: Yup.number()
            .typeError(t('app.mustBeNumber2'))
            .positive(t(ERRORS.POSITIVE_NUMBER))
            .required(t(ERRORS.FIELD_REQUIRED)),
        }).defined(),
      ),
  }).defined();

export const shipmentSchemaStep3 = () =>
  Yup.object({
    currency: Yup.string().required(t(ERRORS.FIELD_REQUIRED)),
    packages: Yup.array()
      .defined()
      .of(
        Yup.object({
          units: Yup.array().of(
            Yup.object({
              nameRU: Yup.string().required(t(ERRORS.FIELD_REQUIRED)),
              nameEN: Yup.string().required(t(ERRORS.FIELD_REQUIRED)),
              tradeMark: Yup.string(),
              code: Yup.number()
                .max(
                  Number.MAX_SAFE_INTEGER,
                  `${t(ERRORS.MAX_VALUE)} ${Number.MAX_SAFE_INTEGER}`,
                )
                .nullable(),
              quantity: Yup.number()
                .typeError(t('app.mustBeNumber'))
                .integer(t('app.mustBeInteger'))
                .positive(t(ERRORS.POSITIVE_NUMBER))
                .required(t(ERRORS.FIELD_REQUIRED)),
              price: Yup.number()
                .positive(t(ERRORS.POSITIVE_NUMBER))
                .typeError(t('app.mustBeNumber2'))
                .required(t(ERRORS.FIELD_REQUIRED)),
              country: Yup.mixed().test({
                name: 'country',
                exclusive: true,
                message: t(messages.REQUIRE_MESSAGE),
                test: (value) => {
                  if (value?.iso) {
                    return true;
                  }
                  return false;
                },
              }),
              netWeight: Yup.number()
                .positive(t(ERRORS.POSITIVE_NUMBER))
                .max(MAX_PARCEL_WEIGHT, t(ERRORS.MAX_WEIGHT))
                .typeError(t('app.mustBeNumber'))
                .required(t(ERRORS.FIELD_REQUIRED)),
              state: Yup.string(),
            }).defined(),
          ),
        }).defined(),
      ),
  }).defined();

export const shipmentSchemaVatStep = () =>
  Yup.object({
    exportReason: Yup.string(),
    paymentOfTaxes: Yup.string(),
    vatKey: Yup.string(),
    vatValue: Yup.string(),
  }).defined();

export const shipmentSchemaStep4 = () =>
  Yup.object({
    senderMark: Yup.string().default('').nullable(),
    senderNotes: Yup.string().default(''),
    additionalInsurance: Yup.bool().default(false).defined(),
    signature: Yup.bool().default(false).defined(),
    insuranceAmount: Yup.number().nullable(),
  }).defined();

export const shipmentSchemaStep5 = () =>
  Yup.object({
    typeDeliveryId: Yup.object({
      typeDeliveryId: Yup.number().required(t(ERRORS.FIELD_REQUIRED)),
      indexValue: Yup.string().required(t(ERRORS.FIELD_REQUIRED)),
    }),
    declaredWeight: Yup.number().nullable(),
    declaredAmount: Yup.number().nullable(),
  }).defined();

export const shipmentSchemas = [
  shipmentSchemaStep1,
  shipmentSchemaStep2,
  shipmentSchemaStep3,
  shipmentSchemaStep4,
  shipmentSchemaStep5,
];
export const shipmentWithVatInformationSchemas = [
  shipmentSchemaStep1,
  shipmentSchemaStep2,
  shipmentSchemaStep3,
  shipmentSchemaVatStep,
  shipmentSchemaStep4,
  shipmentSchemaStep5,
];

export const warehouseShipmentSchemaStep1 = () =>
  Yup.object().shape(
    {
      parcelIds: Yup.array().when('products', {
        is: (val) => val?.length !== 0,
        then: Yup.array(),
        otherwise: Yup.array().min(1, t('app.chooseOrder')),
      }),
      products: Yup.array()
        .when('parcelIds', {
          is: (val) => val?.length !== 0,
          then: Yup.array(),
          otherwise: Yup.array().min(1, t('app.chooseProduct')),
        })
        .of(
          Yup.object({
            id: Yup.number().nullable(),
            quantity: Yup.number()
              .typeError(t('app.mustBeNumber'))
              .integer(t('app.mustBeInteger'))
              .positive(t(ERRORS.POSITIVE_NUMBER))
              .required(t(ERRORS.FIELD_REQUIRED)),
          }),
        ),
    },
    //@ts-ignore
    ['parcelIds', 'products'],
  );

export const warehouseShipmentSchemaStep2 = () =>
  Yup.object({
    cargoPlaces: Yup.array()
      .defined()
      .of(
        Yup.object({
          weight: Yup.number()
            .typeError(t('app.mustBeNumber2'))
            .positive(t(ERRORS.POSITIVE_NUMBER))
            .required(t(ERRORS.FIELD_REQUIRED)),
          length: Yup.number()
            .typeError(t('app.mustBeNumber2'))
            .positive(t(ERRORS.POSITIVE_NUMBER))
            .required(t(ERRORS.FIELD_REQUIRED)),
          width: Yup.number()
            .typeError(t('app.mustBeNumber2'))
            .positive(t(ERRORS.POSITIVE_NUMBER))
            .required(t(ERRORS.FIELD_REQUIRED)),
          height: Yup.number()
            .typeError(t('app.mustBeNumber2'))
            .positive(t(ERRORS.POSITIVE_NUMBER))
            .required(t(ERRORS.FIELD_REQUIRED)),
        }).defined(),
      ),
  });

export const warehouseShipmentSchemaStep3 = () =>
  Yup.object({
    from: formContactPersonalShema(),
  }).defined();
export const warehouseShipmentSchemaStep4 = () =>
  Yup.object({
    warehouseId: Yup.number().required(t(ERRORS.FIELD_REQUIRED)),
  }).defined();
export const warehouseShipmentSchemaStep5 = () =>
  Yup.object({
    carrierCode: Yup.string().nullable(),
    trackNumber: Yup.string().nullable(),
  }).defined();

export const warehouseShipmentSchemas = [
  warehouseShipmentSchemaStep1,
  warehouseShipmentSchemaStep2,
  warehouseShipmentSchemaStep3,
  warehouseShipmentSchemaStep4,
  warehouseShipmentSchemaStep5,
];

export const shipmentFromWarehouseSchemaStep1 = () =>
  Yup.object({
    receiver: formContactNotPersonalShema(),
    warehouseId: Yup.number().required(t(ERRORS.FIELD_REQUIRED)),
  }).defined();

export const shipmentFromWarehouseSchemaStep2 = () =>
  Yup.object({
    products: Yup.array()
      .defined()
      .of(
        Yup.object({
          id: Yup.number().min(1).required(),
          quantity: Yup.number()
            .default(1)
            .typeError(t('app.mustBeNumber'))
            .integer(t('app.mustBeInteger'))
            .positive(t(ERRORS.POSITIVE_NUMBER))
            .required(t(ERRORS.FIELD_REQUIRED)),
          price: Yup.number()
            .typeError(t('app.mustBeNumber'))
            .positive(t(ERRORS.POSITIVE_NUMBER))
            .required(t(ERRORS.FIELD_REQUIRED)),
        }),
      ),
  }).defined();

export const shipmentFromWarehouseSchemaStep3 = () =>
  Yup.object({
    senderMark: Yup.string().default('').nullable(),
    additionalInsurance: Yup.bool().default(false).defined(),
    insuranceAmount: Yup.number().nullable().default(0),
    signature: Yup.bool().default(false).defined(),
    typeDeliveryId: Yup.number().required(t(ERRORS.FIELD_REQUIRED)),
  }).defined();

export const shipmentFromWarehouseSchemas = [
  shipmentFromWarehouseSchemaStep1,
  shipmentFromWarehouseSchemaStep2,
  shipmentFromWarehouseSchemaStep3,
];

export const forwardingSchemas = [
  shipmentFromWarehouseSchemaStep1,
  shipmentSchemaStep2,
  shipmentSchemaStep3,
  shipmentSchemaStep4,
];

export const shipmentDraft = () =>
  Yup.object({
    currency: Yup.string().nullable(),
    sender: Yup.object({
      name: Yup.string(),
      company: Yup.string().nullable(),
      country: Yup.string().nullable(),
      address: Yup.string().nullable(),
      address2: Yup.string().nullable().default(''),
      address3: Yup.string().nullable().default(''),
      zipCode: Yup.string().nullable(),
      city: Yup.string().nullable(),
      state: Yup.string().nullable(true),
      phone: Yup.string().nullable(),
      innKppCode: Yup.string().nullable().default(''),
    }).defined(),
    receiver: Yup.object({
      name: Yup.string().nullable(),
      company: Yup.string().nullable(),
      country: Yup.string().nullable(),
      address: Yup.string().nullable(),
      address2: Yup.string().nullable().default(''),
      address3: Yup.string().nullable().default(''),
      zipCode: Yup.string().nullable(),
      city: Yup.string().nullable(),
      state: Yup.string().nullable(true),
      phone: Yup.string().nullable(),
      email: Yup.string().nullable().default(''),
      eoriCode: Yup.string().nullable().default(''),
      remark: Yup.string().nullable().default(''),
    }).defined(),
    packages: Yup.array()
      .defined()
      .of(
        Yup.object({
          weightKg: Yup.number()
            .typeError(t('app.weightMustBeNumber'))
            .nullable()
            .default(null),
          lengthCm: Yup.number()
            .typeError(t('app.lengthMustBeNumber'))
            .nullable()
            .default(null),
          widthCm: Yup.number()
            .typeError(t('app.widthMustBeNumber'))
            .nullable()
            .default(null),
          heightCm: Yup.number()
            .typeError(t('app.heightMustBeNumber'))
            .nullable()
            .default(null),
          units: Yup.array().of(
            Yup.object({
              nameRU: Yup.string().nullable(),
              nameEN: Yup.string().nullable(),
              tradeMark: Yup.string().nullable(),
              code: Yup.number()
                .max(
                  Number.MAX_SAFE_INTEGER,
                  `${t(ERRORS.MAX_VALUE)} ${Number.MAX_SAFE_INTEGER}`,
                )
                .nullable(),
              quantity: Yup.number()
                .typeError(t('app.quantityMustBeNumber'))
                .nullable()
                .default(null),
              price: Yup.number()
                .typeError(t('app.priceMustBeNumber'))
                .nullable()
                .default(null),
              country: Yup.string().nullable(),
              netWeight: Yup.number()
                .nullable()
                .typeError(t('app.netWeightMustBeNumber'))
                .nullable()
                .default(null),
              state: Yup.string(),
            }).defined(),
          ),
        }).defined(),
      ),
    senderMark: Yup.string().nullable().default(''),
    senderNotes: Yup.string().nullable().default(''),
    additionalInsurance: Yup.bool().default(false).defined(),
    declaredWeight: Yup.number().nullable(),
    declaredAmount: Yup.number().nullable(),
  }).defined();

export const email = () =>
  Yup.object()
    .shape({
      email: Yup.string()
        .email(t('app.invalidEmail'))
        .required(t(ERRORS.FIELD_REQUIRED))
        .defined(),
    })
    .defined();

export const password = () =>
  Yup.object({
    password: Yup.string()
      .trim()
      .required(t('app.fillPassword'))
      .min(8, t('app.passwordLength', { value: 8 }))
      .defined(),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('password')], t('app.passwordsDoNotMatch'))
      .defined(),
  }).defined();

export const changePasswordSchema = () =>
  Yup.object({
    oldPassword: Yup.string()
      .trim()
      .required(t('app.fillPassword'))
      .min(8, t('app.passwordLength', { value: 8 }))
      .defined(),
    newPassword: Yup.string()
      .trim()
      .required(t('app.fillPassword'))
      .min(8, t('app.passwordLength', { value: 8 }))
      .defined(),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref('newPassword')], t('app.passwordsDoNotMatch'))
      .defined(),
  }).defined();

export const warehouseSendFormSchema = () =>
  Yup.object()
    .shape({
      name: Yup.string()
        .max(255, t(ERRORS.MAX_SYMBOLS))
        .required(t(ERRORS.FIELD_REQUIRED))
        .defined(),
      phone: Yup.string().required(t(ERRORS.FIELD_REQUIRED)).defined(),
      CDEK: Yup.object({
        country_code: Yup.string()
          .nullable()
          .when('tariffCode', {
            is: (tariffCode) =>
              tariffCode &&
              +tariffCode &&
              tariffCode !== WAREHOUSE_TARIFFS.SELF_EXPORT,
            then: Yup.string().required(t(ERRORS.FIELD_REQUIRED)).defined(),
          }),
        region_code: Yup.number()
          .nullable()
          .when('tariffCode', {
            is: (tariffCode) =>
              tariffCode &&
              +tariffCode &&
              tariffCode !== WAREHOUSE_TARIFFS.SELF_EXPORT,
            then: Yup.number().required(t(ERRORS.FIELD_REQUIRED)).defined(),
          }),
        city_code: Yup.number()
          .nullable()
          .when('tariffCode', {
            is: (tariffCode) =>
              tariffCode &&
              +tariffCode &&
              tariffCode !== WAREHOUSE_TARIFFS.SELF_EXPORT,
            then: Yup.number().required(t(ERRORS.FIELD_REQUIRED)).defined(),
          }),
        office: Yup.string().nullable(),
      })
        .required(t(ERRORS.FIELD_REQUIRED))
        .defined(),
      selected: Yup.array()
        .of(Yup.string().defined())
        .required(t('app.addRemoveOrders'))
        .defined(),
      tariffCode: Yup.string().required(t(ERRORS.FIELD_REQUIRED)).defined(),
      comment: Yup.string(),
      intake_name: Yup.string().when('tariffCode', {
        is: (tariffCode) =>
          tariffCode &&
          +tariffCode &&
          tariffCode === WAREHOUSE_TARIFFS.CDEK_DOOR,
        then: Yup.string().required(t(ERRORS.FIELD_REQUIRED)).defined(),
      }),
      intake_date: Yup.string().when('tariffCode', {
        is: (tariffCode) =>
          tariffCode &&
          +tariffCode &&
          tariffCode === WAREHOUSE_TARIFFS.CDEK_DOOR,
        then: Yup.string().required(t(ERRORS.FIELD_REQUIRED)).defined(),
      }),
      intake_time_from: Yup.string().when('tariffCode', {
        is: (tariffCode) =>
          tariffCode &&
          +tariffCode &&
          tariffCode === WAREHOUSE_TARIFFS.CDEK_DOOR,
        then: Yup.string().required(t(ERRORS.FIELD_REQUIRED)).defined(),
      }),
      intake_time_to: Yup.string().when('tariffCode', {
        is: (tariffCode) =>
          tariffCode &&
          +tariffCode &&
          tariffCode === WAREHOUSE_TARIFFS.CDEK_DOOR,
        then: Yup.string().required(t(ERRORS.FIELD_REQUIRED)).defined(),
      }),
      street: Yup.string().when('tariffCode', {
        is: (tariffCode) =>
          tariffCode &&
          +tariffCode &&
          tariffCode === WAREHOUSE_TARIFFS.CDEK_DOOR,
        then: Yup.string().required(t(ERRORS.FIELD_REQUIRED)).defined(),
      }),
      house: Yup.string().when('tariffCode', {
        is: (tariffCode) =>
          tariffCode &&
          +tariffCode &&
          tariffCode === WAREHOUSE_TARIFFS.CDEK_DOOR,
        then: Yup.string().required(t(ERRORS.FIELD_REQUIRED)).defined(),
      }),

      recipientCompany: Yup.string(),
      recipientName: Yup.string(),
      recipientEmail: Yup.string().email(t('app.invalidEmail')),
      recipientPhoneNumber: Yup.string(),

      flat: Yup.string(),
    })
    .defined();

export const parcelTrackSchema = () =>
  Yup.object().shape({
    trackNumber: Yup.string()
      .required(t(ERRORS.FIELD_REQUIRED))
      .test({
        test: function (value) {
          if (value && value.length >= 4 && checkAllowedCharacters(value)) {
            return true;
          } else if (value && value.length < 4) {
            return this.createError({
              message: t('app.trackingNumberLength', { value: 4 }),
            });
          } else {
            return this.createError({
              message: t('app.trackingNumberLatin'),
            });
          }
        },
      }),
    carrierCode: Yup.string().required(t(ERRORS.FIELD_REQUIRED)),
  });

export const myProductFormSchema = () =>
  Yup.object({
    name: Yup.string().required(t(ERRORS.FIELD_REQUIRED)),
    countryId: Yup.object().required(t(ERRORS.FIELD_REQUIRED)),
    sku: Yup.string()
      .nullable()
      .matches(/^[-_a-zA-Z0-9]+$/, t('app.onlyLatinAndNumbers')),
    declaredValue: Yup.number()
      .positive(t(ERRORS.POSITIVE_NUMBER))
      .typeError(t('app.mustBeNumber2'))
      .required(t(ERRORS.FIELD_REQUIRED)),
    detailsDescriptionRU: Yup.string().required(t(ERRORS.FIELD_REQUIRED)),
    detailsDescriptionEN: Yup.string().required(t(ERRORS.FIELD_REQUIRED)),
    netWeight: Yup.number()
      .positive(t(ERRORS.POSITIVE_NUMBER))
      .max(MAX_PARCEL_WEIGHT, t(ERRORS.MAX_WEIGHT))
      .typeError(t('app.mustBeNumber'))
      .required(t(ERRORS.FIELD_REQUIRED)),
    code: Yup.number()
      .max(
        Number.MAX_SAFE_INTEGER,
        `${t(ERRORS.MAX_VALUE)} ${Number.MAX_SAFE_INTEGER}`,
      )
      .nullable(),
    trademark: Yup.string().nullable(),
    state: Yup.string().nullable(true),
  });

export const myPackageFormSchema = () =>
  Yup.object({
    name: Yup.string().required(t(ERRORS.FIELD_REQUIRED)),
    weight: Yup.number()
      .positive(t(ERRORS.POSITIVE_NUMBER))
      .max(MAX_PARCEL_WEIGHT, t(ERRORS.MAX_WEIGHT))
      .required(t(ERRORS.FIELD_REQUIRED)),
    length: Yup.number()
      .positive(t(ERRORS.POSITIVE_NUMBER))
      .max(MAX_PARCEL_DIMENSION, t(ERRORS.MAX_DIMENSION))
      .required(t(ERRORS.FIELD_REQUIRED)),
    width: Yup.number()
      .positive(t(ERRORS.POSITIVE_NUMBER))
      .max(MAX_PARCEL_DIMENSION, t(ERRORS.MAX_DIMENSION))
      .required(t(ERRORS.FIELD_REQUIRED)),
    height: Yup.number()
      .positive(t(ERRORS.POSITIVE_NUMBER))
      .max(MAX_PARCEL_DIMENSION, t(ERRORS.MAX_DIMENSION))
      .required(t(ERRORS.FIELD_REQUIRED)),
  });

export const schemaAddPackageToMarketplaceOrder = () =>
  Yup.object({
    packageId: Yup.number().nullable(),
    weight: Yup.number()
      .positive(t(ERRORS.POSITIVE_NUMBER))
      .max(MAX_PARCEL_WEIGHT, t(ERRORS.MAX_WEIGHT))
      .required(t(ERRORS.FIELD_REQUIRED)),
    length: Yup.number()
      .positive(t(ERRORS.POSITIVE_NUMBER))
      .max(MAX_PARCEL_DIMENSION, t(ERRORS.MAX_DIMENSION))
      .required(t(ERRORS.FIELD_REQUIRED)),
    width: Yup.number()
      .positive(t(ERRORS.POSITIVE_NUMBER))
      .max(MAX_PARCEL_DIMENSION, t(ERRORS.MAX_DIMENSION))
      .required(t(ERRORS.FIELD_REQUIRED)),
    height: Yup.number()
      .positive(t(ERRORS.POSITIVE_NUMBER))
      .max(MAX_PARCEL_DIMENSION, t(ERRORS.MAX_DIMENSION))
      .required(t(ERRORS.FIELD_REQUIRED)),
  });

export const pasportFormShema = () =>
  Yup.object().shape({
    firstName: Yup.string().required(t(messages.REQUIRE_MESSAGE)),
    lastName: Yup.string().required(t(messages.REQUIRE_MESSAGE)),
    middleName: Yup.string(),
    birthDay: Yup.string()
      .min(10, t(messages.MIN_10_SYMBOL))
      .required(t(messages.REQUIRE_MESSAGE)),
    seriesAndNumberOfPassport: Yup.string().required(
      t(messages.REQUIRE_MESSAGE),
    ),
    dateOfIssueOfTheDocument: Yup.string()
      .min(10, t(messages.MIN_10_SYMBOL))
      .required(t(messages.REQUIRE_MESSAGE)),
    inn: Yup.string().required(t(messages.REQUIRE_MESSAGE)),
    dataUpToDate: Yup.boolean().oneOf([true], t(messages.REQUIRE_MESSAGE)),

    file: Yup.mixed().test({
      name: 'fileValidation',
      exclusive: true,
      message: t(messages.DROPZONE),
      test: (value) => {
        if (typeof value === 'string' || typeof value === 'object') {
          if (Array.isArray(value)) {
            return false;
          }
          return true;
        }
        return false;
      },
    }),
  });

export const ukFormShema = () =>
  Yup.object().shape({
    company: Yup.string().required(t(messages.REQUIRE_MESSAGE)),
    inn: Yup.string().min(10).max(12).required(t(messages.REQUIRE_MESSAGE)),
    kpp: Yup.string(),
    MSRN: Yup.string().min(13).max(15).required(t(messages.REQUIRE_MESSAGE)),
    generalManager: Yup.string().required(t(messages.REQUIRE_MESSAGE)),
    legalAddress: Yup.string().required(t(messages.REQUIRE_MESSAGE)),
    nameOfTheBank: Yup.string().required(t(messages.REQUIRE_MESSAGE)),
    BIK: Yup.string().required(t(messages.REQUIRE_MESSAGE)),
    paymentAccount: Yup.string().required(t(messages.REQUIRE_MESSAGE)),
    correspondentAccount: Yup.string().required(t(messages.REQUIRE_MESSAGE)),
    dataUpToDate: Yup.boolean().required(t(messages.REQUIRE_MESSAGE)),
    file: Yup.mixed().test({
      name: 'fileValidation',
      exclusive: true,
      message: t(messages.DROPZONE),
      test: (value) => {
        if (typeof value === 'string' || typeof value === 'object') {
          if (Array.isArray(value)) {
            return false;
          }
          return true;
        }
        return false;
      },
    }),
  });
