import React, { useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  FormControl,
  MenuItem,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import { useSnackbar } from 'notistack';
import { useApolloClient } from '@apollo/client';
import {
  DEPARTURE_DATE_FILTER,
  getStatusIdByName,
  handleSelectAllCheckboxes,
  LIMIT_ITEM_PAGE,
} from '../../../utils/helpers';
import useStyles from './styles';
import ParcelTableRowItem from '../../../components/ParcelTableRowItem/ParcelTableRowItem';
import { isObject } from 'formik';
import { ShowLoadingText } from '../../../utils/helperComponents';
import ModalBox from '../../../components/ModalBox/ModalBox';
import getStatusesList from '../../../GraphQL/queries/getStatusesList';
import {
  GetStatusesListQuery,
  GetStatusesListQueryVariables,
  Parcel,
  StatusDelivery,
  useCancelParcelMutation,
  useGetCurrentUserQuery,
  useGetParcelsByTypeLazyQuery,
  UserRoles,
} from '../../../generated/graphql';
import { printShipment } from '../ShimpentPrint/ShimpentPrint';
import PrintIcon from '@material-ui/icons/Print';
import { useDebouncedCallback } from 'use-debounce';
import {
  DELIVERY_STATUS_BY_ID,
  LOCAL_STORAGE_KEYS,
} from '../../../utils/constants';
import FilterSelectLimitItems from '../../../components/_Fields/FilterSelectLimitItems/FilterSelectLimitItems';
import { useTranslation } from 'react-i18next';
import FilterInputSearch from '../../../components/_Fields/FilterInputSearch/FilterInputSearch';

interface IShipmentsFilter {
  paymentStatusId: number | string;
  deliveryStatusId: number | string;
  date: number | string;
  search: string;
  page: number;
  itemsLimit: number;
}

const itemsLimit = localStorage.getItem(
  LOCAL_STORAGE_KEYS.ITEMS_LIMIT_SHIPMENTS_ALL_HISTORY,
);

export default function ShipmentsAllHistory(): JSX.Element {
  const client = useApolloClient();
  const { t } = useTranslation();

  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [parcelList, setParcelList] = useState<Parcel[]>([]);
  const [isOpenConfirmCancel, setOpenConfirmCancel] = useState(false);
  const [parcelToCancel, setParcelToCancel] = useState<Parcel | null>(null);
  const [isLoadingAction, setLoadingAction] = useState(false);
  const [cancelParcelMutation] = useCancelParcelMutation();
  const [selected, setSelected] = React.useState<string[]>([]);

  const [filter, setFilter] = useState<IShipmentsFilter>({
    paymentStatusId: 0,
    deliveryStatusId: 0,
    date: 0,
    search: '',
    page: 1,
    itemsLimit: itemsLimit ? +itemsLimit : LIMIT_ITEM_PAGE,
  });

  const statuses = client.readQuery<
    GetStatusesListQuery,
    GetStatusesListQueryVariables
  >({
    query: getStatusesList,
  });

  const statusDeliveryIdNew = getStatusIdByName(
    statuses?.getStatusesList?.DeliveryStatusList as StatusDelivery[],
    'new',
  );

  const [getParcelsLazyQuery, { loading: isLoadingGetParcels, error, data }] =
    useGetParcelsByTypeLazyQuery({ fetchPolicy: 'network-only' });

  const { data: userData } = useGetCurrentUserQuery({
    fetchPolicy: 'cache-only',
  });
  useEffect(() => {
    data?.getParcelsByType?.rows &&
      setParcelList(data.getParcelsByType.rows as Parcel[]);
  }, [data]);

  useEffect(() => {
    refetchParcels();
    // eslint-disable-next-line
  }, [filter]);

  error?.message && enqueueSnackbar(error.message, { variant: 'error' });

  const refetchParcels = useDebouncedCallback(() => {
    setLoadingAction(true);
    getParcelsLazyQuery({
      variables: {
        offset: (filter.page - 1) * filter.itemsLimit,
        limit: filter.itemsLimit,
        search: filter.search,
        date:
          typeof filter.date === 'string'
            ? DEPARTURE_DATE_FILTER.ALL_TIME
            : filter.date,
        deliveryStatusId:
          typeof filter.deliveryStatusId === 'string'
            ? 0
            : filter.deliveryStatusId,
        paymentStatusId:
          typeof filter.paymentStatusId === 'string'
            ? 0
            : filter.paymentStatusId,
      },
    });
    setLoadingAction(false);
  }, 400);

  const handlerCancel = (id: string) => {
    setLoadingAction(true);
    cancelParcelMutation({
      variables: { id },
    })
      .then((response) => {
        if (
          response &&
          response.data &&
          response.data.cancelParcel &&
          response.data.cancelParcel.deliveryStatusId &&
          response.data.cancelParcel.paymentStatusId
        ) {
          setParcelList((prevState) => {
            return prevState.map((parcel) => {
              if (parcel.id === id) {
                parcel.deliveryStatusId =
                  response?.data?.cancelParcel.deliveryStatusId;
                parcel.paymentStatusId =
                  response?.data?.cancelParcel.paymentStatusId;
              }
              return parcel;
            });
          });

          enqueueSnackbar(t('app.orderCancelled', { value: id }), {
            variant: 'success',
          });
        }
      })
      .catch((err) => {
        enqueueSnackbar(err.message, { variant: 'error' });
      })
      .finally(() => {
        setLoadingAction(false);
        setOpenConfirmCancel(false);
        setParcelToCancel(null);
      });
  };

  const handlePrintMultiple = () => {
    if (selected && selected.length) {
      printShipment(selected);
    }
  };

  return (
    <>
      <Typography variant='h2' align='center' style={{ marginBottom: '24px' }}>
        {t('app.allShipments').toUpperCase()}
      </Typography>
      <div className={classes.filters}>
        <div>
          <FormControl className={classes.filtersItemSelect}>
            <Select
              displayEmpty
              disableUnderline
              value={filter.date}
              onChange={(e) => {
                setFilter((state) => ({
                  ...state,
                  date: Number(e.target.value),
                }));
              }}
            >
              <MenuItem value='' disabled>
                {t('app.period')}
              </MenuItem>
              <MenuItem value={DEPARTURE_DATE_FILTER.ALL_TIME}>
                {t('app.allTime')}
              </MenuItem>
              <MenuItem value={DEPARTURE_DATE_FILTER.LAST_7}>
                {t('app.lastNDays', { value: 7 })}За последние 7 дней
              </MenuItem>
              <MenuItem value={DEPARTURE_DATE_FILTER.LAST_30}>
                {t('app.lastNDays', { value: 30 })}За последние 30 дней
              </MenuItem>
            </Select>
          </FormControl>
          <FormControl className={classes.filtersItemSelect}>
            <Select
              displayEmpty
              disableUnderline
              value={filter.deliveryStatusId}
              onChange={(e) => {
                setFilter((state) => ({
                  ...state,
                  deliveryStatusId: Number(e.target.value),
                  page: 1,
                }));
              }}
            >
              <MenuItem value='' disabled>
                {t('app.deliveryStatus')}
              </MenuItem>
              <MenuItem value={0}>{t('app.allDeliveryStatuses')}</MenuItem>
              {statuses?.getStatusesList?.DeliveryStatusList?.map((status) => {
                return status && status.id ? (
                  <MenuItem key={status.id} value={status.id}>
                    {t(DELIVERY_STATUS_BY_ID[Number(status.id)])}
                  </MenuItem>
                ) : null;
              })}
            </Select>
          </FormControl>

          <FormControl className={classes.filtersItemSelect}>
            <Select
              displayEmpty
              disableUnderline
              value={filter.paymentStatusId}
              onChange={(e) => {
                setFilter((state) => ({
                  ...state,
                  paymentStatusId: Number(e.target.value),
                  page: 1,
                }));
              }}
            >
              <MenuItem value='' disabled>
                {t('app.paymentStatus')}
              </MenuItem>
              <MenuItem value={0}>{t('app.allPaymentStatuses')}</MenuItem>
              {statuses?.getStatusesList?.PaymentStatusList?.map((status) => {
                return status && status.id ? (
                  <MenuItem key={status.id} value={status.id}>
                    {status.explain}
                  </MenuItem>
                ) : null;
              })}
            </Select>
          </FormControl>

          <FilterSelectLimitItems
            value={filter.itemsLimit}
            setFilter={setFilter}
            localStorageItemsLimitKey={
              LOCAL_STORAGE_KEYS.ITEMS_LIMIT_SHIPMENTS_ALL_HISTORY
            }
          />
        </div>

        <div className={classes.buttonsForSelected}>
          <Tooltip title={t('app.sendInfoToPrinter')}>
            <Button
              onClick={handlePrintMultiple}
              className={classes.buttonForSelected}
              size='small'
              disabled={!selected.length}
              variant='outlined'
              startIcon={<PrintIcon />}
            >
              {t('app.printSelected')}
            </Button>
          </Tooltip>

          <FilterInputSearch setFilter={setFilter} />
        </div>
      </div>

      <TableContainer component={Paper}>
        <Table aria-label='simple table'>
          <TableHead>
            <TableRow>
              <TableCell padding='none' align='center' rowSpan={2}>
                <Checkbox
                  className={classes.tableCheckbox}
                  checked={parcelList
                    .map((p) => p.id)
                    .every((pId) => selected.includes(pId))}
                  onChange={(e) =>
                    handleSelectAllCheckboxes(e, setSelected, parcelList)
                  }
                />
              </TableCell>
              <TableCell
                className={classes.tableCell}
                align='center'
                rowSpan={2}
              >
                №
              </TableCell>

              <TableCell
                className={classes.tableCell}
                align='center'
                rowSpan={2}
              >
                {t('app.recipient')}
              </TableCell>
              <TableCell
                className={classes.tableCell}
                align='center'
                rowSpan={2}
              >
                {t('app.cost')}
              </TableCell>
              <TableCell
                className={classes.tableCell}
                align='center'
                rowSpan={2}
              >
                {t('app.orderNumber')}
              </TableCell>
              <TableCell
                className={classes.tableCell}
                align='center'
                rowSpan={2}
              >
                {t('app.trackingNumber')}
              </TableCell>
              <TableCell
                className={classes.tableCell}
                align='center'
                rowSpan={2}
              >
                {t('app.status')}
              </TableCell>
              <TableCell
                className={classes.tableCell}
                align='center'
                rowSpan={2}
              >
                {t('app.payment')}
              </TableCell>
              <TableCell
                className={classes.tableCell}
                align='center'
                rowSpan={2}
              >
                {t('app.action')}
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {(!data || isLoadingGetParcels || isLoadingAction) && (
              <TableRow>
                <TableCell colSpan={15}>
                  <ShowLoadingText />
                </TableCell>
              </TableRow>
            )}
            {!isLoadingGetParcels && !isLoadingAction && !!parcelList.length
              ? parcelList.map((parcel) => {
                  return (
                    <ParcelTableRowItem
                      key={parcel.id}
                      parcel={parcel}
                      selected={selected}
                      setSelected={setSelected}
                      setOpenConfirmCancel={setOpenConfirmCancel}
                      setParcelToCancel={setParcelToCancel}
                      statusesDelivery={
                        statuses?.getStatusesList
                          ?.DeliveryStatusList as StatusDelivery[]
                      }
                      isAdmin={userData?.currentUser?.role as UserRoles}
                    />
                  );
                })
              : !!data &&
                !isLoadingGetParcels &&
                !isLoadingAction && (
                  <TableRow>
                    <TableCell colSpan={15} align='center'>
                      {t('app.noRecords')}
                    </TableCell>
                  </TableRow>
                )}
          </TableBody>
        </Table>
      </TableContainer>

      <Pagination
        className={classes.pagination}
        count={
          data?.getParcelsByType?.count
            ? Math.ceil(data?.getParcelsByType?.count / filter.itemsLimit)
            : 1
        }
        page={filter.page}
        onChange={(_, page) => {
          setFilter((state) => ({
            ...state,
            page: page,
          }));
        }}
        color='primary'
      />

      <ModalBox
        isOpen={isOpenConfirmCancel && isObject(parcelToCancel)}
        setOpen={setOpenConfirmCancel}
      >
        <Typography variant='h6'>{t('app.confirmCancelOrder')}</Typography>
        <div className={classes.boxModalButtons}>
          <Button
            variant='contained'
            onClick={() => {
              setOpenConfirmCancel(false);
              setParcelToCancel(null);
            }}
            color='secondary'
          >
            {t('app.close')}
          </Button>
          {statuses && statuses.getStatusesList && (
            <Button
              variant='contained'
              onClick={() => {
                if (isObject(parcelToCancel)) {
                  handlerCancel(parcelToCancel.id);
                }
              }}
              disabled={
                isLoadingAction ||
                (isObject(parcelToCancel) &&
                  parcelToCancel.deliveryStatusId !== statusDeliveryIdNew)
              }
            >
              {t('app.cancelOrder')}
            </Button>
          )}
        </div>
      </ModalBox>
    </>
  );
}
