import React from 'react';
import { Formik, Form } from 'formik';
import { Button, Grid, Box } from '@material-ui/core';
import { Dropzone } from '../Dropzone/Dropzone';

import {
  FragmentUserPasportFragment as PasportType,
  useUpdatePassportDataMutation,
  useAddPassportDataMutation,
} from '../../generated/graphql';
import { pasportFormShema } from '../../utils/validationSchemes';
import { FastFieldCustom } from './formUtils/FastFieldCustom';
import { FastFieldCustomCheckbox } from './formUtils/FastFieldCustomCheckbox';
import { CircularProgress } from '@material-ui/core';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

type PasportFomrType = {
  passportData?: PasportType | null;
  handleRefetch: () => void;
};

export const PasportForm = ({
  passportData,
  handleRefetch,
}: PasportFomrType) => {
  const { enqueueSnackbar } = useSnackbar();

  const { t } = useTranslation();

  const [updatePassportDataMutation, { loading: loadingData }] =
    useUpdatePassportDataMutation();
  const [addPassportDataMutation, { loading: loadingUpdate }] =
    useAddPassportDataMutation();

  if (loadingData || loadingUpdate) {
    return (
      <Box
        style={{
          height: '80vh',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress />
      </Box>
    );
  }

  return (
    <Formik
      enableReinitialize={true}
      validationSchema={pasportFormShema}
      onSubmit={(data) => {
        let input = {
          firstName: data.firstName,
          lastName: data.lastName,
          middleName: data.middleName,
          birthDay: data.birthDay,
          seriesAndNumberOfPassport: data.seriesAndNumberOfPassport,
          dateOfIssueOfTheDocument: data.dateOfIssueOfTheDocument,
          inn: data.inn,
          dataUpToDate: data.dataUpToDate,
        };
        if (passportData) {
          updatePassportDataMutation({
            variables: {
              input: input,
              file: typeof data.file === 'string' ? null : data.file,
            },
          })
            .then(() => {
              handleRefetch();
            })
            .catch((error: any) => {
              enqueueSnackbar(error.message, { variant: 'error' });
            });
        } else {
          addPassportDataMutation({
            variables: {
              input: input,
              file: data.file as any,
            },
          })
            .then(() => {
              handleRefetch();
            })
            .catch((error: any) => {
              enqueueSnackbar(error.message, { variant: 'error' });
            });
        }
      }}
      initialValues={initDataCreated(passportData)}
    >
      {({ setFieldValue }) => {
        return (
          <Form style={{ maxWidth: '900px' }}>
            <Grid container spacing={3}>
              <Grid item sm={12} md={4}>
                <FastFieldCustom
                  setFieldValue={setFieldValue}
                  name='lastName'
                  label={`${t('app.lastName')} *`}
                />
              </Grid>
              <Grid item sm={12} md={4}>
                <FastFieldCustom
                  setFieldValue={setFieldValue}
                  name='firstName'
                  label={`${t('app.firstName')} *`}
                />
              </Grid>
              <Grid item sm={12} md={4}>
                <FastFieldCustom
                  setFieldValue={setFieldValue}
                  name='middleName'
                  label={`${t('app.patronymic')} *`}
                />
              </Grid>
              <Grid container item spacing={3}>
                <Grid item md={4}></Grid>
                <Grid item md={4}></Grid>
                <Grid item sm={12} md={4}>
                  <FastFieldCustomCheckbox
                    setFieldValue={setFieldValue}
                    name='notMiddleName'
                    label={`${t('app.noPatronymic')}`}
                  />
                </Grid>
              </Grid>

              <Grid container item spacing={3}>
                <Grid item sm={12} md={4}>
                  <FastFieldCustom
                    setFieldValue={setFieldValue}
                    name='birthDay'
                    label={`${t('app.dateOfBirth')} *`}
                    maskType='99/99/9999'
                  />
                </Grid>
                <Grid item md={4}></Grid>
                <Grid item md={4}></Grid>
              </Grid>
              <Grid container item spacing={3}>
                <Grid item sm={12} md={4}>
                  <FastFieldCustom
                    setFieldValue={setFieldValue}
                    name='seriesAndNumberOfPassport'
                    label={`${t('app.passportSeriesAndNumber')} *`}
                  />
                </Grid>
                <Grid item sm={12} md={4}>
                  <FastFieldCustom
                    setFieldValue={setFieldValue}
                    name='dateOfIssueOfTheDocument'
                    label={`${t('app.dateOfDocumentIssue')} *`}
                    maskType='99/99/9999'
                  />
                </Grid>
              </Grid>
              <Grid item sm={12} md={4}>
                <FastFieldCustom
                  setFieldValue={setFieldValue}
                  name='inn'
                  label={`${t('app.tin')} *`}
                />
              </Grid>
              <Grid item sm={12} md={12}>
                <Dropzone name='file' />
                <Box style={{ marginLeft: '35px' }}>
                  {t('app.filesJpgJpegPngGif')}
                </Box>
              </Grid>
              <Grid item sm={12} md={12}>
                <FastFieldCustomCheckbox
                  setFieldValue={setFieldValue}
                  name='dataUpToDate'
                  label={t('app.confirmData')}
                />
              </Grid>
              <Grid item sm={12} md={4}>
                <Button type='submit' variant='contained'>
                  {t('app.save')}
                </Button>
              </Grid>
            </Grid>
          </Form>
        );
      }}
    </Formik>
  );
};

const initDataCreated = (passportData: PasportType | null | undefined) => {
  if (passportData) {
    return {
      firstName: passportData?.firstName,
      lastName: passportData?.lastName,
      middleName: passportData?.middleName,
      birthDay: passportData?.birthDay,
      seriesAndNumberOfPassport: passportData?.seriesAndNumberOfPassport,
      dateOfIssueOfTheDocument: passportData?.dateOfIssueOfTheDocument,
      inn: passportData?.inn,
      file: passportData?.scanPath,
      dataUpToDate: true,
      notMiddleName: passportData?.middleName ? false : true,
    };
  }
  return {
    firstName: '',
    lastName: '',
    middleName: '',
    birthDay: '',
    seriesAndNumberOfPassport: '',
    dateOfIssueOfTheDocument: '',
    inn: '',
    dataUpToDate: false,
    file: '',
    notMiddleName: false,
  };
};
