import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  title: {
    marginBottom: '2rem',
  },
  list: {
    display: 'flex',
    alignItems: 'flex-start',
    listStyle: 'none',
    flexWrap: 'wrap',
  },
  item: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '0 20px',
    marginBottom: '30px',
    width: '33%',
    boxSizing: 'border-box',
    '@media (max-width: 768px)': {
      width: '50%',
    },
  },
  disabled: {
    opacity: 0.2,
    cursor: 'default',
    textDecoration: 'none !important',
  },
  link: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-start',
    textDecoration: 'none',
    '&:hover': {
      textDecoration: 'underline',
    },
  },
  icon: {
    maxWidth: '122px',
    marginBottom: '60px',
    '@media (max-width: 768px)': {
      marginBottom: '10px',
      maxWidth: '80px',
    },
  },
  iconWarehouse: {
    maxWidth: '103px',
    marginBottom: '86px',
    marginTop: '14px',
    '@media (max-width: 768px)': {
      marginBottom: '10px',
      maxWidth: '80px',
    },
  },
  materialIcon: {
    color: 'black',
    fontSize: '9rem',
    maxWidth: '122px',
    maxHeight: '175px',
    marginBottom: '60px',
    '@media (max-width: 768px)': {
      marginBottom: '10px',
      maxWidth: '80px',
      fontSize: '5rem',
    },
  },
  text: {
    color: '#292d30',
    fontSize: '16px',
    textAlign: 'center',
    maxWidth: '175px',
    '@media (max-width: 768px)': {
      fontSize: '11px',
      maxWidth: '80px',
    },
  },
}));
