import { FastField, FieldProps } from 'formik';
import {
  FormControl,
  FormHelperText,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  Tooltip,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import React from 'react';
import { TFormikSetFieldValueDebounce } from '../../../interfaces';
import { useTranslation } from 'react-i18next';

const PaymentOfTaxes: React.FC<{
  fieldName?: string;
  unitIndex?: number;
  types: any;
  setFieldValue: TFormikSetFieldValueDebounce;
}> = ({ fieldName, unitIndex, types, setFieldValue }) => {
  const theme = useTheme();
  const hidden = useMediaQuery(theme.breakpoints.down('md'));

  const { t } = useTranslation();

  return (
    <FastField name={fieldName}>
      {({ field: { value, ...field }, meta }: FieldProps) => (
        <FormControl error={!!(meta.touched && meta.error)}>
          <Grid container justify='space-between'>
            <InputLabel htmlFor='input-payment-of-taxes'>
              {t('app.paymentTaxesDuties')}
            </InputLabel>
            {hidden ? null : (
              <Tooltip
                style={{ padding: 0 }}
                title={t('app.paymentTaxesDuties')}
              >
                <IconButton>
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            )}
          </Grid>

          <Select
            id='input-payment-of-taxes'
            value={value}
            onChange={(e) =>
              setFieldValue(fieldName || 'paymentOfTaxes', e.target.value)
            }
          >
            {Object.entries(types)?.map(([item]) => (
              <MenuItem key={item} value={item}>
                {types[item]?.ru}
              </MenuItem>
            ))}
          </Select>
          {meta.touched && meta.error && (
            <FormHelperText>{meta.error}</FormHelperText>
          )}
        </FormControl>
      )}
    </FastField>
  );
};

export default PaymentOfTaxes;
