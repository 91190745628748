import React, { useState } from 'react';
import {
  Button,
  Divider,
  Grid,
  List,
  ListItem,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import EditIcon from '@material-ui/icons/Edit';
import CancelIcon from '@material-ui/icons/Cancel';
import ModalBox from '../../../components/ModalBox/ModalBox';
import { useSnackbar } from 'notistack';
import { useApolloClient } from '@apollo/client';
import { NavLink, Redirect, useHistory, useParams } from 'react-router-dom';
import {
  getStatusIdByName,
  sumByField,
  sumQuantityByField,
  sumTotalVolumetricWeight,
  TRoutes,
} from '../../../utils/helpers';
import moment from 'moment';
import useStyles from './styles';
import FileCopyIcon from '@material-ui/icons/FileCopy';

import {
  ConvertStatusIdToText,
  GetCarrierCodeImage,
  ShowLoadingText,
} from '../../../utils/helperComponents';
import getStatusesList from '../../../GraphQL/queries/getStatusesList';
import {
  GetStatusesListQuery,
  GetStatusesListQueryVariables,
  Parcel,
  ParcelUnit,
  StatusDelivery,
  useCancelParcelMutation,
  useGetParcelQuery,
} from '../../../generated/graphql';
import {
  COLORS,
  DATE_FORMAT,
  DELIVERY_STATUS_BY_ID,
  ERRORS,
  PAYMENT_ORDER_STATUS_BY_ID,
  TEXT,
} from '../../../utils/constants';
import { BoxCentered } from '../../../components/BoxCentered/BoxCentered';
import ShipmentDetailsContact from '../../../components/ShimpentDetails/ShimpentDetailsContact/ShimpentDetailsContact';
import {
  EXPORT_REASONS,
  PAYMENT_OF_TAXES_IMPORT,
} from '../../../utils/constants';
import { useTranslation } from 'react-i18next';

const ShipmentDetail: React.FC = () => {
  const client = useApolloClient();
  const classes = useStyles();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const isMediaQueryXs = useMediaQuery(theme.breakpoints.down('xs'));
  const { t } = useTranslation();

  const [isLoadingAction, setLoadingAction] = useState(false);
  const [isOpenConfirmEdit, setOpenConfirmEdit] = useState(false);
  const [isOpenConfirmCancel, setOpenConfirmCancel] = useState(false);
  const [cancelParcelMutation] = useCancelParcelMutation();
  const { id } = useParams<{ id: string }>();
  const isValidId = +id && Number.isInteger(Number(id));

  const statuses = client.readQuery<
    GetStatusesListQuery,
    GetStatusesListQueryVariables
  >({
    query: getStatusesList,
  });

  const {
    loading,
    error,
    data: dataParcelQuery,
  } = useGetParcelQuery({
    variables: { id },
    fetchPolicy: 'network-only',
  });

  if (!isValidId) return <Redirect to={TRoutes.NOT_FOUND} />;
  if (loading)
    return (
      <BoxCentered>
        <ShowLoadingText name={t('app.details2')} />
      </BoxCentered>
    );
  if (error) enqueueSnackbar(error.message, { variant: 'error' });
  if (!dataParcelQuery || !dataParcelQuery.parcel)
    return <BoxCentered>{t('app.noData2')}</BoxCentered>;

  const parcel = dataParcelQuery.parcel;
  const units: ParcelUnit[] = [];
  parcel.packages.forEach((p) => {
    if (p && p.units) {
      p.units.forEach((u) => u && units.push(u));
    }
  });

  const handlerCancel = (id: string) => {
    setLoadingAction(true);
    cancelParcelMutation({
      variables: { id },
    })
      .then(() => {
        enqueueSnackbar(t('app.orderSuccessfullyCanceled'), {
          variant: 'success',
        });
        setLoadingAction(false);
      })
      .catch((err) => {
        enqueueSnackbar(err.message, { variant: 'error' });
        setLoadingAction(false);
      });
  };

  const isVatShipment =
    parcel?.receiver?.country?.iso !== parcel?.sender?.country?.iso;

  return (
    <>
      <Typography variant='h2' align='center' className={classes.mainTitle}>
        {t('app.viewOrder')}
      </Typography>
      <List>
        <ListItem>
          <Typography variant='h6'>
            {t('app.orderNumber')}:{' '}
            {parcel?.barcode?.substring(0, parcel?.barcode.length - 4)}
          </Typography>
        </ListItem>
        <ListItem className={classes.itemInfo}>
          {t('app.creationDate')}:{' '}
          {moment(Number(parcel.createdAt)).format(DATE_FORMAT)}
        </ListItem>
        <ListItem className={classes.itemInfo}>
          {t('app.variant')}: {parcel?.typeDelivery?.name}
        </ListItem>
        {parcel && parcel.consolidationTrackNumber && (
          <ListItem className={classes.itemInfo}>
            {t('app.consolidationNumber')}:{' '}
            <BoxCentered>
              <GetCarrierCodeImage
                carrierCode={
                  parcel?.consolidationTrackNumber?.service as string
                }
              />
              <Tooltip
                title={`${t(
                  'app.trackShipment',
                )} ${parcel?.consolidationTrackNumber?.service?.toUpperCase()} ${
                  parcel?.consolidationTrackNumber?.consolidationTrackNumber
                }`}
              >
                <NavLink
                  to={`${TRoutes.TRACK}/${parcel?.consolidationTrackNumber?.service}/${parcel.consolidationTrackNumber.consolidationTrackNumber}`}
                >
                  <span>
                    {parcel.consolidationTrackNumber.consolidationTrackNumber}
                  </span>
                </NavLink>
              </Tooltip>
            </BoxCentered>
          </ListItem>
        )}
        <ListItem className={classes.itemInfo}>
          {t('app.deliveryStatus')}:&nbsp;
          <ConvertStatusIdToText
            type={'StatusDelivery'}
            parcel={parcel as Parcel}
            defaultText={t(
              DELIVERY_STATUS_BY_ID[Number(parcel.deliveryStatusId)],
            )}
          />
        </ListItem>
        <ListItem className={classes.itemInfo}>
          {t('app.paymentStatus')}:&nbsp;
          <ConvertStatusIdToText
            type={'StatusPayment'}
            parcel={parcel as Parcel}
            defaultText={t(
              PAYMENT_ORDER_STATUS_BY_ID[Number(parcel.paymentStatusId)],
            )}
          />
        </ListItem>
        {parcel.trackNumber && parcel.carrierCode && (
          <ListItem className={classes.itemInfo}>
            {t('app.trackingNumber')}:
            <Tooltip title={parcel.carrierCode.toUpperCase()}>
              <GetCarrierCodeImage carrierCode={parcel.carrierCode} />
            </Tooltip>
            <NavLink
              to={TRoutes.TRACK_WITH_NUMBER_AND_CARRIED_CODE.replace(
                ':carrierCodeParam',
                parcel.carrierCode,
              ).replace(':trackNumberParam', parcel.trackNumber)}
            >
              <span>{parcel.trackNumber}</span>
            </NavLink>
          </ListItem>
        )}
        {parcel.senderNotes && (
          <ListItem className={classes.itemInfo}>
            {t('app.senderNote')}:&nbsp;{parcel.senderNotes}
          </ListItem>
        )}
        {parcel.senderMark && (
          <ListItem className={classes.itemInfo}>
            {t('app.orderNumberLabel')}:&nbsp;{parcel.senderMark}
          </ListItem>
        )}
      </List>
      <Grid container spacing={0} alignItems='stretch'>
        <Grid item sm={6} xs={12}>
          <ShipmentDetailsContact type='sender' contact={parcel?.sender} />
        </Grid>
        <Grid item sm={6} xs={12}>
          <ShipmentDetailsContact type='receiver' contact={parcel?.receiver} />
        </Grid>
      </Grid>
      <List>
        <ListItem>
          <Typography variant='h6'>{t('app.cargoInformation')}</Typography>
        </ListItem>
        {parcel?.packages?.map((place, index) => (
          <ListItem className={classes.itemInfo}>
            {`${t('app.cargoPlace')} ${index + 1}: ${place?.lengthCm} x ${
              place?.widthCm
            } x ${place?.heightCm} ${t('app.cm')}, ${place?.weightKg} ${t(
              'app.kg',
            )}`}
          </ListItem>
        ))}
        <ListItem className={classes.itemInfo}>
          {t('app.numberOfItems')}: {units.length}
        </ListItem>
        <ListItem className={classes.itemInfo}>
          <Tooltip title={t(TEXT.GROSS)}>
            <span>{t('app.totalGrossWeight')}</span>
          </Tooltip>
          :&nbsp;
          {sumByField(
            parcel.packages,
            'weightKg',
            (val) => `${val} ${t('app.kg')}`,
          )}
        </ListItem>
        <ListItem className={classes.itemInfo}>
          <Tooltip title={t(TEXT.NET)}>
            <span>{t('app.totalNetWeight')}</span>
          </Tooltip>
          :&nbsp;
          {sumQuantityByField(
            units,
            'netWeight',
            (val) => `${val} ${t('app.kg')}`,
          )}
        </ListItem>
        <ListItem className={classes.itemInfo}>
          <Tooltip title={t('app.volumeFormula')}>
            <span>{t('app.totalVolumetricWeight')}</span>
          </Tooltip>
          :&nbsp;
          {sumTotalVolumetricWeight(
            parcel.packages,
            (val) => `${val} ${t('app.kg')}`,
          )}
        </ListItem>
        <ListItem className={classes.itemInfo}>
          {t('app.chargeableWeightDeclared')}:&nbsp;
          {parcel.declaredWeight} {t('app.kg')}
        </ListItem>
        {parcel.actualWeight && (
          <ListItem className={classes.itemInfo}>
            {t('app.chargeableWeightActual')}:&nbsp;
            {parcel.actualWeight} {t('app.kg')}
          </ListItem>
        )}
        {parcel.actualWeight &&
          parcel.declaredWeight &&
          parcel.actualWeight !== parcel.declaredWeight && (
            <ListItem className={classes.itemInfo}>
              {t('app.chargeableWeightDifference')}:&nbsp;
              {(parcel.actualWeight - parcel.declaredWeight).toFixed(2)}
              {t('app.kg')}
            </ListItem>
          )}
        <ListItem className={classes.itemInfo}>
          {t('app.itemCost2')}:&nbsp;
          {units &&
            // @ts-ignore
            sumQuantityByField(
              units,
              'price',
              (val) => `${val} ${(parcel.currency || '').toUpperCase()}`,
            )}
        </ListItem>
        {isVatShipment ? (
          <>
            <ListItem className={classes.itemInfo}>
              {t('app.exportReason')}:
              <span style={{ marginLeft: '5px' }}>
                {parcel?.exportReason
                  ? //@ts-ignore
                    EXPORT_REASONS[parcel.exportReason]?.ru
                  : null}
              </span>
            </ListItem>
            <ListItem className={classes.itemInfo}>
              {t('app.taxAndDutyPayment')}:
              <span style={{ marginLeft: '5px' }}>
                {parcel?.paymentOfTaxes
                  ? //@ts-ignore
                    PAYMENT_OF_TAXES_IMPORT[parcel.paymentOfTaxes]?.ru
                  : null}
              </span>
            </ListItem>
            <ListItem className={classes.itemInfo}>
              {t('app.taxIDNumber')}:{parcel?.vatID}
            </ListItem>
          </>
        ) : null}
        {parcel.additionalInsurance ? (
          <ListItem className={classes.itemInfo}>
            {t('app.additionalInsurance')}:&nbsp;
            <span style={{ color: COLORS.GREEN }}>{t('app.yes')}</span>
          </ListItem>
        ) : null}
        {parcel.additionalInsurance && (
          <ListItem className={classes.itemInfo}>
            {t('app.insuranceCost')}:&nbsp;
            {parcel.insuranceAmount}&nbsp;USD
          </ListItem>
        )}
        {parcel.signature ? (
          <ListItem className={classes.itemInfo}>
            {t('app.recipientSignature')}:&nbsp;
            <span style={{ color: COLORS.GREEN }}>{t('app.yes')}</span>
          </ListItem>
        ) : null}
        <ListItem className={classes.itemInfo}>
          {t('app.shipmentCostDeclared')}:&nbsp;
          {parcel.declaredAmount} {parcel.orderCostCurrency}
        </ListItem>
        {parcel.actualAmount && (
          <ListItem className={classes.itemInfo}>
            {t('app.shipmentCostActual')}:&nbsp;
            {parcel.actualAmount} {parcel.orderCostCurrency}
          </ListItem>
        )}
        {parcel.actualAmount &&
          parcel.declaredAmount &&
          parcel.actualAmount !== parcel.declaredAmount && (
            <ListItem className={classes.itemInfo}>
              {t('app.shipmentCostDifference')}:&nbsp;
              {parcel.actualAmount - parcel.declaredAmount}{' '}
              {parcel.orderCostCurrency}
            </ListItem>
          )}
      </List>

      <TableContainer>
        <Table>
          <caption style={{ captionSide: 'top' }}>
            {t('app.orderContents')}
          </caption>
          <TableHead>
            <TableRow>
              <TableCell align='center'>№</TableCell>
              <TableCell align='center'>{t('app.description')}</TableCell>
              <TableCell align='center'>{t('app.quantity2')}</TableCell>
              <TableCell align='center'>
                {t('app.weight')} {t('app.kg')}
              </TableCell>
              <TableCell align='center'>
                {t('app.price')} {parcel?.currency?.toUpperCase()}
              </TableCell>
              <TableCell align='center'>{t('app.brand')}</TableCell>
              <TableCell align='center'>{t('app.hsCode2')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {parcel?.packages?.map((packageItem, packageIndex) => {
              return (
                packageItem?.units &&
                packageItem.units.map((unit, unitIndex) => {
                  return (
                    unit && (
                      <TableRow>
                        <TableCell align='center'>
                          {packageIndex + 1}.{unitIndex + 1}
                        </TableCell>
                        <TableCell align='center'>
                          {unit.nameRU} / {unit.nameEN}
                        </TableCell>
                        <TableCell align='center'>{unit.quantity}</TableCell>
                        <TableCell align='center'>{unit.netWeight}</TableCell>
                        <TableCell align='center'>{unit.price}</TableCell>
                        <TableCell align='center'>{unit.tradeMark}</TableCell>
                        <TableCell align='center'>
                          {unit.code && (
                            <Tooltip title={t('app.opensInNewWindow')}>
                              <a
                                target='_blank'
                                rel='noopener noreferrer'
                                href={`https://tnved.info/TnvedTree?Code=${unit.code}`}
                              >
                                {unit.code}
                              </a>
                            </Tooltip>
                          )}
                        </TableCell>
                      </TableRow>
                    )
                  );
                })
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <div className={classes.wrapButtons}>
        <Tooltip title={t('app.goToAllOrdersList')}>
          <Button
            fullWidth={isMediaQueryXs}
            variant='contained'
            onClick={() => history.push(TRoutes.SHIPMENTS)}
            startIcon={<ArrowBackIcon />}
          >
            {t('app.back')}
          </Button>
        </Tooltip>
        <Divider orientation={isMediaQueryXs ? 'horizontal' : 'vertical'} />
        <Tooltip title={t('app.openShipmentFormAndFill')}>
          <Button
            fullWidth={isMediaQueryXs}
            variant='contained'
            onClick={() =>
              history.push(TRoutes.SHIPMENT_EDIT_COPY.replace(':id', parcel.id))
            }
            startIcon={<FileCopyIcon />}
          >
            {t('app.copy')}
          </Button>
        </Tooltip>
        <Divider orientation={isMediaQueryXs ? 'horizontal' : 'vertical'} />
        <Tooltip title={t('app.editThisOrder')}>
          <Button
            fullWidth={isMediaQueryXs}
            variant='contained'
            onClick={() => setOpenConfirmEdit(true)}
            startIcon={<EditIcon style={{ color: COLORS.ORANGE }} />}
            disabled={
              isLoadingAction ||
              !!parcel.actualAmount ||
              parcel.deliveryStatusId !==
                getStatusIdByName(
                  statuses?.getStatusesList
                    ?.DeliveryStatusList as StatusDelivery[],
                  'new',
                ) ||
              !!parcel?.trackNumber
            }
          >
            {t('app.change')}
          </Button>
        </Tooltip>
        <Divider orientation={isMediaQueryXs ? 'horizontal' : 'vertical'} />
        <Tooltip title={t('app.cannotAmendAfterCancel')}>
          <Button
            fullWidth={isMediaQueryXs}
            variant='contained'
            onClick={() => {
              if (parcel.sentToWarehouseId) {
                enqueueSnackbar(ERRORS.CANT_CANCEL_PARCEL_WITH_IS_SENT, {
                  variant: 'error',
                });
              } else {
                setOpenConfirmCancel(true);
              }
            }}
            startIcon={<CancelIcon style={{ color: COLORS.RED }} />}
            disabled={
              isLoadingAction ||
              !!parcel.actualAmount ||
              parcel.deliveryStatusId !==
                getStatusIdByName(
                  statuses?.getStatusesList
                    ?.DeliveryStatusList as StatusDelivery[],
                  'new',
                ) ||
              !!parcel?.trackNumber
            }
          >
            {t('app.cancel')}
          </Button>
        </Tooltip>
      </div>

      <ModalBox isOpen={isOpenConfirmEdit} setOpen={setOpenConfirmEdit}>
        <Typography variant='h6'>{t('app.goToOrderEditingPage')}</Typography>
        <div className={classes.boxModalButtons}>
          <Button
            variant='contained'
            onClick={() => setOpenConfirmEdit(false)}
            color='secondary'
          >
            {t('app.close')}
          </Button>
          <Button
            variant='contained'
            onClick={() => history.push(`${TRoutes.SHIPMENTS}/${parcel.id}`)}
          >
            {t('app.go')}
          </Button>
        </div>
      </ModalBox>

      <ModalBox isOpen={isOpenConfirmCancel} setOpen={setOpenConfirmCancel}>
        <Typography variant='h6'>{t('app.cancelOrderConfirmation')}</Typography>
        <div className={classes.boxModalButtons}>
          <Button
            variant='contained'
            onClick={() => setOpenConfirmCancel(false)}
            color='secondary'
          >
            {t('app.close')}
          </Button>
          <Button
            variant='contained'
            onClick={() => handlerCancel(parcel.id)}
            disabled={
              isLoadingAction ||
              parcel.deliveryStatusId !==
                getStatusIdByName(
                  statuses?.getStatusesList
                    ?.DeliveryStatusList as StatusDelivery[],
                  'new',
                )
            }
          >
            {t('app.cancelOrder')}
          </Button>
        </div>
      </ModalBox>
    </>
  );
};

export default ShipmentDetail;
