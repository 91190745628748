import React, { useEffect, useState } from 'react';
import { useDropzone } from 'react-dropzone';
import { Box, FormHelperText } from '@material-ui/core';
import { useFormikContext, useField } from 'formik';
import AttachFileIcon from '@material-ui/icons/AttachFile';
import ClearIcon from '@material-ui/icons/Clear';
import { t } from 'i18next';

export const Dropzone = ({ name, label = t('app.uploadPassportScan') }) => {
  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    noClick: true,
    noKeyboard: true,
    maxSize: 8388608000, // 100mb
  });

  const { setFieldValue } = useFormikContext();
  const [selectState, setSelectState] = useState(false);

  // eslint-disable-next-line no-unused-vars
  const [field, meta, helpers] = useField({ name });

  useEffect(() => {
    setSelectState(acceptedFiles.length !== 0);
    if (acceptedFiles[0] === undefined) return;
    setFieldValue(name, acceptedFiles[0]);
  }, [acceptedFiles, setFieldValue, name]);

  const removeFile = () => {
    acceptedFiles.length = 0;
    acceptedFiles.splice(0, acceptedFiles.length);
    setFieldValue(name, acceptedFiles);
    setSelectState(false);
  };

  return (
    <Box className={''}>
      {!selectState ? (
        <>
          <Box {...getRootProps({ className: '' })}>
            <input {...getInputProps()} />
          </Box>
          <Box style={{ display: 'flex', alignItems: 'center' }}>
            <AttachFileIcon
              onClick={open}
              style={{
                cursor: 'pointer',
                marginRight: '15px',
                marginLeft: '-5px',
              }}
            />
            {label}
          </Box>
        </>
      ) : (
        <Box style={{ display: 'flex', alignItems: 'center' }}>
          <ClearIcon
            onClick={removeFile}
            style={{ cursor: 'pointer', marginRight: '15px' }}
          />{' '}
          {acceptedFiles[0]?.name}
        </Box>
      )}
      {meta?.touched && meta?.error && (
        <FormHelperText style={{ color: 'red' }}>{meta?.error}</FormHelperText>
      )}
    </Box>
  );
};
