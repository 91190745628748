import React from 'react';
import {
  Box,
  CircularProgress,
  Divider,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import TableBodyItem from './TableBodyItem';
import useStyles from './styles';
import { CalculatorResponse } from '../../generated/graphql';
import { FormikValues } from 'formik';
import {
  TFormikSetFieldValueDebounce,
  TSetStateNumber,
  TSetStateString,
} from '../../interfaces';
import { useTranslation } from 'react-i18next';

interface ITableRezult {
  responseData: CalculatorResponse;
  isCalculatorPage: boolean;
  values: FormikValues;
  setFieldValue: TFormikSetFieldValueDebounce;
  setRemoteAreaCost: React.Dispatch<React.SetStateAction<number | undefined>>;
  setDeclaredAmount: TSetStateNumber | undefined;
  setGlobalProductCode: TSetStateString | undefined;
  isLoading: boolean;
}

export const TableRezult = ({
  responseData,
  isCalculatorPage,
  values,
  setFieldValue,
  setRemoteAreaCost,
  setDeclaredAmount,
  setGlobalProductCode,
  isLoading,
}: ITableRezult) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell className={classes.tableCell}>
              {t('app.shippingOption')}
            </TableCell>
            <TableCell className={classes.tableCell}>
              {t('app.deliveryTime')}
            </TableCell>
            <TableCell className={classes.tableCell}>{t('app.cost')}</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {
            <>
              {responseData?.data?.map((variant, index) => {
                if (!variant) return null;
                return (
                  <TableBodyItem
                    labelDataEasyPost={{
                      shipmentId: variant?.shipmentId as string,
                      rateId: variant?.rateId as string,
                    }}
                    indexValue={
                      //@ts-ignore
                      variant?.typeDelivery + variant?.name
                    }
                    key={index}
                    typeDeliveryId={variant?.typeDelivery}
                    summary_amount={variant?.summary_amount}
                    fuelSurcharge={variant?.fuelSurcharge}
                    dollarRate={variant?.dollarRate}
                    remoteArea={variant?.remoteArea}
                    name={variant.name}
                    days_to_delivery={variant?.days_to_delivery}
                    isCalculatorPage={isCalculatorPage}
                    values={values}
                    setFieldValue={setFieldValue}
                    setDeclaredAmount={setDeclaredAmount}
                    setRemoteAreaCost={setRemoteAreaCost}
                    errors={variant?.errors}
                    info={variant?.info}
                    priceCurrency={variant?.priceCurrency}
                    isLoading={isLoading}
                  />
                );
              })}
              {responseData?.userAccount?.rows?.length ? (
                <>
                  <Box mt={3.25} mb={3.25}>
                    <Divider />
                  </Box>
                  {responseData.userAccount.rows.map((variant, index) => {
                    if (!variant) return null;
                    return (
                      <TableBodyItem
                        labelDataEasyPost={{
                          shipmentId: variant?.shipmentId as string,
                          rateId: variant?.rateId as string,
                        }}
                        index={index}
                        //@ts-ignore
                        indexValue={variant?.typeDelivery + `.${index}`}
                        key={index}
                        typeDeliveryId={variant?.TypeDeliveryId + `.${index}`}
                        summary_amount={variant?.amount}
                        name={variant?.TypeDeliveryName}
                        accountName={variant?.accountName}
                        days_to_delivery={variant?.day_to_delivery}
                        isCalculatorPage={isCalculatorPage}
                        values={values}
                        setFieldValue={setFieldValue}
                        setDeclaredAmount={setDeclaredAmount}
                        setRemoteAreaCost={setRemoteAreaCost}
                        setGlobalProductCode={setGlobalProductCode}
                        priceCurrency={variant?.priceCurrency}
                        isLoading={isLoading}
                      />
                    );
                  })}
                </>
              ) : null}
            </>
          }
          {isLoading ? (
            <div className={classes.circularProgress}>
              <CircularProgress size={40} />
            </div>
          ) : null}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
