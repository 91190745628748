import { t } from 'i18next';
import client from '../../../GraphQL/client';
import { receiverAndSenderOnParcelFragment } from '../../../GraphQL/fragments/receiverAndSenderOnParcelFragment';

const printShipment = (id: string | string[]) => {
  const makeParcelHtml = (parcel: any) => {
    return `
            <div>Заказ ${parcel.id}</div>
            <br/>
            <div style="display: flex; align-items: flex-start; justify-content: space-between">
                 <div>
                    <div><strong>Отправитель:</strong></div>
                    <div>${
                      parcel.sender.name
                        ? `${t('app.name2')}: ${parcel.sender.name}`
                        : ''
                    }</div>
                    <div>${
                      parcel.sender.company
                        ? `${t('app.company')}: ${parcel.sender.company}`
                        : ''
                    }</div>
                    <div>${
                      parcel.sender.country
                        ? `${t('app.country')}: ${parcel.sender.country}`
                        : ''
                    }</div>
                    <div>${
                      parcel.sender.address
                        ? `${t('app.address')} : ${parcel.sender.address}`
                        : ''
                    }</div>
                    <div>${
                      parcel.sender.address2
                        ? `${t('app.addressNum', { value: 2 })}: ${
                            parcel.sender.address2
                          }`
                        : ''
                    }</div>
                    <div>${
                      parcel.sender.address3
                        ? `${t('app.addressNum', { value: 3 })}: ${
                            parcel.sender.address3
                          }`
                        : ''
                    }</div>
                    <div>${
                      parcel.sender.zipCode
                        ? `${t('app.postalCode')}: ${parcel.sender.zipCode}`
                        : ''
                    }</div>
                    <div>${
                      parcel.sender.city
                        ? `${t('app.city')}: ${parcel.sender.city}`
                        : ''
                    }</div>
                    <div>${
                      parcel.sender.state
                        ? `${t('app.stateRegion')}: ${parcel.sender.state}`
                        : ''
                    }</div>
                    <div>${
                      parcel.sender.phone
                        ? `${t('app.phone')}: ${parcel.sender.phone}`
                        : ''
                    }</div>
                </div>
                <div>
                    <div><strong>${t('app.recipient')}:</strong></div>
                    <div>${
                      parcel.receiver.name
                        ? `${t('app.name2')}: ${parcel.receiver.name}`
                        : ''
                    }</div>
                    <div>${
                      parcel.receiver.company
                        ? `${t('app.company')}: ${parcel.receiver.company}`
                        : ''
                    }</div>
                    <div>${
                      parcel.receiver.country.name
                        ? `${t('app.country')}: ${parcel.receiver.country.name}`
                        : ''
                    }</div>
                    <div>${
                      parcel.receiver.address
                        ? `${t('app.address')} : ${parcel.receiver.address}`
                        : ''
                    }</div>
                    <div>${
                      parcel.receiver.address2
                        ? `${t('app.addressNum', { value: 2 })}: ${
                            parcel.receiver.address2
                          }`
                        : ''
                    }</div>
                    <div>${
                      parcel.receiver.address3
                        ? `${t('app.addressNum', { value: 3 })}: ${
                            parcel.receiver.address3
                          }`
                        : ''
                    }</div>
                    <div>${
                      parcel.receiver.zipCode
                        ? `${t('app.postalCode')}: ${parcel.receiver.zipCode}`
                        : ''
                    }</div>
                    <div>${
                      parcel.receiver.city
                        ? `${t('app.city')}: ${parcel.receiver.city}`
                        : ''
                    }</div>
                    <div>${
                      parcel.receiver.state
                        ? `${t('app.stateRegion')}: ${parcel.receiver.state}`
                        : ''
                    }</div>
                    <div>${
                      parcel.receiver.phone
                        ? `${t('app.phone')}: ${parcel.receiver.phone}`
                        : ''
                    }</div>
                    <div>${
                      parcel.receiver.email
                        ? `E-Mail: ${parcel.receiver.email}`
                        : ''
                    }</div>
                </div>
            </div>
        `;
  };

  const closeIframeScript = `
    <script>
      window.onload = function() {
        window.print();
        window.onafterprint = function() {
          window.close();
        };
      };
    </script>
  `;

  if (typeof id === 'string') {
    const parcel = client.readFragment({
      id: `Parcel:${id}`,
      fragment: receiverAndSenderOnParcelFragment,
    });

    if (parcel) {
      const iframe = document.createElement('iframe');
      iframe.setAttribute('id', 'printIFrame');
      iframe.setAttribute('name', 'printIFrame');
      document.body.appendChild(iframe);
      // @ts-ignore
      const printWindow = window.frames['printIFrame'];
      printWindow.document.write(`<body onload="window.print()">
                    ${makeParcelHtml(parcel)}
                </body>
            `);
      printWindow.document.close();
    }
  } else if (Array.isArray(id)) {
    const parcels = id.map((parcelId) => {
      return client.readFragment({
        id: `Parcel:${parcelId}`,
        fragment: receiverAndSenderOnParcelFragment,
      });
    });

    if (parcels) {
      const iframe = document.createElement('iframe');
      iframe.setAttribute('id', 'printIFrame');
      iframe.setAttribute('name', 'printIFrame');
      iframe.style.position = 'absolute';
      iframe.style.left = '-1000px';
      document.body.appendChild(iframe);
      // @ts-ignore
      const printWindow = window.frames['printIFrame'];
      let html = '';
      parcels.forEach((parcel) => {
        html += makeParcelHtml(parcel);
      });
      printWindow.document.write(`<body>
                    ${html}
                    ${closeIframeScript}
                </body>
            `);
      printWindow.document.close();
    }
  } // end isArray
};

export { printShipment };
