import { useApolloClient } from '@apollo/client';
import { useEffect } from 'react';

import { match } from 'react-router';
import {
  GetMyProductListQuery,
  GetMyProductListQueryVariables,
  useGetContactsQuery,
  useGetCurrentUserQuery,
  useGetParcelLazyQuery,
} from '../../../../../generated/graphql';
import { QUERY_MY_PRODUCT_LIST } from '../../../../../GraphQL/queries/getMyProductList';

type useQueryDataWrapperType = {
  isCreation?: match<{}> | null;
  id?: string;
};

export const useQueryDataWrapper = ({
  isCreation,
  id,
}: useQueryDataWrapperType) => {
  const client = useApolloClient();
  const isValidId = id && !isNaN(+id) && Number.isInteger(Number(id));

  const [getParcelLazyQuery, { error: errorLazyParcel, data: dataLazyParcel }] =
    useGetParcelLazyQuery();

  const { error: errorContacts, data: contactsData } = useGetContactsQuery();

  const { error: errorCurrentUser, data: currentUserData } =
    useGetCurrentUserQuery();

  useEffect(() => {
    if (isCreation) return;

    if (id && isValidId) {
      getParcelLazyQuery({ variables: { id } });
    }
    // eslint-disable-next-line
  }, []);

  const cachedProducts = client.readQuery<
    GetMyProductListQuery,
    GetMyProductListQueryVariables
  >({
    query: QUERY_MY_PRODUCT_LIST,
  });

  return {
    getParcelLazyQuery,
    errorLazyParcel,
    dataLazyParcel,
    errorContacts,
    contactsData,
    errorCurrentUser,
    currentUserData,
    isValidId,
    cachedProducts,
  };
};
