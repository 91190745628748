import React, { useEffect, useState } from 'react';
import { SnackbarMessage, useSnackbar } from 'notistack';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  Grid,
  IconButton,
  Tooltip,
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import ThumbUpAltIcon from '@material-ui/icons/ThumbUpAlt';
import { If, When } from 'react-if';
import fetchCalculator from '../../utils/fetchCalculator';
import useStyles from './styles';
import ExposureIcon from '@material-ui/icons/Exposure';
import { sumByField, sumTotalVolumetricWeight } from '../../utils/helpers';
import {
  TFormikSetFieldValueDebounce,
  TSetStateBoolean,
  TSetStateNumber,
  TSetStateString,
} from '../../interfaces';
import { FormikErrors, FormikValues } from 'formik';
import { MAX_PARCEL_WEIGHT } from '../../utils/constants';
import {
  CalculatorResponse,
  GetCurrentUserQuery,
  GetCurrentUserQueryVariables,
  Mode_Types,
  Package,
  ParcelUnit,
} from '../../generated/graphql';
import { useApolloClient } from '@apollo/client';
import { QUERY_CURRENT_USER } from '../../GraphQL/queries/getCurrentUser';
import { BoxCentered } from '../BoxCentered/BoxCentered';
import { TableRezult } from './TableRezult';
import { useTranslation } from 'react-i18next';

const getInitialData: () => CalculatorResponse = () => ({
  weight: 0,
  volume_weight: 0,
  weight_to_pay: 0,
  special_tax: 0,
  insurance: 0,
  remoteArea: 0,
  data: [],
  userAccount: {
    //@ts-ignore
    errors: '',
    rows: [],
  },
});

const CalculationResults: React.FC<{
  errors: FormikErrors<any>;
  values: FormikValues;
  setLoading: TSetStateBoolean;
  setDeclaredWeight?: TSetStateNumber;
  setDeclaredAmount?: TSetStateNumber;
  setIsFetchCalculated: TSetStateBoolean;
  isLoading: boolean;
  isFetchCalculated: boolean;
  setFieldValue: TFormikSetFieldValueDebounce;
  isCalculatorPage: boolean;
  setGlobalProductCode?: TSetStateString;
  simple?: boolean;
  mode?: Mode_Types;
  isFulFilment?: boolean;
}> = ({
  errors,
  values,
  setLoading,
  setDeclaredWeight,
  setDeclaredAmount,
  setIsFetchCalculated,
  isLoading,
  isFetchCalculated,
  setFieldValue,
  isCalculatorPage,
  setGlobalProductCode,
  simple,
  mode,
  isFulFilment,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const client = useApolloClient();

  const { t } = useTranslation();

  const [responseData, setResponseData] = useState(getInitialData());
  const [isShowError, setIsShowError] = useState(false);
  const [remoteAreaCost, setRemoteAreaCost] = useState<number>();

  const units: ParcelUnit[] = [];
  values?.packages?.forEach((p: Package) => {
    p?.units?.forEach((u) => {
      u && units.push(u);
    });
  });

  const cachedCurrentUser = client.readQuery<
    GetCurrentUserQuery,
    GetCurrentUserQueryVariables
  >({
    query: QUERY_CURRENT_USER,
  });

  const handleGetListOfDeliveryOptionsQuery = () => {
    setFieldValue('typeDeliveryId', {
      typeDeliveryId: null,
      indexValue: null,
      labelDataEasyPost: null,
      orderCostCurrency: null,
      handlingFee: null,
    });

    if (
      errors.packages ||
      !values.receiver ||
      !values.receiver.country ||
      !values.currency
    ) {
      setIsShowError(true);
    } else {
      setIsShowError(false);
      setLoading(true);

      fetchCalculator(
        mode,
        values.receiver.country,
        values.additionalInsurance,
        values.signature,
        values.packages,
        values.currency,
        values.additionalInsurance && values.insuranceAmount
          ? +values.insuranceAmount
          : 0,
        cachedCurrentUser?.currentUser?.id,
        values.receiver.city,
        values.receiver.zipCode,
        isCalculatorPage,
        values?.warehouseId,
        {
          receiver: values?.receiver,
          sender: isCalculatorPage ? '' : values?.sender,
        },
        simple,
      )
        .then((calculatorResponse) => {
          if (
            calculatorResponse?.data?.getListOfDeliveryOptions?.data?.length ||
            calculatorResponse?.data?.getListOfDeliveryOptions?.userAccount
              ?.rows?.length
          ) {
            setResponseData(calculatorResponse?.data?.getListOfDeliveryOptions);
            if (
              setDeclaredWeight &&
              calculatorResponse?.data?.getListOfDeliveryOptions?.weight_to_pay
            ) {
              setDeclaredWeight(
                calculatorResponse?.data?.getListOfDeliveryOptions
                  ?.weight_to_pay,
              );
            }
            // Show remoteArea when editing existing parcel
            if (values?.typeDeliveryId?.typeDeliveryId) {
              const variant =
                calculatorResponse?.data?.getListOfDeliveryOptions?.data?.find(
                  (variant) => {
                    return (
                      variant?.typeDelivery !== undefined &&
                      //@ts-ignore
                      +variant?.typeDelivery ===
                        +values?.typeDeliveryId?.typeDeliveryId
                    );
                  },
                );

              if (variant?.remoteArea || variant?.remoteArea === 0) {
                setRemoteAreaCost(variant?.remoteArea);
              }
            }
            setIsFetchCalculated(true);
          } else {
            setResponseData(getInitialData());
            if (calculatorResponse?.error) {
              enqueueSnackbar(
                calculatorResponse.error as unknown as SnackbarMessage,
                { variant: 'error' },
              );
            }
            setIsFetchCalculated(false);
          }
        })
        .catch((reason) => {
          setResponseData(getInitialData());
          if (reason && typeof reason === 'string') {
            enqueueSnackbar(reason, { variant: 'error' });
          } else if (reason?.message) {
            enqueueSnackbar(reason?.message, { variant: 'error' });
          }
          setIsFetchCalculated(false);
        })
        .finally(() => setLoading(false));
    }
  };

  useEffect(() => {
    handleGetListOfDeliveryOptionsQuery();

    // eslint-disable-next-line
  }, [values.signature, values.additionalInsurance, values.insuranceAmount]);

  const handleFetchCalculator = () => {
    handleGetListOfDeliveryOptionsQuery();
  };

  return (
    <Grid container spacing={3} justify='space-between'>
      <Grid item xs={12} md={5}>
        <ul className={classes.items}>
          <li className={classes.item}>
            <span>
              {t('app.volumetricWeightOfCargo')}
              <Tooltip placement='top' title={t('app.deliveryCostCalculation')}>
                <IconButton>
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            </span>
            <span>
              {responseData?.volume_weight} {t('app.kg')}
            </span>
          </li>
          <li className={classes.item}>
            <span>{t('app.actualWeight')}</span>
            <span>
              {responseData?.weight} {t('app.kg')}
            </span>
          </li>
          <li className={classes.item}>
            <span>
              {t('app.weightToBePaid')}
              <Tooltip placement='top' title={t('app.largeCargoExplanation')}>
                <IconButton>
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            </span>
            <span>
              {responseData?.weight_to_pay} {t('app.kg')}
            </span>
          </li>
        </ul>
      </Grid>
      <Grid item xs={12} md={5}>
        <ul className={classes.items}>
          <If condition={!!remoteAreaCost}>
            <li className={classes.item}>
              <span>{t('app.remoteAreaSurcharge')}</span>
              <span>{remoteAreaCost} ₽</span>
            </li>
          </If>
        </ul>
      </Grid>

      <Grid item xs={12}>
        <Box mt={3.25} mb={3.25}>
          <Divider />
        </Box>

        <TableRezult
          responseData={responseData}
          isCalculatorPage={isCalculatorPage}
          values={values}
          setFieldValue={setFieldValue}
          setRemoteAreaCost={setRemoteAreaCost}
          setDeclaredAmount={setDeclaredAmount}
          setGlobalProductCode={setGlobalProductCode}
          isLoading={isLoading}
        />
        <Box mt={3.25} mb={3.25}>
          <Divider />
        </Box>
        <BoxCentered flexDirection='column'>
          <When condition={isShowError}>
            <span className={classes.isShowError}>
              {t('app.fillFormWithoutErrors')}
            </span>
          </When>

          <When condition={!!Object.values(errors).length && !!errors.packages}>
            <span className={classes.isShowError}>
              {t('app.errorInPackagingAndCargo')}
            </span>
          </When>

          <When
            condition={
              Array.isArray(values.packages) &&
              +sumTotalVolumetricWeight(values.packages) > MAX_PARCEL_WEIGHT
            }
          >
            <span className={classes.isShowError}>
              {t('app.totalVolumetricWeightLimit')} {MAX_PARCEL_WEIGHT}{' '}
              {t('app.kg')}.
            </span>
          </When>

          <When
            condition={
              Array.isArray(values.packages) &&
              +sumByField(values.packages, 'weightKg') > MAX_PARCEL_WEIGHT
            }
          >
            <span className={classes.isShowError}>
              {t('app.totalGrossWeightLimit')} {MAX_PARCEL_WEIGHT} {t('app.kg')}
              .
            </span>
          </When>

          <When condition={!values.currency}>
            <span className={classes.isShowError}>
              {t('app.specifyCurrency')}.
            </span>
          </When>

          <When condition={!values.receiver.country}>
            <span className={classes.isShowError}>
              {t('app.specifyRecipientCountry')}.
            </span>
          </When>
          <When condition={!isShowError && isFetchCalculated}>
            <When condition={chechForErrors(responseData?.data)}>
              <span className={classes.isFetchCalculated}>
                <ThumbUpAltIcon />
                {t('app.calculationSuccessfullyCompleted')}
              </span>
            </When>
          </When>
        </BoxCentered>
        <Box
          display={'flex'}
          justifyContent={'flex-start'}
          alignItems={'center'}
          className={classes.buttonWrapper}
        >
          <Button
            className={classes.calculateButton}
            variant='contained'
            onClick={() => handleFetchCalculator()}
            startIcon={
              isLoading ? <CircularProgress size={22} /> : <ExposureIcon />
            }
          >
            {t('app.recalculateCost')}
          </Button>
        </Box>
      </Grid>
    </Grid>
  );
};

export default CalculationResults;

const chechForErrors = (data: any) => {
  if (!data || data?.length === 0) return false;
  let isError = true;
  // eslint-disable-next-line array-callback-return
  data.map((e: any) => {
    if (e.errors === null || e.errors.length === 0) {
      isError = false;
    }
  });
  return isError;
};
