import React from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { Switch as SwitchIf, Case } from 'react-if';
import Main from '../viewes/NavigationPages/NavigationMain/Main';
import Contacts from '../viewes/Сontacts/Contacts';
import FormAddressBookContact from '../viewes/FormAddressBookContact/FormAddressBookContact';
import FormPersonalData from '../viewes/FormPersonalData/FormPersonalData';
import TrackParcelForm from '../viewes/TrackParcelForm/TrackParcelForm';
import PaymentsHistory from '../viewes/PaymentsHistory/PaymentsHistory';
import PaymentSuccessful from '../viewes/PaymentsHistory/PaymentSuccessful';
import NotFound from '../viewes/TextPages/NotFound/NotFound';
import { SenderAccountsList } from '../viewes/Settings/SenderAccountsList/SenderAccountsList';
import SenderAccountsForm from '../viewes/Settings/SenderAccountsForm/SenderAccountsForm';
import ShipmentsForm from '../viewes/Shipments/ShipmentForm/ShipmentsForm';
import ShipmentSelectType from '../viewes/NavigationPages/NavigationSelectTypeShipmentCreate/SelectTypeShipmentCreate';
import { TRoutes } from '../utils/helpers';
import ShipmentDetail from '../viewes/Shipments/ShipmentDetail/ShipmentDetail';
import DeliveryCalculator from '../viewes/DeliveryCalculator/DeliveryCalculator';
import LegalInformation from '../viewes/TextPages/LegalInformation/LegalInformation';
import Eula from '../viewes/TextPages/Eula/Eula';
import Requisites from '../viewes/TextPages/Requisites/Requisites';
import MyPackagesList from '../viewes/Settings/MyPackagesList/MyPackagesList';
import MyProductList from '../viewes/Settings/MyProductList/MyProductList';
import MyMarketplaces from '../viewes/Settings/MyMarketplaces/MyMarketplaces';
import MarketplacesOrders from '../viewes/MarketplacesOrders/MarketplacesOrders';
import UserDataVerify from '../viewes/UserDataVerify/UserDataVerify';
import MyProductDetails from '../viewes/Settings/MyProductList/MyProductDetails/MyProductDetails';
import Reports from '../viewes/Reports/Reports';
import { User } from '../generated/graphql';
import { PersonalData } from '../viewes/UserDataVerify/PersonalData';
import ShipmentsAllHistory from '../viewes/Shipments/ShipmentsAllHistory/ShipmentsAllHistory';

const PrivateRoutes: React.FC<{ user?: User }> = ({ user }) => {
  return (
    <SwitchIf>
      <Case condition={!!user && user.fullAccess}>
        <Switch>
          <Route path={TRoutes.MAIN} exact component={Main} />
          <Route path={TRoutes.CONTACTS} exact component={Contacts} />
          <Route
            path={TRoutes.CONTACTS_CREATE}
            exact
            component={FormAddressBookContact}
          />
          <Route
            path={TRoutes.CONTACTS_DETAIL}
            exact
            component={FormAddressBookContact}
          />

          <Route path={TRoutes.PERSONAL} exact component={FormPersonalData} />

          <Route
            path={TRoutes.SHIPMENTS}
            exact
            component={ShipmentsAllHistory}
          />
          <Route
            path={TRoutes.SHIPMENT_SELECT_TYPE_BEFORE_CREATE}
            exact
            component={ShipmentSelectType}
          />
          <Route
            path={TRoutes.SHIPMENT_CREATE}
            exact
            component={ShipmentsForm}
          />
          <Route
            path={TRoutes.SHIPMENT_EDIT_DRAFT}
            exact
            component={ShipmentsForm}
          />
          <Route path={TRoutes.SHIPMENT_EDIT} exact component={ShipmentsForm} />
          <Route
            path={TRoutes.SHIPMENT_EDIT_COPY}
            exact
            component={ShipmentsForm}
          />
          <Route
            path={TRoutes.SHIPMENT_DETAILS}
            exact
            component={ShipmentDetail}
          />
          <Route path={TRoutes.TRACK} exact component={TrackParcelForm} />
          <Route path={TRoutes.REPORTS} exact component={Reports} />
          <Route
            path={TRoutes.TRACK_WITH_NUMBER}
            exact
            component={TrackParcelForm}
          />
          <Route
            path={TRoutes.TRACK_WITH_NUMBER_AND_CARRIED_CODE}
            exact
            component={TrackParcelForm}
          />
          <Route path={TRoutes.ACCOUNT} exact component={PaymentsHistory} />
          <Route
            path={TRoutes.ACCOUNT_PAYMENT_SUCCESSFUL}
            exact
            component={PaymentSuccessful}
          />
          <Route
            path={TRoutes.ACCOUNT_ID_AMOUNT}
            exact
            component={PaymentsHistory}
          />
          <Route
            path={TRoutes.SETTINGS_MY_PACKAGES}
            exact
            component={MyPackagesList}
          />
          <Route
            path={TRoutes.SETTINGS_MY_PRODUCTS}
            exact
            component={MyProductList}
          />
          <Route
            path={TRoutes.SETTINGS_MY_PRODUCT_DETAILS}
            exact
            component={MyProductDetails}
          />

          <Route
            path={TRoutes.SETTINGS_SENDER_ACCOUNTS}
            exact
            component={SenderAccountsList}
          />
          <Route
            path={TRoutes.SETTINGS_SENDER_ACCOUNTS_CREATE}
            exact
            component={SenderAccountsForm}
          />
          <Route
            path={TRoutes.SETTINGS_SENDER_ACCOUNTS_UPDATE}
            exact
            component={SenderAccountsForm}
          />
          <Route
            path={TRoutes.DELIVERY_CALCULATOR}
            exact
            component={DeliveryCalculator}
          />

          <Route
            path={TRoutes.LEGAL_INFORMATION}
            exact
            component={LegalInformation}
          />
          <Route path={TRoutes.EULA} exact component={Eula} />
          <Route path={TRoutes.REQUISITES} exact component={Requisites} />

          <Route path={TRoutes.MY_SHOPS} exact component={MyMarketplaces} />
          <Route
            path={TRoutes.MY_SHOPS_CREATE}
            exact
            component={MyMarketplaces}
          />
          <Route
            path={TRoutes.MY_SHOPS_EDIT}
            exact
            component={MyMarketplaces}
          />
          <Route
            path={TRoutes.MARKETPLACE_ORDERS}
            exact
            component={MarketplacesOrders}
          />
          <Route
            path={TRoutes.PERSONAl_VERIFY}
            exact
            component={PersonalData}
          />
          <Route
            path={TRoutes.PERSONAl_DATA_VERIFY}
            exact
            component={UserDataVerify}
          />
        </Switch>
      </Case>
      <Case condition={!!user && !user.fullAccess}>
        <Switch>
          <Route path={TRoutes.NOT_FOUND} exact component={NotFound} />
          <Route path={TRoutes.PERSONAL} exact component={FormPersonalData} />
          <Redirect from={TRoutes.AUTH_CONFIRM} exact to={TRoutes.PERSONAL} />
          <Redirect from='*' to={TRoutes.PERSONAL} exact />
        </Switch>
      </Case>
    </SwitchIf>
  );
};

export default PrivateRoutes;
