import React, { useEffect, useMemo, useState } from 'react';
import {
  Box,
  Divider,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import useStyles from './styles';
import { Field, FieldProps, Form, Formik } from 'formik';
import Packages from '../../components/Packages/Packages';
import debounce from 'debounce';
import { getInitialPackage, DELIVERY_VARIANTS } from '../../utils/helpers';
import CalculationResults from '../../components/CalculationResults/CalculationResults';
import { directCalculatorForm } from '../../utils/validationSchemes';
import {
  GetDeliveryCountriesQuery,
  GetDeliveryCountriesQueryVariables,
  Package,
  useGetCitiesByCountryIsoLazyQuery,
} from '../../generated/graphql';
import client from '../../GraphQL/client';
import { QUERY_DELIVERY_COUNTRIES } from '../../GraphQL/queries/getDeliveryCountries';
import {
  RenderCountryFlagImage,
  ShowLoadingText,
} from '../../utils/helperComponents';
import { BoxCentered } from '../../components/BoxCentered/BoxCentered';
import { COLORS, CURRENCIES, DEBOUNCE } from '../../utils/constants';
import { Autocomplete } from '@material-ui/lab';
import { TFormikSetFieldValueDebounce } from '../../interfaces';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';

const DeliveryCalculator = () => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const [isFetchCalculated, setIsFetchCalculated] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [cities, setCities] = useState<string[]>([]);

  const { t } = useTranslation();

  const dataCountries = client.readQuery<
    GetDeliveryCountriesQuery,
    GetDeliveryCountriesQueryVariables
  >({
    query: QUERY_DELIVERY_COUNTRIES,
  });

  const [
    getCitiesByCountryIsoLazyQuery,
    {
      data: dataLazyQueryGetCitiesByISO,
      loading: isLoadingLazyQueryGetCitiesByISO,
      error: errorLazyQueryGetCitiesByISO,
    },
  ] = useGetCitiesByCountryIsoLazyQuery();

  if (errorLazyQueryGetCitiesByISO?.message) {
    enqueueSnackbar(errorLazyQueryGetCitiesByISO?.message);
  }

  /** New cities array fetched from query getCitiesByCountryIso(iso: $iso) */
  useEffect(() => {
    if (dataLazyQueryGetCitiesByISO?.getCitiesByCountryIso?.length) {
      let sorted = dataLazyQueryGetCitiesByISO?.getCitiesByCountryIso?.slice();
      sorted = sorted.sort((a, b) => {
        return a?.name && b?.name && a?.name < b?.name
          ? -1
          : a?.name && b?.name && a?.name > b.name
          ? 1
          : 0;
      });

      const uniqueCities = [...new Set(sorted.map((c) => c?.name!))];

      setCities(uniqueCities);
    }
  }, [dataLazyQueryGetCitiesByISO]);

  const setSelectedCountry = (selectedCountry: any) => {
    let iso: string | undefined | null;

    iso = dataCountries?.deliveryCountries?.find(
      (c) =>
        c?.name === selectedCountry?.name ||
        c?.nameEng === selectedCountry?.nameEng,
    )?.iso;

    if (iso) {
      getCitiesByCountryIsoLazyQuery({
        variables: {
          iso: iso,
        },
      });
    }
  };

  const countriesToItems = useMemo(() => {
    const newArr = dataCountries?.deliveryCountries?.length
      ? [...dataCountries?.deliveryCountries].sort((a, b) => {
          return a && b && a.name < b.name
            ? -1
            : a && b && a.name > b.name
            ? 1
            : 0;
        })
      : [];

    return newArr.map((country) => {
      return (
        country && (
          <MenuItem key={country.name} value={country as any}>
            <BoxCentered justifyContent='flex-start'>
              {country?.iso && (
                <RenderCountryFlagImage
                  countryISO={country?.iso}
                  countryName={country.name}
                />
              )}
              {country.name}
            </BoxCentered>
          </MenuItem>
        )
      );
    });
    // eslint-disable-next-line
  }, []);

  const renderField = (
    name: string,
    label: string,
    setFieldValue: TFormikSetFieldValueDebounce,
    disable?: boolean,
  ) => (
    <Field name={name}>
      {({ field: { value, ...field }, meta }: FieldProps) => (
        <FormControl error={!!(meta.touched && meta.error)}>
          <InputLabel
            className={classes.inputLabel}
            shrink={false}
            htmlFor={`${name}-input`}
          >
            {label}
          </InputLabel>
          <Input
            disabled={disable}
            disableUnderline
            id={`${name}-input`}
            {...field}
            defaultValue={value}
            onChange={(e) => {
              const name = e.target.name;
              const value = e.target.value;
              setFieldValue(name, value);
            }}
          />
          {meta.touched && meta.error && (
            <FormHelperText>{meta.error}</FormHelperText>
          )}
        </FormControl>
      )}
    </Field>
  );

  return (
    <>
      <Grid container spacing={2} justify='center'>
        <Grid item xs={12} sm={12}>
          <Typography variant='h2' className={classes.mainTitle}>
            {t('app.deliveryCalculator').toUpperCase()}
          </Typography>
          <Divider />
        </Grid>
      </Grid>

      <div>
        <Formik
          initialValues={{
            currency: CURRENCIES.RUB.toLowerCase(),
            packages: [getInitialPackage()],
            receiver: {
              country: '',
              city: '',
              zipCode: '',
              state: '',
            },

            typeDelivery: DELIVERY_VARIANTS.FROM_WAREHOUSE_TO_DOOR,
            additionalInsurance: false,
            insuranceAmount: 0,
          }}
          validationSchema={directCalculatorForm}
          onSubmit={() => {}}
        >
          {({ errors, values, setFieldValue, setValues }) => {
            const setFieldValueDebounce = debounce(setFieldValue, DEBOUNCE);

            return (
              <Form>
                <Box padding='20px' margin='auto'>
                  <Grid container spacing={3} style={{ marginBottom: '30px' }}>
                    <Grid item xs={12} className={classes.input}>
                      <Field name='receiver.country'>
                        {({ field: { value }, meta }: FieldProps) => {
                          return (
                            <FormControl error={!!(meta.touched && meta.error)}>
                              <InputLabel
                                shrink={false}
                                htmlFor={`receiver-country-input`}
                              >
                                {t('app.recipientCountry')}
                              </InputLabel>
                              <Select
                                disableUnderline
                                id='receiver-country-input'
                                value={value}
                                onChange={(e) => {
                                  setFieldValueDebounce(
                                    'receiver.country',
                                    e.target.value,
                                  );
                                  //@ts-ignore
                                  setSelectedCountry(e.target.value);
                                }}
                              >
                                {countriesToItems}
                              </Select>
                              {meta.touched && meta.error && (
                                <FormHelperText>{meta.error}</FormHelperText>
                              )}
                            </FormControl>
                          );
                        }}
                      </Field>
                    </Grid>
                    <Grid item xs={12} md={5} className={classes.input}>
                      {renderField(
                        'receiver.zipCode',
                        `${t('app.postalCode')}*`,
                        setFieldValueDebounce,
                      )}
                    </Grid>
                  </Grid>
                  <Grid container spacing={3} style={{ marginBottom: '30px' }}>
                    <Grid item xs={12} className={classes.input}>
                      <Field name='city'>
                        {({ field: { value }, meta }: FieldProps) => (
                          <Box pb={2}>
                            <InputLabel shrink={false}>
                              {t('app.city')}*
                            </InputLabel>
                            {!isLoadingLazyQueryGetCitiesByISO ? (
                              <>
                                <Autocomplete
                                  disableClearable
                                  options={cities}
                                  freeSolo
                                  loading={isLoadingLazyQueryGetCitiesByISO}
                                  autoComplete={true}
                                  loadingText={`${t('app.upload')}...`}
                                  closeText={t('app.close')}
                                  openText={t('app.open')}
                                  clearText={t('app.clear')}
                                  noOptionsText={t('app.noCities')}
                                  defaultValue={value || ''}
                                  onInputChange={(event, currentInputValue) => {
                                    setFieldValue(
                                      'receiver.city',
                                      currentInputValue,
                                    );
                                  }}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      inputProps={{
                                        ...params.inputProps,
                                        autoComplete: 'unique-city-identifier',
                                      }}
                                      InputProps={{
                                        ...params.InputProps,
                                        type: 'search',
                                      }}
                                    />
                                  )}
                                />
                              </>
                            ) : (
                              <BoxCentered border={1} mb={2} p={2}>
                                {value}
                                <ShowLoadingText name={t('app.cities')} />
                              </BoxCentered>
                            )}

                            {meta?.touched && !!meta?.error && (
                              <FormHelperText style={{ color: COLORS.RED }}>
                                {meta?.error}
                              </FormHelperText>
                            )}
                          </Box>
                        )}
                      </Field>
                    </Grid>
                    <Grid item xs={12} className={classes.input}>
                      {renderField(
                        'receiver.state',
                        `${t('app.stateRegion')}*`,
                        setFieldValueDebounce,
                      )}
                    </Grid>
                  </Grid>
                </Box>

                <Packages
                  packages={values.packages as Package[]}
                  currency={values.currency}
                  setFieldValue={setFieldValueDebounce}
                  setIsFetchCalculated={setIsFetchCalculated}
                  isCalculatorPage={true}
                  setValues={setValues as any}
                />

                <Box mt={7.125} mb={8.125}>
                  <Divider />
                </Box>

                <CalculationResults
                  errors={errors}
                  values={values}
                  isLoading={isLoading}
                  setLoading={setLoading}
                  setIsFetchCalculated={setIsFetchCalculated}
                  isFetchCalculated={isFetchCalculated}
                  setFieldValue={setFieldValueDebounce}
                  isCalculatorPage={true}
                  simple={true}
                />

                <Box mt={7.125} mb={8.125}>
                  <Divider />
                </Box>
              </Form>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

export default DeliveryCalculator;
