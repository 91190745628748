import React from 'react';
import { FormikErrors, FormikTouched } from 'formik';
import Packages from '../../../components/Packages/Packages';
import useStyles from './styles';
import { Package, Parcel, ParcelInput } from '../../../generated/graphql';
import {
  TFormikSetFieldValue,
  TFormikSetFieldValueDebounce,
  TSetStateBoolean,
  TSetStateNumber,
} from '../../../interfaces';

const ShipmentsFormStep2: React.FC<{
  values: ParcelInput;
  setFieldValue: TFormikSetFieldValue;
  setIsFetchCalculated: TSetStateBoolean;
  setUpdateView: TSetStateNumber;
  touched: FormikTouched<Parcel>;
  errors: FormikErrors<Parcel>;
  setValues: (
    values: React.SetStateAction<Parcel>,
    shouldValidate?: boolean | undefined,
  ) => void;
  setFieldValueDebounce: TFormikSetFieldValueDebounce;
  updateView: number;
}> = ({
  values,
  setFieldValue,
  setIsFetchCalculated,
  setUpdateView,
  touched,
  errors,
  setFieldValueDebounce,
  setValues,
  updateView,
}) => {
  const classes = useStyles();

  return (
    <div className={classes.wrapper}>
      <Packages
        key={updateView}
        packages={values.packages as Package[]}
        errors={errors}
        touched={touched}
        currency={values.currency as string}
        setFieldValue={setFieldValueDebounce}
        setValues={setValues}
        setIsFetchCalculated={setIsFetchCalculated}
        isCalculatorPage={true}
        setUpdateView={setUpdateView}
      />
    </div>
  );
};

export default ShipmentsFormStep2;
