import React from 'react';
import { TRoutes } from '../../../utils/helpers';
import SelectNextLink from '../../../components/SelectNextLink/SelectNextLink';
import ShipmentCreateIcon from '../../../assets/img/shipment/shipment_create.png';
import { t } from 'i18next';

export default () => {
  const links = [
    {
      id: 1,
      route: TRoutes.SHIPMENT_CREATE,
      icon: ShipmentCreateIcon,
      text: t('app.createLabel'),
      disabled: false,
      external: false,
    },
  ];

  return (
    <SelectNextLink
      title={t('app.createNewShipment').toUpperCase()}
      links={links}
    />
  );
};
