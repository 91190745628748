import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from '@material-ui/core';
import {
  Field,
  FieldArray,
  FieldProps,
  FormikErrors,
  FormikTouched,
} from 'formik';
import AddIcon from '@material-ui/icons/Add';
import ArchiveIcon from '@material-ui/icons/Archive';
import AppsIcon from '@material-ui/icons/Apps';
import DeleteForeverIcon from '@material-ui/icons/DeleteForever';
import ViewModuleIcon from '@material-ui/icons/ViewModule';
import { COLORS, CURRENCIES, UNIT_STATES } from '../../utils/constants';

import useStyles from './styles';
import { getInitialPackage, getInitialUnit } from '../../utils/helpers';
import {
  TFormikSetFieldValueDebounce,
  TSetStateBoolean,
  TSetStateNumber,
} from '../../interfaces';
import {
  DeliveryCountry,
  GetDeliveryCountriesQuery,
  GetDeliveryCountriesQueryVariables,
  GetMyProductListQuery,
  GetMyProductListQueryVariables,
  GetPackagingTemplatesListQuery,
  GetPackagingTemplatesListQueryVariables,
  GetTheExchangeRateQuery,
  GetTheExchangeRateQueryVariables,
  Package,
  PackagingTemplate,
  Parcel,
  ParcelUnit,
  Product,
} from '../../generated/graphql';
import EditIcon from '@material-ui/icons/Edit';
import ModalBox from '../ModalBox/ModalBox';
import { QUERY_DELIVERY_COUNTRIES } from '../../GraphQL/queries/getDeliveryCountries';
import { useApolloClient } from '@apollo/client';
import { QUERY_EXCHANGE_RATE } from '../../GraphQL/queries/getTheExchangeRate';
import { ShowLoadingText } from '../../utils/helperComponents';
import Description from '../Units/Description/Description';
import CodeTnVed from '../Units/FieldCodeTNVED/FieldCodeTNVED';
import FieldWeightNet from '../Units/FieldWeightNet/FieldWeightNet';
import FieldCountry from '../Units/FieldCountry/FieldCountry';
import FieldPrice from '../Units/FieldPrice/FieldPrice';
import FieldWeightGross from './FieldWeightGross/FieldWeightGross';
import FieldWidthCm from './FieldWidthCm/FieldWidthCm';
import FieldHeightCm from './FieldHeightCm/FieldHeightCm';
import FieldLengthCm from './FieldLengthCm/FieldLengthCm';
import { QUERY_MY_PRODUCT_LIST } from '../../GraphQL/queries/getMyProductList';
import { Autocomplete } from '@material-ui/lab';
import { QUERY_PACKAGING_TEMPLATE_LIST } from '../../GraphQL/queries/getPackagingTemplatesList';
import { BoxCentered } from '../BoxCentered/BoxCentered';
import { PackagesRezult } from './PackagesRezult/PackagesRezult';
import { useTranslation } from 'react-i18next';

// Шаг 3 при создании заказа (Упаковка)
const Packages: React.FC<{
  setIsFetchCalculated: TSetStateBoolean;
  setFieldValue: TFormikSetFieldValueDebounce;
  setValues?: (
    values: React.SetStateAction<Parcel>,
    shouldValidate?: boolean | undefined,
  ) => void;
  packages: Package[];
  errors?: FormikErrors<Parcel>;
  touched?: FormikTouched<Parcel>;
  currency: string;
  isCalculatorPage: boolean;
  setUpdateView?: TSetStateNumber;
  isMarketPlace?: boolean;
}> = ({
  setIsFetchCalculated,
  setFieldValue,
  setValues,
  currency,
  packages,
  errors,
  touched,
  isCalculatorPage,
  setUpdateView,
  isMarketPlace,
}) => {
  const classes = useStyles();
  const client = useApolloClient();
  const theme = useTheme();
  const [countries, setCountries] = useState<DeliveryCountry[]>([]);

  const { t } = useTranslation();

  const [elementToRemove, setElementToRemove] = useState<number | null>();
  const [isOpenEditingUnitDescription, setIsOpenEditingUnitDescription] =
    useState(false);
  const [editingUnitSelector, setEditingUnitSelector] = useState('');
  const [editingPackageIndex, setEditingPackageIndex] = useState(0);
  const [editingUnitIndex, setEditingUnitIndex] = useState(0);
  const [isOpenModalMyPackages, setIsOpenModalMyPackages] = useState(false);
  const [isOpenModalMyProducts, setIsOpenModalMyProducts] = useState(false);

  const hidden = useMediaQuery(theme.breakpoints.down('xs'));

  const dataCountries = client.readQuery<
    GetDeliveryCountriesQuery,
    GetDeliveryCountriesQueryVariables
  >({
    query: QUERY_DELIVERY_COUNTRIES,
  });

  const cachedProducts = client.readQuery<
    GetMyProductListQuery,
    GetMyProductListQueryVariables
  >({
    query: QUERY_MY_PRODUCT_LIST,
  });

  const cachedPackages = client.readQuery<
    GetPackagingTemplatesListQuery,
    GetPackagingTemplatesListQueryVariables
  >({
    query: QUERY_PACKAGING_TEMPLATE_LIST,
  });

  const dataExchangeRates = client.readQuery<
    GetTheExchangeRateQuery,
    GetTheExchangeRateQueryVariables
  >({
    query: QUERY_EXCHANGE_RATE,
  });

  useEffect(() => {
    if (dataCountries?.deliveryCountries?.length) {
      // Need Russia here
      setCountries(
        [...(dataCountries?.deliveryCountries as DeliveryCountry[])].sort(
          (a, b) =>
            a.nameEng < b.nameEng ? -1 : a.nameEng > b.nameEng ? 1 : 0,
        ),
      );
    }
    // eslint-disable-next-line
  }, [dataCountries]);

  if (!dataCountries || !countries.length)
    return <ShowLoadingText name={t('app.countries2')} />;

  const units: ParcelUnit[] = [];
  packages?.forEach((p) => {
    p?.units?.forEach((u) => u && units.push(u));
  });

  const showPreRemoveStyle = (element: any) => {
    return elementToRemove === element
      ? { background: '#cacaca', filter: 'blur(1px)' }
      : {};
  };

  const handlerChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const name = e.target.name;
    const value = e.target.value;

    setFieldValue(name, value);
    setIsFetchCalculated(false);
  };

  const isErrored = (
    packageIndex: number,
    unitIndex: number,
    unitName: string,
  ) => {
    return !!(
      errors?.packages?.length &&
      errors?.packages![packageIndex] &&
      // @ts-ignore
      errors?.packages![packageIndex]?.units?.length &&
      // @ts-ignore
      errors?.packages![packageIndex]?.units[unitIndex] &&
      // @ts-ignore
      errors?.packages![packageIndex]?.units[unitIndex][unitName]
    );
  };

  const isTouched = (
    packageIndex: number,
    unitIndex: number,
    unitName: string,
  ) => {
    return !!(
      /* prettier-ignore */
      // @ts-ignore
      touched?.packages?.length &&
        // @ts-ignore
        touched?.packages![packageIndex] &&
        // @ts-ignore
        touched?.packages![packageIndex]?.units?.length &&
        // @ts-ignore
        touched?.packages![packageIndex]?.units[unitIndex] &&
        // @ts-ignore
        touched?.packages![packageIndex]?.units[unitIndex][unitName]
    );
  };

  return (
    <>
      {isCalculatorPage && (
        <FieldArray
          name='packages'
          render={(helpers) => (
            <div>
              {packages.map(
                (packageItem, packageIndex) =>
                  packageItem && (
                    <div
                      key={packageIndex}
                      className={classes.packageItem}
                      style={showPreRemoveStyle(packageIndex)}
                    >
                      <div className={classes.root}>
                        <Grid container spacing={5}>
                          <Grid item xs={12}>
                            <Box
                              display={'flex'}
                              alignItems={'center'}
                              justifyContent={'center'}
                              height={'100%'}
                              position={'relative'}
                            >
                              <Typography variant={'h6'}>
                                {t('app.packageNumber')}
                                {packageIndex + 1}.
                                {!isCalculatorPage &&
                                  `${t('app.itemsCount')}: ${
                                    packageItem?.units?.length
                                  }`}
                              </Typography>
                              {packageIndex !== 0 && (
                                <Tooltip
                                  title={t('app.deletePackage', {
                                    packNumber: packageIndex + 1,
                                    value: packageItem?.units?.length,
                                  })}
                                >
                                  <DeleteForeverIcon
                                    className={classes.removePackage}
                                    style={{ color: COLORS.RED }}
                                    onMouseEnter={() =>
                                      setElementToRemove(packageIndex)
                                    }
                                    onMouseLeave={() =>
                                      setElementToRemove(null)
                                    }
                                    onClick={(e) => {
                                      e.preventDefault();
                                      helpers.remove(packageIndex);
                                    }}
                                  />
                                </Tooltip>
                              )}
                              {!!cachedPackages?.getPackagingTemplatesList?.rows
                                ?.length && (
                                <Tooltip title={t('app.selectPackage')}>
                                  <Box
                                    className={classes.openMyPackages}
                                    onClick={() => {
                                      setEditingPackageIndex(packageIndex);
                                      setIsOpenModalMyPackages(true);
                                    }}
                                  >
                                    <ViewModuleIcon />
                                    {hidden ? null : t('app.myPackage')}
                                  </Box>
                                </Tooltip>
                              )}
                            </Box>
                          </Grid>
                          <Grid item xs={12} sm={3}>
                            <FieldWeightGross
                              handlerChange={handlerChange}
                              packageIndex={packageIndex}
                            />
                          </Grid>

                          <Grid item xs={12} sm={3}>
                            <FieldLengthCm
                              handlerChange={handlerChange}
                              packageIndex={packageIndex}
                            />
                          </Grid>

                          <Grid item xs={12} sm={3}>
                            <FieldWidthCm
                              handlerChange={handlerChange}
                              packageIndex={packageIndex}
                            />
                          </Grid>

                          <Grid item xs={12} sm={3}>
                            <FieldHeightCm
                              handlerChange={handlerChange}
                              packageIndex={packageIndex}
                            />
                          </Grid>
                        </Grid>
                      </div>
                    </div>
                  ),
              )}
              <PackagesRezult
                packages={packages}
                units={units}
                isCalculatorPage={isCalculatorPage}
                currency={currency}
              />

              <Box mt={1}>
                <Button
                  startIcon={<AddIcon />}
                  endIcon={<ArchiveIcon />}
                  className={classes.addParcelAction}
                  variant='contained'
                  onClick={(e) => {
                    e.preventDefault();
                    helpers.push(getInitialPackage());
                  }}
                >
                  {t('app.addPackageNumber')}
                  {packages.length + 1}
                </Button>
              </Box>
            </div>
          )}
        />
      )}
      {/* Информация о грузе начало */}
      {!isCalculatorPage && (
        <FieldArray
          name={`packages[0].units`}
          render={(helpers) => (
            <>
              {packages[0]?.units?.map((unitItem, unitIndex) => {
                const fieldNamePrefix = `packages[0].units[${unitIndex}]`;
                const unit = packages![0]?.units![unitIndex];

                return (
                  <div
                    key={unitIndex}
                    className={classes.packageItem}
                    style={showPreRemoveStyle(unitIndex)}
                  >
                    <div className={classes.root}>
                      <Grid
                        container
                        spacing={2}
                        style={showPreRemoveStyle(`0.${unitIndex}`)}
                      >
                        <Grid item xs={12}>
                          <FormControl
                            error={
                              (isErrored(0, unitIndex, 'state') &&
                                isTouched(0, unitIndex, 'state')) ||
                              (isErrored(0, unitIndex, 'nameRU') &&
                                isTouched(0, unitIndex, 'nameRU')) ||
                              (isErrored(0, unitIndex, 'nameEN') &&
                                isTouched(0, unitIndex, 'nameEN')) ||
                              (isErrored(0, unitIndex, 'tradeMark') &&
                                isTouched(0, unitIndex, 'tradeMark'))
                            }
                          >
                            <InputLabel
                              shrink={false}
                              htmlFor={`input-name-product-${unitIndex}`}
                            >
                              <Grid container spacing={2}>
                                <Grid item xs={12} sm={6}>
                                  <BoxCentered justifyContent='flex-start'>
                                    <Box
                                      display={'flex'}
                                      alignItems={'flex-end'}
                                      height={'100%'}
                                    >
                                      {t('app.itemDescription')} {unitIndex + 1}
                                      {unitIndex !== 0 && (
                                        <Tooltip
                                          title={`${t('app.deleteName')} ${
                                            unitIndex + 1
                                          }`}
                                          onClick={(e) =>
                                            helpers.remove(unitIndex)
                                          }
                                        >
                                          <DeleteForeverIcon
                                            style={{
                                              color: COLORS.RED,
                                              cursor: 'pointer',
                                            }}
                                          />
                                        </Tooltip>
                                      )}
                                    </Box>
                                    {/** Мои товары */}
                                    {!!cachedProducts?.getMyProductList?.rows
                                      ?.length && (
                                      <Tooltip title={t('app.selectProduct')}>
                                        <Box
                                          className={classes.openMyProducts}
                                          onClick={() => {
                                            setEditingUnitIndex(unitIndex);
                                            setEditingPackageIndex(0);
                                            setIsOpenModalMyProducts(true);
                                          }}
                                        >
                                          <ViewModuleIcon />
                                          {hidden ? null : t('app.myProducts')}
                                        </Box>
                                      </Tooltip>
                                    )}
                                  </BoxCentered>
                                </Grid>
                              </Grid>
                            </InputLabel>

                            <Input
                              disableUnderline
                              id={`input-name-product-${unitIndex}`}
                              value={`${
                                unit?.nameRU ? unit?.nameRU + '. ' : ''
                              }${
                                unit?.nameRU && unit?.tradeMark
                                  ? `${t('app.trademark')}: ${
                                      unit?.tradeMark
                                    }. `
                                  : ''
                              }${
                                unit?.nameRU && unit?.state === UNIT_STATES.NEW
                                  ? `${t('app.conditionNew')} `
                                  : unit?.state &&
                                    unit?.state === UNIT_STATES.USED
                                  ? `${t('app.conditionUsed')} `
                                  : ''
                              }${
                                  unit?.nameEN ? unit?.nameEN + '. ' : ''
                              }${
                                unit?.nameEN && unit?.tradeMark
                                  ? 'Trademark: ' + unit?.tradeMark + '. '
                                  : ''
                              }${
                                unit?.nameEN && unit?.state === UNIT_STATES.NEW
                                  ? 'Condition: new. '
                                  : unit?.state &&
                                    unit?.state === UNIT_STATES.USED
                                  ? 'Condition: used. '
                                  : ''
                              }`}
                              placeholder='Наручные часы; Материал: Нержавеющая сталь; Товарный знак: отсутствует. Ручная работа / Wrist watch; Material: Stainless steel; Trademark: no; Handmade.'
                              disabled
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                setIsOpenEditingUnitDescription(true);
                                setEditingUnitSelector(fieldNamePrefix);
                                setEditingPackageIndex(0);
                                setEditingUnitIndex(unitIndex);
                              }}
                              endAdornment={
                                <InputAdornment position='end'>
                                  <EditIcon />
                                </InputAdornment>
                              }
                            />
                            <Box
                              visibility={
                                (isErrored(0, unitIndex, 'state') &&
                                  isTouched(0, unitIndex, 'state')) ||
                                (isErrored(0, unitIndex, 'nameRU') &&
                                  isTouched(0, unitIndex, 'nameRU')) ||
                                (isErrored(0, unitIndex, 'nameEN') &&
                                  isTouched(0, unitIndex, 'nameEN')) ||
                                (isErrored(0, unitIndex, 'tradeMark') &&
                                  isTouched(0, unitIndex, 'tradeMark'))
                                  ? 'visible'
                                  : 'hidden'
                              }
                            >
                              <FormHelperText style={{ color: COLORS.RED }}>
                                {t('app.descriptionErrors')}
                              </FormHelperText>
                            </Box>
                          </FormControl>
                        </Grid>
                      </Grid>
                      <Grid
                        container
                        spacing={2}
                        lg={12}
                        style={showPreRemoveStyle(`0.${unitIndex}`)}
                      >
                        <Grid item xs={6} lg={2}>
                          <Field name={`${fieldNamePrefix}.quantity`}>
                            {({
                              field: { value, ...field },
                              meta,
                            }: FieldProps) => (
                              <FormControl
                                error={!!(meta.touched && meta.error)}
                              >
                                <InputLabel
                                  shrink={false}
                                  htmlFor={`input-quantity-product-${unitIndex}`}
                                  style={{
                                    fontSize: '13px',
                                    whiteSpace: 'nowrap',
                                  }}
                                >
                                  {t('app.quantity')}
                                </InputLabel>
                                <Input
                                  disableUnderline
                                  {...field}
                                  inputProps={{
                                    type: 'number',
                                    min: 1,
                                    step: 1,
                                  }}
                                  startAdornment={
                                    <InputAdornment position='end'>
                                      {t('app.pcs')}
                                    </InputAdornment>
                                  }
                                  defaultValue={value}
                                  onChange={handlerChange}
                                  id={`input-quantity-product-${unitIndex}`}
                                />
                                {meta.touched && meta.error && (
                                  <FormHelperText
                                    style={{
                                      display: 'inline',
                                    }}
                                  >
                                    {meta.error}
                                  </FormHelperText>
                                )}
                              </FormControl>
                            )}
                          </Field>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={2}>
                          <FieldPrice
                            currency={currency}
                            fieldName={fieldNamePrefix}
                            unitIndex={unitIndex}
                            handlerChange={handlerChange}
                            isMarketPlace={isMarketPlace}
                          />
                        </Grid>
                        <Grid item sm={6} lg={1}>
                          <Field name='currency'>
                            {({ field, meta }: FieldProps) => (
                              <FormControl
                                error={!!(meta.touched && meta.error)}
                              >
                                <InputLabel
                                  style={{
                                    fontSize: '13px',
                                    whiteSpace: 'nowrap',
                                  }}
                                  shrink={false}
                                  htmlFor='input-price-unit'
                                >
                                  {t('app.currency')}
                                </InputLabel>
                                <Select
                                  id='input-price-unit'
                                  disableUnderline
                                  {...field}
                                  onChange={(e) => {
                                    setFieldValue('currency', e.target.value);
                                    setUpdateView &&
                                      setUpdateView(
                                        (prevUpdateView) => prevUpdateView + 1,
                                      );
                                  }}
                                  style={{ width: '70px' }}
                                >
                                  <MenuItem
                                    value={CURRENCIES.RUB.toLowerCase()}
                                  >
                                    {CURRENCIES.RUB}
                                  </MenuItem>
                                  <MenuItem
                                    value={CURRENCIES.EUR.toLowerCase()}
                                  >
                                    {CURRENCIES.EUR}
                                  </MenuItem>
                                  <MenuItem
                                    value={CURRENCIES.USD.toLowerCase()}
                                  >
                                    {CURRENCIES.USD}
                                  </MenuItem>
                                  <MenuItem
                                    value={CURRENCIES.GBP.toLowerCase()}
                                  >
                                    {CURRENCIES.GBP}
                                  </MenuItem>
                                </Select>
                                {meta.touched && meta.error && (
                                  <FormHelperText>{meta.error}</FormHelperText>
                                )}
                              </FormControl>
                            )}
                          </Field>
                        </Grid>
                        <Grid item xs={12} sm={6} lg={3}>
                          <FieldCountry
                            countries={countries}
                            setFieldValue={setFieldValue}
                            fieldName={fieldNamePrefix}
                            unitIndex={unitIndex}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={2}>
                          <FieldWeightNet
                            handlerChange={handlerChange}
                            fieldName={fieldNamePrefix}
                            unitIndex={unitIndex}
                          />
                        </Grid>
                        <Grid item xs={12} sm={6} lg={2}>
                          <CodeTnVed
                            handlerChange={handlerChange}
                            fieldName={fieldNamePrefix}
                            unitIndex={unitIndex}
                          />
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                );
              })}

              <Box display={'flex'} justifyContent={'center'} marginTop={2}>
                <Button
                  startIcon={<AddIcon />}
                  endIcon={<AppsIcon />}
                  variant='contained'
                  color='primary'
                  size='small'
                  onClick={(e) => {
                    e.preventDefault();
                    helpers.push(getInitialUnit());
                  }}
                >
                  {t('app.addNameNo')}{' '}
                  {packages[0]?.units?.length
                    ? packages[0]?.units?.length + 1
                    : null}
                </Button>
              </Box>

              <PackagesRezult
                packages={packages}
                units={units}
                isCalculatorPage={isCalculatorPage}
                currency={currency}
              />
            </>
          )}
        />
      )}
      {/* Инфо о грузе конец */}

      <ModalBox
        isOpen={isOpenEditingUnitDescription && !!editingUnitSelector}
        setOpen={setIsOpenEditingUnitDescription}
      >
        <Typography variant='h6' align='center'>
          {t('app.itemDescription2')} {editingPackageIndex + 1}.
          {editingUnitIndex + 1}
        </Typography>

        <Description
          editingUnitSelector={editingUnitSelector}
          setFieldValue={setFieldValue}
        />

        <Box display='flex' justifyContent='center' mt={2}>
          <Button
            variant='contained'
            onClick={() => setIsOpenEditingUnitDescription(false)}
            color='secondary'
          >
            {t('app.close')}
          </Button>
        </Box>
      </ModalBox>

      <ModalBox
        isOpen={
          isOpenModalMyPackages &&
          (!!editingPackageIndex || editingPackageIndex === 0)
        }
        setOpen={setIsOpenModalMyPackages}
      >
        {!!cachedPackages?.getPackagingTemplatesList?.rows?.length && (
          <Autocomplete
            options={
              cachedPackages?.getPackagingTemplatesList
                ?.rows as PackagingTemplate[]
            }
            getOptionLabel={(option) => {
              return `${option?.name || ''} [${option?.length}x${
                option?.width
              }x${option?.height}] ${
                option?.weight ? option?.weight + ` ${t('app.kg')}` : ''
              } `;
            }}
            renderInput={(params) => (
              <div ref={params.InputProps.ref}>
                <TextField
                  label={t('app.selectPackageItem')}
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'my_packages',
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='end'>
                        <ViewModuleIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            )}
            onChange={(e, newValue) => {
              const id = newValue?.id && +newValue?.id ? +newValue?.id : null;
              if (id) {
                const packageSelected =
                  cachedPackages?.getPackagingTemplatesList?.rows?.find(
                    (p) => p?.id && +p.id === +id,
                  );
                if (packageSelected && setValues) {
                  // @ts-ignore
                  setValues((prevState) => {
                    const newPackage = prevState?.packages?.map((p, i) => {
                      if (i === editingPackageIndex) {
                        return {
                          ...p,
                          heightCm: packageSelected.height || 0,
                          lengthCm: packageSelected.length || 0,
                          weightKg: packageSelected.weight || 0,
                          widthCm: packageSelected.width || 0,
                        };
                      }

                      return p;
                    });

                    return {
                      ...prevState,
                      packages: newPackage,
                    };
                  });
                  setUpdateView && setUpdateView((prevState) => prevState + 1);
                }
              }
            }}
          />
        )}
      </ModalBox>

      <ModalBox
        isOpen={
          isOpenModalMyProducts &&
          (!!editingUnitIndex || editingUnitIndex === 0)
        }
        setOpen={setIsOpenModalMyProducts}
      >
        {!!cachedProducts?.getMyProductList?.rows?.length && (
          <Autocomplete
            noOptionsText='Таких товаров не найдено'
            forcePopupIcon={false}
            options={cachedProducts?.getMyProductList?.rows as Product[]}
            renderInput={(params) => (
              <div ref={params.InputProps.ref}>
                <TextField
                  label={t('app.selectPackageItem')}
                  {...params}
                  inputProps={{
                    ...params.inputProps,
                    autoComplete: 'my_products',
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position='end'>
                        <ViewModuleIcon />
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            )}
            renderOption={(option) => {
              return (
                <>
                  {option?.name || ''} {option?.sku ? `[${option?.sku}]` : ''}
                </>
              );
            }}
            getOptionLabel={(option) => {
              return `${option?.name || ''} ${
                option?.sku ? '[' + option?.sku + ']' : ''
              }`;
            }}
            onChange={(e, newValue) => {
              const id = newValue?.id && +newValue?.id ? +newValue?.id : null;
              if (id) {
                const product = cachedProducts?.getMyProductList?.rows?.find(
                  (p) => p && +p.id === +id,
                );
                if (product && setValues) {
                  let myProductPrice = product.declaredValue || 0;
                  if (
                    currency.toUpperCase() !== CURRENCIES.USD.toUpperCase() &&
                    dataExchangeRates?.getTheExchangeRate?.exchangeRate?.length
                  ) {
                    const rateUSD =
                      dataExchangeRates?.getTheExchangeRate.exchangeRate.find(
                        (i) =>
                          i &&
                          i.code.toUpperCase() === CURRENCIES.USD.toUpperCase(),
                      )?.value;
                    const rateSelected =
                      dataExchangeRates?.getTheExchangeRate.exchangeRate.find(
                        (i) =>
                          i && i.code.toUpperCase() === currency.toUpperCase(),
                      )?.value;

                    if (rateUSD) {
                      if (
                        currency.toUpperCase() === CURRENCIES.RUB.toUpperCase()
                      ) {
                        /*
                                            // TODO: page scroll to up after selecting my product and showing snackbar,
                                            // TODO: need to be fixed somehow, useState('') and useEffect doesn't help here.
                                            enqueueSnackbar(
                                                `Стоимость товара ${product.name} сконвертирована:
                                                ${myProductPrice} ${CURRENCIES.USD.toUpperCase()} * ${rateUSD}
                                                = ${myProductPrice * parseFloat(rateUSD)} ${CURRENCIES.RUB.toUpperCase()}`,
                                                {variant: 'success'}
                                            )
                                            */
                        myProductPrice = myProductPrice * parseFloat(rateUSD);
                      } else if (rateSelected) {
                        /*
                                            // TODO: page scroll to up after selecting my product and showing snackbar,
                                            // TODO: need to be fixed somehow, useState('') and useEffect doesn't help here.
                                            enqueueSnackbar(
                                                `Стоимость товара ${product.name} сконвертирована:
                                                 ${myProductPrice} ${CURRENCIES.USD.toUpperCase()} / ${rateSelected} * ${rateUSD}
                                                 = ${myProductPrice / parseFloat(rateSelected) * parseFloat(rateUSD)} ${currency.toUpperCase()}`,
                                                {variant: 'success'}
                                            )
                                            */
                        myProductPrice =
                          (myProductPrice / parseFloat(rateSelected)) *
                          parseFloat(rateUSD);
                      }
                      myProductPrice = +myProductPrice.toFixed(2);
                    }
                  }

                  // @ts-ignore
                  setValues((prevState) => {
                    const newP = prevState?.packages?.map((p, i) => {
                      if (i === editingPackageIndex) {
                        return {
                          ...p,
                          units: p?.units?.map((u, i) => {
                            if (i === editingUnitIndex) {
                              return {
                                ...u,
                                price: myProductPrice,
                                code: product.code || null,
                                country:
                                  countries?.find(
                                    (c) =>
                                      c &&
                                      c.id &&
                                      product?.country?.id &&
                                      +c.id === +product?.country?.id,
                                  ) || '',
                                nameRU: product.detailsDescriptionRU || '',
                                netWeight: product.netWeight || 0,
                                nameEN: product.detailsDescriptionEN || '',
                                tradeMark: product.trademark || '',
                                sku: product.sku || '',
                              } as any;
                            }
                            return u;
                          }),
                        };
                      }
                      return p;
                    });

                    return {
                      ...prevState,
                      packages: newP,
                    };
                  });
                  setUpdateView && setUpdateView((prevState) => prevState + 1);
                }
              }
            }}
          />
        )}
      </ModalBox>
    </>
  );
};

export default Packages;
