import { FastField, FieldProps } from 'formik';
import {
  FormControl,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import { BoxCentered } from '../../BoxCentered/BoxCentered';
import { RenderCountryFlagImage } from '../../../utils/helperComponents';
import React from 'react';
import { DeliveryCountry } from '../../../generated/graphql';
import { TFormikSetFieldValueDebounce } from '../../../interfaces';
import { useTranslation } from 'react-i18next';

const FieldCountry: React.FC<{
  fieldName?: string;
  unitIndex?: number;
  countries: DeliveryCountry[];
  setFieldValue: TFormikSetFieldValueDebounce;
  isDisabled?: boolean;
}> = ({ fieldName, unitIndex, countries, setFieldValue, isDisabled }) => {
  const { t } = useTranslation();

  return (
    <FastField name={fieldName ? fieldName + '.country' : 'countryId'}>
      {({ field: { value, ...field }, meta }: FieldProps) => (
        <FormControl error={!!(meta.touched && meta.error)}>
          <Grid container>
            <InputLabel
              style={{ fontSize: '17px', whiteSpace: 'nowrap' }}
              htmlFor={`input-country-product-${unitIndex}`}
            >
              {t('app.countryOfOrigin')}
            </InputLabel>
          </Grid>

          <Select
            disableUnderline
            displayEmpty
            disabled={isDisabled}
            value={value || ''}
            onChange={(event) => {
              setFieldValue(
                fieldName ? `${fieldName}.country` : 'countryId',
                event.target.value || '',
              );
            }}
            renderValue={
              value !== ''
                ? () => (
                    <BoxCentered justifyContent='flex-start'>
                      {value?.iso && (
                        <RenderCountryFlagImage
                          countryISO={value?.iso}
                          countryName={value?.nameEng}
                        />
                      )}
                      {value?.nameEng}
                    </BoxCentered>
                  )
                : () => <div>{t('app.selectCountry')}</div>
            }
          >
            {countries?.map((country) => {
              return (
                country && (
                  <MenuItem key={country?.id} value={country as any}>
                    <BoxCentered justifyContent='flex-start'>
                      {country?.iso && (
                        <RenderCountryFlagImage
                          countryISO={country?.iso}
                          countryName={country?.nameEng}
                        />
                      )}
                      {country?.nameEng}
                    </BoxCentered>
                  </MenuItem>
                )
              );
            })}
          </Select>
          {meta.touched && meta.error && (
            <FormHelperText>{meta.error}</FormHelperText>
          )}
        </FormControl>
      )}
    </FastField>
  );
};

export default FieldCountry;
