import { createTheme } from '@material-ui/core/styles';
import createBreakpoints from '@material-ui/core/styles/createBreakpoints';

// Allow to use breakpoints without theme.breakpoints
const breakpoints = createBreakpoints({});

export default createTheme({
  overrides: {
    MuiTooltip: {
      tooltip: {
        fontSize: 16,
      },
    },
    MuiTableCell: {
      root: {
        fontSize: '14px',
        lineHeight: '20px',
        color: '#000000',
        borderBottom: 'none',
      },
      head: {
        fontSize: '16px',
        padding: 10,
        lineHeight: '24px',
        marginBottom: 12,
        fontWeight: 500,
        color: '#000000',
      },
    },
    // @ts-ignore
    MuiPagination: {
      root: {
        '& .Mui-selected': {
          backgroundColor: '#000000',

          '&:hover': {
            backgroundColor: '#393939',
          },
        },
      },
    },
    MuiLink: {
      root: {
        color: '#828282',
      },
    },
    MuiIconButton: {
      root: {
        color: '#333333',
      },
    },
    Divider: {
      root: {
        backgroundColor: '#e0e0e0',
      },
    },
    MuiButton: {
      root: {
        alignSelf: 'flex-start',
        fontSize: '15px',
        fontWeight: 500,
        lineHeight: '24px',
        textTransform: 'none',
        width: 'auto',
        borderRadius: 'none',
        boxShadow: 'none',
      },
      contained: {
        padding: '12px 24px',
        boxShadow: 'none',
        backgroundColor: '#333333',
        color: '#fff',
        '&:hover': {
          backgroundColor: '#1d1d1d',
        },
      },
      outlined: {
        color: '#000000',
        border: '1px solid #000000',
        padding: '12px 20px',
        minWidth: '177px',
        '&:hover': {
          borderColor: '#bfb3b3',
        },
      },
      containedSecondary: {
        boxShadow: 'none',
        backgroundColor: '#bdbdbd',
        color: '#4f4f4f',
        '&:hover': {
          backgroundColor: '#c1c1c1',
        },
      },
    },
    MuiCheckbox: {
      colorPrimary: {
        color: '#000000',

        '&$checked': {
          color: '#000000',
        },
      },
    },
    MuiRadio: {
      colorPrimary: {
        color: '#000000',

        '&$checked': {
          color: '#000000',
        },
      },
    },
    MuiTypography: {
      h2: {
        fontWeight: 500,
        fontSize: '30px',
        lineHeight: '35px',
        color: '#333333',

        [breakpoints.down('xs')]: {
          fontSize: '25px',
          lineHeight: '30px',
        },
      },
      h3: {
        fontWeight: 500,
        fontSize: '20px',
        lineHeight: '24px',
      },
    },
    MuiFormControl: {
      root: {
        width: '100%',
      },
    },
    MuiInputLabel: {
      root: {
        fontWeight: 500,
        marginBottom: 8,
        color: '#333333',
        fontSize: '16px',
        lineHeight: '24px',
      },
      formControl: {
        transform: 'none',
        position: 'static',
      },
    },
    MuiSelect: {
      root: {
        border: 'none',
      },
      icon: {
        color: '#000000',
      },
    },
    MuiInput: {
      root: {
        border: '1px solid #4f4f4f',
      },
      input: {
        height: 'auto',
        padding: '13px 16px',
        fontSize: '14px',
        color: '#000',
        lineHeight: '20px',
      },
      formControl: {
        'label + &': {
          marginTop: 0,
        },
      },
    },
    MuiAutocomplete: {
      input: {
        height: 'auto',
        padding: '13px 16px !important',
        fontSize: '14px',
        color: '#000',
        lineHeight: '20px',
      },
    },
    MuiCssBaseline: {
      '@global': {
        '.buttonForSelected': {
          alignSelf: 'center',
          marginLeft: 0,
          marginBottom: 10,

          [breakpoints.up('sm')]: {
            marginLeft: '32px',
            marginBottom: 0,
          },
        },
        '.filtersSearch': {
          display: 'flex',
          marginLeft: 'auto',

          [breakpoints.down('xs')]: {
            width: '100%',
          },
        },
        '.filtersSearchField': {
          width: 162,
          height: 44,

          [breakpoints.down('xs')]: {
            width: '100%',
          },
        },
        '.filtersSearchSubmit': {
          backgroundColor: '#000000',
          padding: 0,
          minWidth: 'auto',
          width: 44,
          height: 44,
          '&:hover': {
            backgroundColor: '#333333',
          },
        },
        '.filtersItemSelect': {
          width: 'auto !important',
          '& .MuiInput-formControl': {
            border: 'none',

            [breakpoints.down('xs')]: {
              '& .MuiInput-input': {
                paddingLeft: 0,
              },
            },
          },
        },
        '.cursorPointer': {
          cursor: 'pointer',
          textDecoration: 'underline',
        },
      },
    },
    MuiList: {
      padding: {
        paddingTop: 0,
        paddingBottom: 0,
      },
    },
  },
});
