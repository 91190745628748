export const MAX_PARCEL_WEIGHT = 150;
export const MAX_PARCEL_DIMENSION = 120;

export const DATE_FORMAT = 'DD.MM.YYYY';
export const DATE_TIME_FORMAT = 'DD.MM.YYYY H:mm:ss';

export const ERRORS = {
  CANT_CANCEL_PARCEL_WITH_IS_SENT: 'app.cannotCancelOrder',
  FIELD_REQUIRED: 'app.fillInTheField',
  POSITIVE_NUMBER: 'app.positiveNumber',
  MAX_WEIGHT: 'app.maxWeightError',
  MAX_DIMENSION: 'app.maxDimensionsError',
  MAX_SYMBOLS: 'app.maxCharacters',
  MAX_VALUE: 'app.maxValue',
};

export const TEXT = {
  GROSS: 'app.grossDefinition',
  NET: 'app.netDefinition',
  CLICK_TO_MAKE_A_CALL: 'app.clickToDial',
  CLICK_TO_SEND_AN_EMAIL: 'app.clickToSendEmail',
  SERVICE_IS_NOT_AVAILABLE_YET: 'app.integrationNotImplemented',
};

export const COLORS = {
  GREEN: '#048c04',
  BLUE: '#0b82f7',
  RED: '#ff0000',
  YELLOW: '#ffc749',
  ORANGE: '#ff6e00',
  GREY: '#808080',
  WHITE: '#ffffff',
  BLACK: '#000000',
};

export const PAYMENT_TYPES: { [key: string]: string } = {
  REFILL: 'app.accountReplenishment',
  REFUND: 'app.refund2',
  ORDER_PAYMENT: 'app.debitForOrder',
  CDEK: 'app.shipmentToWarehouseViaCDEK',
  CANCEL_ADJUSTMENT: 'app.cancelWeightCorrection',
  WEIGHT_ADJUSTMENT: 'app.weightCorrection',
  WAREHOUSE_STORAGE: 'app.storageOnWarehouse',
};
export const MOVEMENT_OPERATIONS: { [key: string]: string } = {
  REFILL: 'app.refill2',
  ORDER: 'app.order',
  DEFILL: 'app.deduction',
  ORDER_REFUND: 'app.refund',
  WAREHOUSE: 'app.arrivalAtWarehouse',
};

export const REPORT_TYPES: { [key: string]: string } = {
  SHIPMENTS: 'app.shipmentReport',
  TRANSACTIONS: 'app.transactionReport',
};

// SEND TO WAREHOUSE TARIFF CODES
export const WAREHOUSE_TARIFFS = {
  // Посылка склад-склад
  CDEK_WAREHOUSE: '136',
  // Посылка дверь-склад
  CDEK_DOOR: '138',
  // Магистральный экспресс склад-склад
  CDEK_MAINLINE_EXPRESS_WAREHOUSE: '62',
  // Магистральный супер-экспресс склад-склад
  CDEK_MAINLINE_SUPER_EXPRESS_WAREHOUSE: '63',
  // Самостоятельно
  SELF_EXPORT: '999',
};

export const CURRENCIES = {
  RUB: 'RUB',
  EUR: 'EUR',
  USD: 'USD',
  GBP: 'GBP',
};

export const COUNTRIES_CIS = [
  {
    iso: 'AZ',
    name: 'Азербайджанская Республика',
    nameEng: 'Azerbaijan Republic',
    id: 248,
  },
  {
    iso: 'AM',
    name: 'Республика Армения',
    nameEng: 'Respublika Armenia',
    id: 244,
  },
  {
    iso: 'BY',
    name: 'Республика Беларусь',
    nameEng: 'Republic of Belarus',
    id: 253,
  },
  {
    iso: 'KZ',
    name: 'Республика Казахстан',
    nameEng: 'Republic of Kazakhstan',
    id: 339,
  },
  { iso: 'KG', name: 'Кыргызская Республика', nameEng: 'Kyrgyzstan', id: 345 },
  { iso: 'MD', name: 'Республика Молдова', nameEng: 'Moldova', id: 369 },
  { iso: 'RU', name: 'Россия', nameEng: 'Russia', id: 459 },
  { iso: 'TJ', name: 'Республика Таджикистан', nameEng: 'Tajikistan', id: 432 },
  { iso: 'TM', name: 'Туркменистан', nameEng: 'Turkmenistan', id: 441 },
  { iso: 'UZ', name: 'Республика Узбекистан', nameEng: 'Uzbekistan', id: 450 },
  { iso: 'UA', name: 'Украина', nameEng: 'Ukraine', id: 445 },
];

export const LOCAL_STORAGE_KEYS = {
  AUTH_TOKEN: 'authToken',
  CDEK_REGIONS: 'CDEKRegions',
  CDEK_CITIES: 'CDEKCities',
  ITEMS_LIMIT_SHIPMENTS_ALL_HISTORY: 'itemsLimit',
  WAREHOUSE_ID: 'warehouseId',
  WAREHOUSE_ID_ORDER: 'warehouseIdOrder',
  ITEMS_LIMIT_MARKETPLACE_ORDERS: 'itemsLimitMarketplaceOrders',
  ITEMS_LIMIT_RETURNS: 'itemsLimitReturns',
  IS_SIDEBAR_HIDDEN: 'isSidebarHidden',
  IS_LIST_OPEN_SENDING: 'isListOpenSending',
  IS_LIST_OPEN_PRODUCTS_AND_WAREHOUSE: 'isListOpenProductsAndWarehouse',
  IS_LIST_OPEN_ORDERS: 'isListOpenOrders',
  IS_LIST_OPEN_INTEGRATION: 'isListOpenIntegration',
  IS_LIST_OPEN_SENDING_TO_WAREHOUSE: 'isListOpenSendingToWarehouse',
  IS_LIST_REPORTS: 'isListOpenReports',
  DELIVERY_COUNTRIES: 'deliveryCountries',
  TAB_VALUE: 'tabValue',
};

export const UNIT_STATES = {
  UNKNOWN: 'UNKNOWN',
  NEW: 'NEW',
  USED: 'USED',
};

export const EXPORT_REASONS = {
  COMMERCIAL: { en: 'COMMERCIAL', ru: 'Продажа' },
  GIFT: { en: 'GIFT', ru: 'Подарок' },
  SAMPLE: { en: 'SAMPLE', ru: 'Образец' },
  RETURN: { en: 'RETURN', ru: 'Возврат' },
  REPAIR: { en: 'REPAIR', ru: 'Возврат для ремонта' },
};

export const PAYMENT_OF_TAXES = {
  RECIPIENT: { en: 'RECIPIENT', ru: 'Получатель' },
  SENDER: { en: 'SENDER', ru: 'Отправитель' },
};

export const PAYMENT_OF_TAXES_IMPORT = {
  CONSIGNEE: { en: 'CONSIGNEE', ru: 'Получатель' },
  SENDER: { en: 'SENDER', ru: 'Отправитель' },
};

export const VATKEY = {
  VAT: 'VAT',
  IOSS: 'IOSS',
  EIN: 'EIN',
  EMPTY: 'EMPTY', //default
};

export const MARKETPLACES = {
  AMAZON: 'amazon',
  EBAY: 'ebay',
  ETSY: 'etsy',
};

export const PARCEL_FIELDS = {
  RECEIVER: {
    NAME: 'receiverName',
    COMPANY: 'receiverCompany',
    COUNTRY: 'receiverCountry',
    ADDRESS: 'receiverAddress',
    ADDRESS2: 'receiverAddress2',
    ADDRESS3: 'receiverAddress3',
    ZIP_CODE: 'receiverZipCode',
    CITY: 'receiverCity',
    STATE: 'receiverState',
    PHONE: 'receiverPhone',
    EMAIL: 'receiverEmail',
  },
  PACKAGE: {
    WEIGHT: 'packageWeight',
    LENGTH: 'packageLength',
    WIDTH: 'packageWidth',
    HEIGHT: 'packageHeight',
  },
  OTHER: {
    ID: 'id',
  },
};

export const DEBOUNCE = 10;

export const DELIVERY_STATUS_BY_ID: { [key: number]: string } = {
  1: 'app.created',
  3: 'app.inTransit',
  4: 'app.accepted',
  5: 'app.delivered',
  7: 'app.exception',
  9: 'app.canceled',
};

export const PAYMENT_ORDER_STATUS_BY_ID: { [key: number]: string } = {
  1: 'app.awaitingPayment',
  2: 'app.paid',
  3: 'app.partialPayment',
  4: 'app.refund',
  5: 'app.notRequired',
};

export enum ShipmentStep4ValuesName {
  SENDER_MARK = 'senderMark',
  ADDITIONAL_INSURANCE = 'additionalInsurance',
  INSURANCE_AMOUNT = 'insuranceAmount',
  SIGNATURE = 'signature',
}
