import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import {
  Box,
  Container,
  Drawer,
  LinearProgress,
  SwipeableDrawer,
  Tooltip,
} from '@material-ui/core';
import Sidebar from '../../components/Sidebar/Sidebar';
import PrivateRoutes from '../../routes/PrivateRoutes';
import useStyles from './styles';
import { useSnackbar } from 'notistack';
import {
  GetCurrentUserQuery,
  GetCurrentUserQueryVariables,
  useGetDeliveryCountriesLazyQuery,
  useGetMyProductListQuery,
  useGetPackagingTemplatesListQuery,
  useGetPublicConfigurationsQuery,
  useGetStatusesListQuery,
  useGetTheExchangeRateQuery,
  User,
} from '../../generated/graphql';
import Footer from '../../components/Footer/Footer';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import PPIcon from '../../assets/img/PPlogo.png';
import { QUERY_DELIVERY_COUNTRIES } from '../../GraphQL/queries/getDeliveryCountries';
import { useApolloClient } from '@apollo/client';
import { LOCAL_STORAGE_KEYS } from '../../utils/constants';
import { QUERY_CURRENT_USER } from '../../GraphQL/queries/getCurrentUser';
import { Header } from '../../components/Header/Header';
import { useTranslation } from 'react-i18next';

const PrivateLayout: React.FC<{
  user: User;
}> = ({ user }) => {
  const client = useApolloClient();
  const { enqueueSnackbar } = useSnackbar();

  const { t } = useTranslation();

  const [isMobileOpen, setMobileOpen] = useState(false);
  const [isSidebarHidden, setIsSidebarHidden] = useState<boolean>(
    localStorage.getItem(LOCAL_STORAGE_KEYS.IS_SIDEBAR_HIDDEN) === 'true' ||
      false,
  );
  const classes = useStyles(isSidebarHidden);

  const [
    queryLazyCountries,
    {
      data: dataQueryLazyCountries,
      loading: isLoadingQueryLazyCountries,
      error: errorQueryLazyCountries,
    },
  ] = useGetDeliveryCountriesLazyQuery();

  useEffect(() => {
    const deliveryCountries = JSON.parse(
      localStorage.getItem(LOCAL_STORAGE_KEYS.DELIVERY_COUNTRIES) || '[]',
    );
    if (
      !deliveryCountries ||
      !deliveryCountries.length ||
      !deliveryCountries[0].id
    ) {
      queryLazyCountries();
    } else {
      client.writeQuery({
        query: QUERY_DELIVERY_COUNTRIES,
        data: {
          deliveryCountries: deliveryCountries,
        },
      });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (dataQueryLazyCountries?.deliveryCountries?.length) {
      localStorage.setItem(
        LOCAL_STORAGE_KEYS.DELIVERY_COUNTRIES,
        JSON.stringify(dataQueryLazyCountries.deliveryCountries),
      );
    }
  }, [dataQueryLazyCountries]);

  const { loading: isLoadingStatuses, error: errorStatuses } =
    useGetStatusesListQuery({
      fetchPolicy: 'cache-first',
    });

  const { loading: isLoadingExchangeRates, error: errorExchangeRates } =
    useGetTheExchangeRateQuery({
      fetchPolicy: 'cache-first',
    });

  const { loading: isLoadingProducts, error: errorProducts } =
    useGetMyProductListQuery({
      fetchPolicy: 'cache-first',
    });

  const { loading: isLoadingMyPackages, error: errorMyPackages } =
    useGetPackagingTemplatesListQuery({
      fetchPolicy: 'cache-first',
    });

  const {
    loading: isLoadingPublicConfigurations,
    error: errorPublicConfigurations,
  } = useGetPublicConfigurationsQuery({
    fetchPolicy: 'cache-first',
  });

  const dataCachedQueryCurrentUser = client.readQuery<
    GetCurrentUserQuery,
    GetCurrentUserQueryVariables
  >({
    query: QUERY_CURRENT_USER,
  });

  if (
    isLoadingStatuses ||
    isLoadingExchangeRates ||
    isLoadingQueryLazyCountries ||
    isLoadingProducts ||
    isLoadingMyPackages ||
    isLoadingPublicConfigurations
  ) {
    return <LinearProgress style={{ height: 10 }} />;
  }

  errorQueryLazyCountries?.message &&
    enqueueSnackbar(errorQueryLazyCountries.message, { variant: 'error' });
  errorStatuses?.message &&
    enqueueSnackbar(errorStatuses.message, { variant: 'error' });
  errorExchangeRates?.message &&
    enqueueSnackbar(errorExchangeRates.message, { variant: 'error' });
  errorProducts?.message &&
    enqueueSnackbar(errorProducts.message, { variant: 'error' });
  errorMyPackages?.message &&
    enqueueSnackbar(errorMyPackages.message, { variant: 'error' });
  errorPublicConfigurations?.message &&
    enqueueSnackbar(errorPublicConfigurations.message, { variant: 'error' });

  const handleToggleSidebar = () => {
    setIsSidebarHidden((prevState) => {
      localStorage.setItem(
        LOCAL_STORAGE_KEYS.IS_SIDEBAR_HIDDEN,
        prevState ? 'false' : 'true',
      );
      return !prevState;
    });
  };

  return (
    <div className={classes.root}>
      <Header
        isSidebarDisabled={false}
        currentUser={dataCachedQueryCurrentUser}
        user={user}
        isSidebarHidden={isSidebarHidden}
        isMobileOpen={isMobileOpen}
        setMobileOpen={setMobileOpen}
      />

      {/* Sidebar */}
      <nav className={classes.drawer}>
        <Box sx={{ display: { xs: 'block', sm: 'none' } }}>
          <SwipeableDrawer
            variant='temporary'
            open={isMobileOpen}
            onOpen={() => {}}
            onClose={() => setMobileOpen(false)}
            classes={{ paper: classes.drawerPaper }}
            ModalProps={{ keepMounted: true }}
          >
            <span className={classes.logo}>
              Pick<span className={classes.logoInner}>nPack</span>
            </span>
            <Sidebar onSelectItem={() => setMobileOpen(false)} />
          </SwipeableDrawer>
        </Box>
        <Box sx={{ display: { xs: 'none', sm: 'block' } }}>
          <Drawer
            classes={{ paper: classes.drawerPaper }}
            variant='permanent'
            open
          >
            <Link to='/' className={classes.logoLink}>
              {isSidebarHidden ? (
                <img
                  src={PPIcon}
                  alt='PicknPack'
                  className={classes.logoWithHiddenSidebar}
                />
              ) : (
                <span className={classes.logo}>
                  Pick<span className={classes.logoInner}>nPack</span>
                  <br />
                  <span className={classes.logoDescriprion}>Freight</span>
                </span>
              )}
            </Link>
            <Sidebar isSidebarHidden={isSidebarHidden} />
            <Box
              className={classes.sidebarToggler}
              onClick={() => handleToggleSidebar()}
            >
              <Tooltip
                title={
                  isSidebarHidden
                    ? t('app.expandSidebar')
                    : t('app.collapseSidebar')
                }
              >
                {isSidebarHidden ? (
                  <ArrowForwardIosIcon />
                ) : (
                  <ArrowBackIosIcon />
                )}
              </Tooltip>
            </Box>
          </Drawer>
        </Box>
      </nav>

      <main className={classes.content}>
        <div className={classes.toolbar} />
        <Container className={classes.container}>
          {/* /web/src/routes/PrivateRoutes.tsx */}
          <PrivateRoutes user={user} />

          {/* Footer */}
          <Footer />
        </Container>
      </main>
    </div>
  );
};

export default PrivateLayout;
