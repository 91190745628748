import { LOCAL_STORAGE_KEYS } from './constants';

const fetchImageFromServer = async (setImageURL: any, user: any) => {
  const token = localStorage.getItem(LOCAL_STORAGE_KEYS.AUTH_TOKEN);
  const base64 = await fetchToBase64Async(
    user?.entityLegalData
      ? `${process.env.REACT_APP_API_DOMAIN}api/get-file/legalEntityData/${user?.entityLegalData?.fileName}`
      : `${process.env.REACT_APP_API_DOMAIN}api/get-file/passportData/${user?.passportData?.scanPath}`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    },
  );
  setImageURL(base64);
};

export default fetchImageFromServer;

const fetchToBase64Async = async (
  url: string,
  init?: RequestInit,
): Promise<string> => {
  try {
    const response = await fetch(url, init);
    if (!response.ok) {
      const responseText = await response.text();
      throw new Error(
        'server status: ' +
          response.status +
          '\n' +
          'server response:' +
          '\n' +
          responseText,
      );
    }
    const blob = await response.blob();

    const base64 = await blobToBase64Async(blob);
    return base64;
  } catch (e) {
    // eslint-disable-next-line
    throw new Error('failed to fetch: ' + url + '\n' + 'caused by: ' + e);
  }
};

const blobToBase64Async = async (blob: Blob): Promise<string> => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.onerror = (e) => reject(fileReader.error);
    fileReader.onloadend = (e) => {
      const dataUrl = fileReader.result as string;
      // remove "data:mime/type;base64," prefix from data url
      const base64 = dataUrl.substring(dataUrl.indexOf(',') + 1);
      resolve(base64);
    };
    fileReader.readAsDataURL(blob);
  });
};
