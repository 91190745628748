import React from 'react';
import { Box, Divider, Grid } from '@material-ui/core';
import useStyles from './styles';
import { TFormikSetFieldValueDebounce } from '../../../interfaces';
import FieldReasonForExport from '../../../components/Packages/FieldReasonForExport/FieldReasonForExport';
import {
  EXPORT_REASONS,
  PAYMENT_OF_TAXES,
  VATKEY,
} from '../../../utils/constants';
import PaymentOfTaxes from '../../../components/Packages/PaymentOfTaxes/PaymentOfTaxes';
import VatKey from '../../../components/Packages/VatKey/VatKey';
import VatValue from '../../../components/Packages/VatValue/VatValue';
import { CardWarning } from '../../../components/CardWarning/CardWarning';
import {
  Contact,
  GetDeliveryCountriesQuery,
  GetDeliveryCountriesQueryVariables,
  ParcelInput,
} from '../../../generated/graphql';
import { QUERY_DELIVERY_COUNTRIES } from '../../../GraphQL/queries/getDeliveryCountries';
import { useApolloClient } from '@apollo/client';
import { useTranslation } from 'react-i18next';
import { useFormikContext } from 'formik';

const ShipmentsFormVatStep: React.FC<{
  setFieldValueDebounce: TFormikSetFieldValueDebounce;
}> = ({ setFieldValueDebounce }) => {
  const classes = useStyles();
  const { t } = useTranslation();
  const client = useApolloClient();
  const { values } = useFormikContext<ParcelInput>();

  const cachedCountries = client.readQuery<
    GetDeliveryCountriesQuery,
    GetDeliveryCountriesQueryVariables
  >({
    query: QUERY_DELIVERY_COUNTRIES,
  });

  const setHtmlUrlsFromString = (string: string) => {
    if (string) {
      return string.replace(/(((https?:\/\/)|(www\.))[^\s]+)/g, (url) => {
        return `<a href="${url}" target="_blank">${url}</a>`;
      });
    } else {
      return string;
    }
  };

  const handlerChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    const name = e.target.name;
    const value = e.target.value;

    setFieldValueDebounce(name, value);
  };

  const receiver = values.receiver as unknown as Contact;

  return (
    <div className={classes.wrapper}>
      <Grid container spacing={5}>
        <Grid item xs={12} sm={6}>
          <FieldReasonForExport
            fieldName='exportReason'
            types={EXPORT_REASONS}
            setFieldValue={setFieldValueDebounce}
          />
        </Grid>

        <Grid item xs={12} sm={6}>
          <PaymentOfTaxes
            fieldName='paymentOfTaxes'
            types={PAYMENT_OF_TAXES}
            setFieldValue={setFieldValueDebounce}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <VatKey
            fieldName='vatKey'
            types={VATKEY}
            setFieldValue={setFieldValueDebounce}
          />
        </Grid>
        <Grid item xs={12} sm={6}>
          <VatValue
            handlerChange={handlerChange}
            fieldName='vatValue'
            label={values.vatKey || ''}
          />
        </Grid>
      </Grid>
      <Box mt={7.125} mb={8.125}>
        <Divider />
      </Box>
      {receiver?.country &&
        cachedCountries?.deliveryCountries?.find(
          (c) => c && c.name === receiver?.country?.name,
        )?.customsInfo && (
          <CardWarning title={t('app.customsTaxesInfo')}>
            <div
              dangerouslySetInnerHTML={{
                __html: setHtmlUrlsFromString(
                  cachedCountries?.deliveryCountries
                    ?.find((c) => c && c.name === receiver?.country?.name)
                    ?.customsInfo?.replaceAll('\n', '</br>') || '',
                ) as string,
              }}
            />
          </CardWarning>
        )}
    </div>
  );
};

export default ShipmentsFormVatStep;
