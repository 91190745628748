import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  filters: {
    display: 'flex',
    flexWrap: 'wrap',
    marginBottom: 25,
    marginTop: 25,
    justifyContent: 'space-between',

    [theme.breakpoints.down('xs')]: {
      justifyContent: 'space-between',
    },
  },
  filtersItem: {
    maxWidth: 100,
  },
  filtersItemSelect: {
    width: 'auto',
    '& .MuiInput-formControl': {
      border: 'none',

      [theme.breakpoints.down('xs')]: {
        '& .MuiInput-input': {
          paddingLeft: 0,
        },
      },
    },
  },
  filtersItemSelectIcon: {
    position: 'absolute',
    right: 0,
    top: 13,
    width: 20,
    height: 20,
  },
  shipmentItems: {
    marginBottom: 35,

    [theme.breakpoints.down('xs')]: {
      marginBottom: 15,
    },
  },
  pagination: {
    marginTop: '32px',
    fontWeight: 'bold',
    '& .MuiPagination-ul': {
      [theme.breakpoints.down('xs')]: {
        flexWrap: 'nowrap',
        justifyContent: 'center',
      },
    },
    '& .MuiButtonBase-root': {
      width: 44,
      height: 44,
      borderRadius: '50%',
      padding: 0,
      margin: 0,
      marginRight: 8,

      [theme.breakpoints.down('xs')]: {
        margin: 0,
        width: 35,
        height: 35,
      },
    },
    '& .MuiPaginationItem-ellipsis': {
      width: 12,
      height: 44,
      padding: 0,
      margin: 0,
      display: 'flex',
      alignItems: 'center',
      minWidth: 'auto',
      marginRight: 8,
    },
    '& .MuiPaginationItem-icon': {
      fontSize: '25px',
    },
  },
  boxModalButtons: {
    margin: '30px 0 0',
    display: 'flex',
    justifyContent: 'space-around',
  },
  buttonsForSelected: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'baseline',
    justifyContent: 'flex-end',

    [theme.breakpoints.up('sm')]: {
      flexDirection: 'row',
    },
  },
  buttonForSelected: {
    alignSelf: 'center',
    marginLeft: 0,
    marginBottom: 10,
    marginRight: '32px',

    [theme.breakpoints.up('sm')]: {
      marginLeft: '32px',
      marginBottom: 0,
    },
  },
  tableCell: {
    padding: '10px',
  },
  tableCheckbox: {
    padding: '0px',
  },
}));
