import { makeStyles } from '@material-ui/core/styles';

export const styles = makeStyles((theme) => ({
  mainTitle: {
    marginBottom: 32,

    [theme.breakpoints.down('sm')]: {
      fontSize: 23,
      marginBottom: 15,
    },
  },
  itemInfo: {
    fontSize: 18,
  },
  wrapButtons: {
    display: 'flex',
    flexDirection: 'row',
    padding: '30px 16px 10px',
    alignItems: 'center',
    justifyContent: 'center',

    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
    },
  },
  boxModalButtons: {
    margin: '30px 0 0',
    display: 'flex',
    justifyContent: 'space-around',
  },
  carrierCodeIcon: {
    maxWidth: '20px',
    maxHeight: '16px',
    marginRight: '5px',
    marginLeft: '5px',
  },
  carrierCodeSelectedItem: {
    display: 'flex',
    alignItems: 'center',
  },
}));

export default styles;
