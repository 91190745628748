import { gql } from '@apollo/client';

export const receiverAndSenderOnParcelFragment = gql`
  fragment receiverAndSender on Parcel {
    id
    receiver {
      address
      address2
      address3
      city
      company
      country
      email
      eoriCode
      id
      innKppCode
      name
      phone
      remark
      state
      zipCode
    }
    sender {
      address
      address2
      address3
      city
      company
      country
      email
      eoriCode
      id
      innKppCode
      name
      phone
      remark
      state
      zipCode
    }
  }
`;
