import React from 'react';
import { Divider, Tooltip } from '@material-ui/core';
import useStyles from '../styles';
import { COLORS, MAX_PARCEL_WEIGHT, TEXT } from '../../../utils/constants';
import {
  sumByField,
  sumQuantityByField,
  sumTotalVolumetricWeight,
} from '../../../utils/helpers';
import { useTranslation } from 'react-i18next';
import { IPackagesRezult } from './types';

export const PackagesRezult = ({
  packages,
  units,
  isCalculatorPage,
  currency,
}: IPackagesRezult) => {
  const classes = useStyles();
  const { t } = useTranslation();

  return (
    <>
      {isCalculatorPage && (
        <div className={classes.packagesInfo}>
          <span>
            {t('app.numberOfPackages')}: <b>{packages.length}</b>
          </span>

          <Divider
            className={classes.packagesInfoDivider}
            orientation='vertical'
          />

          <Tooltip
            title={`${t('app.cannotExceed', { value: MAX_PARCEL_WEIGHT })}. ${t(
              TEXT.GROSS,
            )}`}
          >
            <span
              style={
                Array.isArray(packages) &&
                (+sumByField(packages, 'weightKg') > MAX_PARCEL_WEIGHT ||
                  +sumByField(packages, 'weightKg') <
                    +sumQuantityByField(units, 'netWeight'))
                  ? { color: COLORS.RED }
                  : {}
              }
            >
              {t('app.totalGrossWeight')}:{' '}
              <b>
                {sumByField(
                  packages,
                  'weightKg',
                  (val) => `${val} ${t('app.kg')}`,
                )}
              </b>
            </span>
          </Tooltip>

          <Divider
            className={classes.packagesInfoDivider}
            orientation='vertical'
          />

          <Tooltip
            title={t('app.volumetricWeightFormula', {
              value: MAX_PARCEL_WEIGHT,
            })}
          >
            <span
              style={
                Array.isArray(packages) &&
                +sumTotalVolumetricWeight(packages) > MAX_PARCEL_WEIGHT
                  ? { color: COLORS.RED }
                  : {}
              }
            >
              {t('app.totalVolumetricWeight')}:{' '}
              <b>
                {sumTotalVolumetricWeight(packages)} {t('app.kg')}
              </b>
            </span>
          </Tooltip>
        </div>
      )}
      {!isCalculatorPage && (
        <>
          <div className={classes.parcelInfo}>
            <span>
              {t('app.totalItems')}: <b>{sumByField(units, 'quantity')}</b>
            </span>
            <Divider
              className={classes.parcelInfoDivider}
              orientation='vertical'
            />
            <Tooltip title={t(TEXT.NET)}>
              <span
                style={
                  Array.isArray(packages) &&
                  +sumByField(packages, 'weightKg') <
                    +sumQuantityByField(units, 'netWeight')
                    ? { color: COLORS.RED }
                    : {}
                }
              >
                {t('app.totalNetWeight')}:{' '}
                <b>
                  {sumQuantityByField(
                    units,
                    'netWeight',
                    (val) => `${val} ${t('app.kg')}`,
                  )}
                </b>
              </span>
            </Tooltip>

            <Divider
              className={classes.parcelInfoDivider}
              orientation='vertical'
            />
            <span>
              {t('app.totalCostItems')}:
              <b>
                {sumQuantityByField(
                  units,
                  'price',
                  (val) => `${val} ${(currency || '').toUpperCase()}`,
                )}
              </b>
            </span>
          </div>
        </>
      )}
    </>
  );
};
