import React, { useEffect } from 'react';
import {
  IconButton,
  Radio,
  TableCell,
  TableRow,
  Tooltip,
} from '@material-ui/core';
import InfoIcon from '@material-ui/icons/Info';
import ErrorIcon from '@material-ui/icons/Error';
import useStyles from './styles';
import clsx from 'clsx';
import { useAppFormContext } from '../../context/FormContext';
import { Maybe, VariantInfo } from '../../generated/graphql';
import { FormikValues } from 'formik';
import {
  TFormikSetFieldValueDebounce,
  TSetStateNumber,
  TSetStateString,
} from '../../interfaces';
import { useTranslation } from 'react-i18next';

// TODO it's a temporary array os sender id's
const TODO = [
  '1',
  '11',
  '12',
  '13',
  '17',
  '18',
  '19',
  '23',
  '24',
  '26',
  '27',
  '28',
  '29',
];

const TableBodyItem: React.FC<{
  labelDataEasyPost: {
    shipmentId?: string;
    rateId?: string;
  };
  indexValue: string;
  typeDeliveryId: string | number | undefined | null;
  summary_amount: number | undefined | null;
  remoteArea?: number | undefined | null;
  name: string | undefined | null;
  globalProductCode?: string;
  days_to_delivery: number | undefined | null;
  isCalculatorPage: boolean;
  values: FormikValues;
  setFieldValue: TFormikSetFieldValueDebounce;
  setDeclaredAmount: TSetStateNumber | undefined;
  setRemoteAreaCost: React.Dispatch<React.SetStateAction<number | undefined>>;
  dollarRate?: any;
  setGlobalProductCode?: TSetStateString | undefined;
  fuelSurcharge?: any;
  errors?: Maybe<Maybe<string>[]> | undefined;
  index?: number;
  info?: VariantInfo | undefined | null;
  priceCurrency?: string | undefined | null;
  accountName?: string | undefined | null;
  isLoading: boolean;
}> = ({
  labelDataEasyPost,
  indexValue,
  typeDeliveryId,
  summary_amount,
  remoteArea,
  name,
  globalProductCode,
  days_to_delivery,
  isCalculatorPage,
  values,
  setFieldValue,
  fuelSurcharge,
  dollarRate,
  setDeclaredAmount,
  setRemoteAreaCost,
  setGlobalProductCode,
  errors,
  index,
  info,
  priceCurrency,
  accountName,
  isLoading,
}) => {
  const classes = useStyles();
  const { handleSetFormContext } = useAppFormContext();
  const { t } = useTranslation();

  // disable devault value from create parcel
  useEffect(() => {
    setFieldValue('typeDeliveryId', {
      typeDeliveryId: undefined,
      indexValue: undefined,
      labelDataEasyPost: undefined,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleChangeCheckbox = () => {
    setFieldValue('typeDeliveryId', {
      typeDeliveryId: typeDeliveryId,
      indexValue: indexValue,
      labelDataEasyPost: labelDataEasyPost,
      orderCostCurrency: priceCurrency,
      handlingFee: info?.warehouse_proccesing_cost,
    });

    if (setGlobalProductCode && globalProductCode) {
      setGlobalProductCode(globalProductCode);
    }

    if (setDeclaredAmount && summary_amount !== undefined) {
      setDeclaredAmount(summary_amount as number);
    }
    if (remoteArea) {
      setRemoteAreaCost(remoteArea);
    }
    if (fuelSurcharge) {
      handleSetFormContext('fuelSurcharge', fuelSurcharge);
    }
    if (dollarRate) {
      handleSetFormContext('dollarRate', dollarRate);
    }
  };

  const disableDHL = (typeDeliveryId: any) => {
    let disable = true;

    if (
      TODO.includes(`${typeDeliveryId}`) ||
      typeDeliveryId === `8.${index}` ||
      typeDeliveryId === `30.${index}`
    ) {
      disable = false;
    }
    return disable;
  };

  return (
    <>
      <TableRow>
        <TableCell className={classes.tableCell}>
          <label className={classes.labelRow}>
            {!isCalculatorPage &&
              (summary_amount ? (
                disableDHL(typeDeliveryId) ? (
                  <Tooltip title={t('app.missingInfo')}>
                    <ErrorIcon className={classes.errorIcon} />
                  </Tooltip>
                ) : (
                  <Radio
                    className={classes.radio}
                    value={typeDeliveryId}
                    disabled={isLoading}
                    checked={values?.typeDeliveryId?.indexValue === indexValue}
                    color='primary'
                    onChange={handleChangeCheckbox}
                  />
                )
              ) : (
                <Tooltip title={t('app.missingInfo')}>
                  <ErrorIcon className={classes.errorIcon} />
                </Tooltip>
              ))}
            <span className={classes.labelRowText}>{`${name} ${
              accountName ? `(${accountName})` : ''
            }`}</span>
            {typeDeliveryId === 0 && (
              <Tooltip
                className={classes.tooltipIconButton}
                placement='top'
                title={'app.dispatchFromWarehouse'}
              >
                <IconButton>
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            )}
          </label>
        </TableCell>
        <TableCell className={classes.tableCell}>
          {days_to_delivery
            ? `${t('app.days', { value: days_to_delivery })} `
            : t('app.unknown')}
        </TableCell>
        <TableCell className={clsx(classes.tableCell, classes.itemResult)}>
          {summary_amount ? (
            `${summary_amount?.toFixed(2)} ${priceCurrency}`
          ) : (
            <ul style={{ width: '100%' }}>
              {errors?.map((e: any, index: number) => {
                return <li key={index}>{e}</li>;
              })}
            </ul>
          )}
        </TableCell>
      </TableRow>
    </>
  );
};

export default TableBodyItem;
