import { makeStyles } from '@material-ui/core/styles';

const useStyles = () =>
  makeStyles((theme) => ({
    filters: {
      display: 'flex',
      flexWrap: 'wrap',
      marginBottom: 25,
      marginTop: 25,

      [theme.breakpoints.down('xs')]: {
        justifyContent: 'space-between',
      },
    },
    buttonsForSelected: {
      flexGrow: 1,
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'baseline',
      justifyContent: 'center',

      [theme.breakpoints.up('sm')]: {
        flexDirection: 'row',
      },
    },
    buttonForSelected: {
      alignSelf: 'center',
      marginLeft: 0,
      marginBottom: 10,

      [theme.breakpoints.up('sm')]: {
        marginLeft: '32px',
        marginRight: '32px',
        marginBottom: 0,
      },
    },
    isPaid: {
      textAlign: 'center',
      padding: '1px 7px',
      borderRadius: 5,
    },
    Select: {
      border: 'none',
    },
    selectItem: {
      width: '100%',
    },
    selectItemText: {
      margin: '5px',
    },
    horizontalLine: {
      margin: 0,
      padding: 0,
    },
    pagination: {
      fontWeight: 'bold',
      marginTop: 32,
      '& .MuiPagination-ul': {
        [theme.breakpoints.down('xs')]: {
          flexWrap: 'nowrap',
          justifyContent: 'center',
        },
      },
      '& .MuiButtonBase-root': {
        width: 44,
        height: 44,
        borderRadius: '50%',
        padding: 0,
        margin: 0,
        marginRight: 8,

        [theme.breakpoints.down('xs')]: {
          margin: 0,
          width: 35,
          height: 35,
        },
      },
      '& .MuiPaginationItem-ellipsis': {
        width: 12,
        height: 44,
        padding: 0,
        margin: 0,
        display: 'flex',
        alignItems: 'center',
        minWidth: 'auto',
        marginRight: 8,
      },
      '& .MuiPaginationItem-icon': {
        fontSize: '25px',
      },
    },
  }))();

export default useStyles;
