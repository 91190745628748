import { Field, FieldProps } from 'formik';
import {
  FormControl,
  FormHelperText,
  Input,
  InputAdornment,
  InputLabel,
} from '@material-ui/core';
import React from 'react';
import { MAX_PARCEL_DIMENSION } from '../../../utils/constants';
import { useTranslation } from 'react-i18next';

const FieldHeightCm: React.FC<{
  packageIndex?: number;
  handlerChange: (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => void;
  fieldName?: string;
}> = ({ packageIndex, handlerChange, fieldName }) => {
  const { t } = useTranslation();

  return (
    <Field
      name={
        fieldName
          ? fieldName
          : Number.isInteger(packageIndex)
          ? `packages[${packageIndex}].heightCm`
          : 'height'
      }
    >
      {({ field: { value, ...field }, meta }: FieldProps) => (
        <FormControl error={meta.touched && !!meta.error && value !== 0}>
          <InputLabel
            shrink={false}
            htmlFor={`input-height-package-${packageIndex}`}
          >
            {t('app.height')}
          </InputLabel>
          <Input
            disableUnderline
            id={`input-height-package-${packageIndex}`}
            {...field}
            inputProps={{
              type: 'number',
              min: 0,
              step: 0.1,
              max: MAX_PARCEL_DIMENSION,
            }}
            startAdornment={
              <InputAdornment position='end'>{t('app.cm')}</InputAdornment>
            }
            defaultValue={value}
            onChange={handlerChange}
            value={value}
          />
          {meta.touched && !!meta.error && value !== 0 && (
            <FormHelperText>{meta.error}</FormHelperText>
          )}
        </FormControl>
      )}
    </Field>
  );
};

export default FieldHeightCm;
