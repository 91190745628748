import React, { useState } from 'react';
import clsx from 'clsx';
import { Collapse, List, ListItem, Tooltip } from '@material-ui/core';
import { NavLink } from 'react-router-dom';

import useStyles from './styles';
import { TRoutes } from '../../utils/helpers';
import CardMembershipIcon from '@material-ui/icons/CardMembership';
import SearchIcon from '@material-ui/icons/Search';
import InsertChartOutlinedTwoToneIcon from '@material-ui/icons/InsertChartOutlinedTwoTone';
import LibraryBooksIcon from '@material-ui/icons/LibraryBooks';
import ExposureIcon from '@material-ui/icons/Exposure';
import ShoppingBasketIcon from '@material-ui/icons/ShoppingBasket';
import { LOCAL_STORAGE_KEYS } from '../../utils/constants';
import PackageIcon from '../../assets/img/icon_package_white.png';
import { useTranslation } from 'react-i18next';

const Sidebar: React.FC<{
  isSidebarHidden?: boolean;
  onSelectItem?: () => void;
}> = ({ isSidebarHidden, onSelectItem }) => {
  const classes = useStyles(isSidebarHidden);

  const { t } = useTranslation();

  const [listsOpen, setLists] = useState<{ [key: string]: boolean }>({
    sending:
      localStorage.getItem(LOCAL_STORAGE_KEYS.IS_LIST_OPEN_SENDING) ===
        'true' || false,
    sendingToWarehouse:
      localStorage.getItem(
        LOCAL_STORAGE_KEYS.IS_LIST_OPEN_SENDING_TO_WAREHOUSE,
      ) === 'true' || false,
    productsAndWarehouse:
      localStorage.getItem(
        LOCAL_STORAGE_KEYS.IS_LIST_OPEN_PRODUCTS_AND_WAREHOUSE,
      ) === 'true' || false,
    orders:
      localStorage.getItem(LOCAL_STORAGE_KEYS.IS_LIST_OPEN_ORDERS) === 'true' ||
      false,
    integration:
      localStorage.getItem(LOCAL_STORAGE_KEYS.IS_LIST_OPEN_INTEGRATION) ===
        'true' || false,
    reports:
      localStorage.getItem(LOCAL_STORAGE_KEYS.IS_LIST_REPORTS) === 'true' ||
      false,
  });

  const initValuesForListsOption = {
    sending: false,
    sendingToWarehouse: false,
    productsAndWarehouse: false,
    orders: false,
    integration: false,
  };

  const handleClickItem = (name: string) => () => {
    //eslint-disable-next-line
    Object.keys(listsOpen).map((value) => {
      if (value === name)
        localStorage.setItem(
          'isListOpen' + name[0].toUpperCase() + name.substring(1),
          true.toString(),
        );
      else
        localStorage.setItem(
          'isListOpen' + value[0].toUpperCase() + value.substring(1),
          false.toString(),
        );
    });
    setLists({
      ...initValuesForListsOption,
      [name]: !listsOpen[name],
    });
  };

  return (
    <List className={classes.list} component='div'>
      <ListItem className={classes.listItem} button>
        <NavLink
          className={classes.listItemLink}
          activeClassName={classes.listItemLinkActive}
          exact
          onClick={() => {
            if (onSelectItem) return onSelectItem();
          }}
          to={TRoutes.MAIN}
        >
          <Tooltip title={t('app.addressBook')}>
            <InsertChartOutlinedTwoToneIcon
              className={classes.listItemLinkIcon}
            />
          </Tooltip>
          {!isSidebarHidden && t('app.home')}
        </NavLink>
      </ListItem>
      <ListItem
        className={clsx(classes.listItem, classes.listItemSubList)}
        button
        onClick={handleClickItem('sending')}
      >
        <Tooltip title={t('app.shipments')}>
          <img
            src={PackageIcon}
            alt='PackageIcon'
            className={classes.listItemLinkImage}
          />
        </Tooltip>
        {!isSidebarHidden && t('app.shipments')}
      </ListItem>
      <Collapse in={listsOpen.sending}>
        <List className={clsx(classes.list, classes.listSub)} component='div'>
          <ListItem
            className={clsx(classes.listItem, classes.listItemSub)}
            button
          >
            <NavLink
              className={classes.listItemLink}
              activeClassName={classes.listItemLinkActive}
              exact
              onClick={() => {
                if (onSelectItem) return onSelectItem();
              }}
              to={TRoutes.SHIPMENTS}
            >
              {t('app.allShipments')}
            </NavLink>
          </ListItem>

          <ListItem
            className={clsx(classes.listItem, classes.listItemSub)}
            button
          >
            <NavLink
              className={classes.listItemLink}
              activeClassName={classes.listItemLinkActive}
              exact
              onClick={() => {
                if (onSelectItem) return onSelectItem();
              }}
              to={TRoutes.SHIPMENT_CREATE}
            >
              {t('app.createNew')}
            </NavLink>
          </ListItem>
        </List>
      </Collapse>

      <ListItem
        className={clsx(classes.listItem, classes.listItemSubList)}
        button
        onClick={handleClickItem('orders')}
      >
        <Tooltip title={t('app.marketplaceOrders')}>
          <CardMembershipIcon className={classes.listItemLinkIcon} />
        </Tooltip>
        {!isSidebarHidden && t('app.orders')}
      </ListItem>
      <Collapse in={listsOpen.orders}>
        <List className={clsx(classes.list, classes.listSub)} component='div'>
          <ListItem
            className={clsx(classes.listItem, classes.listItemSub)}
            button
          >
            <NavLink
              className={classes.listItemLink}
              activeClassName={classes.listItemLinkActive}
              exact
              onClick={() => {
                if (onSelectItem) return onSelectItem();
              }}
              to={TRoutes.MARKETPLACE_ORDERS}
            >
              {t('app.allOrders')}
            </NavLink>
          </ListItem>
        </List>
      </Collapse>

      <ListItem
        classes={{
          root: classes.listItem,
          disabled: classes.listItemDisabled,
        }}
        button
      >
        <NavLink
          className={classes.listItemLink}
          activeClassName={classes.listItemLinkActive}
          exact
          onClick={() => {
            if (onSelectItem) return onSelectItem();
          }}
          to={TRoutes.TRACK}
        >
          <Tooltip title={t('app.track')}>
            <SearchIcon className={classes.listItemLinkIcon} />
          </Tooltip>
          {!isSidebarHidden && t('app.track')}
        </NavLink>
      </ListItem>

      <ListItem className={classes.listItem} button>
        <NavLink
          className={classes.listItemLink}
          exact
          activeClassName={classes.listItemLinkActive}
          onClick={() => {
            if (onSelectItem) return onSelectItem();
          }}
          to={TRoutes.DELIVERY_CALCULATOR}
        >
          <Tooltip title={t('app.calculator')}>
            <ExposureIcon className={classes.listItemLinkIcon} />
          </Tooltip>
          {!isSidebarHidden && t('app.calculator')}
        </NavLink>
      </ListItem>
      <ListItem
        className={clsx(classes.listItem, classes.listItemSubList)}
        button
        onClick={handleClickItem('productsAndWarehouse')}
      >
        <Tooltip title={t('app.productsWarehouse')}>
          <ShoppingBasketIcon className={classes.listItemLinkIcon} />
        </Tooltip>
        {!isSidebarHidden && t('app.productsWarehouse')}
      </ListItem>
      <Collapse in={listsOpen.productsAndWarehouse}>
        <List className={clsx(classes.list, classes.listSub)} component='div'>
          <ListItem
            className={clsx(classes.listItem, classes.listItemSub)}
            button
          >
            <NavLink
              className={classes.listItemLink}
              activeClassName={classes.listItemLinkActive}
              exact
              onClick={() => {
                if (onSelectItem) return onSelectItem();
              }}
              to={TRoutes.SETTINGS_MY_PRODUCTS}
            >
              {t('app.myProducts')}
            </NavLink>
          </ListItem>
        </List>
      </Collapse>
      <ListItem
        classes={{
          root: classes.listItem,
          disabled: classes.listItemDisabled,
        }}
        button
      >
        <NavLink
          className={classes.listItemLink}
          activeClassName={classes.listItemLinkActive}
          exact
          onClick={() => {
            if (onSelectItem) return onSelectItem();
          }}
          to={TRoutes.REPORTS}
        >
          <Tooltip title={t('app.reports')}>
            <LibraryBooksIcon className={classes.listItemLinkIcon} />
          </Tooltip>
          {!isSidebarHidden && t('app.reports')}
        </NavLink>
      </ListItem>
    </List>
  );
};

export default Sidebar;
