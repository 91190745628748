import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  root: {
    height: '100%',
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  wrapper: {
    maxWidth: 466,
    padding: '24px 48px',
    border: '1px solid #E0E0E0',

    [theme.breakpoints.down('sm')]: {
      padding: '12px 20px',
    },
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  title: {
    marginBottom: 16,
  },
  content: {
    marginTop: 47,
    marginBottom: 24,
    fontSize: '16px',
    lineHeight: '24px',
    letterSpacing: '-0.1px',
  },
  printButton: {
    marginRight: '30px',
  },
  okButton: {},
  link: {
    textDecoration: 'none',
    color: '#FFFF',
  },
  buttonsWrapper: {
    display: 'flex',
  },
}));
