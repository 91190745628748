import { FastField, FieldProps, Form, Formik, FormikHelpers } from 'formik';
import React from 'react';
import { debounce } from 'debounce';
import {
  Button,
  FormControl,
  FormHelperText,
  Grid,
  Input,
  InputLabel,
  Typography,
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import { myPackageFormSchema } from '../../utils/validationSchemes';
import FieldWeightGross from '../Packages/FieldWeightGross/FieldWeightGross';
import FieldWidthCm from '../Packages/FieldWidthCm/FieldWidthCm';
import FieldHeightCm from '../Packages/FieldHeightCm/FieldHeightCm';
import FieldLengthCm from '../Packages/FieldLengthCm/FieldLengthCm';
import {
  GetPackagingTemplatesListQueryVariables,
  PackagingTemplate,
  useCreatePackagingTemplateMutation,
  useUpdatePackagingTemplateMutation,
} from '../../generated/graphql';
import { QueryLazyOptions } from '@apollo/client';
import { TSetStateBoolean } from '../../interfaces';
import { useSnackbar } from 'notistack';
import { DEBOUNCE } from '../../utils/constants';
import { useTranslation } from 'react-i18next';

const MyPackageForm: React.FC<{
  editingPackage: null | PackagingTemplate;
  setEditingPackage: React.Dispatch<
    React.SetStateAction<PackagingTemplate | null>
  >;
  getMyPackagesLazy: (
    options?: QueryLazyOptions<GetPackagingTemplatesListQueryVariables>,
  ) => void;
  setIsOpenModal: TSetStateBoolean;
}> = ({
  editingPackage,
  setEditingPackage,
  getMyPackagesLazy,
  setIsOpenModal,
}) => {
  const { enqueueSnackbar } = useSnackbar();
  const [createMyPackage] = useCreatePackagingTemplateMutation();
  const [updateMyPackage] = useUpdatePackagingTemplateMutation();

  const { t } = useTranslation();

  const handleSubmit = (
    values: PackagingTemplate,
    helpers: FormikHelpers<PackagingTemplate>,
  ) => {
    const inputValues = {
      name: values.name || '',
      height: values.height && +values.height ? +values.height : 0,
      length: values.length && +values.length ? +values.length : 0,
      weight: values.weight && +values.weight ? +values.weight : 0,
      width: values.width && +values.width ? +values.width : 0,
    };

    if (editingPackage?.id) {
      updateMyPackage({
        variables: {
          input: {
            ...inputValues,
            id: editingPackage?.id,
          },
        },
      })
        .then((value) => {
          if (value.data?.updatePackagingTemplate) {
            setIsOpenModal(false);
            setEditingPackage(null);
            enqueueSnackbar(
              t('app.packagingSaved', { value: editingPackage.name }),
              {
                variant: 'success',
              },
            );
          }
        })
        .catch((reason) => {
          console.error(reason);
          reason?.message &&
            enqueueSnackbar(reason.message, { variant: 'error' });
        })
        .finally(() => {
          getMyPackagesLazy();
          helpers.setSubmitting(false);
        });
    } else {
      createMyPackage({
        variables: {
          input: inputValues,
        },
      })
        .then((value) => {
          if (value.data?.createPackagingTemplate) {
            setIsOpenModal(false);
            enqueueSnackbar(
              t('app.newPackagingSaved', {
                value: value?.data?.createPackagingTemplate?.name,
              }),
              { variant: 'success' },
            );
          }
        })
        .catch((reason) => {
          console.error(reason);
          reason?.message &&
            enqueueSnackbar(reason.message, { variant: 'error' });
        })
        .finally(() => {
          getMyPackagesLazy();
          helpers.setSubmitting(false);
        });
    }
  };

  const initialValues: PackagingTemplate = editingPackage || {
    name: '',
    weight: 0,
    length: 0,
    width: 0,
    height: 0,
  };

  return (
    <>
      <Typography variant='h3' align='center'>
        {t('app.myPackagingOption')}
      </Typography>

      <br />

      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={myPackageFormSchema}
      >
        {({ setFieldValue }) => {
          const setFieldValueDebounce = debounce(
            (name: string, value: string) => {
              setFieldValue(name, value);
            },
            DEBOUNCE,
          );

          return (
            <Form>
              <Grid container spacing={2}>
                <Grid item xs={12}>
                  <FastField name='name'>
                    {({ field: { value, ...field }, meta }: FieldProps) => {
                      return (
                        <FormControl error={meta.touched && !!meta.error}>
                          <InputLabel>{t('app.name')}</InputLabel>
                          <Input
                            disableUnderline
                            defaultValue={value}
                            {...field}
                            onChange={(e) =>
                              setFieldValueDebounce('name', e.target.value)
                            }
                          />
                          {meta.touched && meta.error && (
                            <FormHelperText>{meta.error}</FormHelperText>
                          )}
                        </FormControl>
                      );
                    }}
                  </FastField>
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <FieldWeightGross
                    handlerChange={(e) =>
                      setFieldValueDebounce(e.target.name, e.target.value)
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <FieldLengthCm
                    handlerChange={(e) =>
                      setFieldValueDebounce(e.target.name, e.target.value)
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <FieldWidthCm
                    handlerChange={(e) =>
                      setFieldValueDebounce(e.target.name, e.target.value)
                    }
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={3}>
                  <FieldHeightCm
                    handlerChange={(e) =>
                      setFieldValueDebounce(e.target.name, e.target.value)
                    }
                  />
                </Grid>
              </Grid>

              <br />

              <Button
                type='submit'
                variant='contained'
                startIcon={<SaveIcon />}
              >
                {t('app.save')}
              </Button>
            </Form>
          );
        }}
      </Formik>
    </>
  );
};

export default MyPackageForm;
