import React, { useEffect, useState } from 'react';
import {
  Checkbox,
  Divider,
  FormControl,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import StorefrontIcon from '@material-ui/icons/Storefront';

import {
  getCountryISOByName,
  getCountryOrderByName,
  getCountryOrderByISO,
  handleSelectAllCheckboxes,
  LIMIT_ITEM_PAGE,
  TRoutes,
} from '../../utils/helpers';
import { LOCAL_STORAGE_KEYS, PARCEL_FIELDS } from '../../utils/constants';
import {
  GetDeliveryCountriesQuery,
  GetDeliveryCountriesQueryVariables,
  GetPackagingTemplatesListQuery,
  GetPackagingTemplatesListQueryVariables,
  PackagingTemplate,
  useGetListOfDeliveryOptionsLazyQuery,
  useAddPackageToOrderMutation,
} from '../../generated/graphql';
import { QUERY_PACKAGING_TEMPLATE_LIST } from '../../GraphQL/queries/getPackagingTemplatesList';
import { useApolloClient } from '@apollo/client';
import MarketplacesOrdersTableRow from './MarketplacesOrdersTableRow';
import useStyles from './styles';
import FilterSelectDatePeriodExtended from '../../components/_Fields/FilterSelectDatePeriodExtended/FilterSelectDatePeriodExtended';
import FilterInputSearch from '../../components/_Fields/FilterInputSearch/FilterInputSearch';
import ButtonSendSelected from '../../components/_Buttons/ButtonSendSelected/ButtonSendSelected';
import FilterSelectMarketplace from '../../components/_Fields/FilterSelectMarketplace/FilterSelectMarketplace';
import ModalBoxAddPackageToOrder from './ModalBoxAddPackageToOrder';
import FilterSelectLimitItems from '../../components/_Fields/FilterSelectLimitItems/FilterSelectLimitItems';
import { useSnackbar } from 'notistack';
import { QUERY_DELIVERY_COUNTRIES } from '../../GraphQL/queries/getDeliveryCountries';
import { IMarketplaceOrder } from '../../interfaces';
import { useLazyQuery } from '@apollo/client';
import { QUERY_ORDERS } from '../../GraphQL/queries/getOrders';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

interface IMarketplaceOrdersFilter {
  marketplace: string;
  shippedStatus: boolean | undefined;
  date: number;
  search: string;
  page: number;
  itemsLimit: number;
}

const itemsLimit = localStorage.getItem(
  LOCAL_STORAGE_KEYS.ITEMS_LIMIT_MARKETPLACE_ORDERS,
);

const MarketplacesOrders: React.FC = (props) => {
  const classes = useStyles();
  const client = useApolloClient();
  const { enqueueSnackbar } = useSnackbar();

  const { t } = useTranslation();

  const [orders, setOrders] = useState<IMarketplaceOrder[]>([]);
  const [orderForModal, setOrderForModal] = useState<IMarketplaceOrder>();
  const [selected, setSelected] = useState<string[]>([]);
  const [isOpen, setIsOpen] = useState(false);

  const [filter, setFilter] = useState<IMarketplaceOrdersFilter>({
    date: 0,
    itemsLimit: itemsLimit ? +itemsLimit : LIMIT_ITEM_PAGE,
    marketplace: '',
    page: 1,
    shippedStatus: undefined,
    search: '',
  });

  const initVelueForOrderAdditionalData = {
    weight: 0,
    length: 0,
    width: 0,
    height: 0,
  };

  const [orderAdditionalData, setOrderAdditionalData] = useState<any>(
    initVelueForOrderAdditionalData,
  );

  const [listOfDeliveryOptionsArr, setListOfDeliveryOptionsArr] = useState<any>(
    [],
  );

  const [getOrders, { error: errorOrders, data: dataOrders }] = useLazyQuery(
    QUERY_ORDERS,
    {
      fetchPolicy: 'cache-and-network',
    },
  );

  const [addPackageToOrderMutation] = useAddPackageToOrderMutation();

  const [
    getListOfDeliveryOptions,
    { data: dataListOfDeliveryOptions, error: errorListOfDeliveryOptions },
  ] = useGetListOfDeliveryOptionsLazyQuery();

  const cachedPackages = client.readQuery<
    GetPackagingTemplatesListQuery,
    GetPackagingTemplatesListQueryVariables
  >({
    query: QUERY_PACKAGING_TEMPLATE_LIST,
  });
  const dataCountries = client.readQuery<
    GetDeliveryCountriesQuery,
    GetDeliveryCountriesQueryVariables
  >({
    query: QUERY_DELIVERY_COUNTRIES,
  });

  useEffect(() => {
    if (errorOrders) {
      enqueueSnackbar(t('app.unableToLoadOrders'), { variant: 'error' });
    }
    // eslint-disable-next-line
  }, [errorOrders]);

  useEffect(() => {
    if (errorListOfDeliveryOptions) {
      enqueueSnackbar(t('app.unableToGetDeliveryInfo'), {
        variant: 'error',
      });
    }
    // eslint-disable-next-line
  }, [errorListOfDeliveryOptions]);

  const refetchOrders = () => {
    const shippedStatus =
      filter.shippedStatus !== undefined
        ? { shippedStatus: !!filter.shippedStatus }
        : {};

    getOrders({
      variables: {
        marketplace: filter.marketplace,
        period: filter.date,
        ...shippedStatus,
        limit: filter.itemsLimit,
        offset: (filter.page - 1) * filter.itemsLimit,
        search: filter.search,
      },
    });
  };

  useEffect(() => {
    refetchOrders();
    // eslint-disable-next-line
  }, [filter]);

  useEffect(() => {
    if (dataOrders?.getOrders?.rows) {
      setOrders(dataOrders.getOrders.rows);

      const initValue: any = [];

      dataOrders.getOrders.rows.forEach(() => initValue.push(null));

      setListOfDeliveryOptionsArr(initValue);
    }
  }, [dataOrders]);

  useEffect(() => {
    if (dataListOfDeliveryOptions) {
      let indexDelivery = 0;
      orders.forEach((item, index) =>
        item.id === orderForModal?.id ? (indexDelivery = index) : null,
      );

      const newArr = [...listOfDeliveryOptionsArr];
      newArr[indexDelivery] = dataListOfDeliveryOptions;

      setListOfDeliveryOptionsArr([...newArr]);
    }
    // eslint-disable-next-line
  }, [dataListOfDeliveryOptions]);

  useEffect(() => {
    if (orderAdditionalData?.weight) {
      handleCalculateCost(orderForModal);
    }
    // eslint-disable-next-line
  }, [orderAdditionalData]);

  const handleCalculateCost = (values: any) => {
    if (!values?.package && !orderAdditionalData?.weight) {
      setOrderForModal(values);
      setIsOpen(true);
    } else {
      if (values?.country) {
        const positionsWeight = values?.package
          ? values?.package[0]?.weight
          : +orderAdditionalData.weight;
        const positionsLength = values?.package
          ? values?.package[0]?.length
          : +orderAdditionalData.length;
        const positionsHeight = values?.package
          ? values?.package[0]?.height
          : +orderAdditionalData.height;
        const positionsWidth = values?.package
          ? values?.package[0]?.width
          : +orderAdditionalData.width;

        const date = new Date(moment().format('YYYY-MM-DD'));
        const changedDate = new Date(date.setDate(date.getDate() + 1));
        addPackageToOrderMutation({
          variables: {
            orderId: values.id,
            weight: positionsWeight,
            height: positionsLength,
            length: positionsHeight,
            width: positionsWidth,
          },
        })
          .then(() => {
            refetchOrders();
            getListOfDeliveryOptions({
              variables: {
                input: {
                  receiver: {
                    city: values?.city,
                    countryId: Number(
                      getCountryOrderByName(values.country) ||
                        Number(getCountryOrderByISO(values.country)),
                    ),
                    zipCode: values?.zip,
                  },
                  sender: values.sender,
                  cargo_price: values?.deliveryPrice,
                  currency_code: values?.orderProducts[0]?.priceCurrency,
                  iso:
                    getCountryISOByName(values.country) || values.country || '',
                  positions: [
                    {
                      weight: positionsWeight,
                      length: positionsLength,
                      height: positionsHeight,
                      width: positionsWidth,
                      count: 1,
                    },
                  ],
                  userId: values?.userId,
                  date: moment(changedDate).format('YYYY-MM-DD'),
                  readyTime: 'PT12H00M',
                  address_validation: true,
                },
              },
            });
            setOrderAdditionalData(initVelueForOrderAdditionalData);
          })
          .catch(() => {});
      } else {
        enqueueSnackbar(t('app.missingCountryInOrder'), { variant: 'error' });
      }
    }
  };

  const getSendMarketplaceOrderLink = (
    order: IMarketplaceOrder | null,
    variant: TRoutes,
  ) => {
    const replaceSymbol = (string: string) => {
      const replacedString = string.replace('#', '');
      return replacedString;
    };
    return replaceSymbol(
      `${variant}?` +
        `${PARCEL_FIELDS.RECEIVER.NAME}=${order?.receiverName}&` +
        `${PARCEL_FIELDS.RECEIVER.COMPANY}=&` +
        `${PARCEL_FIELDS.RECEIVER.COUNTRY}=${
          dataCountries?.deliveryCountries?.find(
            (c) =>
              c?.iso ===
              //@ts-ignore
              (getCountryISOByName(order?.country) || order?.country),
          )?.name || order?.country
        }&` +
        `${PARCEL_FIELDS.RECEIVER.ADDRESS}=${order?.addressFirstLine}&` +
        `${PARCEL_FIELDS.RECEIVER.ADDRESS2}=${order?.addressSecondLine}&` +
        `${PARCEL_FIELDS.RECEIVER.ADDRESS3}=&` +
        `${PARCEL_FIELDS.RECEIVER.ZIP_CODE}=${order?.zip}&` +
        `${PARCEL_FIELDS.RECEIVER.CITY}=${order?.city}&` +
        `${PARCEL_FIELDS.RECEIVER.STATE}=${order?.state}&` +
        `${PARCEL_FIELDS.RECEIVER.PHONE}=${order?.phone}&` +
        `${PARCEL_FIELDS.RECEIVER.EMAIL}=${order?.email}&` +
        `${PARCEL_FIELDS.OTHER.ID}=${order?.id}&`,
    );
  };

  return (
    <>
      <Typography variant='h2' align='center'>
        {t('app.orders').toUpperCase()}
      </Typography>

      <Divider orientation='horizontal' />

      <div className={classes.filters}>
        <FilterSelectDatePeriodExtended
          value={filter?.date}
          setFilter={setFilter}
        />

        <FilterSelectMarketplace
          value={filter?.marketplace}
          setFilter={setFilter}
        />

        <FormControl className='filtersItemSelect'>
          <Select
            displayEmpty
            disableUnderline
            value={filter?.shippedStatus}
            onChange={(e) => {
              setFilter((state: any) => ({
                ...state,
                shippedStatus: e.target.value,
                page: 1,
              }));
            }}
          >
            <MenuItem value={undefined} disabled>
              {t('app.shippingStatus')}
            </MenuItem>
            <MenuItem value={undefined}>{t('app.allStatuses')}</MenuItem>
            <MenuItem value={0}>{t('app.notShipped')}</MenuItem>
            <MenuItem value={1}>{t('app.shipped')}</MenuItem>
          </Select>
        </FormControl>

        <FilterSelectLimitItems
          value={filter?.itemsLimit}
          setFilter={setFilter}
          localStorageItemsLimitKey={
            LOCAL_STORAGE_KEYS.ITEMS_LIMIT_MARKETPLACE_ORDERS
          }
        />

        <div className={classes.buttonsForSelected}>
          <ButtonSendSelected
            disabled={!selected.length}
            onClick={() => {}}
            tooltipTitle={t('app.startProcessingShipments')}
          />
        </div>

        <FilterInputSearch setFilter={setFilter} />
      </div>

      <TableContainer>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell align='center'>
                <Tooltip title={t('app.selectAllCheckboxes')}>
                  <Checkbox
                    onChange={(e) =>
                      handleSelectAllCheckboxes(
                        e,
                        setSelected,
                        dataOrders?.getOrders?.rows,
                      )
                    }
                    checked={dataOrders?.getOrders?.rows
                      ?.map((item: any) => item.id.toString())
                      .every((itemId: string) => selected.includes(itemId))}
                  />
                </Tooltip>
              </TableCell>
              <TableCell
                align='center'
                style={{ paddingRight: 0, paddingLeft: 16 }}
              >
                <StorefrontIcon />
              </TableCell>
              <TableCell>{t('app.orderNumber')}</TableCell>
              <TableCell>{t('app.customer')}</TableCell>
              <TableCell>{t('app.product')}</TableCell>
              <TableCell align='center'>{t('app.packaging')}</TableCell>
              <TableCell align='center'>{t('app.delivery')}</TableCell>
              <TableCell align='center'>{t('app.status')}</TableCell>
              <TableCell align='center'>{t('app.actions')}</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {orders?.map((order: any, index: number) => {
              return (
                <MarketplacesOrdersTableRow
                  key={index}
                  order={order}
                  index={index}
                  setIsOpen={setIsOpen}
                  setSelected={setSelected}
                  selected={selected}
                  handleCalculateCost={handleCalculateCost}
                  countries={dataCountries}
                  setOrderForModal={setOrderForModal}
                  listOfDeliveryOptionsArr={listOfDeliveryOptionsArr}
                  getSendMarketplaceOrderLink={getSendMarketplaceOrderLink}
                />
              );
            })}
          </TableBody>
        </Table>
      </TableContainer>

      <Pagination
        className={classes.pagination}
        count={
          dataOrders
            ? Math.ceil(dataOrders?.getOrders?.count / filter.itemsLimit)
            : 1
        }
        page={filter.page}
        onChange={(_, page) => {
          setFilter((state) => ({
            ...state,
            page: page,
          }));
        }}
        color='primary'
      />

      <ModalBoxAddPackageToOrder
        isOpen={isOpen}
        order={orderForModal}
        setIsOpen={setIsOpen}
        setOrders={setOrders}
        cachedPackages={
          cachedPackages?.getPackagingTemplatesList?.rows as PackagingTemplate[]
        }
        dataCountries={dataCountries}
        setOrderAdditionalData={setOrderAdditionalData}
      />
    </>
  );
};

export default MarketplacesOrders;
