import { gql } from '@apollo/client';

export const QUERY_LIST_OF_DELIVERY_OPTIONS = gql`
  query getListOfDeliveryOptions($input: CalculationInput) {
    getListOfDeliveryOptions(input: $input) {
      weight
      volume_weight
      weight_to_pay
      special_tax
      insurance
      data {
        name
        success
        days_to_delivery
        summary_amount
        remoteArea
        typeDelivery
        fuelSurcharge
        dollarRate
        errors
        rateId
        shipmentId
        info {
          warehouse_delivery_cost
          warehouse_proccesing_cost
          last_mile_label_cost
        }
        priceCurrency
      }
      userAccount {
        rows {
          TypeDeliveryName
          TypeDeliveryId
          accountName
          amount
          error
          day_to_delivery
          priceCurrency
          shipmentId
          rateId
        }
      }
    }
  }
`;
