import React, { useState } from 'react';
import {
  Button,
  Step,
  StepContent,
  StepLabel,
  Stepper,
  Tooltip,
  Typography,
} from '@material-ui/core';
import debounce from 'debounce';
import { useSnackbar } from 'notistack';
import { Form, Formik } from 'formik';
import { useApolloClient } from '@apollo/client';
import {
  useMelissa,
  useRouterWrapper,
  useQueryDataWrapper,
  useUpdateView,
  usePackage,
  pickPackages,
  mutateCreate,
  createInitValue,
  createNewParcel,
} from './shipmentUtils';

import {
  shipmentDraft as shipmentSchemaDraft,
  shipmentSchemas,
  shipmentWithVatInformationSchemas,
} from '../../../utils/validationSchemes';

import useStyles from './styles';
import { useHistory } from 'react-router';
import {
  useCreateParcelMutation,
  User,
  useGetOrderByIdQuery,
  OrderProduct,
  useAddEtsyTrackNumberMutation,
} from '../../../generated/graphql';

import { DEBOUNCE } from '../../../utils/constants';
import ShipmentsFormStep1 from './ShipmentsFormStep1';
import ShipmentsFormStep2 from './ShipmentsFormStep2';
import ShipmentsFormStep4 from './ShipmentsFormStep4';
import { When } from 'react-if';
import clsx from 'clsx';
import SaveIcon from '@material-ui/icons/Save';
import { ArrowRightIcon } from '@material-ui/pickers/_shared/icons/ArrowRightIcon';
import { ArrowLeftIcon } from '@material-ui/pickers/_shared/icons/ArrowLeftIcon';
import RedoIcon from '@material-ui/icons/Redo';
import { useAppFormContext } from '../../../context/FormContext';
import ShipmentsFormStep3 from './ShipmentsFormStep3';
import {
  getIsVatShipment,
  sumByField,
  sumQuantityByField,
} from '../../../utils/helpers';
import { PARCEL_FIELDS } from '../../../utils/constants';
import { ShipmentsErrorHandler } from '../../../components/ShipmentsErrorHandler/ShipmentsErrorHandler';
import RequestHandler from '../../../components/RequestHandler/RequestHandler';
import { useTranslation } from 'react-i18next';
import ShipmentsFormVatStep from './ShipmentsFormVatStep';
import ShipmentsFormStep5 from './ShipmentsFormStep5';

const ShipmentsForm: React.FC = () => {
  const classes = useStyles();
  const { t } = useTranslation();

  const client = useApolloClient();
  const history = useHistory();
  const { enqueueSnackbar } = useSnackbar();
  const [isLoading, setLoading] = useState(false);
  const [isFetchCalculated, setIsFetchCalculated] = useState(false);
  const [activeStep, setActiveStep] = useState(0);
  const { allCountries } = useAppFormContext();
  const [isVatInformation, setIsVatInformation] = useState(false);

  const {
    isMelissaValidated,
    setIsMelissaValidated,
    isLoadingValidateAddress,
    setIsLoadingValidateAddress,
  } = useMelissa();

  const { formValue } = useAppFormContext();

  const { id, isCreation, isCopying, urlSearchParams } = useRouterWrapper();

  const {
    errorLazyParcel,
    dataLazyParcel,
    errorContacts,
    contactsData,
    errorCurrentUser,
    currentUserData,
    isValidId,
    cachedProducts,
  } = useQueryDataWrapper({ isCreation, id });

  const {
    data: orderByIdData,
    error: orderByIdError,
    loading: orderByIdLoading,
  } = useGetOrderByIdQuery({
    skip: !urlSearchParams.get(PARCEL_FIELDS.OTHER.ID),
    variables: { id: Number(urlSearchParams.get(PARCEL_FIELDS.OTHER.ID)) },
  });

  const { updateView, setUpdateView } = useUpdateView({ dataLazyParcel });

  const [createParcelMutation, { error: errorCreateParcelMutation }] =
    useCreateParcelMutation();

  const [addEtsyTrackNumberMutation, { loading: addEtsyTrackNumberLoading }] =
    useAddEtsyTrackNumberMutation({
      onError: (error) => {
        enqueueSnackbar(error.message, { variant: 'error' });
      },
    });

  const {
    declaredAmount,
    setDeclaredAmount,
    declaredWeight,
    setDeclaredWeight,
    globalProductCode,
    setGlobalProductCode,
  } = usePackage();

  // Pass new item's id to success message,
  // because we need to pass id to printShipment()
  // if user wants to print created parcel
  const [savedParcelResponseId, setSavedParcelResponseId] = useState<
    string | null
  >(null);

  const getTotalStep = () => {
    let totalStep = 4;
    if (isVatInformation) {
      totalStep = 5;
    }
    return totalStep;
  };

  const getIsSubmitButton = () => {
    return isVatInformation ? activeStep === 5 : activeStep === 4;
  };

  return (
    <RequestHandler loading={orderByIdLoading}>
      <ShipmentsErrorHandler
        id={id}
        isValidId={isValidId}
        dataLazyParcel={dataLazyParcel}
        isCreation={isCreation}
        errorContacts={errorContacts}
        errorCurrentUser={errorCurrentUser}
        errorLazyParcel={errorLazyParcel}
        errorCreateParcelMutation={errorCreateParcelMutation}
        currentUser={currentUserData?.currentUser as User}
        savedParcelResponseId={savedParcelResponseId}
        orderByIdError={orderByIdError}
      >
        <Typography variant='h2' className={classes.mainTitle} align='center'>
          {t('app.shipmentProcessing')}
        </Typography>
        <Formik
          initialValues={createInitValue({
            dataLazyParcel,
            urlSearchParams,
            currentUserData,
            allCountries,
            orderByIdData,
            cachedProducts,
          })}
          enableReinitialize
          validationSchema={
            isVatInformation
              ? shipmentWithVatInformationSchemas[activeStep]
              : shipmentSchemas[activeStep]
          }
          onSubmit={(values: any, helpers) => {
            if (activeStep < getTotalStep()) {
              setActiveStep((prevState) => prevState + 1);
              helpers.setSubmitting(false);
            } else {
              createNewParcel({
                values: values,
                setSubmitting: helpers.setSubmitting,
                isFetchCalculated,
                enqueueSnackbar,
                setLoading,
                declaredWeight,
                declaredAmount,
                pickPackages,
                history,
                shipmentSchemaDraft,
                mutateCreate,
                urlSearchParams,
                formValue,
                globalProductCode,
                isCreation,
                isCopying,
                createParcelMutation,
                setSavedParcelResponseId,
                id,
                client,
                addEtsyTrackNumberMutation,
                orderByIdData,
                isVatInformation,
              });
            }
          }}
        >
          {({
            errors,
            values,
            touched,
            setFieldValue,
            setValues,
            isSubmitting,
          }) => {
            const senderCountryIso = values?.sender?.country?.iso;
            const receiverCountryIso = values?.receiver?.country?.iso;
            const isShowVatStep = getIsVatShipment(
              senderCountryIso,
              receiverCountryIso,
            );
            setIsVatInformation(isShowVatStep);
            const setFieldValueDebounce = debounce(setFieldValue, DEBOUNCE);
            return (
              <Form noValidate autoComplete='off'>
                <Stepper orientation='vertical' activeStep={activeStep}>
                  <Step>
                    <StepLabel>{t('app.contactInformation')}</StepLabel>
                    <StepContent>
                      <ShipmentsFormStep1
                        values={values}
                        contactsData={contactsData}
                        setFieldValue={setFieldValue}
                        updateView={updateView}
                        setUpdateView={setUpdateView}
                        outerIsLoadingValidateAddress={isLoadingValidateAddress}
                        outerIsMelissaValidated={isMelissaValidated}
                        outerSetIsLoadingValidateAddress={
                          setIsLoadingValidateAddress
                        }
                        outerSetIsMelissaValidated={setIsMelissaValidated}
                      />
                    </StepContent>
                  </Step>
                  <Step>
                    <StepLabel>{t('app.packaging')}</StepLabel>
                    <StepContent>
                      <ShipmentsFormStep2
                        values={values}
                        setUpdateView={setUpdateView}
                        setFieldValue={setFieldValue}
                        setIsFetchCalculated={setIsFetchCalculated}
                        touched={touched}
                        errors={errors}
                        setFieldValueDebounce={setFieldValueDebounce}
                        setValues={setValues}
                        updateView={updateView}
                      />
                    </StepContent>
                  </Step>
                  <Step>
                    <StepLabel>{t('app.productInformation')}</StepLabel>
                    <StepContent>
                      <ShipmentsFormStep3
                        values={values}
                        setUpdateView={setUpdateView}
                        setFieldValue={setFieldValue}
                        setIsFetchCalculated={setIsFetchCalculated}
                        touched={touched}
                        errors={errors}
                        setFieldValueDebounce={setFieldValueDebounce}
                        setValues={setValues}
                        updateView={updateView}
                        orderProducts={
                          orderByIdData?.getOrderById
                            ?.orderProducts as OrderProduct[]
                        }
                      />
                    </StepContent>
                  </Step>
                  {isShowVatStep ? (
                    <Step>
                      <StepLabel>{t('app.customsInformation')}</StepLabel>
                      <StepContent>
                        <ShipmentsFormVatStep
                          setFieldValueDebounce={setFieldValueDebounce}
                        />
                      </StepContent>
                    </Step>
                  ) : null}
                  <Step>
                    <StepLabel>{t('app.additionalOptions')}</StepLabel>
                    <StepContent>
                      <ShipmentsFormStep4
                        setFieldValueDebounce={setFieldValueDebounce}
                      />
                    </StepContent>
                  </Step>
                  <Step>
                    <StepLabel>{t('app.tariffsAndTerms')}</StepLabel>
                    <StepContent>
                      <ShipmentsFormStep5
                        values={values}
                        setFieldValueDebounce={setFieldValueDebounce}
                        errors={errors}
                        setDeclaredAmount={setDeclaredAmount}
                        setDeclaredWeight={setDeclaredWeight}
                        isLoading={isLoading}
                        setLoading={setLoading}
                        setIsFetchCalculated={setIsFetchCalculated}
                        isFetchCalculated={isFetchCalculated}
                        setGlobalProductCode={setGlobalProductCode}
                      />
                    </StepContent>
                  </Step>
                </Stepper>

                <div className={classes.actions}>
                  <When condition={Boolean(Object.values(errors).length)}>
                    <p className={classes.errorsMessage}>
                      {t('app.checkAllFieldsAbove')}
                    </p>
                  </When>
                  <When
                    condition={
                      +sumByField(values.packages, 'weightKg') <
                        +sumQuantityByField(
                          values.packages[0].units,
                          'netWeight',
                        ) && activeStep >= 2
                    }
                  >
                    <p className={classes.errorsMessage}>
                      {t('app.netWeightCannotExceedGrossWeight')}
                    </p>
                  </When>

                  <Button
                    className={clsx(classes.arrangeAction, classes.action)}
                    variant='contained'
                    disabled={activeStep === 0}
                    onClick={() => setActiveStep((prevState) => prevState - 1)}
                    startIcon={<ArrowLeftIcon />}
                  >
                    {t('app.back')}
                  </Button>

                  <Button
                    className={clsx(classes.arrangeAction, classes.action)}
                    type={'submit'}
                    disabled={
                      isSubmitting ||
                      isLoading ||
                      addEtsyTrackNumberLoading ||
                      isLoadingValidateAddress ||
                      (+sumByField(values.packages, 'weightKg') <
                        +sumQuantityByField(
                          values.packages[0].units,
                          'netWeight',
                        ) &&
                        activeStep >= 2)
                    }
                    variant='contained'
                    startIcon={
                      getIsSubmitButton() ? <SaveIcon /> : <ArrowRightIcon />
                    }
                    onClick={() => {
                      window.scrollTo(0, 100);
                    }}
                  >
                    {getIsSubmitButton() ? t('app.complete') : t('app.next')}
                  </Button>

                  {process?.env?.NODE_ENV === 'development' &&
                    activeStep === 0 && (
                      <Button
                        className={clsx(classes.arrangeAction, classes.action)}
                        variant='contained'
                        onClick={() => {
                          setIsMelissaValidated(true);
                        }}
                        startIcon={<RedoIcon />}
                      >
                        <Tooltip title='Доступно только при process?.env?.NODE_ENV === development (недоступно в production).'>
                          <span>{t('app.skipCheck')}</span>
                        </Tooltip>
                      </Button>
                    )}
                </div>
              </Form>
            );
          }}
        </Formik>
      </ShipmentsErrorHandler>
    </RequestHandler>
  );
};

export default ShipmentsForm;
