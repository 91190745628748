import { makeStyles } from '@material-ui/core/styles';

export default makeStyles((theme) => ({
  btnIcon: {
    float: 'right',
    marginLeft: 5,
    lineHeight: '30px',
  },
  linkIcon: {
    fill: '#58a6ff',
    verticalAlign: 'middle',
  },
  wrapValueCell: {
    display: 'inline-block',
    padding: '5px 0 5px 10px',
    minWidth: '40px',
  },
  actionMenuItem: {
    padding: 0,
  },
  actionIconWatch: {
    color: 'green',
  },
  actionIconDownload: {
    color: 'black',
  },
  actionIcon: {
    paddingRight: '5px',
    fontSize: '20px',
  },
  actionIconEdit: {
    color: 'tomato',
  },
  actionIconCancel: {
    color: 'red',
  },
  actionLink: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    textDecoration: 'none',
    padding: '6px 16px',
  },
  carrierCodeIcon: {
    maxWidth: '20px',
    maxHeight: '16px',
  },
  tableCell: {
    padding: '8px',
  },
  tableCheckbox: {
    padding: '0px',
  },
  selectActions: {
    '& .MuiInput-input ': {
      paddingRight: '20px',
      paddingLeft: '8px',
    },
  },
}));
