import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import { COLORS } from '../../utils/constants';

const widthDrawerVisible = 345;
const widthDrawerHidden = 100;

export const createStylesHeader = (
  isSidebarHidden: boolean,
  isSidebarDisabled: boolean = false,
) =>
  makeStyles((theme: Theme) =>
    createStyles({
      headerLink: {
        display: 'flex',
        alignItems: 'center',
        textDecoration: 'none',
        color: COLORS.BLACK,
        padding: '5px 10px',
      },
      headerIconWrap: {
        width: 24,
        height: 24,
        margin: '0 5px',
        padding: 0,
        color: COLORS.BLACK,
      },
      appBar: {
        backgroundColor: 'transparent',
        marginLeft: isSidebarDisabled ? 0 : 'auto',

        [theme.breakpoints.up('md')]: {
          width: isSidebarDisabled
            ? 'auto'
            : `calc(100% - ${
                isSidebarHidden ? widthDrawerHidden : widthDrawerVisible
              }px)`,
          marginLeft: isSidebarDisabled
            ? 0
            : isSidebarHidden
            ? widthDrawerHidden
            : widthDrawerVisible,
        },
        boxShadow: 'none',
      },
      toggleMenu: {
        padding: '0',
        margin: '0',
      },
      toolbar: {
        minHeight: 'auto',
        padding: '24px 16px',
        justifyContent: isSidebarDisabled ? 'center' : 'flex-start',
      },
      balance: {
        display: 'flex',
        alignItems: 'center',
        paddingRight: 16,
        color: '#606060',
        fontWeight: 300,
        fontSize: '18px',
        lineHeight: '1.3px',
        textDecoration: 'none',
        '&:hover': {
          textDecoration: 'underline',
          '& $balanceIcon': {
            color: '#606060',
          },
        },
      },
      balanceIcon: {
        margin: '0 5px',
        color: COLORS.BLACK,
      },
      person: {
        display: 'flex',
        alignItems: 'center',
        color: '#606060',
        fontWeight: 300,
        fontSize: '18px',
        lineHeight: '1.3px',
        textDecoration: 'none',
        '&:hover': {
          textDecoration: 'underline',
          '& $personIcon': {
            color: '#606060',
          },
        },
      },
      personIcon: {
        margin: '0 5px',
        color: COLORS.BLACK,
      },
      personImg: {
        width: '24px',
        height: '24px',
        margin: '0 5px',
      },
      signOut: {
        color: COLORS.BLACK,
        lineHeight: '20px',
        padding: 0,
        textTransform: 'none',
        fontWeight: 'normal',
        '&:hover': {
          color: '#606060',
        },
      },
      logo: {
        marginTop: '32px',
        marginLeft: '24px',
        fontSize: '32px',
        lineHeight: '44px',
        fontWeight: 700,
        fontFamily: 'Open Sans, Roboto, Helvetica, Arial, sans-serif',
        color: COLORS.WHITE,
        letterSpacing: '1px',
      },
      logoInner: {
        color: '#a3b4d2',
      },
      logoWithHiddenSidebar: {
        maxHeight: 45,
        margin: '28px auto auto',
      },
      content: {
        flexGrow: 1,
        minHeight: '100vh',
        width: `calc(100% - ${
          isSidebarHidden ? widthDrawerHidden : widthDrawerVisible
        }px)`,
        padding: 20,
        paddingLeft: 32,
        paddingRight: 16,

        [theme.breakpoints.down('sm')]: {
          width: '100%',
          paddingLeft: 10,
          paddingRight: 10,
        },
      },
      container: {
        maxWidth: '100%',
        padding: 0,
        height: '100%',
      },
      sidebarToggler: {
        cursor: 'pointer',
        color: COLORS.WHITE,
        display: 'flex',
        justifyContent: 'center',
        padding: '15px 0',
      },
      userInfoSelect: {
        border: 'none',
        '& .MuiSelect-root': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '0 24px 0 0',
          boxSizing: 'border-box',
        },
        '& .MuiSvgIcon-root': {
          width: '24px',
          height: '24px',
        },
        '& .MuiButtonBase-root': {
          color: '#606060',
          fontWeight: 300,
          fontSize: '18px',
          lineHeight: '1.3px',
          textDecoration: 'none',
        },
        '&::before': {
          display: 'none',
        },
      },
      userSettingsSelect: {
        border: 'none',
        '& .MuiSelect-root': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '0px',
          boxSizing: 'border-box',
        },
        '& .MuiSvgIcon-root': {
          width: '24px',
          height: '24px',
        },
        '& .MuiSvgIcon-root.MuiSelect-icon': {
          display: 'none',
        },
        '&::before': {
          display: 'none',
        },
      },
      boxModalButtons: {
        margin: '30px 0 0',
        display: 'flex',
        justifyContent: 'space-around',
      },
      formControl: {
        marginBottom: 24,
      },
      language: {
        color: '#606060',
        fontWeight: 300,
        fontSize: '18px',
      },
      languageSelect: {
        border: 'none',
        '& .MuiSelect-root': {
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          padding: '0px',
          boxSizing: 'border-box',
        },
        '& .MuiPaper-root': {
          top: '40px',
        },
        '& .MuiSvgIcon-root': {
          width: '24px',
          height: '24px',
        },
        '& .MuiSvgIcon-root.MuiSelect-icon': {
          display: 'none',
        },
        '&::before': {
          display: 'none',
        },
      },
    }),
  )();
