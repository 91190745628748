import { makeStyles } from '@material-ui/core/styles';
import { COLORS } from '../../utils/constants';

export default makeStyles((theme) => ({
  root: {
    marginTop: 30,
    paddingTop: 30,
    borderTop: '1px solid #e0e0e0',

    '&:first-child': {
      marginTop: 0,
      paddingTop: 0,
      border: 'none',
    },
  },
  actionRemove: {
    marginTop: 20,
  },
  addParcelAction: {
    marginTop: 40,
  },
  packagesInfo: {
    fontSize: 16,
    marginTop: 20,
    marginBottom: 20,
    display: 'flex',
    flexWrap: 'wrap',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down(1050)]: {
      flexDirection: 'column',
    },
  },
  packagesInfoDivider: {
    height: 22,
    marginRight: 5,
    marginLeft: 5,

    [theme.breakpoints.down(1050)]: {
      display: 'none',
    },
  },
  parcelInfo: {
    fontSize: 16,
    marginTop: 20,
    marginBottom: 20,
    display: 'flex',
    flexWrap: 'wrap',
    whiteSpace: 'nowrap',
    [theme.breakpoints.down(1050)]: {
      flexDirection: 'column',
    },
  },
  parcelInfoDivider: {
    height: 22,
    marginRight: 5,
    marginLeft: 5,

    [theme.breakpoints.down(1050)]: {
      display: 'none',
    },
  },
  packageItem: {
    padding: '20px',
    borderRadius: '10px',
    border: '2px solid transparent',
  },
  removePackage: {
    cursor: 'pointer',
    color: COLORS.RED,
  },
  prohibitedListButton: {
    padding: 0,
    fontSize: '12px',
    lineHeight: '12px',
    color: '#bdbdbd',
    alignSelf: 'flex-end',
  },
  codeProduct: {
    height: '100%',

    '& .MuiInput-root': {
      [theme.breakpoints.up('lg')]: {
        marginTop: 12,
      },
      [theme.breakpoints.up(1298)]: {
        marginTop: 0,
      },
    },
  },
  tooltipIconButton: {
    padding: 0,
  },
  inputLabelMini: {
    [theme.breakpoints.up('lg')]: {
      fontSize: 15,
    },
    [theme.breakpoints.down(1290)]: {
      fontSize: 'inherit',
    },
  },
  formControl: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-between',
    height: '100%',
  },
  openMyPackages: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    right: 0,
    cursor: 'pointer',
    fontWeight: 'normal',
    fontSize: 15,
    letterSpacing: 0,
  },
  openMyProducts: {
    display: 'flex',
    alignItems: 'center',
    position: 'absolute',
    right: 0,
    cursor: 'pointer',
    fontWeight: 'normal',
    fontSize: 15,
    letterSpacing: 0,
  },
  errorsMessage: {
    color: '#f44336',
  },
}));
