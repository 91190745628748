import { t } from 'i18next';
import {
  CalculationInput,
  GetDeliveryCountriesQuery,
  GetDeliveryCountriesQueryVariables,
  GetListOfDeliveryOptionsQuery,
  GetListOfDeliveryOptionsQueryVariables,
  Mode_Types,
  Package,
  ParcelUnit,
} from '../generated/graphql';
import client from '../GraphQL/client';
import { QUERY_DELIVERY_COUNTRIES } from '../GraphQL/queries/getDeliveryCountries';
import { QUERY_LIST_OF_DELIVERY_OPTIONS } from '../GraphQL/queries/getListOfDeliveryOptions';

const fetchCalculator = (
  mode = 'FULL' as Mode_Types,
  receiverCountry: any,
  additionalInsurance: boolean,
  signature: boolean,
  packages: Package[],
  currency: string,
  // (Оценочная стоимость груза) used only for calculator, while packages without units but we still need price to calculate insurance
  insuranceAmount: number,
  userId: string | undefined = undefined,
  city: string | undefined,
  zipCode: string | undefined,
  isCalculatorPage: boolean,
  warehouseId?: string,
  consolidation?: any,
  simple = false,
  addressValidation = false,
) => {
  const cachedCountries = client.readQuery<
    GetDeliveryCountriesQuery,
    GetDeliveryCountriesQueryVariables
  >({
    query: QUERY_DELIVERY_COUNTRIES,
  });

  if (cachedCountries?.deliveryCountries?.length) {
    const country = cachedCountries.deliveryCountries?.find((country) => {
      return country && country?.iso === receiverCountry?.iso;
    });
    if (country && country.order) {
      const units: ParcelUnit[] = [];
      packages?.forEach((p) => {
        if (p && p.units) {
          p.units.forEach((u) => {
            u && units.push(u);
          });
        }
      });

      const cargo_price = additionalInsurance
        ? units.reduce((acc, unit) => {
            return unit && unit.quantity && unit.price
              ? acc + +unit.quantity * +unit.price
              : acc;
          }, 0)
        : 0;

      const input: CalculationInput = {
        mode,
        receiver: receiverInout(consolidation, simple),
        sender: simple ? undefined : senderInout(consolidation),
        address_validation: addressValidation,
        cargo_price: insuranceAmount ? insuranceAmount : +cargo_price,
        iso: country.iso || '',
        currency_code: currency.toUpperCase(),
        positions: packages?.map((item) => {
          const units: any = [];
          item?.units?.forEach((unit: any) =>
            units.push({
              price: +unit.price,
              quantity: +unit.quantity,
              code: +unit.code,
              countryId: unit.country.id,
              nameEN: unit.nameEN,
              weight: +unit.netWeight,
              ...(unit.sku && { sku: unit.sku }),
            }),
          );

          return item
            ? {
                weight: Number(item.weightKg),
                length: Number(item.lengthCm),
                height: Number(item.heightCm),
                width: Number(item.widthCm),
                count: 1,
                units: units,
              }
            : null;
        }),
        ...(userId ? { userId: +userId } : {}),
        signature: signature,
        //@ts-ignore
        ...(additionalInsurance && {
          insuranceAmount: Number(insuranceAmount),
        }),
        additionalInsurance: additionalInsurance,
        ...(mode === 'FORWARDING_ONLY' && { warehouseId: warehouseId }),
      };

      return client.query<
        GetListOfDeliveryOptionsQuery,
        GetListOfDeliveryOptionsQueryVariables
      >({
        query: QUERY_LIST_OF_DELIVERY_OPTIONS,
        fetchPolicy: 'network-only',
        variables: { input },
      });
    }
    return Promise.reject(
      `${t('app.unableToFindCountry')} ${receiverCountry.trim()}.`,
    );
  }
  return Promise.reject(t('app.unableToLoadCountries'));
};

export default fetchCalculator;

const receiverInout = (consolidation: any, simple: any) => {
  const receiver = {
    ...consolidation?.receiver,
    countryId: consolidation?.receiver?.country?.id,
    id: parseInt(consolidation?.receiver?.id),
  };
  delete receiver?.country;
  delete receiver?.__typename;
  delete receiver?.type;
  if (simple) delete receiver?.id;
  return receiver;
};

const senderInout = (consolidation: any) => {
  const sender = {
    ...consolidation?.sender,
    countryId: consolidation?.sender?.country?.id,
    id: parseInt(consolidation?.sender?.id),
  };
  delete sender?.country;
  delete sender?.__typename;
  delete sender?.type;
  return sender;
};
