import React, { useEffect } from 'react';
import { Box, Divider } from '@material-ui/core';
import { FormikErrors, FormikTouched } from 'formik';

import Packages from '../../../components/Packages/Packages';

import useStyles from './styles';
import {
  OrderProduct,
  Package,
  Parcel,
  ParcelInput,
} from '../../../generated/graphql';
import {
  TFormikSetFieldValue,
  TFormikSetFieldValueDebounce,
  TSetStateBoolean,
  TSetStateNumber,
} from '../../../interfaces';
import { getInitialUnit } from '../../../utils/helpers';

const ShipmentsFormStep3: React.FC<{
  values: ParcelInput;
  setFieldValue: TFormikSetFieldValue;
  setIsFetchCalculated: TSetStateBoolean;
  setUpdateView: TSetStateNumber;
  touched: FormikTouched<Parcel>;
  errors: FormikErrors<Parcel>;
  setValues: (
    values: React.SetStateAction<Parcel>,
    shouldValidate?: boolean | undefined,
  ) => void;
  setFieldValueDebounce: TFormikSetFieldValueDebounce;
  updateView: number;
  orderProducts?: OrderProduct[];
}> = ({
  values,
  setFieldValue,
  setIsFetchCalculated,
  setUpdateView,
  touched,
  errors,
  setFieldValueDebounce,
  setValues,
  updateView,
  orderProducts,
}) => {
  const classes = useStyles();

  useEffect(() => {
    if (!orderProducts?.length) return;

    setFieldValue('packages[0].units', orderProducts);
    // eslint-disable-next-line
  }, [orderProducts]);

  useEffect(() => {
    if (values?.packages[0]?.units) return;
    setFieldValue('packages[0].units', [getInitialUnit()]);
    // eslint-disable-next-line
  }, []);

  return (
    <div className={classes.wrapper}>
      <Packages
        key={updateView}
        packages={values.packages as Package[]}
        errors={errors}
        touched={touched}
        currency={values.currency as string}
        setFieldValue={setFieldValueDebounce}
        setValues={setValues}
        setIsFetchCalculated={setIsFetchCalculated}
        isCalculatorPage={false}
        setUpdateView={setUpdateView}
        isMarketPlace={!!orderProducts}
      />
      <Box mt={7.125} mb={8.125}>
        <Divider />
      </Box>
    </div>
  );
};

export default ShipmentsFormStep3;
