import React, { useEffect, useRef, useState } from 'react';
import { Unless } from 'react-if';
import {
  Box,
  Button,
  CircularProgress,
  Divider,
  FormControl,
  FormHelperText,
  IconButton,
  Input,
  InputLabel,
  TextareaAutosize,
  TextField,
  Tooltip,
} from '@material-ui/core';
import SaveIcon from '@material-ui/icons/Save';
import InfoIcon from '@material-ui/icons/Info';
import {
  FastField,
  Field,
  FieldProps,
  Form,
  Formik,
  FormikHelpers,
  FormikProps,
  useFormikContext,
} from 'formik';
import PhoneInput from 'react-phone-number-input';
import {
  getCountryCallingCode,
  isPossiblePhoneNumber,
  isSupportedCountry,
} from 'react-phone-number-input';
import debounce from 'debounce';
import {
  formContactNotPersonalShema,
  formContactPersonalShema,
  formContactPersonalShemaRus,
} from '../../utils/validationSchemes';
import useStyles from './styles';
import {
  IMelissaSuggestions,
  TFormikSetFieldValue,
  TFormikSetFieldValueDebounce,
  TSetStateBoolean,
} from '../../interfaces';
import {
  Contact,
  GetDeliveryCountriesQuery,
  GetDeliveryCountriesQueryVariables,
  Parcel,
  useGetCitiesByCountryIsoLazyQuery,
} from '../../generated/graphql';
import { useSnackbar } from 'notistack';
import { ShowLoadingText } from '../../utils/helperComponents';
import { useApolloClient } from '@apollo/client';
import { QUERY_DELIVERY_COUNTRIES } from '../../GraphQL/queries/getDeliveryCountries';
import { BoxCentered } from '../BoxCentered/BoxCentered';
import { COLORS, DEBOUNCE } from '../../utils/constants';
import { Autocomplete } from '@material-ui/lab';
import { Spellcheck } from '@material-ui/icons';
import {
  getRequiredMelissaTitle,
  queryValidateAddressMelissa,
} from '../../utils/helpers';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import { SearchSelect } from '../SearchSelect/SearchSelect';
import { useAppFormContext } from '../../context/FormContext';
import { useTranslation } from 'react-i18next';

/**
 * Этот компонент можно использовать как самостоятельно (т.е. уже обёрнутый в <Formik>),
 * так и только его поля (Отправитель и Получатель в форме ОФОРМЛЕНИЕ ОТПРАВЛЕНИЯ).
 * Чтобы использовать компонент самостоятельно,
 * необходимо передать ему инициализацонные параметры для Formik: initialValues и onSubmit
 * Чтобы использовать как отправитель или получатель - нужно передать nameShape.
 *
 * Use cases:
 1) Форма редактирования Личных Данных.
 2) Отправитель в форме Оформления Отправления.
 3) Получатель в форме Оформления Отправления.
 4) Редактирование контакта в Адресной Книге.

 * @param {string} nameShape - эта форма используется для выбора отправителя или получателя ('sender' | 'receiver')?
 *
 * @param {boolean} isPersonal - это Форма редактирования Личных Данных?
 * @param {boolean} isRestrict - ограничено ли что? Выводит поле "Примечание об этом контакте"
 * @param {boolean} isCreate - создаётся ли что?
 * @param onSubmit - что произойдёт при сабмите формы?
 * @param outerSetFieldValue - функция Formik для установки значения полю
 * @param initialValues - текущий пользователь, инициализационные данные для формы,
 * @param outerSelectedCountry
 * @param isLoadingValidateAddress
 * @constructor
 */
const FormContact: React.FC<{
  nameShape?: 'sender' | 'receiver';
  isPersonal?: boolean;
  isRestrict?: boolean;
  isCreate?: boolean;
  isAddress?: boolean;
  onSubmit?: (values: any, { setSubmitting }: FormikHelpers<any>) => void;
  outerSetFieldValue?: TFormikSetFieldValue;
  initialValues?: Contact;
  outerSelectedCountry?: string | null | undefined;
  outerIsLoadingValidateAddress?: boolean;
  outerIsMelissaValidated?: boolean;
  outerSetIsMelissaValidated?: TSetStateBoolean;
  outerSetIsLoadingValidateAddress?: TSetStateBoolean;
  contactType?: 'sender' | 'recipient';
}> = ({
  nameShape,
  isPersonal,
  isRestrict,
  isCreate,
  isAddress,
  onSubmit,
  outerSetFieldValue,
  initialValues,
  outerSelectedCountry,
  outerIsLoadingValidateAddress,
  outerIsMelissaValidated,
  outerSetIsLoadingValidateAddress,
  outerSetIsMelissaValidated,
  contactType,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const client = useApolloClient();
  const { sngCountry: COUNTRIES_CIS } = useAppFormContext();

  const { t } = useTranslation();

  const formikContext = useFormikContext<Parcel>();
  const formikRef = useRef<FormikProps<Contact>>(null);
  const [cities, setCities] = useState<string[]>([]);
  const [phoneCountryCodeToChange, setPhoneCountryCodeToChange] = useState('');
  const [isChangePhoneAfterCountryChange, setIsChangePhoneAfterCountryChange] =
    useState(false);
  const [innerSelectedCountry, setInnerSelectedCountry] = useState('');
  const [isMelissaValidated, setIsMelissaValidated] = useState(false);
  const [isLoadingValidateAddress, setIsLoadingValidateAddress] =
    useState(false);
  const [numberWithoutCountryCode, setNumberWithoutCountryCode] = useState('');

  const [melissaSuggestions, setMelissaSuggestions] =
    useState<IMelissaSuggestions>({
      state: '',
      city: '',
      zipCode: '',
      address: '',
    });

  const [
    getCitiesByCountryIsoLazyQuery,
    {
      data: dataLazyQueryGetCitiesByISO,
      loading: isLoadingLazyQueryGetCitiesByISO,
      error: errorLazyQueryGetCitiesByISO,
    },
  ] = useGetCitiesByCountryIsoLazyQuery();

  if (errorLazyQueryGetCitiesByISO?.message) {
    enqueueSnackbar(errorLazyQueryGetCitiesByISO?.message);
  }

  const dataDeliveryCountries = client.readQuery<
    GetDeliveryCountriesQuery,
    GetDeliveryCountriesQueryVariables
  >({
    query: QUERY_DELIVERY_COUNTRIES,
  });

  const setSelectedCountry = (selectedCountry: any) => {
    let iso: string | undefined | null;

    iso = dataDeliveryCountries?.deliveryCountries?.find(
      (c) =>
        c?.name === selectedCountry?.name ||
        c?.nameEng === selectedCountry?.nameEng,
    )?.iso;

    if (iso) {
      getCitiesByCountryIsoLazyQuery({
        variables: {
          iso: iso,
        },
      });

      if (isChangePhoneAfterCountryChange) {
        if (isSupportedCountry(iso)) {
          setPhoneCountryCodeToChange(
            /* prettier-ignore */
            //@ts-ignore
            "+" + getCountryCallingCode(iso) + numberWithoutCountryCode,
          );
        } else {
          enqueueSnackbar(t('app.failedToChangePhoneCode'), {
            variant: 'warning',
          });
        }
      }
    }
  };

  /** componentDidMount */
  useEffect(() => {
    let iso;
    const country =
      outerSelectedCountry || formikRef?.current?.values?.country || '';
    if (nameShape === 'sender' || isPersonal) {
      iso = COUNTRIES_CIS.find((cc: any) => cc.name === country)?.iso;
    } else {
      iso = dataDeliveryCountries?.deliveryCountries?.find(
        (c) => c?.name === country || c?.nameEng === country,
      )?.iso;
    }

    let phoneNumber =
      (nameShape === 'sender'
        ? formikContext?.values?.sender?.phone
        : formikContext?.values?.receiver?.phone) ||
      formikRef?.current?.values?.phone ||
      '';
    //@ts-ignore
    const countryCallingCode = iso ? getCountryCallingCode(iso) : null;

    if (
      countryCallingCode &&
      phoneNumber.indexOf('+' + countryCallingCode) > -1
    ) {
      phoneNumber = phoneNumber.slice(
        countryCallingCode.length + 1,
        phoneNumber.length,
      );
    }

    setNumberWithoutCountryCode(phoneNumber);

    if (iso) {
      getCitiesByCountryIsoLazyQuery({ variables: { iso } });
    }
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (innerSelectedCountry) {
      setSelectedCountry(innerSelectedCountry);
    } else if (numberWithoutCountryCode && outerSelectedCountry) {
      setSelectedCountry(outerSelectedCountry);
    }
    // eslint-disable-next-line
  }, [innerSelectedCountry, numberWithoutCountryCode]);

  /** New cities array fetched from query getCitiesByCountryIso(iso: $iso) */
  useEffect(() => {
    if (dataLazyQueryGetCitiesByISO?.getCitiesByCountryIso?.length) {
      let sorted = dataLazyQueryGetCitiesByISO?.getCitiesByCountryIso?.slice();
      sorted = sorted.sort((a, b) => {
        return a?.name && b?.name && a?.name < b?.name
          ? -1
          : a?.name && b?.name && a?.name > b.name
          ? 1
          : 0;
      });

      const uniqueCities = [...new Set(sorted.map((c) => c?.name!))];

      setCities(uniqueCities);
    }
  }, [dataLazyQueryGetCitiesByISO]);

  useEffect(() => {
    if (phoneCountryCodeToChange) {
      if (outerSetFieldValue) {
        outerSetFieldValue(getFieldName('phone'), phoneCountryCodeToChange);
      } else if (formikRef?.current?.setFieldValue) {
        formikRef?.current?.setFieldValue(
          getFieldName('phone'),
          phoneCountryCodeToChange,
        );
      }
    }
    // eslint-disable-next-line
  }, [phoneCountryCodeToChange]);

  const handleValidateAddress = () => {
    const countryIso =
      formikContext?.values?.receiver?.country?.iso ||
      formikRef?.current?.values?.country?.iso ||
      '';
    const city =
      formikContext?.values?.receiver?.city ||
      formikRef?.current?.values?.city ||
      '';
    const state =
      formikContext?.values?.receiver?.state ||
      formikRef?.current?.values?.state ||
      '';
    const zipCode =
      formikContext?.values?.receiver?.zipCode ||
      formikRef?.current?.values?.zipCode ||
      '';
    const address =
      formikContext?.values?.receiver?.address ||
      formikRef?.current?.values?.address ||
      '';

    const country =
      formikContext?.values?.receiver?.country?.name ||
      formikRef?.current?.values?.country?.name ||
      '';

    if (!country || !city || !address) {
      enqueueSnackbar(getRequiredMelissaTitle(country, city, state, zipCode), {
        variant: 'error',
      });
      return;
    }
    outerSetIsLoadingValidateAddress && outerSetIsLoadingValidateAddress(true);
    setIsLoadingValidateAddress(true);
    queryValidateAddressMelissa(
      country,
      city,
      state,
      zipCode,
      address,
      countryIso,
    )
      .then((value) => {
        if (value?.data?.validationAddress) {
          outerSetIsMelissaValidated && outerSetIsMelissaValidated(true);
          setIsMelissaValidated(true);
          const va = value?.data?.validationAddress;
          if (
            (va?.city?.returnValue &&
              va?.city?.returnValue?.toUpperCase() !==
                va?.city?.inputValue?.toUpperCase()) ||
            (va?.state?.returnValue &&
              va?.state?.returnValue?.toUpperCase() !==
                va?.state?.inputValue?.toUpperCase()) ||
            (va?.postalCode?.returnValue &&
              va?.postalCode?.returnValue?.toUpperCase() !==
                va?.postalCode?.inputValue?.toUpperCase()) ||
            (va?.address?.returnValue &&
              va?.address?.returnValue?.toUpperCase() !==
                va?.address?.inputValue?.toUpperCase())
          ) {
            enqueueSnackbar(t('app.validationServiceCorrection'), {
              variant: 'warning',
            });
          } else {
            enqueueSnackbar(t('app.addressCorrect'), {
              variant: 'success',
            });
          }

          setMelissaSuggestions((prevState) => ({
            ...prevState,
            ...(va?.city?.returnValue &&
              va?.city?.returnValue?.toUpperCase() !==
                va?.city?.inputValue?.toUpperCase() && {
                city: va?.city?.returnValue,
              }),
            ...(va?.state?.returnValue &&
              va?.state?.returnValue?.toUpperCase() !==
                va?.state?.inputValue?.toUpperCase() && {
                state: va?.state?.returnValue,
              }),
            ...(va?.postalCode?.returnValue &&
              va?.postalCode?.returnValue?.toUpperCase() !==
                va?.postalCode?.inputValue?.toUpperCase() && {
                zipCode: va?.postalCode?.returnValue,
              }),
            ...(va?.address?.returnValue &&
              va?.address?.returnValue?.toUpperCase() !==
                va?.address?.inputValue?.toUpperCase() && {
                address: va?.address?.returnValue,
              }),
          }));
        }
      })
      .catch((reason) => {
        console.error(reason);
        if (reason?.message) {
          enqueueSnackbar(reason?.message, { variant: 'error' });
        }
      })
      .finally(() => {
        outerSetIsLoadingValidateAddress &&
          outerSetIsLoadingValidateAddress(false);
        setIsLoadingValidateAddress(false);
      });
  };

  const renderField = (
    name: string,
    label: string,
    setFieldValue: TFormikSetFieldValueDebounce,
    disable?: boolean,
  ) => (
    <Field name={name}>
      {({ field: { value, ...field }, meta }: FieldProps) => (
        <FormControl error={!!(meta.touched && meta.error)}>
          <InputLabel
            className={classes.inputLabel}
            shrink={false}
            htmlFor={`${nameShape}-${name}-input`}
          >
            {label}
          </InputLabel>
          <Input
            disabled={outerIsLoadingValidateAddress || disable}
            disableUnderline
            id={`${nameShape}-${name}-input`}
            {...field}
            defaultValue={value}
            onChange={(e) => {
              const name = e.target.name;
              const value = e.target.value;
              setFieldValue(name, value);
            }}
          />
          {name === getFieldName('state') &&
            suggestion(name, melissaSuggestions?.state)}
          {name === getFieldName('zipCode') &&
            suggestion(name, melissaSuggestions?.zipCode)}
          {name === getFieldName('address') &&
            suggestion(name, melissaSuggestions?.address)}
          {meta.touched && meta.error && (
            <FormHelperText>{meta.error}</FormHelperText>
          )}
        </FormControl>
      )}
    </Field>
  );

  const getFieldName = (name: string) =>
    nameShape ? `${nameShape}.${name}` : name;

  const suggestion = (
    fieldName: string,
    suggestionValue: string | undefined,
  ) => {
    return (
      !!suggestionValue && (
        <BoxCentered color={COLORS.ORANGE} justifyContent='flex-start'>
          {t('app.youMeant')} {suggestionValue}?
          <Tooltip title={t('app.clickToCopy')}>
            <IconButton
              size='small'
              onClick={() => {
                if (navigator?.clipboard?.writeText) {
                  navigator?.clipboard?.writeText(suggestionValue);
                }

                setMelissaSuggestions((prevState) => ({
                  ...prevState,
                  ...(fieldName === getFieldName('city') && { city: '' }),
                  ...(fieldName === getFieldName('state') && { state: '' }),
                  ...(fieldName === getFieldName('address') && { address: '' }),
                  ...(fieldName === getFieldName('zipCode') && { zipCode: '' }),
                }));
              }}
            >
              <FileCopyIcon fontSize='small' />
            </IconButton>
          </Tooltip>
        </BoxCentered>
      )
    );
  };

  const getFields = (setFieldValue: TFormikSetFieldValueDebounce) => (
    <>
      {isAddress &&
        renderField(
          getFieldName('contactName'),
          `${t('app.addressName')}*`,
          setFieldValue,
        )}
      {renderField(
        getFieldName('name'),
        `${t('app.fullName')}*`,
        setFieldValue,
      )}

      <Field name={getFieldName('company')}>
        {({ field: { value, ...field }, meta }: FieldProps) => (
          <FormControl error={!!(meta.touched && meta.error)}>
            <InputLabel
              className={classes.inputLabel}
              shrink={false}
              htmlFor={`${nameShape}-company-input`}
            >
              {t('app.company')}
              <Tooltip
                enterTouchDelay={50}
                placement='top'
                title={t('app.noCompanyName')}
              >
                <IconButton className={classes.tooltipIconButton}>
                  <InfoIcon />
                </IconButton>
              </Tooltip>
            </InputLabel>
            <Input
              disableUnderline
              id={`${nameShape}-company-input`}
              {...field}
              defaultValue={value}
              onChange={(e) => {
                const name = e.target.name;
                const value = e.target.value;
                setFieldValue(name, value);
              }}
            />
            {meta.touched && meta.error && (
              <FormHelperText>{meta.error}</FormHelperText>
            )}
          </FormControl>
        )}
      </Field>
      <SearchSelect
        nameShape={nameShape}
        getFieldName={getFieldName}
        isPersonal={isPersonal}
        isChangePhoneAfterCountryChange={isChangePhoneAfterCountryChange}
        setIsChangePhoneAfterCountryChange={setIsChangePhoneAfterCountryChange}
        outerIsLoadingValidateAddress={outerIsLoadingValidateAddress}
        setFieldValue={setFieldValue}
        setInnerSelectedCountry={setInnerSelectedCountry}
      />

      {renderField(
        getFieldName('address'),
        t('app.addressStreetHouse'),
        setFieldValue,
      )}
      {renderField(
        getFieldName('address2'),
        t('app.addressApartmentSuite'),
        setFieldValue,
      )}

      <Field name={getFieldName('city')}>
        {({ field: { value }, meta }: FieldProps) => (
          <Box pb={2}>
            <InputLabel shrink={false}>{t('app.city')}*</InputLabel>
            {!isLoadingLazyQueryGetCitiesByISO ? (
              <>
                <Autocomplete
                  disabled={outerIsLoadingValidateAddress}
                  disableClearable
                  options={cities}
                  freeSolo
                  loading={isLoadingLazyQueryGetCitiesByISO}
                  autoComplete={true}
                  loadingText={`${t('app.upload')}...`}
                  closeText={t('app.close')}
                  openText={t('app.open')}
                  clearText={t('app.clear')}
                  noOptionsText={t('app.noCities')}
                  defaultValue={value || ''}
                  onInputChange={(event, currentInputValue) => {
                    setFieldValue(getFieldName('city'), currentInputValue);
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'unique-city-identifier',
                      }}
                      InputProps={{ ...params.InputProps, type: 'search' }}
                    />
                  )}
                />
                {suggestion(getFieldName('city'), melissaSuggestions?.city)}
              </>
            ) : (
              <BoxCentered border={1} mb={2} p={2}>
                {value}
                <ShowLoadingText name={t('app.cities')} />
              </BoxCentered>
            )}

            {meta?.touched && !!meta?.error && (
              <FormHelperText style={{ color: COLORS.RED }}>
                {meta?.error}
              </FormHelperText>
            )}
          </Box>
        )}
      </Field>

      {renderField(getFieldName('state'), t('app.stateRegion'), setFieldValue)}

      {renderField(
        getFieldName('zipCode'),
        `${t('app.postalZipCode')}*`,
        setFieldValue,
      )}

      <FastField name={getFieldName('phone')}>
        {({ field, meta }: FieldProps) => (
          <FormControl className={classes.phoneInput}>
            <InputLabel
              shrink={false}
              htmlFor={`${nameShape}phone-input-${field.name}`}
            >
              {t('app.phone')}*
            </InputLabel>
            <PhoneInput
              id={`${nameShape}-phone-input-${field.name}`}
              defaultCountry={isPersonal ? 'RU' : 'US'}
              international
              {...field}
              value={field?.value || ''}
              onChange={(phone) => setFieldValue(getFieldName('phone'), phone)}
            />
            <Unless
              condition={
                !meta.touched || isPossiblePhoneNumber(field.value || '')
              }
            >
              <FormHelperText className={classes.phoneInputWarning}>
                {t('app.warningCheckNumber')}
              </FormHelperText>
            </Unless>
          </FormControl>
        )}
      </FastField>

      <Unless condition={!!isPersonal}>
        {renderField(
          getFieldName('email'),
          t('app.emailAddress'),
          setFieldValue,
        )}
      </Unless>
      <Unless condition={!!!isPersonal}>
        {renderField(getFieldName('email'), 'email*', setFieldValue, true)}
      </Unless>

      <Unless condition={!!isPersonal || !!isRestrict}>
        <FastField name={getFieldName('remark')}>
          {({ field }: FieldProps) => (
            <FormControl>
              <InputLabel shrink={false} htmlFor={`${nameShape}-remark-input`}>
                {t('app.noteAboutContact')}
              </InputLabel>
              <TextareaAutosize
                className={classes.textarea}
                id={`${nameShape}-remark-input`}
                rowsMin={3}
                {...field}
                disabled={outerIsLoadingValidateAddress}
              />
            </FormControl>
          )}
        </FastField>
      </Unless>

      {nameShape === 'receiver' && (
        <Button
          disabled={isLoadingValidateAddress || outerIsLoadingValidateAddress}
          variant='contained'
          type='button'
          onClick={() => {
            handleValidateAddress();
          }}
          startIcon={
            isLoadingValidateAddress || outerIsLoadingValidateAddress ? (
              <CircularProgress size={22} />
            ) : (
              <Spellcheck />
            )
          }
        >
          {outerIsMelissaValidated || isMelissaValidated
            ? t('app.checkAgain')
            : t('app.checkAddressCorrectness')}
        </Button>
      )}
    </>
  );

  if (nameShape && outerSetFieldValue) {
    const setFieldValueDebounce = debounce((name: string, value: string) => {
      outerSetFieldValue(name, value);
    }, DEBOUNCE);
    return getFields(setFieldValueDebounce);
  }

  const selectShema = (contactType?: string, isPersonal?: boolean) => {
    if (isPersonal) {
      if (contactType === 'sender' || nameShape === 'sender') {
        return formContactPersonalShema;
      }

      return formContactPersonalShemaRus;
    }
    return formContactNotPersonalShema;
  };

  return initialValues && onSubmit ? (
    <Formik
      innerRef={formikRef}
      initialValues={initialValues}
      enableReinitialize
      validationSchema={selectShema(contactType, isPersonal)}
      onSubmit={onSubmit}
    >
      {({ values, isSubmitting, setFieldValue }) => {
        const setFieldValueDebounce = debounce(
          (name: string, value: string) => {
            setFieldValue(name, value);
          },
          DEBOUNCE,
        );

        return (
          <Form noValidate autoComplete='off' className={classes.root}>
            {getFields(setFieldValueDebounce)}

            <Unless condition={!!isRestrict}>
              <Divider className={classes.dividerSmall} />

              <BoxCentered justifyContent='space-between'>
                <Button
                  disabled={
                    isSubmitting ||
                    isLoadingValidateAddress ||
                    outerIsLoadingValidateAddress
                  }
                  variant='contained'
                  type='submit'
                  startIcon={
                    isSubmitting ? <CircularProgress size={22} /> : <SaveIcon />
                  }
                >
                  {isPersonal || !isCreate ? t('app.save') : t('app.create')}
                </Button>

                <Button
                  disabled={
                    isSubmitting ||
                    isLoadingValidateAddress ||
                    outerIsLoadingValidateAddress
                  }
                  variant='contained'
                  type='button'
                  onClick={() => {
                    handleValidateAddress();
                  }}
                  startIcon={
                    isLoadingValidateAddress ||
                    outerIsLoadingValidateAddress ? (
                      <CircularProgress size={22} />
                    ) : (
                      <Spellcheck />
                    )
                  }
                >
                  {isMelissaValidated
                    ? t('app.checkAgain')
                    : t('app.checkAddressCorrectness')}
                </Button>
              </BoxCentered>
            </Unless>
          </Form>
        );
      }}
    </Formik>
  ) : (
    <BoxCentered border={1} p={1} color={COLORS.RED}>
      src/components/FormContact/FormContact.tsx{' '}
      {t('app.addressOnSubmitNotProvided')}!
    </BoxCentered>
  );
};

export default FormContact;
