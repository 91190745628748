import React from 'react';
import AddShoppingCartIcon from '@material-ui/icons/AddShoppingCart';
import SearchIcon from '@material-ui/icons/Search';
import AccountBalanceWalletIcon from '@material-ui/icons/AccountBalanceWallet';

import { TRoutes } from '../../../utils/helpers';
import SelectNextLink from '../../../components/SelectNextLink/SelectNextLink';
import iconCalculate from '../../../assets/img/icon_calculate.png';
import { useTranslation } from 'react-i18next';
import { TFunction } from 'i18next';

const links = (t: TFunction<'translation', undefined>) => [
  {
    id: 1,
    route: TRoutes.DELIVERY_CALCULATOR,
    icon: iconCalculate,
    text: t('app.calculateShippingCost'),
    disabled: false,
    external: false,
  },
  {
    id: 2,
    route: TRoutes.SHIPMENT_SELECT_TYPE_BEFORE_CREATE,
    icon: AddShoppingCartIcon,
    text: t('app.newOrder'),
    disabled: false,
    external: false,
  },
  {
    id: 3,
    route: TRoutes.TRACK,
    icon: SearchIcon,
    text: t('app.track').toUpperCase(),
    disabled: false,
    external: false,
  },
  {
    id: 4,
    route: TRoutes.ACCOUNT,
    icon: AccountBalanceWalletIcon,
    text: t('app.balance').toUpperCase(),
    disabled: false,
    external: false,
  },
];

export default function Main() {
  const { t } = useTranslation();
  return (
    <SelectNextLink title={t('app.home').toUpperCase()} links={links(t)} />
  );
}
