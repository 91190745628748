import {
  GetMyProductListQuery,
  OrderProduct,
} from './../../../../../generated/graphql';
import {
  PARCEL_FIELDS,
  EXPORT_REASONS,
  PAYMENT_OF_TAXES,
  VATKEY,
  CURRENCIES,
} from '../../../../../utils/constants';
import {
  getInitialPackage,
  getInitialUnit,
  getInitialUser,
} from '../../../../../utils/helpers';
import {
  ParcelContact,
  GetParcelQuery,
  GetCurrentUserQuery,
  GetOrderByIdQuery,
} from '../../../../../generated/graphql';

type createInitValueType = {
  dataLazyParcel: GetParcelQuery | undefined;
  currentUserData: GetCurrentUserQuery | undefined;
  urlSearchParams: URLSearchParams;
  allCountries: any;
  orderByIdData: GetOrderByIdQuery | undefined;
  cachedProducts: GetMyProductListQuery | null;
};
export const createInitValue = ({
  dataLazyParcel,
  urlSearchParams,
  currentUserData,
  allCountries,
  orderByIdData,
  cachedProducts,
}: createInitValueType) => {
  const currentUser = currentUserData?.currentUser;
  const order = orderByIdData?.getOrderById;
  const myProducts = cachedProducts?.getMyProductList?.rows;
  //@ts-ignore
  const productsSku = order?.orderProducts?.map((product: OrderProduct) => {
    return product.sku;
  });

  const marketPlaceProducts = myProducts?.filter(
    //@ts-ignore
    (product) => product?.sku && productsSku?.indexOf(product?.sku) !== -1,
  );

  const initialValues = dataLazyParcel
    ? {
        ...dataLazyParcel.parcel,
        intake_date: '',
        intake_time_from: '',
        intake_time_to: '',
        name: '',
        houseNumber: '',
        apartmentNumber: '',
        street: '',
        paymentOfTaxes:
          dataLazyParcel?.parcel?.paymentOfTaxes === 'CONSIGNEE'
            ? 'RECIPIENT'
            : dataLazyParcel?.parcel?.paymentOfTaxes,
        vatKey: dataLazyParcel?.parcel?.vatKey,
        vatValue: dataLazyParcel?.parcel?.vatID,
        typeDeliveryId: dataLazyParcel?.parcel?.typeDelivery?.id,
      }
    : {
        ...initialShipment,

        ...(order && {
          //@ts-ignore
          currency: order?.orderProducts[0]?.priceCurrency?.toLowerCase(),
        }),
        ...(order && {
          //@ts-ignore
          senderMark: order?.orderId,
        }),
        packages: !productsSku?.length
          ? [
              getInitialPackage(
                Number(urlSearchParams.get(PARCEL_FIELDS.PACKAGE.WEIGHT)),
                Number(urlSearchParams.get(PARCEL_FIELDS.PACKAGE.LENGTH)),
                Number(urlSearchParams.get(PARCEL_FIELDS.PACKAGE.WIDTH)),
                Number(urlSearchParams.get(PARCEL_FIELDS.PACKAGE.HEIGHT)),
              ),
            ]
          : [
              {
                ...getInitialPackage(
                  order?.package?.weight,
                  order?.package?.length,
                  order?.package?.width,
                  order?.package?.height,
                ),
                //@ts-ignore
                units: marketPlaceProducts.map((product: Product) => ({
                  ...getInitialUnit(),
                  code: product.code,
                  country: { ...product.country },
                  id: product.id,
                  name: product.name,
                  nameEN: product.detailsDescriptionEN,
                  nameRU: product.detailsDescriptionRU,
                  netWeight: product.netWeight,
                  price: product.declaredValue,
                  sku: product.sku,
                  state: product.state,
                  tradeMark: product.trademark,
                })),
              },
            ],
        sender: {
          address: currentUser?.address,
          address2: currentUser?.address2,
          address3: currentUser?.address3,
          city: currentUser?.city,
          company: currentUser?.company,
          country: currentUser?.country,
          email: currentUser?.email,
          eoriCode: currentUser?.eoriCode,
          id: currentUser?.id,
          //@ts-ignore
          innKppCode: currentUser?.innKppCode,
          name: currentUser?.name,
          phone: currentUser?.phone,
          //@ts-ignore
          remark: currentUser?.remark,
          state: currentUser?.state,
          //@ts-ignore
          type: currentUser?.type,
          zipCode: currentUser?.zipCode,
        },
        receiver: {
          ...(getInitialUser(
            urlSearchParams.get(PARCEL_FIELDS.RECEIVER.NAME),
            urlSearchParams.get(PARCEL_FIELDS.RECEIVER.COMPANY),
            urlSearchParams.get(PARCEL_FIELDS.RECEIVER.COUNTRY),
            urlSearchParams.get(PARCEL_FIELDS.RECEIVER.ADDRESS),
            urlSearchParams.get(PARCEL_FIELDS.RECEIVER.ADDRESS2),
            urlSearchParams.get(PARCEL_FIELDS.RECEIVER.ADDRESS3),
            urlSearchParams.get(PARCEL_FIELDS.RECEIVER.ZIP_CODE),
            urlSearchParams.get(PARCEL_FIELDS.RECEIVER.CITY),
            urlSearchParams.get(PARCEL_FIELDS.RECEIVER.STATE),
            urlSearchParams.get(PARCEL_FIELDS.RECEIVER.PHONE),
            urlSearchParams.get(PARCEL_FIELDS.RECEIVER.EMAIL),
          ) as ParcelContact),
          country: allCountries.find(
            (city: any) =>
              city.name === urlSearchParams.get(PARCEL_FIELDS.RECEIVER.COUNTRY),
          ),
        },
      };

  return initialValues;
};

const initialShipment = {
  packages: [getInitialPackage()],
  senderNotes: '',
  senderMark: '',
  typeDeliveryId: 1,
  additionalInsurance: false,
  signature: false,
  insuranceAmount: 0,
  currency: CURRENCIES.USD.toLowerCase(),
  intake_date: '',
  intake_time_from: '',
  intake_time_to: '',
  name: '',
  houseNumber: '',
  apartmentNumber: '',
  street: '',
  exportReason: EXPORT_REASONS.COMMERCIAL.en,
  paymentOfTaxes: PAYMENT_OF_TAXES.RECIPIENT.en,
  vatKey: VATKEY.EMPTY,
  vatValue: '',
};
