import { useState } from 'react';

export const useMelissa = () => {
  const [isMelissaValidated, setIsMelissaValidated] = useState(false);

  const [isLoadingValidateAddress, setIsLoadingValidateAddress] =
    useState(false);

  return {
    isMelissaValidated,
    setIsMelissaValidated,
    isLoadingValidateAddress,
    setIsLoadingValidateAddress,
  };
};
