import { useState } from 'react';

export const usePackage = () => {
  const [declaredAmount, setDeclaredAmount] = useState(0);
  const [declaredWeight, setDeclaredWeight] = useState(0);
  const [globalProductCode, setGlobalProductCode] = useState('');

  return {
    declaredAmount,
    setDeclaredAmount,
    declaredWeight,
    setDeclaredWeight,
    globalProductCode,
    setGlobalProductCode,
  };
};
